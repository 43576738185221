import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Observable, Subject, takeUntil } from 'rxjs';
import { EventType, MessageType } from '../createInteractiveVideo.interface';
import { createVideoEvent, createVideoMessage, createVideoStep } from '../createInteractiveVideo.data';
import { LocalStorageService } from 'src/app/services/localStorage.service';
import { DashboardService } from 'src/app/services/dashboard.service';
import { Router } from '@angular/router';
import { CrudService } from 'src/app/services/crud.service';
import { ToastrService } from 'ngx-toastr';
import { saveAs } from 'file-saver';
import { predefinedTopicsForAIVideoCreator } from 'src/app/tour-data';

declare var $;

@Component({
  selector: 'app-ai-video-creator-chat',
  templateUrl: './ai-video-creator-chat.component.html',
  styleUrls: ['./ai-video-creator-chat.component.css']
})
export class AiVideoCreatorChatComponent implements OnInit {

  @Input() upEvents: Observable<EventType>;
  @Output() event = new EventEmitter<EventType>();

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  active: boolean = false;
  msgStep = createVideoStep;
  msgMap = createVideoMessage;
  msgEvent = createVideoEvent;
  currentMessageStep = 0;
  messages: MessageType[] = [];
  msgValue = '00:00:00';
  isMessageLoad: boolean;
  spinner: boolean = false;
  userData: any;
  previousAITopic: string = '';
  previousAIResponse: string = '';
  currentSelectedNode: any;
  voiceModels = [];
  selectedVoiceModel: any;
  disabledDropDown: boolean = false;
  mainId: string = '';
  @ViewChild('audioPlayer') audioPlayer: any;
  @ViewChild('aiScriptAudioPlayer') aiScriptAudioPlayer: any;
  aiGeneratedScriptAudio;
  createInteractiveVideoObj: any = {
    currentObj: '',
  };
  hierarchyArr;
  inputDisable: boolean = false;
  predefinedTopics:any[] = [];

  constructor(
    private localStorageService: LocalStorageService,
    private dashboardService: DashboardService,
    private router: Router,
    private crudService: CrudService,
    private toastr: ToastrService
  ) { 
    this.predefinedTopics = predefinedTopicsForAIVideoCreator
  }

  ngOnInit(): void {
    this.getUserInfo();

    this.upEvents.pipe(takeUntil(this._unsubscribeAll)).subscribe((e) => {
      if (e.type === this.msgEvent.init) {
        this.active = true;
        this.clear();
        this.getBotAnswer('');
      } else if (e.type === this.msgEvent.flowNotCompleted) {
        this.active = true;
        this.clear();
        const botMessage = `Hi ${this.localStorageService.getItem('userName')}, I'm Lexie. Happy to assist you in creating a content tree!`;
        this.addMessage('bot', botMessage);
        
        if (e.data === this.msgStep.contentTreeCreated) {
          this.currentMessageStep = e.data;
          this.getBotAnswer('');
        } else if (e.data === this.msgStep.scriptAttached) {
          this.addMessage('bot', 'Scripts are successfully generated. You can click on the icon to view or update them.');
          this.currentMessageStep = e.data;
        } else if (e.data === this.msgStep.attachVideosToNodes) {
          this.loadVoiceModels();
          this.addMessage('bot', 'Scripts are successfully generated. You can click on the icon to view or update them.');
          this.addMessage('bot', 'Videos are successfully generated and attached successfully to each nodes.');
          this.currentMessageStep = e.data;
        } else if (e.data === this.msgStep.nodeSelectedForVoice) {
          this.addMessage('bot', 'Scripts are successfully generated. You can click on the icon to view or update them.');
          this.addMessage('bot', 'Videos are successfully generated and attached successfully to each nodes.');
          this.addMessage('bot', 'Voice model are also attached successfully');
          this.addMessage('bot', 'Click on the node which you\'d like to hear the audio playing for the script');
          this.currentMessageStep = this.msgStep.nodeSelectedForVoice;
          this.currentMessageStep = e.data;
        } else if (e.data?.event === this.msgEvent.scriptBeingCreated) {
          if (e?.data?.isScriptGenerated) {
            this.currentMessageStep = this.msgStep.scriptAttached;
            this.addMessage('bot', 'Scripts are successfully generated. You can click on the icon to view or update them.');
          } else {
            this.addMessage('bot', 'Looks like we have trouble with generating all scripts. Would you like me to try again?');
            this.currentMessageStep = this.msgStep.scriptNotGeneratedProperly;
          }
          this.scrollMessage();
        } else if (e.data.event === this.msgEvent.videoAttachedToAllChoice) {
          if (e?.data?.isVideoGenerated) {
            this.currentMessageStep = this.msgStep.attachVideosToNodes;
            this.addMessage('bot', 'Videos are successfully generated and attached successfully to each nodes.');
          } else {
            this.addMessage('bot', 'Looks like we have trouble with generating all videos. Would you like me to try again?');
            this.currentMessageStep = this.msgStep.videoNotGeneratedProperly;
          }
          this.scrollMessage();
        }
      } else if (this.active) {
        if (e.type === this.msgEvent.contentTreeCreated) {
          this.currentMessageStep = this.msgStep.contentTreeCreated;
          this.mainId = e.data ? e.data : '';
          this.getBotAnswer('');
        } else if (e.type === this.msgEvent.scriptBeingCreated) {
          if (e?.data) {
            this.currentMessageStep = this.msgStep.scriptAttached;
            this.addMessage('bot', 'Scripts are successfully generated. You can click on the icon to view or update them.');
          } else {
            this.addMessage('bot', 'Looks like we have trouble with generating all scripts. Would you like me to try again?');
            this.currentMessageStep = this.msgStep.scriptNotGeneratedProperly;
          }
          this.scrollMessage();
        } else if (e.type === this.msgEvent.saveStoryBoardName) {
            this.currentMessageStep = this.msgStep.voiceSettled;
            this.getBotAnswer(e?.data);
            this.scrollMessage();
        } else if (e.type === this.msgEvent.updateTheScenario) {
          this.currentMessageStep = this.msgStep.aiTopicCreated;
        } else if (e.type === this.msgEvent.nodeSelected) {
          if (this.currentMessageStep === this.msgStep.choicesTimeAdded) {
            this.currentMessageStep = this.msgStep.attachVideosToNodes;
          }
          if (this.currentMessageStep === this.msgStep.uploadStoryBoardVideo) {
            this.currentMessageStep = this.msgStep.seletedVideoForNode;
            this.currentSelectedNode = e.data;
            this.getBotAnswer(e.data.name, 'image', e.data?.poster);
          }
          if (this.currentMessageStep === this.msgStep.nodeSelectedForVoice) {
            if (this.createInteractiveVideoObj.currentObj.aiVoiceModel || this.selectedVoiceModel) {
              this.currentSelectedNode = e.data;
              this.currentMessageStep = this.msgStep.playAnotherScript;
              this.generateTTSForSelectedNode();
              this.scrollMessage();
            } else {
              this.currentMessageStep = this.msgStep.attachVideosToNodes;
            }
            return;
          }
          if (this.currentMessageStep === this.msgStep.saveTheVideo) {
            this.currentMessageStep = this.msgStep.videoSelectedForCombine;
            this.currentSelectedNode = e.data;
            this.getBotAnswer(e.data.name, 'image', e.data?.poster);
            return;
          }
          this.scrollMessage();
        } else if (e.type === this.msgEvent.close) {
          this.currentMessageStep = this.msgStep.choicesTimeAdded;
          this.getBotAnswer(e?.data?.name, 'image', e?.data?.poster);
        } else if (e.type === this.msgEvent.videoAttachedToAllChoice) {
          if (e?.data) {
            this.currentMessageStep = this.msgStep.attachVideosToNodes;
            this.addMessage('bot', 'Videos are successfully generated and attached successfully to each nodes.');
          } else {
            this.addMessage('bot', 'Looks like we have trouble with generating all videos. Would you like me to try again?');
            this.currentMessageStep = this.msgStep.videoNotGeneratedProperly;
          }
          this.scrollMessage();
        } else if (e.type === this.msgEvent.videoSelectedForCombine) {
          this.addMessage('bot', e.data);
          this.currentMessageStep = this.msgStep.complete;
          this.scrollMessage();
          // setTimeout(() => {
          //   this.router.navigate(['my-videos'], { queryParams: { currentTab: 'draft' } });
          // }, 2000);
        } else if (e.type === this.msgEvent.mainVideo) {
          this.createInteractiveVideoObj.currentObj = e?.data;
        } else {
          this.currentMessageStep = this.msgStep.choicesTimeAdded;
        }
      }
    });
  }

  addMessage = (author, content, type = 'text', url?) => {
    this.messages.push({author, content, type, url});
    $('#messagesCard').animate({scrollTop: $('#messagesCard .messages').height()}, 500);
  }

  scrollMessage() {
    $('#messagesCard').animate({scrollTop: $('#messagesCard .messages').height()}, 500);
  }

  onEnterKeyPressed(event: KeyboardEvent) {
    if (this.msgValue.trim() === '') {
      this.msgValue = '';
    } else {
      this.sendMessage();
    }
  }

  sendPreDefinedTopic(data:any){
    this.sendMessage(data?.value);
  }

  sendMessage(msg?: string) {
    if (msg) {
      this.msgValue = msg;
    }
    
    this.msgValue = this.msgValue.trim();
    if(this.msgValue === this.msgMap.cancel){
        this.currentMessageStep = this.msgStep.cancelNodeUpdate
        this.msgValue = this.msgMap.generateTextToSpeech
        this.addMessage('user', 'Cancel');
        this.scrollMessage();
        return
      }

    if (this.currentMessageStep === this.msgStep.setAITopic) {
      this.previousAITopic = this.msgValue;
      this.addMessage('user', this.msgValue);
      this.callChatGPTAPI(this.msgValue);
      return;
      } else if (this.msgValue === this.msgMap.contentTreeBasedOnTheScenarios) {
        this.addMessage('user', this.msgValue);
        this.createStoryBoard();
        return;
      } else if (this.currentMessageStep === this.msgStep.contentTreeCreated) {
        if (this.msgValue === this.msgMap.yes) {
          this.addMessage('user', this.msgValue);
          this.addMessage('bot', 'The scripts are being generated...')
          this.currentMessageStep = this.msgStep.scriptBeingCreated;
          this.createScriptForStoryBoardNodes();
          return;
        } else {
          this.currentMessageStep = this.msgStep.aiTopicCreated;
          this.scrollMessage();
        }
      } else if (this.currentMessageStep === this.msgStep.scriptNotGeneratedProperly) {
        if (this.msgValue === this.msgMap.yes) {
          this.addMessage('user', this.msgValue);
          this.addMessage('bot', 'The scripts are being generated...')
          this.currentMessageStep = this.msgStep.scriptBeingCreated;
          this.createScriptForStoryBoardNodes();
          return;
        } else {
          this.currentMessageStep = this.msgStep.scriptAttached;
          this.scrollMessage();
        }
      } else if (this.currentMessageStep === this.msgStep.videoNotGeneratedProperly) {
        if (this.msgValue === this.msgMap.yes) {
          this.currentMessageStep = null;
          this.addMessage('user', this.msgValue);
          this.addMessage('bot', 'Getting videos for all nodes...');
          this.scrollMessage();
          this.triggerEvent(this.msgEvent.getTheVideoForAllNodes, true);
          return;
        } else {
          this.currentMessageStep = this.msgStep.attachVideosToNodes;
          this.scrollMessage();
        }
      } else if (this.currentMessageStep === this.msgStep.scriptAttached || this.currentMessageStep === this.msgStep.attachVideosToNodes || this.currentMessageStep === this.msgStep.cancelNodeUpdate) {
        if (this.msgValue === this.msgMap.getTheVideoForAllNodes) {
          this.currentMessageStep = null;
          this.addMessage('user', this.msgValue);
          this.addMessage('bot', 'Getting videos for all nodes...');
          this.scrollMessage();
          this.triggerEvent(this.msgEvent.getTheVideoForAllNodes, true);
          return;
        }
        if (this.msgValue === this.msgMap.updateVideoOnNode) {
          this.currentMessageStep = this.msgStep.uploadStoryBoardVideo;
          this.triggerEvent(this.msgEvent.updateVideoOnNode, false);
        }
        if (this.msgValue === this.msgMap.generateTextToSpeech) {
          this.addMessage('user', this.msgValue);
          this.loadVoiceModels();
          this.currentMessageStep = this.msgStep.audioGenerated;
          this.addMessage('bot', 'Choose a voice :', 'dropdown');
          this.scrollMessage();
          return;
        }
      } else if (this.currentMessageStep === this.msgStep.saveStoryBoardName) {
        this.triggerEvent(this.msgEvent.saveStoryBoardName, {title: this.msgValue})
        return;
      } else if (this.msgValue === this.msgMap.createAnotherScenarioWithTheSameTopic) {
        this.currentMessageStep = null;
        let userPrompt = `${this.previousAIResponse}\n\n. Write a script in the same format for ${this.previousAITopic} that’s different than previous one.`;
        this.addMessage('user', this.msgValue);
        this.callChatGPTAPI(userPrompt);
        return;
      } else if (this.msgValue === this.msgMap.updateTheScenario) {
        this.inputDisable = false;
        this.currentMessageStep = null;
        this.addMessage('user', this.msgValue);
        this.createNestedArr();
        return;
      } else if (this.msgValue === this.msgMap.startOver) {
        this.messages = [];
        this.msgValue = '';
        this.currentMessageStep = null;
        this.getBotAnswer('');
      } else if (this.currentMessageStep === this.msgStep.seletedVideoForNode) {
        this.triggerEvent(this.msgEvent.addVideoForEmptyNode, {
          currentObj: this.currentSelectedNode,
          isAIContentTree: false
        });
        this.msgValue = '';
      } else if (this.msgValue === this.msgMap.generateAAudio) {
        this.currentMessageStep = this.msgStep.nodeSelectedForVoice;
      } else if (this.currentMessageStep === this.msgStep.audioGenerated) {
        if (this.msgValue === this.msgMap.goodWithVoice) {
          this.disabledDropDown = true;
          this.currentMessageStep = this.msgStep.nodeSelectedForVoice;
          let payload = {
            videoId: this.mainId,
            voiceModel: this.selectedVoiceModel.value
          }
          this.triggerEvent(this.msgEvent.setVoiceModelToNode, payload);
        }
      } else if (this.currentMessageStep === this.msgStep.voiceSettled) {
        if (this.msgValue === this.msgMap.saveTheVideo) {
          this.currentMessageStep = this.msgStep.saveTheVideo;
          this.triggerEvent(this.msgEvent.iAmGoodSaveTheTree, this.createInteractiveVideoObj.currentObj);
        }
        this.scrollMessage();
      } else if (this.msgValue === this.msgMap.playAnotherScript) {
        this.currentMessageStep = this.msgStep.nodeSelectedForVoice;
      } else if (this.msgValue === this.msgMap.iAmGoodSaveTheTree || this.msgValue === this.msgMap.generateTheVideo) {
        this.currentMessageStep = this.msgStep.saveTheStoryBoard;
      } else if (this.currentMessageStep === this.msgStep.complete) {
        this.router.navigate(['my-videos'], { queryParams: { currentTab: 'draft' } });
      } else if (this.currentMessageStep === this.msgStep.updateTheScenario) {
        this.mainId = null;
        this.inputDisable = true;
        this.saveTheUpdatedScenario();
        this.currentMessageStep = this.msgStep.aiTopicCreated;
        this.scrollMessage();
      }

    if (this.msgValue) {
      this.getBotAnswer(this.msgValue);
    }
    this.msgValue = '';
  }

  createNestedArr() {
    this.hierarchyArr = this.parseResponse(this.previousAIResponse);

    if (this.hierarchyArr) {
      this.addMessage('bot', 'Update a scenario :', 'UPDATE_SCENARIO');
      this.currentMessageStep = this.msgStep.updateTheScenario;
    }
  }

  parseResponse(response: string): any[] {
    // Split the response string by lines
    const lines = response.split('\n');
    
    const hierarchy = [];
    let currentLevel = -1;
    let currentParent = null;

    // Add level 0 description
    hierarchy.push({ level: 0, child: [{ title: lines[1].trim() }] });

    // Parse each line to construct the hierarchy
    for (let i = 1; i < lines.length; i++) {
      const line = lines[i];
      const levelMatch = line.match(/^\d+(\.\d+)*/); // Extract level number
      if (!levelMatch) continue; // Skip invalid lines

      const levelStr = levelMatch[0];
      const title = line.substring(levelStr.length).trim(); // Extract title
      const level = levelStr.split('.').length - 1; // Calculate level
      const label = levelStr.replace(/\./g, '.');

      // Check if level is increased
      if (level > currentLevel) {
        hierarchy.push({ level, child: [] });
        currentParent = hierarchy[hierarchy.length - 1];
      } else if (level < currentLevel) {
        // Move up in hierarchy if level is decreased
        for (let j = currentLevel; j > level; j--) {
          currentParent = currentParent.parent;
        }
      }

      // Add child to current parent
      currentParent?.child.push({ title, label });

      // Update current level
      currentLevel = level;
    }

    return hierarchy;
  }

  saveTheUpdatedScenario() {
    const results = this.formatData(this.hierarchyArr)
    this.previousAIResponse = results;
  }

  formatData(dataArray: any[]): string {
    let result = '';
    dataArray.forEach(item => {
      result += `Level ${item.level}:\n`;
      item.child.forEach(childItem => {
        result += `${childItem.label ? childItem.label + ' ' : ''}${childItem.title}\n`;
      });
    });
    return result;
  }

  getBotAnswer(userMessage: string, type = 'text', url = '') {

    if (userMessage) {
        this.addMessage('user', userMessage, type, url)
    }
    this.isMessageLoad = true;
    setTimeout(() => {
      /*output validation*/
      let botMessage = '';
      if (!this.messages.length) {
        this.currentMessageStep = this.msgStep.setAITopic;
        botMessage = `Hi ${this.localStorageService.getItem('userName')}, I'm Lexie. Happy to assist you in creating a multipath video! What’s the topic you’d like to create the content tree for?`;
        this.addMessage('bot', botMessage)
        this.getBotAnswer('');
        return;
      }
      if (this.currentMessageStep === this.msgStep.contentTreeCreated) {
        botMessage = 'Content tree created successfully! Would you like to create scripts for all choices? You can update the scripts afterwards.';
      } else if (this.currentMessageStep === this.msgStep.uploadStoryBoardVideo) {
        botMessage = 'Select the node that you’d like the video to be uploaded for'
      } else if (this.currentMessageStep === this.msgStep.saveTheStoryBoard) {
        this.currentMessageStep = this.msgStep.saveStoryBoardName;
        botMessage = `OK! You’d like to generate the video. What would the name be?`;
      } else if (this.currentMessageStep === this.msgStep.contentTreeNameCreator) {
        botMessage = `It’s combining the video and audio so it will take a while.`;
        setTimeout(() => {
          this.router.navigate(['my-videos'], { queryParams: { currentTab: 'draft' } });
        }, 2000);
      } else if (this.currentMessageStep === this.msgStep.nodeSelectedForVoice) {
        botMessage = `Click on the node which you'd like to hear the audio playing for the script`;
      }

      this.isMessageLoad = false;
      if (botMessage) {
        this.addMessage('bot', botMessage)
      }
    }, 1000);
  }

  loadVoiceModels() {
    this.crudService.getVoiceModels().subscribe((res) => {
      this.voiceModels = res.data;
      //console.log("this.voiceModels = ", this.voiceModels);
      this.voiceModels.forEach((ele) => {
        ele['audioUrl'] = `assets/voices/${ele.value}.mp3`
      })

      const isDefaultValue = this.voiceModels.find((i) => i.default === true)
      if(isDefaultValue) {
        this.selectedVoiceModel = isDefaultValue;
      }

    }, (err) => {
      this.voiceModels = [];
    })
  }

  onSelectVoiceModel(voiceModel) {
    this.selectedVoiceModel = voiceModel;
    this.audioPlayer?.nativeElement.pause();
    (this.audioPlayer).nativeElement.src = this.selectedVoiceModel.audioUrl;
    this.scrollMessage();
    this.triggerEvent(this.msgEvent.updateVideoOnNode, false);
  }

  generateTTSForSelectedNode() {
    let payload = {
      voiceModel: this.selectedVoiceModel?.value ? this.selectedVoiceModel?.value : this.createInteractiveVideoObj.currentObj?.aiVoiceModel,
      text: this.currentSelectedNode.aiGeneratedScript,
      videoId: this.currentSelectedNode._id,
      type: this.currentSelectedNode.type
    }

    //console.log("payload = ", payload);
    this.triggerEvent(this.msgEvent.mergingVideoAndAudio, {currentNode: this.currentSelectedNode, payload: payload});
  }


  encodeToBase64(str: string): string {
    const utf8String = unescape(encodeURIComponent(str));
    return btoa(utf8String);
  }

  createScriptForStoryBoardNodes() {
    this.triggerEvent(this.msgEvent.scriptBeingCreated, '');
  }

  createStoryBoard() {
    //this.triggerEvent(this.msgEvent.createContentTreeBasedOnScenario, this.previousAIResponse);
    if (this.previousAIResponse) {
      if (this.mainId) {
        this.triggerEvent(this.msgEvent.createContentTreeBasedOnScenario, 
          {
            "scenarios": this.previousAIResponse,
            "videoId": this.mainId
          });
      } else {
        const obj = [{
          level: 0,
          parentId: null,
          name: this.hierarchyArr && this.hierarchyArr.length && this.hierarchyArr[0]?.child[0]?.title ? this.hierarchyArr[0]?.child[0]?.title : ''
        }];

        this.crudService.createEmptyNodes(obj).subscribe((success) => {
          this.mainId = success.data._id;
          this.triggerEvent(this.msgEvent.createContentTreeBasedOnScenario, 
            {
              "scenarios": this.previousAIResponse,
              "videoId": this.mainId
            }
          );
        })
      }
    } else {
      this.msgValue = '';
      this.addMessage('bot', 'What’s the topic you’d like to create the content tree for?');
      this.currentMessageStep = this.msgStep.setAITopic;
      this.scrollMessage();
    }
  }

  callChatGPTAPI(msgValue: string) {
    this.spinner = true;

    const obj = [{
      level: 0,
      parentId: null,
      name: msgValue
    }];

    //console.log("createEmptyNodes - payload = ", obj);
    
    this.crudService.createEmptyNodes(obj).subscribe((success) => {

      this.mainId = success.data._id;

      let payload;
      payload = {
        "assistantType" : "ScenarioWriter",
        "userPrompt" : msgValue,
        "videoId" : this.mainId
      };

      // if (this.currentSelectedNode) {
      //   payload['videoId'] = this.mainId ? this.mainId : '';
      // }

      //console.log("createLexieChat - payload = ", payload);

      this.crudService.createLexieChat(payload).subscribe((res: any) => {
        if(res) {
          //console.log("callChatGPT - res = ", res);

          const responseMessage = res.data.choices[0].message.content;
          this.previousAIResponse = responseMessage;
          this.addMessage('bot', responseMessage);
          this.spinner = false;
          this.currentMessageStep = this.msgStep.aiTopicCreated;
        }
      }, (err) => {
        this.toastr.error('Error!');
        this.spinner = false;
      })
    });
  }

  getUserInfo() {
    this.dashboardService.getUserData.subscribe((res) => {
      if (res) {
        this.userData = res;
      }
    });
  }

  cancel() {
    this.clear();
    this.active = false;
    this.triggerEvent(this.msgEvent.close);
  }

  triggerEvent(type, data?) {
    this.event.emit({type, data});
  }

  clear() {
    this.msgValue = '';
    this.messages = [];
    this.currentMessageStep = this.msgStep.welcome;
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

}
