import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {DeleteConfirmationComponent} from '../delete-confirmation/delete-confirmation.component';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {JsConfettiService} from '../../../services/js-confetti.service';
import {ToastrService} from 'ngx-toastr';
import {CrudService} from '../../../services/crud.service';

@Component({
    selector: 'app-create-questionnaire',
    templateUrl: './create-questionnaire.component.html',
    styleUrls: ['./create-questionnaire.component.css']
})
export class CreateQuestionnaireComponent implements OnInit {

    @ViewChild('deleteDialogRef') deleteDialogRef: DeleteConfirmationComponent;
    @ViewChild('emojiInputElement') emojiInputElement: ElementRef;
    defaultUserMessage = 'Congratulations! You have earned a questionnaire!';
    processing = false;
    questionnaire: any;
    questionnaireForm = new FormGroup({
        name: new FormControl('', [Validators.required, Validators.maxLength(50)]),
        description: new FormControl('', [Validators.required, Validators.maxLength(200)]),
        passingScore: new FormControl('', [Validators.required, Validators.min(0), Validators.max(100)]),
        isShowCorrectAnswer: new FormControl(false),
        questions: new FormArray([]),
    });
    maxQuestion = 50;
    maxAnswer = 5;
    currentIndex = 0;
    currentQuestionType: string

    private isFormSubmitted = false;

    get questions(): FormArray {
        return this.questionnaireForm.controls.questions as FormArray;
    }

    constructor(
        @Inject(MAT_DIALOG_DATA) public data,
        private jsConfettiService: JsConfettiService,
        private toaster: ToastrService,
        private fb: FormBuilder,
        private crudService: CrudService,
        private dialogRef: MatDialogRef<CreateQuestionnaireComponent>
    ) {
        if (data.questionnaire) {
            this.questionnaire = data.questionnaire;
        }
    }

    ngOnInit(): void {
        if (this.questionnaire) {
            this.initQuestionnaire();
        } else {
            this.addQuestion();
        }
    }

    initQuestionnaire() {
        this.questionnaireForm.patchValue({
            name: this.questionnaire.name,
            description: this.questionnaire.description,
            passingScore: this.questionnaire.passingScore,
            isShowCorrectAnswer: this.questionnaire.isShowCorrectAnswer
        })
        for (const question of this.questionnaire.questions) {
            const answerControl = []
            for (const answer of question.answers) {
                answerControl.push(this.fb.group({
                    answerText: this.fb.control(answer.answerText, [Validators.required, Validators.maxLength(200)]),
                    isCorrectAnswer: this.fb.control(answer.isCorrectAnswer)
                }))
            }
            const questionType = (question?.questionType === 'FreeForm' || question?.questionType === 'freeForm') ? 'freeForm' : 'multipleChoice';
            const questionControl = this.fb.group({
                questionText: [question.questionText, [Validators.required, Validators.maxLength(200)]],
                answers: new FormArray(answerControl),
                questionType: [questionType, [Validators.required]]
            });

            this.questions.push(questionControl);
        }
    }

    addQuestion(value = '') {
        const answerControl = this.fb.group({
            answerText: this.fb.control('', [Validators.required, Validators.maxLength(200)]),
            isCorrectAnswer: this.fb.control(true)
        });
        const questionControl = this.fb.group({
            questionType: ['multipleChoice', [Validators.required]],
            questionText: [value, [Validators.required, Validators.maxLength(200)]],
            answers: new FormArray([answerControl]),
        })
        this.questions.push(questionControl);
        this.currentIndex = this.questions.length - 1;
    }

    addAnswer(question: FormGroup) {
        const answerControl = this.fb.group({
            answerText: this.fb.control('', [Validators.required, Validators.maxLength(200)]),
            isCorrectAnswer: this.fb.control(false)
        });
        const answers = question.get('answers') as FormArray;
        answers.push(answerControl);
    }

    removeQuestion(i: number) {
        this.questions.removeAt(i);
        if (this.currentIndex > this.questions.length - 1) {
            this.currentIndex = this.questions.length - 1;
        }
    }

    removeAnswer(question, i: number) {
        const isCorrectAnswer = question.get('answers').controls[i].get('isCorrectAnswer').value;
        question.get('answers').removeAt(i);
        if (isCorrectAnswer) {
            question.get('answers').controls[0].patchValue({
                isCorrectAnswer: true
            });
        }
    }

    setCorrect(question: any, j: number) {
        let i = 0;
        for (const answers of question.get('answers').controls) {
            answers.patchValue({
                answerText: answers.controls.answerText.value,
                isCorrectAnswer: j === i
            });
            i++;
        }
    }

    applyQuestionType(event, questionIndex) {
        this.currentQuestionType = event.target.value;
        if(event.target.value === 'freeForm') {
            const questionControl = this.questions.at(questionIndex) as FormGroup;
            const answersArray = questionControl.get('answers') as FormArray;
            answersArray.clear();
        } else {
            const questionControl = this.questions.at(questionIndex) as FormGroup;
            const answersArray = questionControl.get('answers') as FormArray;
            if(this.questionnaire.questions[questionIndex].answers.length > 0) {
                for (const key of this.questionnaire.questions[questionIndex].answers) {
                    const answerControl = this.fb.group({
                        answerText: this.fb.control(key.answerText, [Validators.required, Validators.maxLength(200)]),
                        isCorrectAnswer: this.fb.control(key.isCorrectAnswer)
                    });
                    answersArray.push(answerControl);
                }
            } else {
                const answerControl = this.fb.group({
                    answerText: this.fb.control('', [Validators.required, Validators.maxLength(200)]),
                    isCorrectAnswer: this.fb.control(true)
                });
                answersArray.push(answerControl)
            }
        }
    }

    onSubmit() {
        this.isFormSubmitted = true;
        if (this.questionnaireForm.valid) {
            this.processing = true;
            const obj = this.questionnaireForm.value;
            if (this.questionnaire) {
                obj.id = this.questionnaire._id;
            }
            // console.log(obj)
            this.crudService.setQuestionnaire(obj).subscribe(res => {
                this.processing = false;
                this.toaster.success(`Questionnaire ${this.questionnaire ? 'updated' : 'created'} successfully !!`);
                this.dialogRef.close(true);
            });
        }
    }


    openConfirmationDeleteQuestionnaire(item: any) {
        this.deleteDialogRef.open({
            header: `Delete Questionnaire`,
            title: `Are you sure you want to delete this questionnaire?`
        }, (e) => {
            if (e) {
                this.processing = true;
                this.crudService.deleteQuestionnaire(item._id).subscribe((success) => {
                    this.processing = false;
                    this.toaster.success(`Questionnaire deleted successfully !!`);
                    this.dialogRef.close(true);
                });
            }
        });
    }

    errorHandling = (control: string | number, error: string, form: any = this.questionnaireForm) => {
        if (form) {
            return form.controls[control].hasError(error) &&
                (form.controls[control].dirty || form.controls[control].touched || this.isFormSubmitted);
        }
    }
}
