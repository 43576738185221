import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {BehaviorSubject, Subscription} from 'rxjs';

import {debounceTime, distinctUntilChanged, filter, map, tap,} from 'rxjs/operators';
import {DashboardService} from '../../services/dashboard.service';
import {CrudService} from '../../services/crud.service';
import {LocalStorageService} from '../../services/localStorage.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-new-header',
  templateUrl: './new-header.component.html',
  styleUrls: ['./new-header.component.css'],
})
export class NewHeaderComponent implements OnInit, OnDestroy, AfterViewInit {
  subscription: Subscription;
  notifications: any[] = [];
  searchOnGroup: any[] = [];
  searchbygroup: any[] = [];
  searchbyuser: any[] = [];
  searchdata: any[] = [];
  @ViewChild('dropdownContent') dropdownContent: ElementRef;
  @ViewChild('myInput') myInput: ElementRef;
  @Input() headerWithProfileIcon = false;
  @Input() headerWithLocalSearch = false;
  @Output() searchValue = new EventEmitter<string>();
  searchChange$ = new BehaviorSubject('');
  isSearching = false;
  userData: any;
  isLoggedIN = false;
  noOfShoppingCartItems = 0;
  noOfShoppingCarts = 0;
  shoppingCarts: any;
  isSearchOpen = false;
  groupDetails: any;
  isVideoPage = false;
  isLibraryPage = false;
  activePage = '';
  searchOnPage = {
    groupPage: false,
    myVideoPage: false,
    libraryPage: false,
  }
  isDisplaySearchInput:boolean = false;
  @Input() searchTerm = '';
  toggleRender: any;


  get searchPlaceholder() {
    if (this.searchOnPage.groupPage) {
      return 'Search in ' + (this.groupDetails.groupName || 'group');
    }
    if (this.searchOnPage.myVideoPage && this.isVideoPage) {
      return 'Search my own videos';
    }
    return 'Search';
  }

  constructor(
      private readonly router: Router,
      private readonly crudService: CrudService,
      private readonly dashboardService: DashboardService,
      private readonly localStorageService: LocalStorageService,
      private activeRoute: ActivatedRoute,
      private toaster: ToastrService
  ) {
    this.isDisplaySearchInput = ['/login', '/signup', '/forgotPassword', '/resetPassword'].includes(this.router.url)
  }

  ngOnInit(): void {
    this.isVideoPage = this.router.url === '/my-videos';
    this.isLibraryPage = this.router.url === '/library';

    this.activeRoute.queryParams.subscribe(params => {
      this.searchTerm = params.search;
      this.toggleRender = params.render;
    });

    if (this.localStorageService.getItem('user') && this.localStorageService.getItem('token')) {
      this.isLoggedIN = true;
    }

    this.dashboardService.getGroupDetails.subscribe((res) => {
      this.groupDetails = res;
      if (!this.groupDetails) {
        this.searchOnPage.groupPage = false;
      }
    });
    this.dashboardService.getActivePage.subscribe((res) => {
      this.activePage = res;
    });

    if (this.isLoggedIN) {
      this.subscription = this.crudService.notifications.subscribe((res) => {
        // alert(res)
        this.getNotificationData();
      });

      this.subscription = this.dashboardService.getShoppingCarts.subscribe((res) => {

        // console.log("new-header --- dashboardService.getShoppingCarts = ", res);

        if (res && res.length) {
          this.shoppingCarts = res;
          this.noOfShoppingCarts = res.length;
          // console.log("this.noOfShoppingCarts = ", this.noOfShoppingCarts);
        }
      });

      // console.log("this.headerWithProfileIcon = ", this.headerWithProfileIcon);

      if (this.headerWithProfileIcon) {
        // console.log("getting getUserData");

        this.subscription = this.dashboardService.getUserData.subscribe((res) => {
          if (res) {
            this.userData = res;
            // console.log("this.userData = ", this.userData);
          }
        });
      }
    }
  }

  viewNotification(id: any) {
    for (const i of this.notifications) {
      if (id === i.notificationId) {
        if (i.notificationType === 'follow') {
          this.router.navigate(['/public-profile'], {queryParams: {user: i.userInfo._id}});
        } else if (i.notificationType === 'contributor') {
          this.router.navigate(['/create-interactive-video'], {queryParams: {video: i.videoInfo._id}});
        } else if (i.notificationType === 'publishVideo') {
          this.router.navigate(['/videos'], {queryParams: {video: i.videoInfo._id}});
        } else if (i.notificationType === 'Comment') {
          this.router.navigate(['/videos'], {queryParams: {video: i.videoInfo._id}});
        } else if (i.notificationType === 'Like') {
          this.router.navigate(['/videos'], {queryParams: {video: i.videoInfo._id}});
        } else if (i.notificationType === 'collab') {
          const queryParams: any = {
            video: i.videoInfo?.childVideoId ? i.videoInfo?.childVideoId : i.videoInfo._id,
            comment: i.category.id,
            requester: i.category.userID,
          }
          if (!this.toggleRender) {
            queryParams.render = true;
          }
          this.router.navigate(['/my-group', i.category.activityTypeID], {queryParams});
        } else if (i.notificationType === 'mainCommunityCollab') {
          const queryParams: any = {
            video: i.videoInfo?.childVideoId ? i.videoInfo?.childVideoId : i.videoInfo._id,
            comment: i.category.id,
            requester: i.category.userID,
          }
          if (!this.toggleRender) {
            queryParams.render = true;
          }
          if (i.sellerInfo?.business?.communityUrl) {
            // this.router.navigate(['/main-community', i.sellerInfo?.business?.communityUrl], {queryParams});
            this.router.navigate(['/my-community', i.sellerInfo?.business?.communityUrl], {queryParams});
          } else {
            queryParams.user = i.sellerInfo._id;
            // this.router.navigate(['/main-community'], {queryParams});
            this.router.navigate(['/my-community'], {queryParams});
          }
        } else if (i.notificationType === 'videoPlayerCollab') {
          const queryParams: any = {
            video: i?.mainVideoInfo?._id,
            collabVideo: i.videoInfo?.childVideoId ? i.videoInfo?.childVideoId : i.videoInfo._id,
            comment: i.category.id,
            requester: i.category.userID,
          }
          if (!this.toggleRender) {
            queryParams.render = true;
          }
          this.router.navigate(['/videos'], {queryParams});
        } //WC 08082023 -- Adding video player collab respons
        else if (i.notificationType === 'videoPlayerCollabResponse') {
          const queryParams: any = {
            video: i?.videoInfo?._id,
          }
          if (!this.toggleRender) {
            queryParams.render = true;
          }

          // console.log("navigating with queryParams = ", queryParams);
          this.router.navigate(['/videos'], {queryParams});
        } else if (i.notificationType === 'mainCommunityShare') {
          if (i.sellerInfo?.business?.communityUrl) {
            this.router.navigate(['/my-community', i.sellerInfo?.business?.communityUrl]);
          } else {
            const queryParams = {user: i.sellerInfo._id};
            this.router.navigate(['/my-community'], {queryParams});
          }
        } else {
          this.router.navigate(['/videos'], {queryParams: {video: i.videoInfo._id}});
        }
      }
    }
  }

  checkout() {
    if (this.router.url.indexOf('/checkout') !== -1 || this.router.url.indexOf('/community-checkout') !== -1) {
      window.location.reload()
    } else {
      // Check if we have only FreeFuse shopping cart which is the shopping cart with no userId
      if (this.shoppingCarts.length == 1 && (this.shoppingCarts[0].groupId == undefined)) {
        this.localStorageService.setItem('shoppingCartId', this.shoppingCarts[0]._id);
        this.router.navigate(['checkout'], {queryParams: {subscription_clientSecret: this.localStorageService.getItem('clientSecret')}});
      } else
        this.router.navigate(['community-checkout']);
    }

  }

  onClickMenu() {
    this.dashboardService.onClickMobileMenu(true);
  }

  onSearchValue(event: any) {
    let value = event.target.value;
    value = value.trim();

    //WC -- To prevent chars which will cause any issue with MongoDB
    const regex = /^[a-zA-Z0-9 ]*$/; // Allows letters, numbers, spaces
    if (!regex.test(value)) {
      this.toaster.error('Invalid input detected. Please enter only letters, numbers, and spaces');
      return;
    }

    // tslint:disable-next-line: no-string-literal
    // this.searchChange$.next(event.target.value);
    // if (!this.headerWithLocalSearch) {
    //   this.searchChange$.next(value);
    // } else {
    //   this.dashboardService.setSearchTerm = value;
    //   this.searchValue.emit(value);
    // }

    this.gotoSearch(value);
  }

  @HostListener('document:click', ['$event.target'])
  onClick(targetElement) {
    try {
      const clickedInside = this.myInput.nativeElement.contains(targetElement);
      if (!clickedInside) {
        this.onBlurInput();
      }
    } catch (err) {

    }
  }

  ngAfterViewInit() {
    try {

      this.searchChange$
          .asObservable()
          .pipe(
              filter(Boolean),
              debounceTime(1500),
              distinctUntilChanged(),
              tap((event) => {
                this.onSearchItem(event);
              })
          )
          .subscribe();
      // this.dropdownContent.nativeElement.onclick = () => console.log('clicked');
    } catch (err) {
      // search component is hidden
    }
  }

  toggleIsSearchGroupPage(i) {
    this.searchOnPage.groupPage = i;
    this.onSearchItem(this.searchTerm);
  }

  toggleIsSearchVideoPage(i) {
    this.searchOnPage.myVideoPage = i;
    this.onSearchItem(this.searchTerm);
  }

  toggleIsSearchLibraryPage(i) {
    this.searchOnPage.libraryPage = i;
    if (i) {
      this.searchbygroup = [];
      this.searchbyuser = [];
      this.searchdata = [];
      this.searchOnGroup = [];
      this.headerWithLocalSearch = true;
      this.dashboardService.setSearchTerm = this.searchTerm;
    } else {
      this.headerWithLocalSearch = false;
      this.onSearchItem(this.searchTerm);
      this.dashboardService.setSearchTerm = '';
    }
  }

  onSearchItem(text: any) {
    this.isSearching = true;
    this.searchbygroup = [];
    this.searchbyuser = [];
    this.searchdata = [];
    this.searchOnGroup = [];
    if (this.groupDetails && this.searchOnPage.groupPage) {
      this.subscription = this.crudService.searchGroupNode(text, this.groupDetails._id).subscribe(
          (response) => {
            this.isSearching = false;
            this.searchOnGroup = response.data;
          },
          (error) => (this.isSearching = false)
      );
    } else {
      const params: any = {
        word: text,
        limit: 3,
        skip: 0,
        searchType: 'all',
      }
      if (this.isVideoPage && this.searchOnPage.myVideoPage) {
        params.searchType = 'ownVideos';
        if (this.activePage === 'draft') {
          params.isPublished = false;
        }
      }
      this.subscription = this.crudService.searchVideo(params).subscribe(
          (response) => {
            this.isSearching = false;
            this.searchbygroup = response.data.result.searchbygroup || [];
            this.searchbyuser = response.data.result.searchbyuser || [];
            this.searchdata = response.data.result.searchdata || [];
          },
          (error) => (this.isSearching = false)
      );
    }
  }

  gotoUser(id: any) {
    this.onBlurInput();
    // console.log('gotoUser');
    this.router.navigate(['public-profile'], {queryParams: {user: id}});
  }

  gotoGroup(id: any) {
    this.onBlurInput();
    if (!this.localStorageService.getItem('token')) {
      this.router.navigate(['login']);
      this.localStorageService.setItem('grpId', id);
    } else {
      this.router.navigate(['/my-group/' + id]);
    }
  }

  gotoSearch(search: any) {
    this.onBlurInput();
    this.router.navigate(['/search-results'], {queryParams: {search}});
  }

  gotoVideo(id: any) {
    this.onBlurInput();
    this.router.navigate(['/videos'], {queryParams: {video: id}});
  }

  onFocusInput() {
    this.dropdownContent.nativeElement.style.display = 'none';
  }

  onBlurInput() {
    setTimeout(() => {
      this.dropdownContent.nativeElement.style.display = 'none';
    }, 100);
  }

  onClickCreateNewVideos() {
    this.router.navigate(['/upload-videos']);
  }

  viewAllNotifications() {
    this.router.navigate(['/notifications']);
  }

  getNotificationData() {
    const type: any = 'notViewed';
    this.subscription = this.crudService
      .getNotificationsV2(type, 6, 1)
      .pipe(
        map((notifications) => {
          if (!notifications.data || !Array.isArray(notifications.data)) {
            return {notification: []};
          }
          return {
            notification: notifications.data.map((noti) => {
              return {
                show: this.getNotificationType(noti),
                ...noti,
              };
            }),
          };
        })
      )
      .subscribe((response) => {
        this.notifications = response.notification;
      });
  }

  getNotificationType(noti) {
    const userName = noti?.userInfo?.username;
    const videoInfo = noti?.videoInfo?.Title ? noti.videoInfo.Title : (noti?.videoInfo?.name ? noti?.videoInfo?.name : 'new video');
    let returnString = ``;
    const notificationType = noti.notificationType;
    if (notificationType === 'choice' && noti.videoInfo) {
      returnString = `${userName} chose ${noti.videoInfo.childInfo} on ${videoInfo}`;
    } else if (notificationType === 'Emphasised') {
      returnString = `${userName} emphasized on ${videoInfo}`;
    } else if (notificationType === 'Question') {
      returnString = `${userName} questioned on ${videoInfo}`;
    } else if (notificationType === 'Comment') {
      returnString = `${userName} commented on ${videoInfo}`;
    } else if (notificationType === 'Like') {
      returnString = `${userName} reacted on ${videoInfo}`;
    } else if (notificationType === 'Share') {
      returnString = `${userName} shared ${videoInfo}`;
    } else if (notificationType === 'follow') {
      returnString = `${userName} started following you`;
    } else if (notificationType === 'publishVideo') {
      returnString = `${userName} published ${videoInfo}`;
    } else if (notificationType === 'contributor') {
      returnString = `${userName} added you as a contributor for ${videoInfo}`;
    } else if (notificationType === 'remove contributor') {
      returnString = `${userName} removed you as a contributor for ${videoInfo}`;
    } else if (notificationType === 'collab') {
      returnString = `${userName} requested you to respond to ${videoInfo}`
    } else if (notificationType === 'mainCommunityCollab') {
      returnString = `${userName} requested you to respond to ${videoInfo}`
    } else if (notificationType === 'videoPlayerCollab') {
      const mainVideoInfo = noti?.mainVideoInfo?.Title ? noti.mainVideoInfo.Title : 'new video';
      returnString = `${userName} requested you to respond to video collab in ${videoInfo}`
    } else if (notificationType === 'videoPlayerCollabResponse') {
      const mainVideoInfo = noti?.mainVideoInfo?.Title ? noti.mainVideoInfo.Title : 'new video';
      returnString = `${userName} has responded to your video collab request in ${videoInfo}`
    } else if (notificationType === 'mainCommunityShare') {
      const sellerName = noti?.sellerInfo ? (noti?.sellerInfo?.firstname + ' ' + noti?.sellerInfo?.lastname) : 'community';
      const sellerInfo = noti?.sellerInfo?.business?.name ? noti?.sellerInfo?.business?.name : sellerName;
      returnString = `${userName} is inviting you to join ${sellerInfo}`
    } else if (notificationType === 'transferVideoOwnership') {
      returnString = `${userName} transferred ownership of ${videoInfo} to you`
    }
    return returnString;
  }

  redirect() {

    if (this.localStorageService.getItem('isSubscriber') === 'true') {
      if (this.localStorageService.getItem('interactive') === 'isTemplate') {
        this.localStorageService.setItem('interactive', 'isInteractive')
        location.reload();
      } else {
        this.router.navigate(['upload-videos']);
      }
    } else {
      if (this.userData?.planName === null || this.userData?.planName === undefined) {
        document.getElementById('becomeCreator').click();
      } else {
        this.router.navigate(['pricing']);
      }
    }

  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  onClickSearch() {
      this.isSearchOpen = !this.isSearchOpen;
  }
}
