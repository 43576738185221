import {Component, OnInit} from '@angular/core';
import {CrudService} from '../../services/crud.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {Location} from '@angular/common';

declare var $;

@Component({
  selector: 'categoryWiseVideo',
  templateUrl: './categoryWiseVideo.component.html',
  styleUrls: ['./categoryWiseVideo.component.css']
})
export class CategoryWiseVideoComponent implements OnInit {

  public mainVideoList: Array<any> = [];
  public currentCat: any;
  public spinner: any = false;
  public val: any = 'recent';
  totalCount:number;
  page:number = 1;
  sort:any = 'recent';

  constructor(public urlService: CrudService,
              private location: Location,
              public toastr: ToastrService,
              public router: Router,
              public activeRoute: ActivatedRoute
  ) {
    this.activeRoute.queryParams.subscribe(params => {
      this.spinner = true;
      if (params.category) {
        this.currentCat = decodeURI(params.category);
        // this.getMainVideos('recent');
        this.getVideosByCategoryCount();
      } else {
        this.router.navigate(['profile'])
      }
    })
  }
 
  ngOnInit() {


  }

  getVideosByCategoryCount(){
    const payLoad: any = {category: [this.currentCat]}
    this.urlService.getVideosByCategoryCount(payLoad).subscribe(success => {
      this.totalCount = success.data.totalCount;
      this.getMainVideos('recent');
    });
  }

  // Get all main videos
  getMainVideos(sortValue?:any) {
    this.spinner = true;
    if(!sortValue){
      this.sort = 'recent'
      this.val = 'recent'
    }else{
        this.sort = sortValue
    }

    const payLoad: any = {category: [this.currentCat], sortValue: this.sort, page: this.page, limit: 12}
    this.urlService.userCategoryWiseVideosV2(payLoad).subscribe(success => {
      // this.mainVideoList = success.data;
      // this.mainVideoList = this.mainVideoList.reverse();
      let videos = success.data;
      for (const item of videos) {
          this.mainVideoList.push(item);
      }
      this.spinner = false;
    }, error => {
      // this.getMainVideos();
    });
  }

  getVideos(){
    console.log("Called")
    ++this.page;
    this.getMainVideos(this.sort);
}

  showVideo(id: any) {
    this.router.navigate(['videos'], {queryParams: {video: id}});
  }

  showUser(id: any) {
    this.router.navigate(['public-profile'], {queryParams: {user: id}});
  }

  showCategory(id: any) {
    this.mainVideoList = [];
    this.totalCount = null;
    this.page = 1;
    this.router.navigate(['category'], {queryParams: {category: id}});
  }


  changeFilter(val: any) {
    this.sort = val;
    this.page = 1
    this.mainVideoList = [];

    this.getMainVideos(val)
    return
    if (val === 'recent') {
      this.mainVideoList.sort((a, b) => {
        if (a.publishTime > b.publishTime) {
          return -1;
        }
        return 1
      })
    } else if (val === 'viewed') {
      this.mainVideoList.sort((a, b) => {
        if (a.viewCount > b.viewCount) {
          return -1;
        }
        return 1
      })
    } else if (val === 'interactive') {
      this.mainVideoList.sort((a, b) => {
        if (a.totalchild > b.totalchild) {
          return -1;
        }
        return 1
      })

    } else {
      this.mainVideoList.sort((a, b) => {
        if (a.comments?.length > b.comments?.length) {
          return -1;
        }
        return 1
      })
    }

  }

  goBack() {
    if ((this.location.getState() as any)?.navigationId > 1)
      this.location.back();
    else
      this.router.navigateByUrl('/my-videos');
  }


}
