import {Component, Inject, OnInit} from '@angular/core';
import {CrudService} from '../../../services/crud.service';
import {ToastrService} from 'ngx-toastr';
import {AbstractControl, FormArray, FormBuilder, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-add-update-plan',
    templateUrl: './add-update-plan.component.html',
    styleUrls: ['./add-update-plan.component.css']
})
export class AddUpdatePlanComponent implements OnInit {
    spinner = false;
    step = 1;
    maxFeatures = 7;
    isUpdate: any;
    isFormSubmitted = false;
    featuresForm: any;
    createGroupForm: any;
    planCounts: any;
    groups = [];
    constructor(private api: CrudService,
                private fb: FormBuilder,
                private toaster: ToastrService,
                public dialog: MatDialogRef<AddUpdatePlanComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        this.isUpdate = data.plan;
        this.planCounts = data.planCounts;
    }


    ngOnInit(): void {
        this.initForm();
    }

    get plansControls() {
        return this.createGroupForm.controls;
    }

    get features(): FormArray {
        return this.featuresForm.controls.features as FormArray;
    }

    isPopularExistValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            let flag = false;
            if (control.value) {
                if (this.createGroupForm?.controls.unit.value === 'Monthly') {
                    flag = this.planCounts.monthlyPopularId && this.isUpdate?._id !== this.planCounts.monthlyPopularId;
                } else {
                    flag = this.planCounts.yearlyPopularId && this.isUpdate?._id !== this.planCounts.yearlyPopularId;
                }
            }
            return flag ? {isPopularExist: true} : null;
        }
    }

    maxPlanValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            let flag = false;
            if (control.value === 'Monthly') {
                if (this.isUpdate?.unit === 'Monthly') {
                    flag = false;
                } else {
                    flag = this.planCounts.monthly >= this.planCounts.maxMonthly;
                }
            } else {
                if (this.isUpdate?.unit === 'Yearly') {
                    flag = false;
                } else {
                    flag = this.planCounts.yearly >= this.planCounts.maxYearly;
                }
            }
            return flag ? {isMaxPlan: true} : null;
        }
    }

    initForm() {
        this.createGroupForm = this.fb.group({
            name: ['', [Validators.required, Validators.maxLength(30)]],
            description: ['', [Validators.required, Validators.maxLength(100)]],
            unit: ['', [Validators.required, this.maxPlanValidator()]],
            price: ['', [Validators.required, Validators.pattern(/^[0-9]*\.?[0-9]*$/)]],
            currency: ['usd', [Validators.required]],
            isMostPopular: [false, [Validators.required, this.isPopularExistValidator()]],
            groupId: ['', [Validators.required]]
        });

        this.featuresForm = this.fb.group({
            features: new FormArray([]),
        });
        this.createGroupForm.controls.unit.valueChanges.subscribe((e) => {
            this.createGroupForm.controls.isMostPopular.updateValueAndValidity();
            this.getGroups();
        });

        if (this.isUpdate) {
            this.createGroupForm.patchValue({
                name: this.isUpdate.name,
                description: this.isUpdate.description,
                unit: this.isUpdate.unit,
                price: this.isUpdate.price,
                isMostPopular: this.isUpdate.isMostPopular,
                groupId: this.isUpdate.groupId
            });
            for (const updateElement of this.isUpdate.features) {
                this.addFeature(updateElement);
            }
        } else {
            this.addFeature();
        }
    }

    getGroups() {
        this.groups = [];
        this.createGroupForm.patchValue({groupId: ''});
        if (this.createGroupForm.controls.unit.value) {
            this.spinner = true;
            const obj = {
                unit: this.createGroupForm.controls.unit.value,
                planId: this.isUpdate?._id || ''
            }
            this.api.getEligibleGroupsForSubscriptionPlan(obj).subscribe((res) => {
                if (res.data && res.data.length) {
                    this.groups = res.data;
                }
            }).add(() => {
                this.spinner = false;
            });
        }
    }

    addFeature(value = '') {
        this.features.push(
            this.fb.group({
                name: [value, [Validators.required, Validators.maxLength(30)]]
            })
        );
    }


    removeFeature(i: number) {
        this.features.removeAt(i)
    }

    onSubmit() {
        if (this.step === 1) {
            this.step = 2;
            return;
        }
        const payload = this.createGroupForm.value;
        const features = this.featuresForm.value;
        payload.features = features.features.map(x => x.name);

        if (this.isUpdate) {
            payload._id = this.isUpdate._id;
            payload.paymentProductId = this.isUpdate.paymentProductId;
            payload.paymentPriceId = this.isUpdate.paymentPriceId;
        }
        //console.log(payload);
        // return;
        this.spinner = true;
        this.api.createPlan(payload).subscribe((res) => {
            // const payload1 = {
            //     groupId : payload.groupId,
            //     isSubscriptionGroup: true,
            //     planId: res.data._id
            // }
            // this.api.updateGroupSubscriptionStatus(payload1).subscribe(response => {
                this.dialog.close(res);
                this.toaster.success('Plan successfully ' + (this.isUpdate ? 'updated' : 'created'));
            // });
        }).add(() => {
            setTimeout(() => {
                this.spinner = false;
            }, 1000);
        });
    }


    errorHandling = (control: string, error: string, form = this.createGroupForm) => {
        return form.controls[control].hasError(error) &&
            (form.controls[control].dirty || form.controls[control].touched || this.isFormSubmitted);
    }

}
