import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {DashboardService} from '../services/dashboard.service';
import {LocalStorageService} from '../services/localStorage.service';

@Injectable({
    providedIn: 'root'
})
export class SellerGuard implements CanActivate {

    constructor(private dashboardService: DashboardService,private localStorageService:LocalStorageService, private router: Router) {
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

        if (this.localStorageService.getItem('isSeller') === 'true') {
            return true;
        } else {
            this.router.navigateByUrl('/');
            return false;
        }
    }


}
