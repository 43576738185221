import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {CrudService} from '../../services/crud.service';
import {ActivatedRoute, Router} from '@angular/router';
import {DOCUMENT} from '@angular/common';
import {DashboardService} from '../../services/dashboard.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {LocalStorageService} from '../../services/localStorage.service';

declare var Stripe;

@Component({
    selector: 'app-community-payment-confirmation',
    templateUrl: './community-payment-confirmation.component.html',
    styleUrls: ['./community-payment-confirmation.component.css']
})
export class CommunityPaymentConfirmationComponent implements OnInit, OnDestroy {

    private clientSecret: any = '';
    private paymentConf: any = '';
    private stripe: any;
    spinner = false;
    headerSpinner = false;
    paymentStatus: any = '';
    paymentIntent: any = null;
    userEmail: any = '';
    userFirst: any = '';
    paymentTransDate: any = '';
    private existingPayment: any;
    private shoppingCartId: any;
    private isPaymentSubmitted: any;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    private subscriptionPlanId: any;
    private groupId: string;
    private isZeroAmtSubscription = false;
    private sellerUserId: string;
    private sellerName: string;
    private sellerEmail: string;
    // private isPartnerProgram = false;

    constructor(
        private dashboardService: DashboardService,
        private urlService: CrudService,
        private router: Router,
        private route: ActivatedRoute,
        private localStorageService: LocalStorageService,
        @Inject(DOCUMENT) private document) {

        this.route.queryParams.pipe(takeUntil(this._unsubscribeAll)).subscribe(params => {
            // console.log("params.payment_intent_client_secret = ", params.payment_intent_client_secret);

            this.clientSecret = params.payment_intent_client_secret;
            this.paymentConf = params.payment_intent;
        });

    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    ngOnInit(): void {

        this.spinner = true;
        this.headerSpinner = true;
        this.shoppingCartId = this.localStorageService.getItem('sellerShoppingCartId');
        // console.log("this.shoppingCartId = ", this.localStorageService.getItem('sellerShoppingCartId'));

        this.groupId = this.localStorageService.getItem('groupSubscriptionId');
        this.sellerUserId = this.localStorageService.getItem('groupSubscriptionAdminId');
        this.isZeroAmtSubscription = this.localStorageService.getItem('subscriptionPlanZero') === 'true';
        // console.log("this.isZeroAmtSubscription = ", this.isZeroAmtSubscription);

        // get seller info
        this.urlService.getUserInfoV2(this.sellerUserId).subscribe(res => {
            this.sellerName = res.data.userInfo.username;
            this.sellerEmail = res.data.userInfo.email;
        });

        // get user email addr
        this.dashboardService.getUserData.pipe(takeUntil(this._unsubscribeAll)).subscribe((res) => {
            if (res) {
                // console.log("dashboardService -  to get user email, res = ", res);

                this.userEmail = res.email;
                this.userFirst = res.firstname;
            }
        });

        if (this.isZeroAmtSubscription) {
            // console.log("&&& ZeroAmtSubscription - Deactivating Shopping Cart");
            this.deactivateShoppingCart();

            // console.log("&&& ZeroAmtSubscription - Setting Subscription Plan");
            this.setSubscriptionPlan();
            this.paymentStatus =  'ZeroAmtSubscription';
        } else {
            // Retrieving the payment intent status
            this.checkStatus();
        }

    }

    async checkStatus() {

        // console.log("checkStatus - this.clientSecret = ", this.clientSecret);
        // console.log("checkStatus - this.localStorageService.getItem('sellerAccountId') = ", this.localStorageService.getItem('sellerAccountId'));
        // console.log("checkStatus - this.localStorageService.getItem('isPartnerProgram')  = ", this.localStorageService.getItem('isPartnerProgram'));

        const isPartnerProgram = this.localStorageService.getItem('isPartnerProgram') == 'true' ? true : false;
        // console.log("checkStatus - isPartnerProgram = ", isPartnerProgram);

        if (isPartnerProgram) {

            // console.log("checkStatus - isPartnerProgram = ", isPartnerProgram);

            if (!this.clientSecret ) {
                this.spinner = false;
                this.headerSpinner = false;
                return;
            }

        } else {

            // console.log("checkStatus - isPartnerProgram = ", isPartnerProgram);

            if (!this.clientSecret || !this.localStorageService.getItem('sellerAccountId')) {
                this.spinner = false;
                this.headerSpinner = false;
                return;
            }
        };

        const getStripe = () => {

            // console.log("In getStripe()");

            if (isPartnerProgram) {

                const stripe = Stripe(environment.Stripe_PK);
                // console.log("partnerProgram - stripe = ", stripe);
                return stripe;

            } else {

                const stripe = Stripe(environment.Stripe_PK, {stripeAccount: this.localStorageService.getItem('sellerAccountId')});
                // console.log("NON partnerProgram - stripe = ", stripe);
                return stripe;
            }
        };

        this.stripe = getStripe();

        const paymentIntentResult = await this.stripe.retrievePaymentIntent(this.clientSecret);
        // console.log("checkStatus - paymentIntentResult = ", paymentIntentResult);

        this.paymentIntent = paymentIntentResult.paymentIntent;
        // console.log("checkStatus - this.paymentIntent = ", this.paymentIntent);

        const date = new Date(this.paymentIntent.created * 1000);
        this.paymentTransDate = (date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear();

        if (!this.isPaymentSubmitted && this.paymentIntent.status === 'succeeded') {
            // deactivate shopping cart
            this.deactivateShoppingCart();
        }

        this.paymentStatus = this.getPaymentStatus();

        this.urlService.getPaymentByConfirmationNo(this.paymentConf).subscribe(success => {

            //WC 06122024 --- Set the spinner so all backend database updates are completed before redirecting the user to the group page to access content
            this.headerSpinner = true;
            this.spinner = true;

            // No payment by confirmation no found
            if (success.data == null) {
                const payload = {
                    shoppingCartId: this.shoppingCartId,
                    clientSecret: this.clientSecret,
                    paymentConfirmation: this.paymentConf,
                    paymentStatus: this.paymentIntent.status
                }

                // save the payment confirmation and status
                this.urlService.setPaymentConfirmation(payload).subscribe((success1) => {
                    // console.log('Successful setting paymentConfirmation = ', success1.data)
                    this.setSubscriptionPlan();
                });
            }
        });
    };

    deactivateShoppingCart() {
        // console.log("deactiveShoppingCart - this.shoppingCartId = ", this.shoppingCartId);

        if (this.shoppingCartId) {
            const payload = {shoppingCartId: this.shoppingCartId};

            this.urlService.deactivateShoppingCart(payload).subscribe(success => {
                this.localStorageService.removeItem('sellerShoppingCartId');
                // console.log("*** Successfully deactivate shopping cart id ", this.shoppingCartId);
                this.dashboardService.onGetShoppingCart();
                this.headerSpinner = false;
                this.spinner = false;
            });
        } else {
            this.headerSpinner = false;
            this.spinner = false;
        }
    }

    setSubscriptionPlan() {

        //console.log("&&& community-payment-confirmation - setSubscriptionPlan - this.shoppingCartId = ", this.shoppingCartId);

        if (this.shoppingCartId) {

            this.urlService.getShoppingCart(this.shoppingCartId).subscribe(success1 => {

                const shoppingCart = success1.data;

                if (shoppingCart.type === 'subscriptionPlan') {

                    //console.log('shoppingCart = ', shoppingCart);

                    const payload = {
                        planId: shoppingCart.shoppingCartItems[0].itemNo,
                        // Stripe subscription Id
                        subscriptionId: this.localStorageService.getItem('sellerSubscriptionId'),
                        clientSecret: this.localStorageService.getItem('sellerClientSecret'),
                        customerId: this.localStorageService.getItem('payCustomerId'),
                        sellerAccountId: shoppingCart.sellerAccountId,
                        appFeePct: shoppingCart.appFeePct,
                        isPartnerProgram: shoppingCart?.isPartnerProgram ? true : false,
                        sellerUserId: this.sellerUserId
                    };

                    if (this.isZeroAmtSubscription) {
                        payload.subscriptionId = 'ZeroAmtSubscription';
                        payload.clientSecret = 'ZeroAmtSubscription';
                    }

                    this.urlService.setUserBuyerSubscriptionPlan(payload).subscribe(success2 => {
                        // console.log('&&&& successfully set user buyer subscription plan = ', success2);
                        this.subscriptionPlanId = success2.data._id;

                        // add seller follower and group subscriber
                        if (this.localStorageService.getItem('isFollowingSeller') === 'true') {
                            // console.log("Adding to Group Subscriber")
                            this.addGroupSubscriber();
                        } else {

                            if (this.sellerUserId) {
                                const obj: any = {
                                    type: 'follow',
                                    followToUserID: this.sellerUserId
                                };

                                try {
                                    this.urlService.followUser(obj).subscribe().add(() => {
                                        // console.log('successfully set user as follower of the subscription group admin');
                                        this.addGroupSubscriber();
                                    });
                                } catch (err) {
                                    // do nothing
                                }
                            }
                        }
                    }).add(() => {
                        this.spinner = false; 
                        this.headerSpinner = false;
                    });
                };
            });
        };
    };

    addGroupSubscriber() {
        if (this.groupId) {
            this.spinner = true;
            this.urlService.addGroupSubscriber({
                groupId: this.groupId,
                subscriber: {
                    accessType: 'SUBSCRIBER',
                    userId: this.localStorageService.getItem('user'),
                    subscriptionPlanId: this.subscriptionPlanId
                }
            }).subscribe().add(() => this.spinner = false);
        }
        this.localStorageService.removeItem('isFollowingSeller');
    }

    getPaymentStatus() {

        let message = '';

        switch (this.paymentIntent.status) {
            case 'succeeded':
                message = 'Payment succeeded';
                break;
            case 'processing':
                message = 'Your payment is processing';
                break;
            case 'requires_payment_method':
                message = 'Your payment was not successful, please try again';
                break;
            default:
                message = 'Something went wrong';
                break;
        }

        return message;
    }

    redirect() {

        if (this.localStorageService.getItem('isPartnerProgram') != undefined)
            this.localStorageService.removeItem('isPartnerProgram');

        if (this.groupId) {
            this.router.navigate(['community/' + this.groupId], { queryParams: { status: 'subscribed' }});
        } else {
            // this.router.navigate(['main-community']);
            this.router.navigate(['my-community']);
        }
    }

}
