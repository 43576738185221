import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {LocalStorageService} from '../services/localStorage.service';

@Injectable()
export class AuthGuardService implements CanActivate {
    constructor(public router: Router, private localStorageService: LocalStorageService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        const redirectURL = state.url;
        
        if (this.localStorageService.getItem('token')) {
            if (this.localStorageService.getItem('redirectURL')) {
                this.router.navigateByUrl(this.localStorageService.getItem('redirectURL'));
                this.localStorageService.removeItem('redirectURL');
            }
            return true;
        } else {
            this.localStorageService.removeItem('token');
            this.localStorageService.setItem('redirectURL', redirectURL);
            this.router.navigate(['login']);
            return false;
        }
    }
}
