import {BrowserModule, Title} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ToastrModule} from 'ngx-toastr';
// import {SelectDropDownModule} from 'ngx-select-dropdown';
// import {AutocompleteLibModule} from 'angular-ng-autocomplete';
// import {CarouselModule} from 'ngx-owl-carousel-o';
import {OwlModule} from 'ngx-owl-carousel';
import {NgxSliderModule} from '@angular-slider/ngx-slider';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {HttpConfigInterceptor} from './interceptor';
import {NgModule} from '@angular/core';
import {ServiceWorkerModule} from '@angular/service-worker';
import {LoginComponent} from './beforeLogin/login/login.component';
import {SignupComponent} from './beforeLogin/signup/signup.component';
import {ResetPasswordComponent} from './beforeLogin/resetPassword/resetPassword.component';
import {ForgetPasswordComponent} from './beforeLogin/forgotPassword/forgetPassword.component';
import {OtpComponent} from './beforeLogin/otp/otp.component';
import {NewLandingPageComponent} from './beforeLogin/new-landing-page/new-landing-page.component';
import {ExplorePageComponent} from './beforeLogin/explore/explore.component';
import {PrivacyPolicyComponent} from './beforeLogin/privacy-policy/privacy-policy.component';
import {TermsConditionsComponent} from './beforeLogin/terms-conditions/terms-conditions.component';
import {FaqComponent} from './beforeLogin/faq/faq.component';
import {CategoryWiseVideoComponent} from './beforeLogin/categoryWiseVideo/categoryWiseVideo.component';
import {ProfileCompletionComponent} from './afterLogin/profileCompletion/profileCompletion.component';
import {SideMenuComponent} from './afterLogin/sideMenu/sideMenu.component';
import {HeaderComponent} from './afterLogin/header/header.component';
import {UploadVideosComponent} from './afterLogin/uploadVideos/uploadVideos.component';
import {CreateInteractiveVideoComponent} from './afterLogin/createInteractiveVideo/createInteractiveVideo.component';
import {ThatsallComponent} from './afterLogin/thatsall/thatsall.component';
import {PublicVideoComponent} from './afterLogin/public-video/public-video.component';
import {FollowingComponent} from './afterLogin/following/following.component';
import {FollowersComponent} from './afterLogin/followers/followers.component';
import {UserActivitiyComponent} from './afterLogin/user-activitiy/user-activitiy.component';
import {PublicUserActivitiyComponent} from './afterLogin/public-user-activitiy/public-user-activitiy.component';
import {TrendingComponent} from './beforeLogin/trending/trending.component';
import {NotificationsComponent} from './afterLogin/notifications/notifications.component';
import {LibraryComponent} from './afterLogin/library/library.component';
import {environment} from '../environments/environment';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {RoleGuardService} from './guards/role-gaurd.service';
import {AuthGuardService} from './guards/router-gaurd.service';
import {MatSelectModule} from '@angular/material/select';
import {NgSelectModule} from '@ng-select/ng-select';
import {AboutComponent} from './beforeLogin/about/about.component';
import {CloudinaryModule} from '@cloudinary/angular-5.x';
import {Cloudinary} from 'cloudinary-core';
// import {GoogleTagManagerModule} from 'angular-google-tag-manager';
import {Ng5SliderModule} from 'ng5-slider';
import {CustomBrandComponent} from './afterLogin/custom-brand/custom-brand.component';
import {SavedNodesComponent} from './afterLogin/saved-nodes/saved-nodes.component';
import {SavedNodesAlbumComponent} from './afterLogin/saved-nodes-album/saved-nodes-album.component';
import {CreateGroupComponent} from './afterLogin/group/create-group/create-group.component';
import {ClipboardModule} from 'ngx-clipboard';
import {Ng2SearchPipeModule} from 'ng2-search-filter';
// import {NgbModule, NgbPaginationModule} from '@ng-bootstrap/ng-bootstrap';
//import {NgApexchartsModule} from 'ng-apexcharts';
import {ImageCropperModule} from 'ngx-image-cropper';
import {LayoutModule} from './layout/layout.module';
// import {PagesModule} from './pages/new-front/pages.module';
import {MatDialogModule} from '@angular/material/dialog';
import {MatCardModule} from '@angular/material/card';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {DragScrollModule} from 'ngx-drag-scroll';
import {GtagModule} from 'angular-gtag';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {NgxPageScrollCoreModule} from 'ngx-page-scroll-core';
import {NgxPageScrollModule} from 'ngx-page-scroll';
import {PricingComponent} from './pages/pricing/pricing.component';
import {CheckoutComponent} from './afterLogin/checkout/checkout.component';
import {PaymentConfirmationComponent} from './afterLogin/payment-confirmation/payment-confirmation.component';
import {DiscountPricingComponent} from './pages/discount-pricing/discount-pricing.component';
import {ValidateEqualModule} from 'ng-validate-equal';
import {PublicPlaylistComponent} from './afterLogin/group/public-playlist/public-playlist.component';
import {NewDashboardComponent} from './afterLogin/new-dashboard/new-dashboard.component';
import {GroupListComponent} from './afterLogin/group/group-list/group-list.component';
import {GroupDetailComponent} from './afterLogin/group/group-detail/group-detail.component';
import {VideoTreeComponent} from './layout/modals/video-tree/video-tree.component';
import {SingleVideoComponent} from './layout/modals/video-tree/single-video/single-video.component';
import {DirectiveModule} from './directives/directive.module';
import {NewMyGroupComponent} from './afterLogin/group/new-my-group/new-my-group.component';
import {MyGroupListComponent} from './afterLogin/group/my-group-list/my-group-list.component';
import {NewMyVideosComponent} from './afterLogin/new-my-videos/new-my-videos.component';
import {SettingsComponent} from './afterLogin/settings/settings.component';
import {NewSearchResultsComponent} from './afterLogin/new-search-results/new-search-results.component';
import {NewPublicProfileComponent} from './afterLogin/new-public-profile/new-public-profile.component';
import {NewMyVideosPublicComponent} from './afterLogin/new-my-videos-public/new-my-videos-public.component';
import {PipesModule} from './pipes/pipes.module';
import {PopoverModule} from 'ngx-bootstrap/popover';
import {SellerOnboardingComponent} from './afterLogin/seller-onboarding/seller-onboarding.component';
import {MyPlansComponent} from './afterLogin/my-plans/my-plans.component';
import {AddUpdatePlanComponent} from './afterLogin/my-plans/add-update-plan/add-update-plan.component';
import {PlanDetailsComponent} from './afterLogin/my-plans/plan-details/plan-details.component';
import {PricingDialogComponent} from './afterLogin/group/pricing-dialog/pricing-dialog.component';
import {CommunityCheckoutComponent} from './afterLogin/community-checkout/community-checkout.component';
import {
    CommunityPaymentConfirmationComponent
} from './afterLogin/community-payment-confirmation/community-payment-confirmation.component';
import {BuildCommunityComponent} from './afterLogin/build-community/build-community.component';
import {MainCommunityComponent} from './afterLogin/main-community/main-community.component';
// import {EmbedVideoComponent} from './embed-video/embed-video/embed-video.component';
import {ContactGroupComponent} from './afterLogin/group/contact-group/contact-group.component';
import { SubscriptionChangeConfirmationComponent } from './afterLogin/subscription-change-confirmation/subscription-change-confirmation.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {NgxEditorModule} from 'ngx-editor';
import { PartnerCheckoutComponent } from './afterLogin/partner-checkout/partner-checkout.component';
import { CreateVideoChatComponent } from './afterLogin/createInteractiveVideo/create-video-chat/create-video-chat.component';
import { DelegateAccessComponent } from './pages/delegate-access/delegate-access.component';
import { EditVideoComponent } from './afterLogin/createInteractiveVideo/edit-video/edit-video.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import { CreateInterActiveVideo1Component } from './afterLogin/createInteractiveVideo/create-video/create-inter-active-video1.component';
import { SecurityPolicyComponent } from './beforeLogin/security-policy/security-policy.component';
import { MyBadgesPublicComponent } from './afterLogin/my-badges-public/my-badges-public.component';
import { PositionChoicesComponent } from './afterLogin/createInteractiveVideo/position-choices/position-choices.component';
import {ColorPickerModule} from 'ngx-color-picker';
import { CouponComponent } from './afterLogin/coupon/coupon.component';
import { CreateCouponComponent } from './afterLogin/coupon/create-coupon/create-coupon.component';
import { CreatePromoCodeComponent } from './afterLogin/coupon/create-promo-code/create-promo-code.component';
import { DatePipe } from '@angular/common';
import { EditCouponComponent } from './afterLogin/coupon/edit-coupon/edit-coupon.component';
import { AnalyticsModalComponent } from './afterLogin/new-my-videos/analytics-modal/analytics-modal.component';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { MinuteSecondsPipe } from './pipes/pipeFormatSecond';
import { MinimalistTemplateComponent } from './afterLogin/main-community/minimalist-template/minimalist-template.component';
import { QuestionnairesDashboardComponent } from './afterLogin/questionnaires-dashboard/questionnaires-dashboard.component';
import { QuestionnaireDetailsComponent } from './afterLogin/questionnaire-details/questionnaire-details.component';
import { BoldTemplateComponent } from './afterLogin/main-community/bold-template/bold-template.component';
import { ModernTemplateComponent } from './afterLogin/main-community/modern-template/modern-template.component';
import { SamlComponent } from './pages/saml/saml.component';
import { UploadTemplateVideosComponent } from './afterLogin/upload-template-videos/upload-template-videos.component';
import { QRCodeModalComponent } from './afterLogin/qr-code-modal/qr-code-modal.component';
import { LeadGenAnalyticsComponent } from './afterLogin/lead-gen-analytics/lead-gen-analytics.component';
import { MatTableModule } from '@angular/material/table';
import { AiVideoCreatorComponent } from './afterLogin/createInteractiveVideo/ai-video-creator/ai-video-creator.component';
import { AiVideoCreatorChatComponent } from './afterLogin/createInteractiveVideo/ai-video-creator-chat/ai-video-creator-chat.component';
import { BadgesComponent } from './afterLogin/badges/badges.component';
import { MainCertificatesComponent } from './afterLogin/main-certificates/main-certificates.component';
import { CloneCommunityComponent } from './pages/clone-community/clone-community.component';
import { FeatureGroupOverviewComponent } from './pages/feature-group-overview/feature-group-overview.component';
import { FeatureRequestGroupInfoComponent } from './pages/feature-request-group-info/feature-request-group-info.component';

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        LoginComponent,
        SignupComponent,
        ResetPasswordComponent,
        ForgetPasswordComponent,
        OtpComponent,
        ProfileCompletionComponent,
        SideMenuComponent,
        UploadVideosComponent,
        CategoryWiseVideoComponent,
        CreateInteractiveVideoComponent,
        ThatsallComponent,
        PublicVideoComponent,
        FollowingComponent,
        FollowersComponent,
        NewLandingPageComponent,
        ExplorePageComponent,
        UserActivitiyComponent,
        PublicUserActivitiyComponent,
        TrendingComponent,
        NotificationsComponent,
        LibraryComponent,
        PrivacyPolicyComponent,
        TermsConditionsComponent,
        FaqComponent,
        AboutComponent,
        CustomBrandComponent,
        SavedNodesComponent,
        SavedNodesAlbumComponent,
        CreateGroupComponent,
        PricingComponent,
        CheckoutComponent,
        PaymentConfirmationComponent,
        DiscountPricingComponent,
        NewDashboardComponent,
        GroupListComponent,
        GroupDetailComponent,
        VideoTreeComponent,
        SingleVideoComponent,
        PublicPlaylistComponent,
        NewMyGroupComponent,
        MyGroupListComponent,
        NewMyVideosComponent,
        SettingsComponent,
        NewSearchResultsComponent,
        NewPublicProfileComponent,
        NewMyVideosPublicComponent,
        SellerOnboardingComponent,
        MyPlansComponent,
        AddUpdatePlanComponent,
        PlanDetailsComponent,
        MainCommunityComponent,
        PricingDialogComponent,
        CommunityCheckoutComponent,
        CommunityPaymentConfirmationComponent,
        BuildCommunityComponent,
        // EmbedVideoComponent,
        ContactGroupComponent,
        SubscriptionChangeConfirmationComponent,
        PartnerCheckoutComponent,
        CreateVideoChatComponent,
        DelegateAccessComponent,
        EditVideoComponent,
        CreateInterActiveVideo1Component,
        SecurityPolicyComponent,
        MyBadgesPublicComponent,
        PositionChoicesComponent,
        CouponComponent,
        CreateCouponComponent,
        CreatePromoCodeComponent,
        EditCouponComponent,
        AnalyticsModalComponent,
        MinimalistTemplateComponent,
        QuestionnairesDashboardComponent,
        QuestionnaireDetailsComponent,
        BoldTemplateComponent,
        ModernTemplateComponent,
        SamlComponent,
        UploadTemplateVideosComponent,
        QRCodeModalComponent,
        LeadGenAnalyticsComponent,
        AiVideoCreatorComponent,
        AiVideoCreatorChatComponent,
        BadgesComponent,
        MainCertificatesComponent,
        CloneCommunityComponent,
        FeatureGroupOverviewComponent,
        FeatureRequestGroupInfoComponent,
    ],
    imports: [
        BrowserModule.withServerTransition({appId: 'serverApp'}),
        BrowserAnimationsModule,
        HttpClientModule,
        FormsModule,
        MatDialogModule,
        MatCardModule,
        MatButtonModule,
        MatInputModule,
        MatSlideToggleModule,
        NgxSliderModule,
        DragScrollModule,
        ReactiveFormsModule,
        CloudinaryModule.forRoot({Cloudinary}, {cloud_name: 'oodlestechnologies'}),
        ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
        AppRoutingModule,
        // SelectDropDownModule,
        ToastrModule.forRoot(),
        // AutocompleteLibModule,
        // CarouselModule,
        OwlModule,
        NgSelectModule,
        MatSelectModule,
        Ng5SliderModule,
        ClipboardModule,
        Ng2SearchPipeModule,
        // NgbPaginationModule,
        //NgApexchartsModule,
        ImageCropperModule,
        // GoogleTagManagerModule.forRoot({
        //   id: 'GTM-55KF2V9',
        // }),
        // NgbModule,
        LayoutModule,
        // PagesModule,
        GtagModule.forRoot({trackingId: environment.GA_TRACKING_ID, trackPageviews: true}),
        InfiniteScrollModule,
        NgxPageScrollCoreModule.forRoot(),
        NgxPageScrollModule,
        ValidateEqualModule,
        DirectiveModule,
        PipesModule,
        PopoverModule.forRoot(),
        DragDropModule,
        MatCheckboxModule,
        NgxEditorModule,
        MatTooltipModule,
        ColorPickerModule,
        AccordionModule.forRoot(),
        MatTableModule,
        // NgbModule,
    ],
    providers: [RoleGuardService, AuthGuardService, Title,
        {provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true},
        DatePipe,
        MinuteSecondsPipe
    ],
    exports: [],
    bootstrap: [AppComponent]
})
export class AppModule { }
