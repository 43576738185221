import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {WithoutLoginComponent} from './footer/without-login/without-login.component';
import {WithLoginComponent} from './footer/with-login/with-login.component';
import {SpinnerComponent} from './spinner/spinner.component';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {MatCardModule} from '@angular/material/card';
import {Ng2SearchPipeModule} from 'ng2-search-filter';
import {MatInputModule} from '@angular/material/input';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatSelectModule} from '@angular/material/select';
import {HeaderComponent} from './header/header.component';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {LayoutComponent} from './layout/layout.component';
import {NewHeaderComponent} from './new-header/new-header.component';
import {BreadcrumbComponent} from './breadcrumb/breadcrumb.component';
import {SidebarComponent} from './sidebar/sidebar.component';
import {CreateVideoComponent} from './modals/create-video/create-video.component';
import {DirectiveModule} from '../directives/directive.module';
import {SectionSpinnerComponent} from './sectionSpinner/sectionSpinner.component';
import {DeleteConfirmationComponent} from './modals/delete-confirmation/delete-confirmation.component';
import {ModalVideoPlayerComponent} from './modals/modal-video-player/modal-video-player.component';
import {UploadLinkDialogComponent} from './modals/create-video/upload-link-dialog/upload-link-dialog.component';
import {ConfirmationDialogComponent} from './modals/confirmation-dialog/confirmation-dialog.component';
import {ProgressBarComponent} from './progress-bar/progress-bar.component';
import {OpenCollabComponent} from './modals/open-collab/open-collab.component';
import {DragScrollModule} from 'ngx-drag-scroll';
import {CollabSingleVideoComponent} from './collab-single-video/collab-single-video.component';
import {RecordVideoComponent} from './modals/record-video/record-video.component';
import {CollabTreeNodeComponent} from './collab-tree-node/collab-tree-node.component';
import {PopoverModule} from 'ngx-bootstrap/popover';
import {InteractiveVideosDialogComponent} from './modals/interactive-videos-dialog/interactive-videos-dialog.component';
import {VideoCommentTypeDialogComponent} from './modals/video-comment-type-dialog/video-comment-type-dialog.component';
import {ShareVideoDialogComponent} from './modals/share-video-dialog/share-video-dialog.component';
import {ClipboardModule} from 'ngx-clipboard';
import {PipesModule} from '../pipes/pipes.module';
import {MatMenuModule} from '@angular/material/menu';
import { OnlineVideosComponent } from './modals/online-videos/online-videos.component';
import { CreateBadgeComponent } from './modals/create-badge/create-badge.component';
import {MatTooltipModule} from "@angular/material/tooltip";
import {PickerModule} from "@ctrl/ngx-emoji-mart";
import {OverlayModule} from "@angular/cdk/overlay";
import { CreateQuestionnaireComponent } from './modals/create-questionnaire/create-questionnaire.component';
import { LexieAiChatbotComponent } from './lexie-ai-chatbot/lexie-ai-chatbot.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        MatButtonModule,
        MatDialogModule,
        MatCardModule,
        MatInputModule,
        MatCheckboxModule,
        MatSelectModule,
        Ng2SearchPipeModule,
        InfiniteScrollModule,
        DirectiveModule,
        DragScrollModule,
        PopoverModule,
        ClipboardModule,
        PipesModule,
        MatMenuModule,
        ReactiveFormsModule,
        MatTooltipModule,
        PickerModule,
        OverlayModule,
    ],
  declarations: [
    WithoutLoginComponent,
    WithLoginComponent,
    SpinnerComponent,
    HeaderComponent,
    LayoutComponent,
    NewHeaderComponent,
    BreadcrumbComponent,
    SidebarComponent,
    CreateVideoComponent,
    SectionSpinnerComponent,
    DeleteConfirmationComponent,
    ModalVideoPlayerComponent,
    UploadLinkDialogComponent,
    ConfirmationDialogComponent,
    ProgressBarComponent,
    OpenCollabComponent,
    CollabSingleVideoComponent,
    RecordVideoComponent,
    CollabTreeNodeComponent,
    InteractiveVideosDialogComponent,
    VideoCommentTypeDialogComponent,
    ShareVideoDialogComponent,
    OnlineVideosComponent,
    CreateBadgeComponent,
    CreateQuestionnaireComponent,
    LexieAiChatbotComponent,
  ],
    exports: [
        WithLoginComponent,
        WithoutLoginComponent,
        SpinnerComponent,
        HeaderComponent,
        LayoutComponent,
        NewHeaderComponent,
        BreadcrumbComponent,
        SectionSpinnerComponent,
        DeleteConfirmationComponent,
        ModalVideoPlayerComponent,
        OpenCollabComponent,
        RecordVideoComponent,
        CreateVideoComponent,
        CollabTreeNodeComponent,
    ],
  bootstrap: [
    CreateVideoComponent,
    OpenCollabComponent,
  ],
})
export class LayoutModule {}
