import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {CrudService} from '../../services/crud.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {DeleteConfirmationComponent} from '../../layout/modals/delete-confirmation/delete-confirmation.component';
import languages from '../../services/languages';
import {ShareVideoDialogComponent} from '../../layout/modals/share-video-dialog/share-video-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {environment} from '../../../environments/environment';
import {DashboardService} from '../../services/dashboard.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {ZipService} from '../../services/zip.service';
import {UtilityService} from '../../services/utility.service';
import {LocalStorageService} from '../../services/localStorage.service';
import { AnalyticsModalComponent } from './analytics-modal/analytics-modal.component';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

declare var $: any;

@Component({
  selector: 'app-new-my-videos',
  templateUrl: './new-my-videos.component.html',
  styleUrls: ['./new-my-videos.component.css'],
})
export class NewMyVideosComponent implements OnInit, OnDestroy {

  @ViewChild('deleteDialogRef') deleteDialogRef: DeleteConfirmationComponent;
  @ViewChild('cloneDialog') cloneDialog: any;
  @ViewChild('shareWithGroupAdminsDialog') shareWithGroupAdminsDialog: any;
  @ViewChild('transferDialog') transferDialog: any;
  @ViewChild('downloadTranscriptDialog') downloadTranscriptDialog: any;
  @ViewChild('generatingVideoTitle') generatingVideoTitle: any;

  currentTab: 'published' | 'draft' | 'collab' = 'published';
  currentChildTab: 'individual' | 'groups' = 'individual';
  languages = languages;
  isAnalytics = false;
  public mainVideoList: Array<any> = [];
  public publishedVideos: Array<any> = [];
  public sortedPublishedVideos: Array<any> = [];
  public drafts: Array<any> = [];
  public intialDraft: Array<any> = [];
  public isSecond: any = false;
  public currentItem: any = {};
  public currentItem1: any = {};
  public currentItem2: any = {};
  public currentItem3: any = {};
  public currentItem4: any = {};
  public currentItem5: any = {};
  public currentItem6: any = {};
  public currentItem7: any = {};
  // public totalGroup = 5;

  public currentItemback: any = {};
  public draftChunks: Array<any> = [];
  public userDetails: Array<any> = [];
  public contributors: Array<any> = [];
  public contributorVideos: Array<any> = []; // videos which I'm one of the collaborators
  public initialContributorVideos: Array<any> = [];
  public topChoices: any = [];
  public childChoices1: any = [];
  public childChoices2: any = [];
  public childChoices3: any = [];
  public childChoices4: any = [];
  public childChoices5: any = [];
  public childChoices6: any = [];
  public spinner: any = false;
  public val: any = 'recent';
  currentUserId: any;

  leftAarrow = true;
  choice1: any;
  choice2: any;
  choice3: any;
  choice6: any;
  choice4: any;
  choice5: any;
  choice7: any;

  resetIt = false;
  vtable: any;

  currentViews = false;

  // WC 09212022
  draftPage = 1;
  collabPage = 1;
  publishedPage = 1;
  limit = 12;
  draftsCount = 0;
  publishedCount = 0;
  groupPublishedCount = 0;
  collabVideosCount = 0;
  groupCollabVideosCount = 0;
  // WC 09212022

  // WC 11062022
  sort = 'recent';

  // WC 11062022
  copyVideoTitle = '';
  transferToEmailAddr = '';
  adminGroups = [];
  allAdminGroups = [];
  shareGroupAdmin = [];
  currentGroupIndex = 0;
  term: any;
  currentTransferTab = 'email';
  transferToFollower = '';
  private deleteWaitingId: any;
  private followerPage = 0;
  myFollowers = [];
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  private userData: any;
  language;
  allSubtitles = [];
  QRcode:any;
  safeQRcode: SafeHtml;

  constructor(
      private dashboardService: DashboardService,
      private route: ActivatedRoute,
      public crudService: CrudService,
      public utilityService: UtilityService,
      public zipService: ZipService,
      public toaster: ToastrService,
      public router: Router,
      public dialog: MatDialog,
      public localStorageService: LocalStorageService,
      private sanitizer: DomSanitizer
  ) {
    this.route.queryParams.subscribe((params) => {
      if (params.currentTab) {
        this.currentTab = params.currentTab;
      }

      if (params.analytics) {
        this.isAnalytics = params.analytics;
      }
    });
  }

  ngOnInit() {
    this.spinner = true;
    this.getMainVideos();
    this.getCheckGroupAdmin();
    this.getFollower();
    this.currentUserId = this.localStorageService.getItem('user');
    this.dashboardService.setActivePage = this.currentTab;

    this.dashboardService.getUserData
        .pipe(takeUntil(this._unsubscribeAll)).subscribe((res) => {
      if (res) {
        this.userData = res;
      }
    });
  }

  openAnalyticsModal() {
    const dialogRef = this.dialog.open(AnalyticsModalComponent, {
      width: '100%',
      maxWidth: '1400px',
      panelClass: 'analytics-modal',
      height: 'auto',
      data: {topChoices: this.topChoices}
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
      }
    });
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
    this.dashboardService.setActivePage = '';
  }

  getCheckGroupAdmin() {
    this.crudService.getAllGroups({accessType: 'ADMIN'}).subscribe((res) => {
      if (res.data && res.data.length) {
        // this.adminGroups = res.data.filter(x => x.admin !== this.currentUserId);
        this.adminGroups = res.data;

        this.allAdminGroups = res.data;
        // console.log(this.adminGroups)
        if (this.adminGroups.length) {
          this.getInteractiveVideosCountForGroup(this.adminGroups[0].admin);
          // this.getCollabVideosCountForGroup(this.adminGroups[0].admin);
        }
      }
    });
  }


  // Get all main videos
  getMainVideos() {
    this.spinner = true;
    this.intialDraft = [];
    this.mainVideoList = [];
    this.publishedVideos = [];
    this.drafts = [];
    this.draftPage = 1;
    this.collabPage = 1;
    this.publishedPage = 1;

    // WC 09212022 -- Get drafts videos
    // this.getInteractiveVideos(false, this.limit, this.draftPage, true, this.sort);

    // this.getCollabVideos(this.limit, this.collabPage, true, this.sort);

    // this.getInteractiveVideos(true, this.limit, this.publishedPage, true, this.sort);

    this.changeFilter(this.sort);

    // Get count of main videos
    this.getInteractiveVideosCount();

    // Get count of collab videos
    this.getCollabVideosCount();
  }

  getAdminSharedVideos(limit: number, page: number, isOnInit: boolean, sort: string) {

    this.crudService.getAdminSharedVideos(limit, page, sort).subscribe(success => {

      const mainVideos = success.data || [];

      if (isOnInit) {
          this.publishedVideos = mainVideos;
      } else {

        for (const item of mainVideos) {
          this.publishedVideos.push(item);
        }
      }

      if (this.isAnalytics && this.publishedVideos.length) {
        this.isAnalytics = false;
        this.showAnalytics(this.publishedVideos[0]);
      }

      for (const item of mainVideos) {
        if (item.subtitleTranslations) {
          item.subtitleTranslations = item.subtitleTranslations.map(x => {
            x.subtitle = languages.find(c => c.code === Object.keys(x)[0]);
            return x;
          });
        }
      }
      this.spinner = false;
    });

  }

  getInteractiveVideos(isPublished: boolean, limit: number, page: number, isOnInit: boolean, sort: string) {

    // console.log("getInteractiveVideo - isPublished = " + isPublished + ", page = " + page + ", isOnInit = " + isOnInit);
    const object: any = {
      isPublished,
      limit,
      page,
      sort,
    }

    if (isOnInit) {
      this.spinner = true;
    }

    // if (this.currentChildTab === 'groups') {
    //   object.isGroupContent = true;
    // }

    if (this.currentChildTab === 'groups') {
      this.getAdminSharedVideos(limit, page, isOnInit, sort);
    } else {

      this.crudService.getallmainvideos(object).subscribe(success => {

        const mainVideos = success.data || [];

        if (isOnInit) {

          if (isPublished) {
            this.publishedVideos = mainVideos;
          } else {
            this.drafts = mainVideos;
          }
        } else {

          for (const item of mainVideos) {
            if (isPublished) {
              this.publishedVideos.push(item);
            } else {
              this.drafts.push(item);
            }
          }
        }

        if (this.isAnalytics && isPublished && this.publishedVideos.length) {
          this.isAnalytics = false;
          this.showAnalytics(this.publishedVideos[0]);
        }

        for (const item of mainVideos) {
          if (item.subtitleTranslations) {
            item.subtitleTranslations = item.subtitleTranslations.map(x => {
              x.subtitle = languages.find(c => c.code === Object.keys(x)[0]);
              return x;
            });
            if(item.subtitleUrl) {
              const defaultLang = this.getDefaultLangTranscription(item)
              item.subtitleTranslations.unshift(defaultLang)
            }
          }
        }

        this.spinner = false;
        const latestPublishedVideoId = this.localStorageService.getItem('latestPublishedVideoId')
        if (latestPublishedVideoId && isPublished) {
          this.localStorageService.removeItem('latestPublishedVideoId');
          if (this.publishedVideos.find(x => x._id === latestPublishedVideoId)) {
            this.openShareDialog(latestPublishedVideoId);
          }
        }
      });
    }
  };

  getInteractiveVideosCountForGroup(userID) {
    // this.crudService.getAllMainVideosCountV2({userID: userID}).subscribe(success => {
    //   //this.groupPublishedCount = success.data.groupVideoCount;

    //   const videoCounts = success.data;
    //   for (const item of videoCounts) {
    //     if (item._id.isPublished != undefined) {
    //       if (item._id.isPublished)
    //         this.groupPublishedCount = item.count;
    //     }
    //   }

    // });

    this.crudService.adminSharedVideosCount().subscribe(success => {

      this.groupPublishedCount = success.data.count;

    });
  }

  getCollabVideosCountForGroup(userID) {
    this.crudService.getCollabVideosCountV2({userID}).subscribe(success => {
      this.groupCollabVideosCount = success.data[0]?.collabVideosCount || 0;
    });
  }

  getInteractiveVideosCount() {
     if (this.currentChildTab === 'groups') {
      this.getInteractiveVideosCountForGroup(this.adminGroups[this.currentGroupIndex].admin);
      return;
    }
    this.crudService.getAllMainVideosCountV2().subscribe(success => {
      const videoCounts = success.data;
      this.publishedCount = 0;
      this.draftsCount = 0;
      if (videoCounts.length) {
        for (const item of videoCounts) {
          if (item._id.isPublished != undefined) {
            if (item._id.isPublished)
              this.publishedCount = item.count;
            else if (!item._id.isPublished)
              this.draftsCount = item.count;
          }
        }
      } else {
        this.publishedCount = 0;
        this.draftsCount = 0;
      }

    });
  }

  getCollabVideosCount() {
     if (this.currentChildTab === 'groups') {
      this.getCollabVideosCountForGroup(this.adminGroups[this.currentGroupIndex].admin);
      return;
    }
    this.crudService.getCollabVideosCountV2().subscribe(success => {
      this.collabVideosCount = success.data[0]?.collabVideosCount;
      if (!this.collabVideosCount) {
        this.collabVideosCount = 0;
      }
    })
  }

  openShareDialog(video) {
    //console.log("openShareDialog - video = ", video);

    const id = video;
    
    const dialogRef = this.dialog.open(ShareVideoDialogComponent, {
      width: '100%',
      maxWidth: '600px',
      minHeight: '400px',
      panelClass: 'my-dialog',
      height: 'auto',
      data: {
        videoID: id,
        URL: `${environment.currentSharedDomain}videos?video=${id}`,
        embedURL: `${environment.currentSharedDomain}embed/${id}`,
      },
    });
  }

  getDefaultLangTranscription(video) {
    if(video.subtitleUrl) {
      const defaultLang = this.utilityService.getTranscriptLang(video.subtitleUrl)
      const langCode = defaultLang.code.split('-')[0];
      let defaultSubTitle = {
        subtitle: {
          code: langCode,
          disabled: false,
          language: defaultLang.language
        }
      }
      defaultSubTitle[langCode] = video.subtitleUrl;
      return defaultSubTitle
    }
  }

  downloadTranscript(video) {
    this.allSubtitles = video.subtitleTranslations;
    this.language = video.subtitleTranslations[0];
    const dialogRef = this.dialog.open(this.downloadTranscriptDialog, {
      width: '100%',
      maxWidth: '500px',
      panelClass: 'my-dialog',
      height: 'auto',
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.spinner = true;
        this.crudService.getTranscripts(video._id).subscribe((res) => {
          const subtitles = [];
          let zipFileName;
          for (const datum of res.data) {
            if (datum.subtitleTranslations) {
              datum.subtitleTranslations = datum.subtitleTranslations.map(x => {
                x.subtitle = languages.find(c => c.code === Object.keys(x)[0]);
                return x;
              });
              if(datum.subtitleUrl) {
                const defaultLang = this.getDefaultLangTranscription(datum);
                datum.subtitleTranslations.unshift(defaultLang);
              }
            }
            const selectedSubtitle = datum.subtitleTranslations.find((i) => i.subtitle.language === this.language.subtitle.language);
            if (selectedSubtitle) {
              const code = this.utilityService.getTranscriptLang(selectedSubtitle[this.language.subtitle.code]).code;
              let fileName = (datum.name + '_' + selectedSubtitle.subtitle.code).toLowerCase();
              fileName = fileName.replace(/\s+/g, '_').trim() + '.txt';
              subtitles.push({
                url: selectedSubtitle[this.language.subtitle.code],
                name: fileName,
              });
              zipFileName = video.Title + ' ' + (selectedSubtitle.subtitle.code).toLowerCase();
            }
          }
          this.zipService.compress(subtitles, zipFileName).then(() => {
            this.spinner = false;
          });
          //console.log(subtitles)
        });
      }
    });
  }


  getCollabVideos(limit: number, page: number, isOnInit: boolean, sort: string) {

    const object: any = {
      limit, page, sort
    }
    if (this.currentChildTab === 'groups') {
      object.userID = this.adminGroups[this.currentGroupIndex].admin;
    }
    this.crudService.getCollabVideosV2(object).subscribe(success => {

      const mainVideos = success.data;

      if (isOnInit) {

        this.contributorVideos = mainVideos;

      } else {

        for (const item of mainVideos) {
          this.contributorVideos.push(item);
        }
      }

      /*TODO::remove this mock for subtitles*/
      for (const item of mainVideos) {
        if (item.subtitleTranslations) {
          item.subtitleTranslations = item.subtitleTranslations.map(x => {
            x.subtitle = languages.find(c => c.code === Object.keys(x)[0]);
            return x;
          });
        }
      }
      /*TODO::remove this mock for subtitles*/

      this.spinner = false;
    })
  }

  onScrollTab1(): void {

    ++this.draftPage;

    this.getInteractiveVideos(false, this.limit, this.draftPage, false, this.sort);
  }

  onScrollTab2(): void {
    ++this.collabPage;

    this.getCollabVideos(this.limit, this.collabPage, false, this.sort);
  }

  onScrollTab3(): void {
    ++this.publishedPage;

    this.getInteractiveVideos(true, this.limit, this.publishedPage, false, this.sort);
  }

  /*viewAll() {
    this.intialDraft = this.drafts;
  }

  viewAllCollaborations() {
    this.initialContributorVideos = this.contributorVideos;
  }*/

  emptyChoicesData() {
    this.topChoices = [];
    this.childChoices1 = [];
    this.childChoices2 = [];
    this.childChoices3 = [];
    this.childChoices4 = [];
    this.childChoices5 = [];
    this.childChoices6 = [];
  }

  showAnalytics_ORIGINAL(video: any) {
    this.spinner = true;
    this.currentItem = video;
    this.currentItemback = video;
    this.isSecond = false;
    this.emptyChoicesData();
    this.crudService.getTopChoicesAnalytics(video._id).subscribe((success) => {
      this.topChoices = success.data;
      // console.log('this.topChoices', this.topChoices);
      this.currentItem.totalViews = 0;
      for (const i of this.topChoices) {
        this.currentItem.totalViews = this.currentItem.totalViews + i.viewCount;
      }
      if (this.currentItem.totalViews === 0) {
        this.currentItem.totalViews = 1;
      }
      // this.currentItem.
      $('#anlyticsModal').modal('show');
      this.spinner = false;
    });
  }
  
  showAnalytics(video: any) {
    this.spinner = true;
    this.currentItem = video;
    this.currentItemback = video;
    this.isSecond = false;
    this.emptyChoicesData();
    this.crudService.getTree(video._id).subscribe((success) => {
      this.topChoices = success.data;
      // console.log('this.topChoices', this.topChoices);

      if (!!!this.topChoices?.URL) {
        this.topChoices.basck = 'gt';
      } else {
        this.topChoices.basck = 'videochart';
        this.topChoices.imvd = 'ncs';
      }

      this.openAnalyticsModal();
      this.spinner = false;
    });
  }

  getChildDetails1(id: any) {
    this.choice1 = id;
    // this.leftAarrow = false;
    this.spinner = true;

    this.crudService.getTopChoicesAnalytics(id).subscribe((success) => {
      this.childChoices1 = success.data;
      if (this.childChoices1.length == 0) {
        confirm('No child available for the selected video.');
        this.spinner = false;
      } else {
        this.resetIt = true;
        this.isSecond = true;
        this.currentItem1.totalViews = 0;
        for (const i of this.childChoices1) {
          this.currentItem1.totalViews =
            this.currentItem1.totalViews + i.viewCount;
        }
        if (this.currentItem1.totalViews == 0) {
          this.currentItem1.totalViews = 1;
        }
        this.spinner = false;
      }
    });
    // console.log(this.childChoices1)
  }

  getChildDetails2(id: any) {
    this.choice2 = id;
    this.spinner = true;

    this.crudService.getTopChoicesAnalytics(id).subscribe((success) => {
      this.childChoices2 = success.data;
      if (this.childChoices2.length == 0) {
        confirm('No child available for the selected video.');
      }
      this.resetIt = true;
      this.isSecond = true;
      this.currentItem2.totalViews = 0;
      for (const i of this.childChoices2) {
        this.currentItem2.totalViews =
          this.currentItem2.totalViews + i.viewCount;
      }
      if (this.currentItem2.totalViews == 0) {
        this.currentItem2.totalViews = 1;
      }
      // this.currentItem.
      // $('#anlyticsModal').modal('show');
      this.spinner = false;
    });
    // console.log(this.childChoices2)
  }

  getChildDetails3(id: any) {
    this.choice3 = id;
    this.spinner = true;
    this.crudService.getTopChoicesAnalytics(id).subscribe((success) => {
      this.childChoices3 = success.data;
      if (this.childChoices3.length == 0) {
        confirm('No child available for the selected video.');
        this.spinner = false;
      } else {
        this.resetIt = true;
        this.isSecond = true;
        this.currentItem3.totalViews = 0;
        for (const i of this.childChoices3) {
          this.currentItem3.totalViews =
            this.currentItem3.totalViews + i.viewCount;
        }
        if (this.currentItem3.totalViews == 0) {
          this.currentItem3.totalViews = 1;
        }
        // this.currentItem.
        // $('#anlyticsModal').modal('show');
        this.spinner = false;
      }
    });
    // console.log(this.childChoices3)
  }

  getChildDetails4(id: any) {
    this.choice4 = id;
    this.spinner = true;

    this.crudService.getTopChoicesAnalytics(id).subscribe((success) => {
      this.childChoices4 = success.data;
      if (this.childChoices4.length == 0) {
        confirm('No child available for the selected video.');
        this.spinner = false;
      } else {
        this.resetIt = true;
        this.isSecond = true;
        this.currentItem4.totalViews = 0;
        for (const i of this.childChoices4) {
          this.currentItem4.totalViews =
            this.currentItem4.totalViews + i.viewCount;
        }
        if (this.currentItem4.totalViews == 0) {
          this.currentItem4.totalViews = 1;
        }
        // this.currentItem.
        // $('#anlyticsModal').modal('show');
        this.spinner = false;
      }
    });
    // console.log(this.childChoices4)
  }

  getChildDetails5(id: any) {
    this.choice5 = id;
    this.spinner = true;

    this.crudService.getTopChoicesAnalytics(id).subscribe((success) => {
      this.childChoices5 = success.data;
      if (this.childChoices5.length == 0) {
        confirm('No child available for the selected video.');
        this.spinner = false;
      } else {
        this.resetIt = true;
        this.isSecond = true;
        this.currentItem5.totalViews = 0;
        for (const i of this.childChoices5) {
          this.currentItem5.totalViews =
            this.currentItem5.totalViews + i.viewCount;
        }
        if (this.currentItem5.totalViews == 0) {
          this.currentItem5.totalViews = 1;
        }
        // this.currentItem.
        // $('#anlyticsModal').modal('show');
        this.spinner = false;
      }
    });
    // console.log(this.childChoices5)
  }

  getChildDetails6(id: any) {
    this.choice6 = id;
    this.spinner = true;

    this.crudService.getTopChoicesAnalytics(id).subscribe((success) => {
      this.childChoices6 = success.data;
      if (this.childChoices6.length == 0) {
        confirm('No child available for the selected video.');
        this.spinner = false;
      } else {
        this.resetIt = true;
        this.isSecond = true;
        this.currentItem6.totalViews = 0;
        for (const i of this.childChoices6) {
          this.currentItem6.totalViews =
            this.currentItem6.totalViews + i.viewCount;
        }
        if (this.currentItem6.totalViews == 0) {
          this.currentItem6.totalViews = 1;
        }
        // this.currentItem6.
        // $('#anlyticsModal').modal('show');
        this.spinner = false;
      }
    });
    // console.log(this.childChoices6)
  }

  getChildDetails7(id: any) {
    this.choice7 = id;
    this.spinner = true;

    this.crudService.getTopChoicesAnalytics(id).subscribe((success) => {
      this.childChoices6 = success.data;
      if (this.childChoices6.length == 0) {
        confirm('No child available for the selected video.');
        this.spinner = false;
      } else {
        this.resetIt = true;
        this.isSecond = true;
        this.currentItem7.totalViews = 0;
        for (const i of this.childChoices6) {
          this.currentItem7.totalViews =
            this.currentItem7.totalViews + i.viewCount;
        }
        if (this.currentItem7.totalViews == 0) {
          this.currentItem7.totalViews = 1;
        }
        // this.currentItem6.
        // $('#anlyticsModal').modal('show');
        this.spinner = false;
      }
    });
    // console.log(this.childChoices6)
  }

  resetStats() {
    this.childChoices1 = [];
    this.childChoices2 = [];
    this.childChoices3 = [];
    this.childChoices4 = [];
    this.childChoices5 = [];
    this.childChoices6 = [];
    this.resetIt = false;
  }

  showVideo(video: any) {

    const id = video._id ? video?._id : video

    // if (video?.totalConnectorNodes && video?.totalConnectorNodes > 0)
    //   this.router.navigate(['videos'], {queryParams: {video: id, isConnectorView: true}});
    // else

    if (video?.isPublished)
      this.router.navigate(['/videos'], { queryParams: { video: id } });

  }

  edit_ORIGINAL(id: any, isAIVideoCreator, data?) {
    
    if(isAIVideoCreator && data?.aiVideoStatus === "Generating Video"){
      this.spinner = false;
      const dialogRef = this.dialog.open(this.generatingVideoTitle, {
        width: '100%',
        maxWidth: '500px',
        panelClass: 'generate_title',
        height: 'auto',
      });
      return
    }


    // 10192022 - To fix the issue with opening AI Editor Video uploading list if we access AI Editor before editing a video
    this.localStorageService.setItem('interactive', 'isInteractive');
    if (isAIVideoCreator) {
      //console.log("data = ", data);
      //console.log("data?.aiVideoStatus != 'DONE' = ", data?.aiVideoStatus != 'DONE');
      //console.log("data?.aiGeneratedTS == null = ", data?.aiGeneratedTS == null);
      if (data?.aiVideoStatus != 'DONE' && data?.aiGeneratedTS == null) {
        this.localStorageService.setItem('interactive', 'aiContentCreator');
        this.localStorageService.setItem('aiContentCreatorassist', String(true));
        this.localStorageService.setItem('aiVideoCreatorFlow', String(true));
  
        this.router.navigate(['ai-video-creator'], {
          queryParams: {video: id},
        });
      } else {
        this.router.navigate(['create-interactive-video'], {
          queryParams: {video: id},
        });
      }
    } else {
      this.router.navigate(['create-interactive-video'], {
        queryParams: {video: id},
      });
    }

  }

  edit(data) {
    
    if(data?.isAIVideoCreator && data?.aiVideoStatus === "Generating Video"){
      this.spinner = false;
      const dialogRef = this.dialog.open(this.generatingVideoTitle, {
        width: '100%',
        maxWidth: '500px',
        panelClass: 'generate_title',
        height: 'auto',
      });
      return
    }


    // 10192022 - To fix the issue with opening AI Editor Video uploading list if we access AI Editor before editing a video
    this.localStorageService.setItem('interactive', 'isInteractive');
    if (data?.isAIVideoCreator) {
      if (data?.aiVideoStatus != 'DONE' && data?.aiGeneratedTS == null) {
        this.localStorageService.setItem('interactive', 'aiContentCreator');
        this.localStorageService.setItem('aiContentCreatorassist', String(true));
        this.localStorageService.setItem('aiVideoCreatorFlow', String(true));
  
        this.router.navigate(['ai-video-creator'], {
          queryParams: {video: data._id},
        });
      } else {
        this.router.navigate(['create-interactive-video'], {
          queryParams: {video: data._id},
        });
      }
    } else {
      this.router.navigate(['create-interactive-video'], {
        queryParams: {video: data._id},
      });
    }

  }

  setVideoToDraft(item) {
    // console.log("setVideoToDraft");

    const obj = {
      videoId: item._id,
    };

    this.crudService.setVideoToDraft(obj).subscribe((success) => {
      this.toaster.success('Video set to draft status successfully!');
      this.onTabChange('draft');
    }, (err) => {
      this.spinner = false;
    });
  }

  cloneVideo(item) {
    const obj = {
      videoId: item._id,
      copyVideoTitle: '',
    };
    this.copyVideoTitle = item.Title + '.copy';
    const dialogRef = this.dialog.open(this.cloneDialog, {
      width: '100%',
      maxWidth: '500px',
      panelClass: 'my-dialog',
      height: 'auto',
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        if (!this.copyVideoTitle.trim()) {
          this.copyVideoTitle = item.Title + '.copy';
        }
        obj.copyVideoTitle = this.copyVideoTitle;
        this.crudService.cloneVideo(obj).subscribe(() => {
          this.toaster.success('Video copied successfully!');
          this.onTabChange('draft');
        });
      }
    });
  }

  getFollower() {
    ++this.followerPage;
    // Followers V2
    this.crudService.getFollowers(this.limit, this.followerPage).subscribe(success => {
      const followersArray = success.data.followers;
      for (const item of followersArray) {
        this.myFollowers.push(item);
      }
    })
  }

  selectToTransfer(id) {
    this.transferToFollower = id;
  }

  changeTransferTab(e) {
    this.currentTransferTab = e;
  }

  transferVideoOwnership(item) {
    this.transferToEmailAddr = '';
    this.transferToFollower = '';
    const dialogRef = this.dialog.open(this.transferDialog, {
      width: '100%',
      maxWidth: '500px',
      panelClass: 'my-dialog',
      height: 'auto',
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        const obj: any = {
          videoId: item._id
        };
        if (this.currentTransferTab === 'email') {
          obj.transferToEmailAddr = this.transferToEmailAddr;
        } else {
          obj.transferToFollower = this.transferToFollower;
        }
        this.spinner = true;
        this.crudService.transferVideoOwnership(obj).subscribe(() => {
          this.toaster.success('Video ownership transferred successfully!');
          this.getMainVideos();
        }).add(() => this.spinner = false);
      }
    });
  }

  showShareWithGroupAdminDialog(item) {

    // console.log('item = ', item);

    if (item != 'All') {
      const obj = {
        videoIds: [item._id],
        groupIds: [],
      };
      this.shareGroupAdmin = item?.adminSharedVideoGroupInfo?.map(x => x._id);

      const dialogRef = this.dialog.open(this.shareWithGroupAdminsDialog, {
        width: '100%',
        maxWidth: '500px',
        panelClass: 'my-dialog',
        height: 'auto',
      });
      dialogRef.afterClosed().subscribe((res) => {
        if (res) {
          obj.groupIds = this.shareGroupAdmin;
          this.spinner = true;
          this.crudService.adminSharedVideos(obj).subscribe((response) => {
            // console.log(response);
            this.getMainVideos();
            // this.toaster.success('Video successfully shared to group admins!');
            this.toaster.success(response.message);
          }, () => {
            this.spinner = false;
          });
        }
      });
    } else {

      // Share all published videos with the selected groups
      this.shareGroupAdmin = [];

      const object: any = {
        isPublished: true,
        limit: 0,
        page: 0,
        sort: ''
      }

      this.crudService.getallmainvideos(object).subscribe(success => {

        const mainVideos = success.data || [];

        const videoIds = mainVideos.map(x => x._id);

        // console.log("videoIds = ", videoIds);

        const obj = {
          videoIds,
          groupIds: [],
        };

        const dialogRef = this.dialog.open(this.shareWithGroupAdminsDialog, {
          width: '100%',
          maxWidth: '500px',
          panelClass: 'my-dialog',
          height: 'auto',
        });

        dialogRef.afterClosed().subscribe((res) => {
          if (res) {
            obj.groupIds = this.shareGroupAdmin;
            this.spinner = true;
            this.crudService.adminSharedVideos(obj).subscribe((response) => {
              // console.log(response);
              this.getMainVideos();
              // this.toaster.success('Video successfully shared to group admins!');
              this.toaster.success(response.message);
            }, () => {
              this.spinner = false;
            });
          }
        });
      });
    };
  };

  selectToShare(id: any) {
    if (this.shareGroupAdmin.includes(id)) {
      const index = this.shareGroupAdmin.indexOf(id);
      if (index > -1) {
        this.shareGroupAdmin.splice(index, 1);
      }
    } else {
      this.shareGroupAdmin.push(id);
    }
  }

  showContributorModal(id: any) {
    for (const i of this.contributorVideos) {
      if (id === i.videoInfo._id) {
        this.contributors = i.contributorInfo;
      }
    }
    $('#exampleModalForContributors').modal('show');
  }

  publish(item: any) {
    this.spinner = true;
    const finalObj = {
      type: 'publish',
      videoID: item._id,
    };

    if(item?.isAIVideoCreator && item?.aiVideoStatus === "Generating Video"){
      this.spinner = false;
      const dialogRef = this.dialog.open(this.generatingVideoTitle, {
        width: '100%',
        maxWidth: '500px',
        panelClass: 'generate_title',
        height: 'auto',
      });

      return
    }

    if (item.Title == '' || item.Categories.length == 0) {
      // this.toaster.warning('Please add title and at least one category');
      this.router.navigate(['create-interactive-video'], {
        queryParams: {video: item._id},
      });
    } else {
      this.crudService.publishunpublish(finalObj).subscribe(
          (success) => {
            this.localStorageService.setItem('tempToken', '848484');
            this.getMainVideos();
            this.toaster.success('Published successfully!');
          },
          (error) => {
            this.spinner = false;
          }
      );
    }
    // this.crudService.publishunpublish(finalObj).subscribe(
    //   success => {
    //     this.localStorageService.setItem('tempToken', '848484')
    //     this.getMainVideos();
    //     this.createInteractiveVideoObj.spinner = false;
    //     this.toaster.success('Published successfully!');
    //   }
    // );
  }

  showCategory(id: any) {
    this.router.navigate(['category'], {queryParams: {category: id}});
  }


  confirmDelete(id: any, data:any) {

    if(data?.isAIVideoCreator && data?.aiVideoStatus === "Generating Video"){
      this.spinner = false;
      const dialogRef = this.dialog.open(this.generatingVideoTitle, {
        width: '100%',
        maxWidth: '500px',
        panelClass: 'generate_title',
        height: 'auto',
      });

      return
    }

    this.deleteWaitingId = id;
    // console.log(this.deleteWaitingId)
    this.deleteDialogRef.open({
      header: 'Delete Video',
      title: 'Are you sure you want to delete this video?'
    }, (e) => {
      if (e) {
        this.spinner = true;
        this.crudService.deleteBookMarkVideo(id).subscribe((success) => {
          this.getMainVideos();
          this.dashboardService.onGetUserData();
          // this.createInteractiveVideoObj.spinner = false;
          this.toaster.success('Deleted successfully!');
        },  (err) => {
          this.spinner = false;
        });
      }
    });
  }

  // changeFilter(val: any) {
  //   if (val === 'recent') {
  //     this.sortedPublishedVideos.sort((a, b) => {
  //       if (a.publishTime > b.publishTime) {
  //         return -1;
  //       }
  //       return 1;
  //     });
  //   } else if (val === 'viewed') {
  //     this.sortedPublishedVideos.sort((a, b) => {
  //       if (a.viewCount > b.viewCount) {
  //         return -1;
  //       }
  //       return 1;
  //     });
  //   } else if (val === 'interactive') {
  //     this.sortedPublishedVideos.sort((a, b) => {
  //       if (a.totalchild > b.totalchild) {
  //         return -1;
  //       }
  //       return 1;
  //     });
  //   } else {
  //     this.sortedPublishedVideos.sort((a, b) => {
  //       if (a.comments.length > b.comments.length) {
  //         return -1;
  //       }
  //       return 1;
  //     });
  //   }
  // }

  changeFilter(sort: any) {

    this.sort = sort;
    if (this.currentTab === 'published') {
      this.publishedPage = 1;
      this.spinner = true;
      this.publishedVideos = [];
      this.getInteractiveVideos(true, this.limit, this.publishedPage, true, this.sort);
      // Get count of main videos
      this.getInteractiveVideosCount();
    } else if (this.currentTab === 'draft') {
      this.draftPage = 1;
      this.spinner = true;
      this.drafts = [];
      this.getInteractiveVideos(false, this.limit, this.draftPage, true, this.sort);
      // Get count of main videos
      this.getInteractiveVideosCount();
    } else {
      this.collabPage = 1;
      this.spinner = true;
      this.contributorVideos = [];
      this.getCollabVideos(this.limit, this.collabPage, true, this.sort);
      // Get count of main videos
      this.getCollabVideosCount();
    }
  }

  displayIt(id: any) {
    // console.log(id);
  }

  displayTable(c: any) {
    this.vtable = c.video._id;
    // console.log(this.vtable)
    // console.log(this.topChoices)
  }

  changeBg() {
    this.currentViews = true;
  }

  setCurrent() {
    this.currentViews = false;
  }

  // getAllGroups() {
  //   this.crudService.getAllGroups().subscribe(res => {
  //     this.allGroups = res.data
  //     // console.log('all groups',this.allGroups);
  //
  //   })
  // }

  // onMyGroup(item: any) {
  //   this.router.navigate(['/my-group/' + item._id])
  // }

  // deleteGroup(item: any) {
  //   if (this.currentUserId == item.admin) {
  //     const cnfrm = confirm('Are you sure ? Do you want to delete this group ?')
  //     if (cnfrm) {
  //       this.crudService.deleteGroup(item._id).subscribe(res => {
  //         // console.log(res);
  //         this.getAllGroups();
  //       })
  //     }
  //   }
  //   else {
  //     const cnfrm = confirm('Are you sure ? Do you want to leave this group ?')
  //     if (cnfrm) {
  //       this.crudService.leaveGroup(item._id).subscribe(res => {
  //         // console.log(res);
  //         this.getAllGroups();
  //       })
  //     }
  //   }
  //
  //
  // }
  // viewAllGroup() {
  //   this.totalGroup = this.allGroups.length;
  // }
  objectKeys(subtitleTranslations: any) {
    // console.log(subtitleTranslations)
    return subtitleTranslations.keys()
  }

  onTabChange(tab) {
    this.dashboardService.setActivePage = tab;
    this.currentChildTab = 'individual';
    this.currentTab = tab;
    this.changeFilter(this.sort);
  }

  onChildTabChange(tab) {
    this.currentChildTab = tab;
    this.changeFilter(this.sort);
  }

  onFilterGroup(i) {
    this.changeFilter(this.sort);
  }

  redirectCreateVideo() {
    if (this.localStorageService.getItem('isSubscriber') === 'true') {
      if (this.localStorageService.getItem('interactive') === 'isTemplate') {
        this.localStorageService.setItem('interactive', 'isInteractive')
        location.reload();
      } else {
        this.router.navigate(['upload-videos']);
      }
    } else {
      if (this.userData?.planName === null) {
        document.getElementById('becomeCreator').click();
      } else {
        this.router.navigate(['pricing']);
      }
    }
  }

  routeToQnaireDashboard(videoId: any) {
    this.router.navigate(['/questionnaires-dashboard/' + videoId])
  }
}
