import {Component, Inject, OnInit} from '@angular/core';
import {DashboardService} from '../../../services/dashboard.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {CrudService} from '../../../services/crud.service';
import {ToastrService} from 'ngx-toastr';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {LocalStorageService} from '../../../services/localStorage.service';
import { Meta } from '@angular/platform-browser';
import { QRCodeModalComponent } from 'src/app/afterLogin/qr-code-modal/qr-code-modal.component';
import { environment } from 'src/environments/environment';

declare var $;

interface ModalData {
    URL: string;
    embedURL: string;
    videoID: string;
    sellerID?: string;
    groupID?: string;
    title?: string;
    name?: string;
}

@Component({
    selector: 'app-share-video-dialog',
    templateUrl: './share-video-dialog.component.html',
    styleUrls: ['./share-video-dialog.component.css']
})
export class ShareVideoDialogComponent implements OnInit {
    title = 'Share Video';
    description = 'Anyone that follows your link will be able to see your first decisions on the current video';
    currentUserData: any;
    page = 0;
    limit = 10;
    myFollowers = [];
    embedVideoUrl: string;
    embedVideoUrl1: string;
    embedVideoUrl2: string;
    isLoggedIN :any;
    isEmbedShow = false;
    fullShareURL: string;
    embedURL: string;
    isCopied = false;
    shareVideos = [];
    findorselect: string;
    selectedMedia:any = 'link';
    sellerID: string;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: ModalData,
        private dashboardService: DashboardService,
        private localStorageService: LocalStorageService,
        private crudService: CrudService,
        private toaster: ToastrService,
        private meta: Meta,
        private dialog: MatDialog,
        private dialogRef: MatDialogRef<ShareVideoDialogComponent>
    ) {        
        this.isLoggedIN = localStorageService.getItem('token');
        this.fullShareURL = data.URL;
        console.log(data);
        
        this.embedURL = data.embedURL;
        if (this.embedURL) {
            
            if (data.sellerID) {
                this.sellerID = data.sellerID;
                // this.embedVideoUrl = `<iframe allowfullscreen frameborder="0" width="1200" height="800" src="${this.embedURL}"></iframe>`;
                this.embedVideoUrl = `<iframe allowfullscreen frameborder="0" width="100%" height="800" src="${this.embedURL}"></iframe>`;
            } else {
                this.embedVideoUrl = `<iframe allowfullscreen frameborder="0" width="100%" height="400" src="${this.embedURL}"></iframe>`;
            }

            console.log("this.embedVideoUrl = ", this.embedVideoUrl);
            
        }
        if (data.videoID) {
            this.embedVideoUrl1 = `<iframe allowfullscreen frameborder="0" width="100%" height="1000" src="${this.fullShareURL + '&embed=true&sharing=yes'}"></iframe>`;


            this.embedVideoUrl2 = `<iframe allowfullscreen frameborder="0" width="100%" height="784" scrolling="no" src="${this.fullShareURL + '&noTreeEmbed=yes'}"></iframe>`;
        }
        if (data.title) {
            this.title = data.title;
        }
        if (data.sellerID || data.groupID) {
            this.description = '';
        }
    }

    ngOnInit(): void {
        this.dashboardService.getUserData.pipe(takeUntil(this._unsubscribeAll)).subscribe((user) => {
            if (user) {
                this.currentUserData = user;
            }
        });
        this.onScroll();
    }

    onScroll() {
        ++this.page;

        // Followers V2
        this.crudService.getFollowers(this.limit, this.page).pipe(takeUntil(this._unsubscribeAll)).subscribe(
            success => {
                const followersArray = success.data.followers;
                for (const item of followersArray) {
                    this.myFollowers.push(item);
                }
            }
        )
    }

    copyLink() {
        this.isCopied = true;
        this.toaster.info('Link copied successfully !!');

        if(!this.isEmbedShow){
            this.sendEmailNotification('copying link');
        }

        const obj: any = {
            videoID: this.data.videoID,
            sharedWith: ["shared by copying link"]
        }

        this.crudService.shareInternally(obj).pipe(takeUntil(this._unsubscribeAll)).subscribe();
    }

    copyEmbedLink() {
        this.isCopied = true;
        this.toaster.info('Link copied successfully !!');

        this.sendEmailNotification('embedding link in iFrame');

        const obj: any = {
            videoID: this.data.videoID,
            sharedWith: ["shared by copying link for iFrame"]
        }

        this.crudService.shareInternally(obj).pipe(takeUntil(this._unsubscribeAll)).subscribe();
    }

    onClickLinkButton() {
        this.isEmbedShow = false;
        this.selectedMedia = 'link'
        this.sendEmailNotification('copying link');
        const obj: any = {
            videoID: this.data.videoID,
            sharedWith: ["shared by embedding link in iFrame"]
        }
        
        this.crudService.shareInternally(obj).pipe(takeUntil(this._unsubscribeAll)).subscribe();
    }

    onClickEmbedButton() {
        this.isEmbedShow = true;
        this.selectedMedia = 'embedLink'
        this.sendEmailNotification('embedding link in iFrame');
        const obj: any = {
            videoID: this.data.videoID,
            sharedWith: ["shared by embedding link in iFrame"]
        }

        this.crudService.shareInternally(obj).pipe(takeUntil(this._unsubscribeAll)).subscribe();
    }

    popupEmail() {
        this.selectedMedia = 'email'
        const obj: any = {
            videoID: this.data.videoID,
            sharedWith: ["shared by email"]
        }

        this.crudService.shareInternally(obj).pipe(takeUntil(this._unsubscribeAll)).subscribe();
        this.sendEmailNotification('Email');

        let url = '';
        if (this.data.sellerID) {
            url = `mailto:?subject=Community Invite&body=Check out the ${this.data.name} on FreeFuse: ${this.fullShareURL}`;
        } else if (this.data.groupID) {
            url = `mailto:?subject=Group Invite&body=Check out the ${this.data.name} on FreeFuse: ${this.fullShareURL}`;
        } else {
            url = `mailto:?subject=Interactive Video&body=Check this interactive video experience I made on FreeFuse: ${this.fullShareURL}`;
        }
        const newwindow = window.open(url);
        if (window.focus) {
            newwindow.focus()
        }
    }

    popupFacebook() {
        this.selectedMedia = 'Facebook'
        const obj: any = {
            videoID: this.data.videoID,
            sharedWith: ["shared in Facebook"]
        }

        this.crudService.shareInternally(obj).pipe(takeUntil(this._unsubscribeAll)).subscribe();
        this.sendEmailNotification('Facebook');

        const url = `https://www.facebook.com/sharer.php?u=${this.fullShareURL}`;
        const newwindow = window.open(url, 'name', 'height=500,width=520,top=200,left=300,resizable');
        if (window.focus) {
            newwindow.focus()
        }
    }

    popupTwitter() {
        this.selectedMedia = 'Twitter'
        const obj: any = {
            videoID: this.data.videoID,
            sharedWith: ["shared in Twitter/X"]
        }

        this.crudService.shareInternally(obj).pipe(takeUntil(this._unsubscribeAll)).subscribe();
        this.sendEmailNotification('X/Twitter');

        const url = `https://twitter.com/intent/tweet?text=${this.fullShareURL}`;
        const newwindow = window.open(url, 'name', 'height=500,width=520,top=200,left=300,resizable');
        if (window.focus) {
            newwindow.focus()
        }
    }
    


    popupLinkedIn() {
        this.selectedMedia = 'LinkedIn'
        const obj: any = {
            videoID: this.data.videoID,
            sharedWith: ["shared in LinkedIn"]
        }

        this.crudService.shareInternally(obj).pipe(takeUntil(this._unsubscribeAll)).subscribe();
        this.sendEmailNotification('LinkedIn');

        const encodedURL = encodeURIComponent(this.fullShareURL);
        const url = `https://www.linkedin.com/sharing/share-offsite/?url=${encodedURL}`;
        
        const newwindow = window.open(url, 'name', 'height=500,width=520,top=200,left=300,resizable');
        if (window.focus) {
            newwindow.focus()
        }
    }

    selectToShare(id: any) {
        // console.log('selectToShare - id = ', id);

        if (this.shareVideos.includes(id)) {
            const index = this.shareVideos.indexOf(id);
            if (index > -1) {
                this.shareVideos.splice(index, 1);
            }
        } else {
            this.shareVideos.push(id)
        }
    }

    shareToUsers(id: any) {
        $('#' + id).prop('disabled', true);
        const obj: any = {
            sharedWith: this.shareVideos,
        }
        if (this.data.videoID) {
            obj.videoID = this.data.videoID;
        }
        if (this.data.sellerID) {
            obj.sellerID = this.data.sellerID;
        }
        if (this.data.groupID) {
            obj.groupID = this.data.groupID;
        }
        this.crudService.shareInternally(obj).pipe(takeUntil(this._unsubscribeAll)).subscribe(success => {
            if (this.data.videoID) {
                this.toaster.success('Video Shared!')
            } else {
                this.toaster.success('Invitation sent!')
            }
            this.shareVideos = [];
            for (const i of this.myFollowers) {
                i.selected = false;
            }
            $('#' + id).prop('disabled', false);
        });

    }

    openModalForQRCode(){    
        this.selectedMedia = 'QR Code'
        this.sendEmailNotification('QR Code');

        this.dialog.open(QRCodeModalComponent, {
            width: '100%',
            maxWidth: '400px',
            panelClass: 'my-dialog',
            height: 'auto',
            data : this.data.URL
        });
        //this.dialogRef.close();
    }

    sendEmailNotification(mediaType:any){
        let payload:any = {
            mediaType,
        }
        
        if(this.data?.groupID){
            payload.groupId =  this.data?.groupID
        }

        if(this.data?.videoID){
            payload.videoId =  this.data?.videoID
        }

        if(this.data?.sellerID){
            payload.sellerID =  this.data?.sellerID
        }

        this.crudService.sendEmailNotificationForShareLink(payload).subscribe();
    }

    searchUser(value:any){
        this.crudService.searchFollowers(value).pipe(takeUntil(this._unsubscribeAll)).subscribe(
            success => {
                const followersArray = success.data.followers;
                this.myFollowers = success.data.followers
            }
        )
    }
}
