import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, OnDestroy, OnInit, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { DeleteConfirmationComponent } from '../../../layout/modals/delete-confirmation/delete-confirmation.component';
import { BehaviorSubject } from 'rxjs';
import { EventType, IFormField, MessageType } from '../createInteractiveVideo.interface';
import { HoursCount, MinutesSecondsCount, createVideoEvent } from '../createInteractiveVideo.data';
import { DashboardService } from '../../../services/dashboard.service';
import { CrudService } from '../../../services/crud.service';
import { UtilityService } from '../../../services/utility.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { debounceTime, distinctUntilChanged, filter, takeUntil, tap } from 'rxjs/operators';
import { EditVideoComponent } from '../edit-video/edit-video.component';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import languages from '../../../services/languages';
import { CreateVideoComponent } from '../../../layout/modals/create-video/create-video.component';
import { UploadedFileResponse } from '../../../interfaces/upload-video.interface';
import { PositionChoicesComponent } from '../position-choices/position-choices.component';
import { CreateQuestionnaireComponent } from '../../../layout/modals/create-questionnaire/create-questionnaire.component';
import sanitizeSVG from '@mattkrick/sanitize-svg';
import { LocalStorageService } from '../../../services/localStorage.service';
import * as _ from 'lodash';
import { HttpClient } from '@angular/common/http';

declare var $;
declare var d3;

@Component({
    selector: 'app-create-video',
    templateUrl: './create-inter-active-video1.component.html',
    styleUrls: ['./create-inter-active-video1.component.css']
})
export class CreateInterActiveVideo1Component implements OnInit, OnDestroy, AfterViewInit {

    @ViewChild('popTmpAddChoiceEle') popTmpAddChoiceEle: PopoverDirective;
    @ViewChild('deleteDialogRef') deleteDialogRef: DeleteConfirmationComponent;
    @ViewChild('unlinkEndNodeDialog') unlinkEndNodeDialog: TemplateRef<any>;
    @ViewChild('updateTranscriptDialog') updateTranscriptDialog: any;
    @ViewChild('updateAIGeneratedScriptDialog') updateAIGeneratedScriptDialog: any;
    @ViewChild('confirmationDialog') confirmationDialog: any;
    @ViewChild('setTimerForCurrentNodeDialog') setTimerForCurrentNodeDialog: any;

    findorselect: string;
    currentVideoData: any = {};
    buttonType: string;
    name;
    videoName: any = null;
    currentType = 'application/x-mpegURL';
    currentHeight: any;
    // videoShareTo: VideoShareTo[] = [];
    // videoShareToValues = [];
    groupList = [];
    followerListForPublic = [];

    allGroups: any = [];
    allFollowers: any = [];
    groupLength: number;
    followersLength: number;
    inputValues: string[] = [];
    inputLength: any[] = ['']
    isDisableInput: boolean = false

    private spinner: any = false;

    item: any = ';';
    private contrubutors: any = [];
    private zoomRef: any = 1;
    private interval: any;
    private visRef: any;
    footerSection: any;
    buttonView: any;
    zoomBtnPosition: any;
    iconPosition: any = '0px';
    createInteractiveVideoObj: any = {
        spinner: false,
        selectedType: '',
        selectedVideoCheck: false,
        interactiveStep: 'one',
        tree: '',
        root: '',
        duration: 750,
        diagonal: '',
        svg: '',
        i: 0,
        currentObj: {},
        selectedItem: '',
        finalObj: [],
        endingNodes: [],
        connectorNodes: [],
        currentPlayingItem: {},
        mainVideo: '',
        child: [],
        publish: false,
        currentNodeOption: '',
        preview: false,
        // WC 03102022
        totalUploadedVideoCount: 0,
        // WC 04062022
        isRestricted: false,
        pathways: [],
        finalObjTree: [],
        conclusionLinks: [],
        activePathNodes: [],
    };
    clonedArr;
    allPossiblePaths = [];
    totalPaths = [];
    currentBtnName: any = null;
    isOpenCreateVideo: any = false;
    OpenPopupAll: any = false;
    availableCategory: any = [];
    languages = languages;
    targetLanguages = [];
    alreadyTargetedLanguages = [];
    allowTranslate = false;
    isShareOpen: any = false;
    createChoiceObj: any = { child: [], currentNo: 2, parent: '' };
    mainID: any = '';

    followers: Array<any> = [];
    following: Array<any> = [];

    // WC 04062022 - To handle uploaded list of AI Editor Videos
    private aiEditorVideo = 'no';
    private collabLimit = 10;
    private collabPage = 1;
    private contributors = [];
    // WC 04062022

    subtool = false;
    minValue = 50;
    maxValue = 200;
    value = 0;
    highValue = 100;
    isPrivate = false;
    options: any = {
        floor: 0,
        ceil: 100,
    };

    isProjectCreated = false;
    selectedTime: string = '';
    userMessage: string = '';

    childTitleChange$ = new BehaviorSubject('');
    titleChange$ = new BehaviorSubject('');
    duationChange$ = new BehaviorSubject(0);
    userPromptChange$ = new BehaviorSubject('');
    nodes = [];
    latestNodeId: string;

    undoOrderedNodes = [];
    redoOrderedNodes = [];

    // WC 11152022
    maxNoOfCreatedVideos = 0;
    private currNoOfRetries = 0;
    planInfo: any = null;
    currSubscriptionId = '';
    pricingUrl = '';
    lstForm: IFormField[] = [];

    // Form declaration for email exchanges for videos
    formEmail = this.formBuilder.group({});
    // WC 09062022

    editWatchPath: any;
    isAddWatchPath = false;
    startWatchNode: any;
    endWatchNode: any;
    assignBadge: any;
    assignPublishBadge: any;
    watchPathName = '';
    submitPathWaysFormError = '';
    isSubmitPathWaysForm = false;
    pathNodes = [];
    allPathNodes = [];
    selectedPathNodes = [];
    selectedPathIndex = 0;
    isSelectedPathIndex = false;
    messages: MessageType[] = [];
    currentMessageStep = 0;
    msgValue = '';
    isMessageLoad = false;
    messageMap = {
        rename: 'Rename Pathway',
        restart: 'Update Starting & Ending Nodes',
        addBadge: 'Assign Badge',
        selectBadge: 'Choose Badge',
        reChooseEndNode: 'Update Ending Node',
        addLearningObjectives: 'Set Objectives',
        hubSpotLifeCycle: 'Set HubSpot LifeCycle',
        save: 'Save',
        cancel: 'Cancel',
    };
    userData: any;
    objectivesPopupData: any;
    isDisplayPopupWithObjective:boolean = false

    private isInitialStep = true;
    isAssistCreateVideo: any;
    assistEventsSubject = new BehaviorSubject<EventType>({ type: '' });
    private msgEvent = createVideoEvent;
    private zoomListener: any;
    dragDropNode = false;
    deleteCurrentNodeType = 1;
    private rootInitialPosition: any;
    isReorderChoice = false;

    language;
    textareaData;
    initialValue;
    preSignedUrltreeData: any = []
    treeData: any = []

    svgHeight;
    svgWidth;
    svgHeightForReset;
    svgWidthForReset;
    scaleForReset;
    //WC 02172024
    scaleIncrement;

    hoursCount = HoursCount;
    minutesSecondsCount = MinutesSecondsCount;
    hh: string = '00';
    mm: string = '00';
    ss: string = '00';
    isHHSelected = false;
    isMMSelected = false;
    isSSSelected = false;
    isChatWindowVisible: boolean = false;
    userMeData:any;
    lifeCycleStages:any;
    ishubSpotLifeCycle:boolean = false;
    hubSpotLifeCycle:any;
    isAiContentCreatorassist: any;
    selectedNodesScript: string = '';
    userPrompt:any; 

    constructor(
        private dashboardService: DashboardService,
        private urlService: CrudService,
        private utilityService: UtilityService,
        private router: Router,
        private toastr: ToastrService,
        private activatedRoute: ActivatedRoute,
        private dialog: MatDialog,
        private formBuilder: FormBuilder,
        private localStorageService: LocalStorageService,
        private location: Location,
        private http: HttpClient,
        private cdr: ChangeDetectorRef
    ) {
        this.initialFunction('');
       

        this.isAssistCreateVideo = this.localStorageService.getItem('assistCreateVideo');
        this.isAiContentCreatorassist = this.localStorageService.getItem('aiContentCreatorassist');
        this.activatedRoute.queryParams.subscribe((params) => {
            
            this.createInteractiveVideoObj.currentObj.id = params.video;
            this.createInteractiveVideoObj.currentObj._id = params.video;
            this.mainID = params.video;

            if (params.node) {
                setTimeout(() => {
                    $('#tree-Modal').modal('show');
                    
                    let nodeData = this.treeData.filter((i: any) => i._id === params.node);
                    
                    this.createInteractiveVideoObj.currentObj = nodeData[0];
                    this.createInteractiveVideoObj.currentObj.open = true;
                    this.videoName = this.createInteractiveVideoObj.currentObj.name
                    $('#tree-Modal').on('hidden.bs.modal', () => {
                        this.router.navigate([], {
                            queryParams: {
                                'node': null,
                            },
                            queryParamsHandling: 'merge'
                        })
                    });
                }, 5000);
            }

            this.initialFunction('');
            // console.log('initial call')
        });

        // setTimeout(() => {
        //   console.log(this.createInteractiveVideoObj)
        // }, 5000)
    }

    get dragListOrientation() {
        return window.innerWidth < 768 ? 'vertical' : 'horizontal';
    }

    get isStoryBoard() {
        return this.buttonType === 'storyBoard';
    }

    get filterFollowers() {
        const keys = 'username,firstname,lastname';
        return (this.followers || []).filter(item => {
            item = item.followersDetails[0];
            return keys.split(',').some(key => item.hasOwnProperty(key) && new RegExp(this.findorselect, 'gi').test(item[key]))
        });
    }

    get isTimer() {
        return this.createInteractiveVideoObj.currentObj?.timer && (this.createInteractiveVideoObj.currentObj?.timer?.hh || this.createInteractiveVideoObj.currentObj?.timer?.mm || this.createInteractiveVideoObj.currentObj?.timer?.ss);
    }

    get timeString() {
        if(this.isTimer) {
            return `${this.pad(this.createInteractiveVideoObj.currentObj?.timer?.hh)}:${this.pad(this.createInteractiveVideoObj.currentObj?.timer?.mm)}:${this.pad(this.createInteractiveVideoObj.currentObj?.timer?.ss)}`;
        }
        return '00:00:00';
    }

    pad(val: number): string {
        return val < 10 ? '0' + val : val.toString();
    }

    get canSaveChoices() {
        return this.createChoiceObj.child.find(x => !x?._id);
    }

    get canPublishVideo() {
        return !this.createInteractiveVideoObj.finalObj.find(x => !!!x.URL);
    }
    @ViewChildren('inputField') inputFields: QueryList<ElementRef>;

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        if (this.createInteractiveVideoObj.finalObjTree.length) {
            this.callTree(this.createInteractiveVideoObj.finalObjTree);
        }
        setTimeout(() => {
            this.setHorizontalScrollbarPosition();
        }, 100);
    }

    ngOnInit() {
        // WC 11152022
        // this.getEmailsforVideoForm();
        // WC 11152022

        this.checkTranslationAvailability();
        this.getUserInfo();


        this.buttonType = this.localStorageService.getItem('interactive');
        // this.buttonType = 'storyBoard';

        // console.log('this.buttonType = ', this.buttonType);

        // WC 04062022 -- To handle listing of uploaded AI Editor Video
        if (this.localStorageService.getItem('interactive') === 'isEditor')
            this.aiEditorVideo = 'yes';
        else
            this.aiEditorVideo = 'no';

        // console.log("this.aiEditorVideo = ", this.aiEditorVideo);
        // WC 04062022
        // this.activatedRoute.queryParams.subscribe((params) => {
        //     // console.log(params)
        //     this.initialFunction('');
        // });

        if (this.isAssistCreateVideo) {
            this.assistEventsSubject.next({ type: this.msgEvent.init });
        }
        if (this.isAiContentCreatorassist) {
            this.assistEventsSubject.next({ type: this.msgEvent.init });
        }
        // WC 04062022
        // this.getFollowersList();
        // if (this.mainID != undefined) this.getFollowerContributors();
        // WC 04062022

        $(document).ready(() => {
            $('.filterBtn,.fa-times').click(() => {
                $('#dropdownMenu').slideToggle();
            });
        });

        $('.modal').on('hidden.bs.modal', () => {
            $('video').trigger('pause');
        });

        this.getGroupList();
        this.getFollowersList();
        if (this.buttonType === 'isInteractive') {
            this.currentBtnName = 'Create interactive Video';
        } else {
            this.currentBtnName = 'Auto Edit';
        }

        if (this.isStoryBoard && !this.mainID && !this.isAssistCreateVideo) {
            this.createEmptyNodes();
        }

    }

    ngOnDestroy(): void {
        this.localStorageService.removeItem('interactive');
        this.localStorageService.removeItem('freeTemp');
        this.localStorageService.removeItem('assistCreateVideo');
        this.localStorageService.removeItem('aiContentCreatorassist');
        this.localStorageService.removeItem('aiVideoCreatorFlow');
    }

    ngAfterViewInit() {

        this.titleChange$
            .asObservable()
            .pipe(
                filter(Boolean),
                debounceTime(1500),
                distinctUntilChanged(),
                tap((event) => {
                    // console.log(event);
                    this.updateCurrentName(event);
                })
            )
            .subscribe();
        this.childTitleChange$
            .asObservable()
            .pipe(
                filter(Boolean),
                debounceTime(1500),
                distinctUntilChanged(),
                tap((event) => {
                    // console.log(event);
                    this.updateChildNodeName(event);
                })
            )
            .subscribe();
        this.duationChange$
            .asObservable()
            .pipe(
                filter(Boolean),
                debounceTime(1500),
                distinctUntilChanged(),
                tap((event) => {
                    // console.log('duration', event);
                    this.updateCurrentTime(event);
                })
            )
            .subscribe();
        this.userPromptChange$
            .asObservable()
            .pipe(
                filter(Boolean),
                debounceTime(1500),
                distinctUntilChanged(),
                tap((event) => {
                    // console.log('duration', event);
                    this.updateVideoUserPromptValue(event);
                })
            )
            .subscribe();
    }


    getUserInfo() {

        this.dashboardService.getUserData.subscribe((res) => {
            if (res) {
                this.userData = res;
            }
        });
    }

    getFirstAndLastTwoElements(array: any[]): any[] {
        if (array.length < 4) {
            return array;
        } else {
            return array.slice(0, 2).concat(array.slice(-2));
        }
    }

    checkTranslationAvailability() {

        this.urlService.getUserSubscriptionPlan().subscribe(success => {
            this.planInfo = success.data.subscriptionPlanInfo;

            // console.log("this.planInfo.name = ", this.planInfo.name);

            this.allowTranslate = !this.planInfo.name.includes('Freemium') && !this.planInfo.name.includes('Bronze');

        });
    }


    headerSearchValues(value: string) {
        if (value) {
            const matchingItems = this.nodes.filter(item => item.name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
            const notMatchingItems = this.nodes.filter(item => item.name.toLowerCase().indexOf(value.toLowerCase()) === -1);
            matchingItems.forEach((i) => {
                const matchNodeItem = document.getElementById(`node-button-${i.id}`);
                matchNodeItem.style.fill = '#d3c1eb';
            });
            notMatchingItems.forEach((i) => {
                const notMatchNodeItem = document.getElementById(`node-button-${i.id}`);
                notMatchNodeItem.style.fill = null;
            });
        } else {
            this.nodes.forEach((i) => {
                const allNodeItem = document.getElementById(`node-button-${i.id}`);
                allNodeItem.style.fill = null;
            });
            const latestItem = this.getLatestItem(this.nodes, this.latestNodeId);
            const latestNodeItem = document.getElementById(`node-button-${latestItem[0].id}`);
            latestNodeItem.style.fill = '#d3c1eb';
        }
    }


    // WC 04062022 -- Scrolling Collaborators
    onScrollCollab(): void {
        ++this.collabPage;

        // console.log("onScrollCollab - this.page: " + this.page);

        this.getFollowerContributors();
    }

    onCollabChange(follower: any): void {
        //console.log('onCollabChange - follower = ', follower);

        this.createInteractiveVideoObj.spinner = true;
        // This follower is not a contributor yet
        if (follower.followersWhoContribute.length == 0) {
            const contributor = {
                videoID: this.mainID,
                contributorID: follower.followersDetails[0]._id,
            };

            this.urlService.setContributorV2(contributor).subscribe((success) => {
                follower.followersWhoContribute.push(success.data);
                this.toastr.success(
                    follower.followersDetails[0].username + ' added as a contributor'
                );
            }).add(() => this.createInteractiveVideoObj.spinner = false);
        } else {
            // Remove the contributor
            const docId = follower.followersWhoContribute[0]._id;

            //console.log('Remove Contributor - docId: ', docId);
            const data = { id: docId };
            this.urlService.deleteContributor(data).subscribe((success) => {
                follower.followersWhoContribute = [];
                this.toastr.success(
                    follower.followersDetails[0].username + ' removed as a contributor'
                );
            }).add(() => this.createInteractiveVideoObj.spinner = false);
        }

        // this.createInteractiveVideoObj.spinner = false;
    }

    // WC 04062022 -- Scrolling Collaborators

    getGroupList() {
        // const id = this.localStorageService.getItem('users');
        this.urlService.getAllGroups().subscribe((res) => {
            // const type: GroupType[] = [];
            // res.data.forEach((e: any, i: number) => {
            //   type.push({value: e._id, data: e});
            // });
            // this.videoShareTo.push({name: 'Groups', type});
            // console.log("this.videoShareTo for Groups: ", this.videoShareTo);

            this.allGroups = res?.data;
            this.groupLength = this.allGroups?.length;
        });
    }

    onScrollFollowers() {
        ++this.collabPage;
        this.getFollowersList();
    }

    getFollowersList(reset = false) {
        if (reset) {
            this.collabPage = 1;
            this.allFollowers = [];
        }
        this.urlService.getFollowers(this.collabLimit, this.collabPage).subscribe((res) => {
            this.allFollowers.push(...res.data.followers);
        });
        // console.log(this.allFollowers)
    }

    getFollowerContributors() {
        // console.log("this.mainID ", this.mainID);
        if (!this.mainID) {
            return;
        }
        this.urlService
            .getFollowerContributors(this.mainID, this.collabLimit, this.collabPage)
            .subscribe((res) => {
                // const type: GroupType[] = [];
                // res.data.forEach((e: any, i: number) => {
                //   const info = {
                //     _id: e.followersDetails[0]._id,
                //     username: e.followersDetails[0].username,
                //     profilePic: e.followersDetails[0].profilePic,
                //   };
                //   type.push({value: e._id, data: info});
                // });

                // this.videoShareTo.push({name: 'Followers', type});
                // console.log("this.videoShareTo for Followers: ", this.videoShareTo);

                this.followers.push(...res.data);

                // console.log("this.followers: ", this.followers);

                this.followersLength = this.followers.length;
            });
    }

    // WC 04062022

    recipientValueCheckV2(event: any) {
        const groupList = [];
        const followersList = [];
        setTimeout(() => {
            this.allGroups.forEach((e) => {
                if (e.checked) {
                    groupList.push(e._id)
                }
            });
            // console.log(this.allGroups, groupList)
            this.allFollowers.forEach((e) => {
                if (e.checked) {
                    followersList.push(e._id)
                }
            });
            this.groupList = groupList;
            this.followerListForPublic = followersList;
        });
    }

    getPublicPrivate(event: any) {
        this.isPrivate = event.checked;
        $(document).ready(() => {
            $('.filterBtn,.fa-times').click(() => {
                $('#dropdownMenu').slideToggle();
            });
        });
    }

    initialFunction(p) {
        // this.getCurrentWidth();
        const freeTemp = this.localStorageService.getItem('freeTemp');
        
        if (freeTemp) {
            this.clickSelectVideo();
        }
        if (this.mainID) {
            this.getMainVideoInfo();
        }
    }

    hideShowFooter() {
        const width = window.innerWidth;
        if (this.footerSection === 'none') {
            this.footerSection = 'block';
            this.zoomBtnPosition = '70px';
            if (width < 991) {
                this.zoomBtnPosition = '115px';
            }
            this.iconPosition = '98px';
        } else {
            this.footerSection = 'none';
            this.zoomBtnPosition = '115px';
            this.iconPosition = '0px';
        }
    }

    getCurrentWidth() {
        const width = window.innerWidth;
        if (width < 991) {
            this.footerSection = 'none';
            this.buttonView = 'block';
            this.zoomBtnPosition = '115px';
        } else {
            this.footerSection = 'block';
            this.buttonView = 'none';
            this.zoomBtnPosition = '70px';
        }
        return width;
    }
    callChild(p: any) {
        
        this.urlService
            .getChildVideosAll(this.createInteractiveVideoObj.currentObj._id)
            .subscribe((success) => {
                
                const tree = [];
                if (!!!this.createInteractiveVideoObj.currentObj.URL) {
                    this.createInteractiveVideoObj.currentObj.name = this.createInteractiveVideoObj.currentObj.name ? this.createInteractiveVideoObj.currentObj.name : 'Add Video';
                    this.createInteractiveVideoObj.currentObj.poster1 = 'assets/images/H-vidcam.svg';
                    this.createInteractiveVideoObj.currentObj.basck = 'gt';
                    this.createInteractiveVideoObj.currentObj.title = this.createInteractiveVideoObj.currentObj.title ? this.createInteractiveVideoObj.currentObj.title : 'Add Video';
                    this.createInteractiveVideoObj.currentObj.imvd = 'imvd';
                } else {
                    this.createInteractiveVideoObj.currentObj.basck = 'videochart';
                    this.createInteractiveVideoObj.currentObj.imvd = 'ncs';
                }
                this.createInteractiveVideoObj.currentObj.parentId = '0';
                tree.push(this.createInteractiveVideoObj.currentObj);
                for (const i of success.data) {
                    // i.children = null;
                    let isCheckUrl = false;
                    if (i.URL) {
                        isCheckUrl = this.utilityService.checkIsVideoUrl(i.URL);
                    }
                    i.transcriptLang = this.utilityService.getTranscriptLang(i.subtitleUrl);
                    i.isUrl = !isCheckUrl;
                    i.durationInHHMMSS = this.utilityService.convertSecondsToHHMMSSFormat(i.duration);
                    if (!!!i.URL) {
                        i.name = i.name ? i.name : 'Add Video';
                        i.poster1 = 'assets/images/H-vidcam.svg';
                        i.basck = 'gt';
                        i.title = i.title ? i.title : 'Add Video';
                        i.imvd = 'imvd';
                    } else {
                        i.basck = 'videochart';
                        i.imvd = 'ncs';
                    }
                    if (i.subtitleTranslations) {
                        i.subtitleTranslations = i.subtitleTranslations.map(x => {
                            x.subtitle = languages.find(c => c.code === Object.keys(x)[0]);
                            return x;
                        });
                        if (i.subtitleUrl) {
                            const defaultLang = this.getDefaultLangTranscription(i);
                            i.subtitleTranslations.unshift(defaultLang);
                        }
                    }
                    tree.push(i);
                    
                    this.treeData = tree
                }
                this.createInteractiveVideoObj.selectedVideoCheck = false;
                this.createInteractiveVideoObj.interactiveStep = 'three';
                this.createInteractiveVideoObj.spinner = false;
                this.createInteractiveVideoObj.finalObj = tree;
                this.createInteractiveVideoObj.endingNodes = tree.filter(x => x.isEndingNode);
                this.createInteractiveVideoObj.connectorNodes = tree.filter(x => x.isConnectorNode);
                this.createInteractiveVideoObj.currentPlayingItem = tree[0];
                this.createInteractiveVideoObj.currentObj.open = false;
                this.createInteractiveVideoObj.finalObjTree = this.createNestedArray(tree);
                this.getVideoPathWays();
                this.callTree(this.createInteractiveVideoObj.finalObjTree);
                // console.log("====> ", this.createInteractiveVideoObj.finalObjTree)
                // this.editVideo();
                if (this.currentVideoData?.isEdit) {
                    const currentObj = this.createInteractiveVideoObj.finalObj.find(x => x._id === this.currentVideoData._id)
                    if (currentObj) {
                        this.clickedItem(currentObj)
                    }
                }
            });
    }

    callChild1(d?) {
        $('#tree-view').html();
        $('svg[width="100%"]').remove();

        this.urlService.getChildVideosAll(this.mainID).subscribe((success) => {
            const tree = [];
            this.createInteractiveVideoObj.mainVideo.parentId = '0';
            this.createInteractiveVideoObj.mainVideo.level = 0;
            if (!!!this.createInteractiveVideoObj.mainVideo.URL) {
                this.createInteractiveVideoObj.mainVideo.name = this.createInteractiveVideoObj.mainVideo.name ? this.createInteractiveVideoObj.mainVideo.name : 'Add Video';
                this.createInteractiveVideoObj.mainVideo.poster1 = 'assets/images/H-vidcam.svg';
                this.createInteractiveVideoObj.mainVideo.basck = 'gt';
                this.createInteractiveVideoObj.mainVideo.title = this.createInteractiveVideoObj.mainVideo.title ? this.createInteractiveVideoObj.mainVideo.title : 'Add Video';
                this.createInteractiveVideoObj.mainVideo.imvd = 'imvd';
            } else {
                this.createInteractiveVideoObj.mainVideo.basck = 'videochart';
                this.createInteractiveVideoObj.mainVideo.imvd = 'ncs';
            }
            tree.push(this.createInteractiveVideoObj.mainVideo);
            for (const i of success.data) {
                // i.children = null;
                let isCheckUrl = false;
                if (i.URL) {
                    isCheckUrl = this.utilityService.checkIsVideoUrl(i.URL);
                }
                i.transcriptLang = this.utilityService.getTranscriptLang(i.subtitleUrl);
                i.isUrl = !isCheckUrl;
                i.durationInHHMMSS = this.utilityService.convertSecondsToHHMMSSFormat(i.duration);
                if (!!!i.URL) {
                    i.name = i.name ? i.name : 'Add Video';
                    i.poster1 = 'assets/images/H-vidcam.svg';
                    i.basck = 'gt';
                    i.title = i.title ? i.title : 'Add Video';
                    i.imvd = 'imvd';
                } else {
                    i.basck = 'videochart';
                    i.imvd = 'ncs';
                }
                i.id = undefined;
                if (i.subtitleTranslations) {
                    i.subtitleTranslations = i.subtitleTranslations.map(x => {
                        x.subtitle = languages.find(c => c.code === Object.keys(x)[0]);
                        return x;
                    });
                    if (i.subtitleUrl) {
                        const defaultLang = this.getDefaultLangTranscription(i);
                        i.subtitleTranslations.unshift(defaultLang);
                    }
                }
                tree.push(i);
            }
            this.createInteractiveVideoObj.spinner = false;
            this.createInteractiveVideoObj.finalObj = tree;
            this.createInteractiveVideoObj.endingNodes = tree.filter(x => x.isEndingNode);
            this.createInteractiveVideoObj.connectorNodes = tree.filter(x => x.isConnectorNode);

            if (d) {
                this.createInteractiveVideoObj.connectorNodes = tree.filter(x => x.isConnectorNode === true && d._id !== x._id);
            }
            this.createInteractiveVideoObj.finalObjTree = this.createNestedArray(tree);
            this.getVideoPathWays();
            this.callTree(this.createInteractiveVideoObj.finalObjTree);
        });
    }

    createNestedArray(list) {
        this.createInteractiveVideoObj.conclusionLinks = [];
        // list[8].endingNodeId = list[1]._id;
        const map = {};
        let node;
        const roots = [];
        let i;
        for (i = 0; i < list.length; i += 1) {
            map[list[i]._id] = i; // initialize the map
            list[i].children = []; // initialize the children

            //WC 12/09/2023 --- To hold connectorChildren
            list[i].connectorChildren = [];
        }
        for (i = 0; i < list.length; i += 1) {
            node = list[i];
            if (node.endingNodeId && list[map[node.endingNodeId]]) {
                if (!list[map[node.endingNodeId]].connection) {
                    list[map[node.endingNodeId]].connection = 1;
                } else {
                    list[map[node.endingNodeId]].connection += 1;
                }
                if (list[map[node.endingNodeId]].isEndingNode) {
                    if (!list[map[node.parentId]].endNodes) {
                        list[map[node.parentId]].endNodes = [];
                    }
                    list[map[node.parentId]].endNodes.push(list[map[node.endingNodeId]]);
                    // add direct links between endnodes
                    this.createInteractiveVideoObj.conclusionLinks.push({
                        source: list[map[node.endingNodeId]],
                        target: list[map[node.parentId]],
                        type: 'conclusion'
                    });
                    continue;
                }
            }

            if (node.isConnectorNode && node.parentIds && node.parentIds.length > 0) {
                for (const parentId of node.parentIds) {
                    if (list[map[parentId]]) {
                        this.createInteractiveVideoObj.conclusionLinks.push({
                            source: list[map[parentId]],
                            target: node,
                            type: 'conclusion'
                        });
                    }
                    continue;
                }
            }
            if (node.parentId !== '0') {
                // if you have dangling branches check that map[node.parentId] exists
                if (list[map[node.parentId]])
                    list[map[node.parentId]].children.push(node);

                //WC 12/09/2023 --- To store connectorChildren
                if (node.parentIds != undefined) {
                    for (const parentId of node.parentIds) {
                        //WC 12/09/23 -- For each connector child, there will be one real parent where the connector child will be registered as children instead of connector children
                        if (list[map[parentId]]?.children && list[map[parentId]].children.length > 0) {

                            //If the connector child is registered as a children then don't add it to the connector children
                            const existingChild = list[map[parentId]].children.filter(x => { x._id == node._id });

                            if (!existingChild)
                                list[map[parentId]].connectorChildren.push(node);
                        } else
                            list[map[parentId]]?.connectorChildren.push(node);
                    }
                }
            } else {
                roots.push(node);
            }
        }

        //console.log("roots = ", roots);

        const clonedArr = _.cloneDeep(roots);
        this.clonedArr = clonedArr;
        this.utilityService.updateConnectorChildren(clonedArr[0]);

        // const allPaths = this.utilityService.listPaths(clonedArr[0]);

        return roots;
    }

    // checkedItem(val) {
    //   this.createInteractiveVideoObj.selectedItem = val;
    // }

    closeModal() {
        this.createInteractiveVideoObj.selectedItem = {};
        $('video').trigger('pause');
    }

    // closeUploadModal() {
    //     // console.log("In closeUploadModal");
    //
    //     this.isOpenCreateVideo = false;
    //     this.createInteractiveVideoObj.selectedItem = {};
    //     this.createInteractiveVideoObj.interactiveStep = 'one';
    //     this.createInteractiveVideoObj.selectedVideoCheck = true;
    //     $('video').trigger('pause');
    //
    //     const freeTemp = this.localStorageService.getItem('freeTemp');
    //     // console.log("In closeUploadModal, freeTemp: ", freeTemp);
    //
    //     if (freeTemp) {
    //         this.router.navigate(['/upload-videos']);
    //     }
    // }

    checkDocument(url:any) {
        // console.log("url", url);
        
        this.http.get(url, { responseType: 'blob' }).subscribe(
            (response: Blob) => {
            const contentType = response.type;
            return ['application/pdf'].includes(contentType)
        },
        );
    }

    callTree(data) {

        if (this.createInteractiveVideoObj.interactiveStep === 'two') {
            return;
        }

        const a = this.getCurrentWidth();
        $('#tree-view svg').remove();
        this.createInteractiveVideoObj.spinner = true;
        this.createInteractiveVideoObj.interactiveStep = 'two';
        setTimeout(() => {
            const that = this;
            if (a < 991) {
                this.currentHeight = $(window).height();
            } else {
                this.currentHeight = $(window).height() - 250;
            }

            const m = [20, 20, 20, 20];
            const w = $(window).width();
            let h = this.currentHeight;
            let i = 0;

            const x = d3.scale.linear().domain([0, w]).range([0, w]);
            const y = d3.scale.linear().domain([0, h]).range([0, h]);
            let root;

            that.zoomListener = d3.behavior.zoom().x(x).y(y).scaleExtent([0.1, 10]).on('zoom', zoom);

            const vis = d3
                .select('#tree-view')
                .append('svg:svg')
                .attr('viewBox', '0 -300 900 900')
                .attr('class', 'svgViewBox')
                .attr('id', 'svg-id')
                .attr('width', w)
                .attr('height', this.currentHeight)
                .attr('xmlns', 'http://www.w3.org/2000/svg')
                .append('svg:g')

                .attr('id', 'rootg')
                .call(that.zoomListener);


            this.visRef = vis;

            vis.append('defs').append('marker')
                .attr('id', 'arrowhead')
                .attr('viewBox', '0 -5 10 10')
                .attr('refX', 10)
                .attr('refY', 0)
                .attr('markerWidth', 6)
                .attr('markerHeight', 6)
                .attr('orient', 'auto')
                .append('path')
                .attr('d', 'M0,-5L10,0L0,5');

            vis
                .append('rect')
                .attr('class', 'overlay')
                .attr('width', w + m[1] + m[3])
                .attr('height', h + m[0] + m[2])
                .attr('opacity', 0)
                .attr('fill', '#eee');

            let tree = d3.layout
                .tree()
                .size([h, w])
                .nodeSize([200 + 10, 700 + 10])
                .separation(() => {
                    return 0.5;
                });

            const diagonal = d3.svg.diagonal().projection((d) => {
                return [d.y, d.x];
            });

            root = data[0];
            root.x0 = h / 2;
            root.y0 = 0;

            // console.log("callTree - h = ", h);
            // console.log("root = ", root);
            // console.log("root.x0 = ", root.x0);
            // console.log("root.y0 = ", root.y0);



            // initialize the display to show a few nodes.

            this.visRef.attr('class', this.dragDropNode ? 'rearrange' : '');

            update(root);

            function update(source) {
                i = 0;
                const duration = d3.event && d3.event.altKey ? 5000 : 500;
                // Compute the new height, function counts total children of root node and sets tree height accordingly.
                // This prevents the layout looking squashed when new nodes are made visible or looking sparse when nodes are removed
                // This makes the layout more consistent.
                const levelWidth = [1];
                const childCount = (level, n) => {

                    if (n.children && n.children.length > 0) {
                        if (levelWidth.length <= level + 1) levelWidth.push(0);

                        levelWidth[level + 1] += n.children.length;
                        n.children.forEach(d => {
                            childCount(level + 1, d);
                        });
                    }
                };
                childCount(0, root);
                h = (d3.max(levelWidth) + 1) * 175; // 25 pixels per line
                const ww = (levelWidth.length + 1) * 250; // 25 pixels per line
                tree = tree.size([h, ww]);
                vis.select('rect.overlay')
                    .attr('height', h)
                    .attr('width', ww)
                // Compute the new tree layout.
                const nodes = tree.nodes(root).reverse();

                // Normalize for fixed-depth.
                nodes.forEach((d) => d.y = d.depth * 250);

                // Update the nodes...
                const node = vis.selectAll('g.node').data(nodes, (d) => {
                    return (d.id = ++i);
                });

                // Enter any new nodes at the parent's previous position.
                const nodeEnter = node
                    .enter()
                    .append('svg:g')
                    .attr('class', 'node')
                    .attr('id', (d) => 'node-' + d.id)
                    .attr('transform', (d) => 'translate(' + source.y0 + ',' + source.x0 + ')')
                    .on('click', (d) => {
                        if (d3.event.defaultPrevented) return;
                        that.clickedItem(d);
                    });

                // Update the nodes...
                vis.selectAll('g.node').data(nodes, (d) => {
                    return (d.ele = this);
                });

                nodeEnter.append('svg:circle')
                    .attr('r', 1e-6)
                    .style('fill', (d) => {
                        return d._children ? '#000' : '#000';
                    })

                nodeEnter.append('rect')
                    .attr('joint-selector', 'body')
                    .attr('fill', 'rgba(255,255,255,0)')
                    .attr('width', 140)
                    .attr('height', 130)
                    .attr('y', -60)
                    .attr('x', -60)


                nodeEnter.append('rect')
                    .attr('joint-selector', 'body')
                    .attr('fill', '#fff')
                    .attr('width', '100')
                    .attr('height', '90')
                    //WC 12072023 --- To set the color of connector node and ending node to red
                    //.attr('stroke', (d) => d.isEndingNode  ? '#DC3545' : d.isConnectorNode ? '#3149e8' : '#ccc')
                    .attr('stroke', (d) => d.isEndingNode || d.isConnectorNode ? '#DC3545' : '#ccc')
                    .attr('y', -40)
                    .attr('x', -40)
                    .attr('rx', '8')
                    .attr('ry', '8')
                    .attr('id', (d) => 'node-button-' + d.id)
                    .attr('data-id', (d) => d._id)
                    .attr('class', (d) => {
                        if (d.isEndingNode) {
                            return 'not-droppable';
                        }
                        return `${d.children && d.children.length >= 4 ? 'not-droppable' : 'droppable'}`
                    });

                const dragListener = d3.behavior.drag().on('dragstart', dragstart).on('drag', drag).on('dragend', dragEnd);
                nodeEnter.call(dragListener)
                nodeEnter.on('mouseover', d => overCircle(d)).on('mouseout', d => outCircle(d));

                const imgGroup = nodeEnter.append('g');

                imgGroup.append('title').text(d => d.name);

                imgGroup.append('image')
                    .attr('y', -30)
                    .attr('x', -35)
                    .attr('clip-path', 'inset(0% round 8px)')
                    .attr('xlink:href', (d) => d.poster)
                    .attr('class', (d) => d.basck);

                imgGroup.append('image')
                    .attr('y', -30)
                    .attr('x', -5)
                    .attr('clip-path', 'inset(0% round 8px)')
                    .attr('cursor', 'pointer')
                    .attr('class', (d) => d.imvd)
                    .attr('xlink:href', (d) => d.poster1);

                const title = nodeEnter
                    .append('text')
                    .attr('y', 30)
                    .attr('x', 10);

                title.append('tspan')
                    .attr('text-anchor', 'middle')
                    .text(d => d.name)
                    .each(wrap);

                title.append('title').text(d => d.name);
                
                const btnGroup = nodeEnter.append('svg:g')
                .attr('transform', 'matrix(1,0,0,1,10,50)')
                .attr('fill', '#7d43be')
                .attr('cursor', 'pointer')
                .attr('class', 'node-action')
                .attr('visibility', (d) => {

                    // let isDoc = d.URL.split('?')[0].includes('.pdf')
                    
                    //     if(isDoc){
                    //         return 'visible'
                    //     }
                        
                        if (!d.URL || d.isEndingNode || d.isUrl) {
                            return 'hidden';
                        }

                        return `${d.children && d.children.length >= 4 ? 'hidden' : 'visible'}`
                    })
                    .on('click', (d) => {
                        if (d3.event.defaultPrevented) return;
                        d3.event.stopPropagation();
                        if (!d?.children || d?.children?.length < 4) {
                            that.createInteractiveVideoObj.currentObj = d;
                            that.openVideoListModal(d?.children?.length || 0);
                        }
                    });

                btnGroup.append('title').text('Add choice');

                btnGroup.append('rect')
                    .attr('rx', 20)
                    .attr('ry', 20)
                    .attr('x', -10)
                    .attr('y', -10)
                    .attr('width', 20)
                    .attr('height', 20);

                btnGroup.append('svg:path')
                    .attr('d', 'M -4 0 4 0 M 0 -4 0 4')
                    .attr('stroke', '#ffffff')
                    .attr('stroke-width', '2');

                // questionnaire image
                const quesImgGroup = nodeEnter.append('svg:g')
                    .attr('transform', 'matrix(1,0,0,1,45,-50)')
                    .attr('fill', 'mediumpurple')
                    .attr('cursor', 'pointer')
                    .attr('class', 'node-action')
                    .attr('visibility', (d) => {
                        return `${d.questionnaireInfo ? 'visible' : 'hidden'}`
                    });

                quesImgGroup.append('rect')
                    .attr('rx', 6)
                    .attr('ry', 6)
                    .attr('height', 25)
                    .attr('width', 25);

                quesImgGroup.append('image')
                    .attr('x', 3)
                    .attr('y', 3)
                    .attr('height', 19)
                    .attr('width', 19)
                    .attr('xlink:href', 'assets/images/questionnaire.png');

                const timerIcon = nodeEnter.append('svg:g')
                    .attr('transform', (d) => {
                        const position = that.getTimerIconPosition(d);
                        return position;
                    })
                    .attr('fill', 'transparent')
                    .attr('cursor', 'pointer')
                    .attr('class', 'node-action')
                    .attr('visibility', (d) => {
                        return `${d?.timer ? 'visible' : 'hidden'}`
                    });
            
                let timerIconHTML = `<div style="font-size: medium; background: mediumpurple; border-radius: 20%; padding: 1px 4px 0px; color: white;"><i class="fa fa-clock"></i></div>`;
            
                timerIcon.append('foreignObject')
                    .attr('height', 25)
                    .attr('width', 25)
                    .html(timerIconHTML);
                
                const aiGeneratedScriptIcon = nodeEnter.append('svg:g')
                    .attr('transform', (d) => {
                        const position = that.getScriptIconPosition(d);
                        return position;
                    })
                    .attr('fill', 'white')
                    .attr('cursor', 'pointer')
                    .attr('class', 'node-action')
                    .attr('visibility', (d) => {
                        return `${d?.aiGeneratedScript ? 'visible' : 'hidden'}`
                    })
                    .on('click', (d) => {
                        d3.event.stopPropagation();
                        if (d?.aiGeneratedScript) {
                            that.updateAIGeneratedScriptOfNode(d)
                        }
                    });

                aiGeneratedScriptIcon.append('rect')
                .attr('rx', 6)
                .attr('ry', 6)
                .attr('height', 25)
                .attr('width', 25);

                aiGeneratedScriptIcon.append('image')
                .attr('x', 3)
                .attr('y', 3)
                .attr('height', 19)
                .attr('width', 19)
                .attr('xlink:href', 'assets/images/script.svg');

                const subTitleIcon = nodeEnter.append('svg:g')
                    .attr('transform', (d) => {
                        return `${d.questionnaireInfo ? 'matrix(1,0,0,1,12,-50)' : 'matrix(1,0,0,1,45,-50)'}`
                    })
                    .attr('fill', '#fff')
                    .attr('cursor', 'pointer')
                    .attr('class', 'node-action')
                    .attr('visibility', (d) => {

                        return `${d.videoID && (d.subtitleUrl || d.subtitleTranslations?.length > 0) ? 'visible' : 'hidden'}`

                    });

                subTitleIcon.append('rect')
                    .attr('rx', 6)
                    .attr('ry', 6)
                    .attr('height', 25)
                    .attr('width', 30);

                subTitleIcon.append('image')
                    .attr('x', 0)
                    .attr('y', 0)
                    .attr('height', 25)
                    .attr('width', 30)
                    .attr('xlink:href', 'assets/images/subtitle.svg')

                subTitleIcon.on('mouseover', function (d) {
                    if (d) {
                        let subTitleContent = '<div><b>Subtitles</b>';
                        d.subtitleTranslations.forEach((subtitleTranslation) => {
                            subTitleContent += `
                                <div>
                                    ${subtitleTranslation?.subtitle?.language} 
                                    (${subtitleTranslation?.subtitle?.code})
                                </div>
                            `;
                        });
                        subTitleContent += '</div>';
                        $(this).popover({
                            content: subTitleContent,
                            placement: 'right',
                            trigger: 'hover',
                            html: true
                        });
                        $(this).popover('show');
                    }
                }).on('mouseout', function () {
                    $(this).popover('hide');
                });

                function wrap() {
                    const self = d3.select(this);
                    let textLength = self.node().getComputedTextLength();
                    let text = self.text();
                    while (textLength > (100 - 2 * 10) && text.length > 0) {
                        text = text.slice(0, -1);
                        self.text(text + '...');
                        textLength = self.node().getComputedTextLength();
                    }
                }

                // Transition nodes to their new position.
                const nodeUpdate = node
                    .transition()
                    .duration(duration)
                    .attr('transform', (d) => {
                        return 'translate(' + d.y + ',' + d.x + ')';
                    });
                // // .attr("-webkit-transform", function (d) {
                // });

                nodeUpdate
                    .select('circle')
                    .attr('r', 4.5)
                    .style('fill', (d) => {
                        return d._children ? '#000' : '#000';
                    });

                nodeUpdate.select('text').style('fill-opacity', 1);

                // Transition exiting nodes to the parent's new position.
                const nodeExit = node
                    .exit()
                    .transition()
                    .duration(duration)
                    .attr('transform', (d) => {
                        return 'translate(' + source.y + ',' + source.x + ')';
                    })
                    .remove();

                nodeExit.select('circle').attr('r', 1e-6);
                nodeExit.select('text').style('fill-opacity', 1e-6);

                // Add choice between two nodes
                const plusButton = vis
                    .append('g')
                    .attr('cursor', 'pointer')
                    .attr('class', 'node-action')
                    .style('display', 'none')
                    .on('click', (d) => {
                        // console.log( plusButton.attr('data-source'));
                        // console.log( plusButton.attr('data-target'));
                        if (d3.event.defaultPrevented) return;
                        d3.event.stopPropagation();
                        that.insertNode({
                            parent: plusButton.attr('data-source'),
                            child: plusButton.attr('data-target')
                        });
                    })
                    .on('mouseover', () => plusButton.style('display', null))
                    .on('mouseout', () => plusButton.style('display', 'none'));

                plusButton.append('rect')
                    .attr('joint-selector', 'body')
                    .attr('fill', 'rgba(0,0,0,0.1)')
                    .attr('transform', 'translate(-20, -20)') // center the button inside the `g`
                    .attr('width', 40)
                    .attr('height', 40)
                    .attr('rx', 40);

                plusButton
                    .append('rect')
                    .attr('fill', '#7d43be') // center the button inside the `g`
                    .attr('transform', 'translate(-12, -12)') // center the button inside the `g`
                    .attr('width', 25)
                    .attr('height', 25)
                    .attr('rx', 25);

                plusButton.append('title').text('Add choice');

                plusButton.append('svg:path')
                    .attr('d', 'M -5 0 5 0 M 0 -5 0 5')
                    .attr('stroke', '#ffffff')
                    .attr('stroke-width', '2');

                const closeButton = vis
                    .append('g')
                    .attr('cursor', 'pointer')
                    .attr('class', 'node-action')
                    .style('display', 'none')
                    .on('click', (d) => {
                        if (d3.event.defaultPrevented) return;
                        d3.event.stopPropagation();
                        that.deleteParentIdsNode({
                            parent: closeButton.attr('data-source'),
                            child: closeButton.attr('data-target')
                        });
                    })
                    .on('mouseover', () => closeButton.style('display', null))
                    .on('mouseout', () => closeButton.style('display', 'none'));

                closeButton.append('rect')
                    .attr('joint-selector', 'body')
                    .attr('fill', 'rgba(0,0,0,0.1)')
                    .attr('transform', 'translate(-20, -20)')
                    .attr('width', 40)
                    .attr('height', 40)
                    .attr('rx', 20);

                closeButton
                    .append('rect')
                    .attr('fill', '#df1717')
                    .attr('transform', 'translate(-12.5, -12.5)')
                    .attr('width', 25)
                    .attr('height', 25)
                    .attr('rx', 12.5);

                closeButton.append('svg:path')
                    .attr('d', 'M 7.5 7.5 L 17.5 17.5 M 7.5 17.5 L 17.5 7.5')
                    .attr('stroke', '#ffffff')
                    .attr('stroke-width', '2')
                    .attr('transform', 'translate(-12, -12)');

                // Update the links...
                const jsonLinks = tree.links(nodes);
                // console.log(that.createInteractiveVideoObj.conclusionLinks)
                for (const conclusionLink of that.createInteractiveVideoObj.conclusionLinks) {
                    jsonLinks.push(conclusionLink)
                }
                vis.selectAll('path.link').remove();
                const link = vis.selectAll('path.link').data(jsonLinks);


                // Enter any new links at hte parent's previous position
                link
                    .enter()
                    .insert('svg:path', 'g')
                    .attr('class', (d) => {
                        return 'link' + (d.type ? (' ' + d.type) : '');
                    })
                    .attr('d', (d) => {
                        const o = {
                            x: source.x0,
                            y: source.y0,
                        };
                        return diagonal({
                            source: o,
                            target: o,
                        });
                    })
                    // Add choice between two nodes
                    .on('mouseover', function (d) {
                        if (d.type === 'conclusion') {
                            d3.select(this).style('stroke-width', '4px');
                            const linkElement = d3.select(this);
                            const linkWidth = linkElement.node().getTotalLength();
                            const pointAtLength = linkElement.node().getPointAtLength(linkWidth * 0.3);

                            const xb = pointAtLength.x;
                            const yb = pointAtLength.y;

                            closeButton.style('display', null)
                                .attr('transform', 'translate(' + xb + ', ' + yb + ')')
                                .attr('data-source', d.source._id)
                                .attr('data-target', d.target._id);

                            return;
                        }
                        d3.select(this).style('stroke-width', '4px');
                        // calculate the center of the path
                        const bbox = this.getBBox();
                        const xb = bbox.x + bbox.width / 2;
                        const yb = bbox.y + bbox.height / 2;

                        plusButton.style('display', null)
                            .attr('transform', 'translate(' + xb + ', ' + yb + ')')
                            .attr('data-source', d.source._id)
                            .attr('data-target', d.target._id);
                    })
                    .on('mouseout', function () {
                        d3.select(this)
                            .style('stroke-width', null)
                        plusButton.style('display', 'none');
                        closeButton.style('display', 'none');
                    });

                // Transition links to their new position.
                link.transition().duration(duration)
                    .attr('d', (d) => {
                        let sourceX = d.source.x;
                        let sourceY = d.source.y + 40;

                        let targetX = d.target.x;
                        let targetY = d.target.y - 40;

                        if (d.source.y > d.target.y) {
                            targetX = (d.target.x + 10);
                            targetY = (d.target.y + 60);
                            sourceY = (d.source.y - 20);
                        }

                        if (d.source.y === d.target.y) {
                            targetX = (d.target.x - 50);
                            targetY = (d.target.y + 45);
                        }

                        return diagonal({
                            source: { x: sourceX, y: sourceY },
                            target: { x: targetX, y: targetY },
                        });
                    })
                    .attr('marker-end', 'url(#arrowhead)');

                // Transition exiting nodes to the parent's new position.
                link
                    .exit()
                    .transition()
                    .duration(duration)
                    .attr('d', (d) => {
                        const o = {
                            x: source.x,
                            y: source.y,
                        };
                        return diagonal({
                            source: o,
                            target: o,
                        });
                    })
                    .remove();
                // link.attr('marker-start', 'url(#arrow)');

                // Stash the old positions for transition.
                nodes.forEach((d) => {
                    d.x0 = d.x;
                    d.y0 = d.y;
                });

                const rootG = document.getElementById('rootg');
                const rect = rootG.getBoundingClientRect();

                that.nodes = nodes;

                const calculateTranslation = (currentNode) => {
                    const xAxis = rect.x + (currentNode[0].y0);
                    const yAxis = (currentNode[0].x0 > 0) ? (rect.y + currentNode[0].x0) : (-currentNode[0].x0 - rect.y);

                    const x2 = window.innerWidth / 2;
                    const y2 = window.innerHeight / 2;
                    const x3 = xAxis > x2 ? (x2 - xAxis) : (xAxis - x2);
                    let y3 = 0;
                    if (currentNode[0].x0 > 0) {
                        y3 = Math.floor((yAxis > y2) ? (y2 - yAxis) : (yAxis - y2));
                    } else {
                        y3 = Math.floor((-yAxis < y2) ? (yAxis + y2) : (yAxis - (-y2)));
                    }
                    return [x3, y3];
                }
                let trans;
                if (that.latestNodeId) {
                    const latestNodeItem = that.getLatestItem(nodes, that.latestNodeId);
                    const latestNode = document.getElementById(`node-button-${latestNodeItem[0]?.id}`);
                    if (latestNode) {
                        latestNode.style.fill = '#d3c1eb';
                    }
                    trans = calculateTranslation(latestNodeItem);
                    // that.visRef.attr('transform', 'translate(' + trans + ') scale(1)');
                    // that.zoomListener.translate(trans);
                    that.translateTreeAndAddScrollBar(that.nodes);
                } else {
                    const latestNodeItem = that.getLatestItem(nodes, root._id);
                    trans = calculateTranslation(latestNodeItem);
                    that.visRef.attr('transform', 'translate(' + trans + ') scale(1)');
                }
                if (!that.rootInitialPosition) {
                    that.rootInitialPosition = trans;
                }
                // that.visRef.attr('transform', 'translate(' + trans + ') scale(1)');
                // that.zoomListener.translate(trans);


                that.updateCurrentHighlight();
            }

            const panSpeed = 100;
            const panBoundary = 10;
            let panTimer;
            let translateCoords;
            let translateX;
            let translateY;
            let dragStarted = false;
            let nodesChild;
            let selectedNode = null
            let draggingNode = null;
            let domNode;

            const overCircle = d => {
                if (draggingNode && d.id !== draggingNode.id) {
                    selectedNode = d;
                    // console.log('over', d)
                    updateTempConnector();
                }
            };
            const outCircle = d => {
                selectedNode = null;
                updateTempConnector();
            };

            // Function to update the temporary connector indicating dragging affiliation
            const updateTempConnector = () => {
                if (draggingNode !== null && selectedNode !== null) {
                    $(`#node-button-${selectedNode.id}`).addClass('can-drop');
                } else {
                    $(`.can-drop`).removeClass('can-drop');
                }
            };

            function dragstart(d) {
                if (!that.dragDropNode) {
                    return;
                }
                if (d === root) {
                    return;
                }
                dragStarted = true;
                nodesChild = tree.nodes(d);
                d3.event.sourceEvent.stopPropagation();
                d3.select(this).classed('fixed', d.fixed = true);
            }

            function pan(domNode1, direction) {
                const speed = panSpeed;
                if (panTimer) {
                    clearTimeout(panTimer);
                    translateCoords = d3.transform(vis.attr('transform'));
                    if (direction === 'left' || direction === 'right') {
                        translateX = direction === 'left' ? translateCoords.translate[0] + speed : translateCoords.translate[0] - speed;
                        translateY = translateCoords.translate[1];
                    } else if (direction === 'up' || direction === 'down') {
                        translateX = translateCoords.translate[0];
                        translateY = direction === 'up' ? translateCoords.translate[1] + speed : translateCoords.translate[1] - speed;
                    }
                    const scaleX = translateCoords.scale[0];
                    const scaleY = translateCoords.scale[1];
                    const scale = that.zoomListener.scale();
                    vis.transition().attr('transform', 'translate(' + translateX + ',' + translateY + ')scale(' + scale + ')');
                    d3.select(domNode1).select('g.node').attr('transform', 'translate(' + translateX + ',' + translateY + ')');
                    // that.zoomListener.scale(that.zoomListener.scale());
                    // that.zoomListener.translate([translateX, translateY]);
                    // that.zoomListener.event(that.visRef);
                    panTimer = setTimeout(() => {
                        pan(domNode1, direction);
                    }, 50);
                }
            }

            function drag(d) {
                if (!that.dragDropNode) {
                    // clearTimeout(panTimer);
                    return;
                }
                if (d === root) {
                    // clearTimeout(panTimer);
                    return;
                }
                // console.log('drag',d);
                if (!dragStarted) {
                    d.x0 += d3.event.dy;
                    d.y0 += d3.event.dx;
                }
                if (dragStarted) {
                    initiateDrag(d, this);
                }


                // get coords of mouseEvent relative to svg container to allow for panning
                const relCoords = d3.mouse($('#tree-view').get(0));
                if (relCoords[0] < panBoundary) {
                    panTimer = true;
                    pan(this, 'left');
                } else if (relCoords[0] > ($('#tree-view svg').width() - panBoundary)) {
                    panTimer = true;
                    pan(this, 'right');
                } else if (relCoords[1] < panBoundary) {
                    panTimer = true;
                    pan(this, 'up');
                } else if (relCoords[1] > ($('#tree-view svg').height() - panBoundary)) {
                    panTimer = true;
                    pan(this, 'down');
                } else {
                    try {
                        clearTimeout(panTimer);
                    } catch (e) {

                    }
                }

                // console.log(d.x0, d.y0, d.x, d.y, d)

                const node1 = d3.select(this);
                node1.attr('transform', 'translate(' + d.y0 + ',' + d.x0 + ')');

            }

            function initiateDrag(d, domNode) {
                draggingNode = d;

                d3.select(domNode).select(`#node-button-${d.id}`).attr('class', 'active-drag');

                vis.selectAll('g.node').sort((a, b) => { // select the parent and sort the path's
                    if (a.id !== draggingNode.id) return 1; // a is not the hovered element, send "a" to the back
                    else return -1; // a is the hovered element, bring "a" to the front
                });
                // if nodes has children, remove the links and nodes
                if (nodesChild.length > 1) {
                    // remove link paths
                    const links = tree.links(nodesChild);
                    const nodePaths = vis.selectAll('path.link')
                        .data(links, d => d.target.id).remove();
                    // remove child nodes
                    const nodesExit = vis.selectAll('g.node')
                        .data(nodesChild, d => d.id).filter((d, i) => {
                            return d.id !== draggingNode.id;
                        }).remove();
                }

                // remove parent link
                const parentLink = tree.links(tree.nodes(draggingNode.parent));
                vis.selectAll('path.link').filter((d, i) => {
                    return d.target.id === draggingNode.id;

                }).remove();

                dragStarted = false;
            }

            function dragEnd(d) {
                clearTimeout(panTimer);
                if (!that.dragDropNode) {
                    return;
                }
                if (d === root) {
                    return;
                }
                domNode = this;
                // console.log(selectedNode, draggingNode)
                if (selectedNode) {
                    if (!selectedNode.children) {
                        selectedNode.children = [];
                    }
                    if (!selectedNode.isEndingNode && selectedNode.children.length < 4) {
                        // now remove the element from the parent, and insert it into the new elements children
                        const index = draggingNode.parent.children.indexOf(draggingNode);
                        if (index > -1) {
                            draggingNode.parent.children.splice(index, 1);
                        }
                        if (typeof selectedNode.children !== 'undefined' || typeof selectedNode._children !== 'undefined') {
                            if (typeof selectedNode.children !== 'undefined') {
                                selectedNode.children.push(draggingNode);
                            } else {
                                selectedNode._children.push(draggingNode);
                            }
                        } else {
                            selectedNode.children = [];
                            selectedNode.children.push(draggingNode);
                        }
                        // Make sure that the node being added to is expanded so user can see added node is correctly moved
                        // expand(selectedNode);
                        // sortTree();
                        const object = {
                            videoId: draggingNode._id,
                            parentId: draggingNode.parentId,
                            newParentId: selectedNode._id
                        }
                        that.saveDragDropNode(object);
                    } else {
                        endDrag();
                    }
                } else {
                    endDrag();
                }
            }

            function endDrag() {
                selectedNode = null;
                d3.selectAll('.ghostCircle').attr('class', 'ghostCircle');
                d3.select(domNode).attr('class', 'node');
                // now restore the mouseover event or we won't be able to drag a 2nd time
                d3.select(domNode).select('.ghostCircle').attr('pointer-events', '');
                if (draggingNode !== null) {
                    update(root);
                    draggingNode = null;
                    $(`.node button`).removeClass('drag-node');
                }
            }


            // zoom in / out
            function zoom() {
                if(d3.event.sourceEvent?.type === 'mousemove' && d3.event.sourceEvent.target.classList.contains('overlay')) {
                    clearTimeout(panTimer);

                    let treeContainer = document.getElementById('svg-id');
                    let transformScale = that.getTransformScale(treeContainer);
                    const scale = that.zoomRef ? that.zoomRef : transformScale;
                    const eTransform = d3.transform(that.visRef.attr('transform'));
                    const nTranslateX = (eTransform.translate[0] + d3.event.translate[0] * scale) / 2;
                    const nTranslateY = (eTransform.translate[1] + d3.event.translate[1] * scale) / 2;
                    that.visRef.attr('transform', 'translate(' + [nTranslateX, nTranslateY] + ') scale(' + scale + ')');
                    // that.visRef.attr('transform', 'translate(' + d3.event.translate + ') scale(' + d3.event.scale + ')');
    
                    that.zoomRef = scale;
                }
            }

            this.createInteractiveVideoObj.selectedVideoCheck = false;
            this.createInteractiveVideoObj.interactiveStep = 'three';
            this.createInteractiveVideoObj.spinner = false;

            // WC 03012024 --- To prevent the tree scrolled to high and hide most of them including the main node
            // if (!that.latestNodeId) {
            //     that.translateTreeAndAddScrollBar(that.nodes);
            // }
        }, 500);

    }

    translateTreeAndAddScrollBar(nodes, scale?) {
        let svgContainer = document.getElementById('rootg');
        let treeContainer = document.getElementById('svg-id');
        let treeView = document.getElementById('tree-view');

        const treeRect = svgContainer.getBoundingClientRect();
        const isTreeOutsideContainer = treeRect.right > svgContainer.clientWidth || treeRect.bottom > svgContainer.clientHeight;
        const scrollTopValue = (treeRect.height) / 2;


        //console.log("isTreeOutsideContainer = ", isTreeOutsideContainer);


        if (isTreeOutsideContainer) {
            treeContainer.style.width = treeRect.width < treeView.clientWidth ? treeView.clientWidth + 'px' : (treeRect.width * 2) + 'px';
            treeContainer.style.height = (treeRect.height * 2) + 'px';
            treeContainer.style.overflow = 'auto';
            const scrollLeftValue = (treeContainer.scrollWidth - treeRect.width)/3;
            
            let transformScale = this.getTransformScale(treeContainer);

            if (treeRect.width > 2000) {
                treeView.scrollTop = scrollTopValue;
                let trans = [50, -250];
                this.visRef.attr('transform', 'translate(' + trans + ') scale(' + transformScale + ')');
            } else {
                let trans;
                if (treeRect.width > 1000) {
                    trans = [50, -300];
                } else {
                    trans = [100, -200];
                }
                this.visRef.attr('transform', 'translate(' + trans + ') scale(' + transformScale + ')');
                if(treeContainer.clientWidth > 2000) {
                    // treeView.scrollLeft = scrollLeftValue;
                }
                if(treeContainer.clientHeight > 1200) {
                    treeView.scrollTop = scrollTopValue;
                }
            }
            this.zoomRef = transformScale;
            this.scaleForReset = transformScale;
        }
        if(this.latestNodeId) {
            this.takeMeToLatestUpdatedNode(nodes)
        }
        else {
            const mainNode = nodes.find(node => node._id === this.mainID);
    
            //console.log("mainNode = ", mainNode);
    
            const mainNodePosition = treeContainer.querySelector(`g[id=node-${mainNode.id}]`).getBoundingClientRect();
            //console.log("mainNodePosition = ", mainNodePosition);
    
            const gTransform  = (document.querySelector('#rootg') as SVGGElement).getCTM();
            //console.log("gTransform = ", gTransform);
    
            const topValue = Math.abs(mainNodePosition.y) + Math.abs(gTransform.f);
            //console.log("topValue for scrolling = ", topValue);
            // const topValue = mainNode.x - 350;
            
    
            // console.log("mainNode.x = ", mainNode.x);
            // console.log("treeView.scrollTop before assignment = ", treeView.scrollTop);
            // console.log("treeView.scrollLeft before assignment = ", treeView.scrollLeft);
    
            treeView.scrollLeft = (Math.abs(mainNodePosition.x)) - (Math.abs(gTransform.e) - treeView.clientWidth/2);
    
            if(mainNode.x > 400) {
                // console.log("mainNode.x > 400");
                treeView.scrollTop = topValue;
    
                if (treeView.scrollTop == 0)
                    console.log("treeView.scrollTop = 0")
                else
                    console.log("treeView.scrollTop = ", treeView.scrollTop);
    
                treeView.scrollLeft = Math.abs(mainNodePosition.x) - 200;
    
                if (treeView.scrollLeft == 0)
                    console.log("treeView.scrollLeft = 0")
                else
                    console.log("treeView.scrollLeft = ", treeView.scrollLeft);
            }
        }

        this.svgHeight = treeContainer.clientHeight;
        this.svgWidth = treeContainer.clientWidth;

        this.svgHeightForReset = treeContainer.clientHeight;
        this.svgWidthForReset = treeContainer.clientWidth;
    }

    getTransformScale(treeContainer) {
        const desiredHeight = 700;
        let transformScale = desiredHeight / treeContainer.clientHeight;

        //WC 02172024 to avoid scaling too small

        //console.log("transformScale = ", transformScale);
        if (transformScale < 0.12) {
            transformScale = 0.12;
            //console.log("updated transformScale = ", transformScale);

        }
        //WC 02172024

        return transformScale;
    }

    takeMeToLatestUpdatedNode(nodes) {
        if (this.latestNodeId) {
            const latestNodeItem = this.getLatestItem(nodes, this.latestNodeId);
            const scrollX = latestNodeItem[0].y - 1000;
            const scrollY = latestNodeItem[0].x - 400;

            const container = document.getElementById('tree-view');
            container.scrollTo(scrollX, scrollY);

        }
    }

    getTimerIconPosition(d) {
        if(d.questionnaireInfo && (d.videoID && (d.subtitleUrl || d.subtitleTranslations?.length > 0))) {
            return 'matrix(1,0,0,1,-19,-50)';
        } else if(d.questionnaireInfo || (d.videoID && (d.subtitleUrl || d.subtitleTranslations?.length > 0))) {
            return 'matrix(1,0,0,1,18,-50)';
        } else {
            return 'matrix(1,0,0,1,45,-50)';

        }
    }

    getScriptIconPosition(d) {
        if(d.questionnaireInfo && (d.videoID && (d.subtitleUrl || d.subtitleTranslations?.length > 0)) && d?.timer) {
            return 'matrix(1,0,0,1,30,-22)';
        } else if((d.questionnaireInfo && (d.videoID && (d.subtitleUrl || d.subtitleTranslations?.length > 0)) && !d?.timer)
                || (d.questionnaireInfo && !(d.videoID && (d.subtitleUrl || d.subtitleTranslations?.length > 0)) && d?.timer)
                || (!d.questionnaireInfo && (d.videoID && (d.subtitleUrl || d.subtitleTranslations?.length > 0)) && d?.timer)) {
            return 'matrix(1,0,0,1,-24,-50)';
        } else if((!d.questionnaireInfo && !(d.videoID && (d.subtitleUrl || d.subtitleTranslations?.length > 0)) && d?.timer)
                || (d.questionnaireInfo && !(d.videoID && (d.subtitleUrl || d.subtitleTranslations?.length > 0)) && !d?.timer)
                || (!d.questionnaireInfo && (d.videoID && (d.subtitleUrl || d.subtitleTranslations?.length > 0)) && !d?.timer)) {
            return 'matrix(1,0,0,1,6,-50)';
        } else {
            return 'matrix(1,0,0,1,45,-50)';
        }
    }

    saveDragDropNode(object, type?) {
        this.createInteractiveVideoObj.spinner = true;
        this.urlService.moveVideoNode(object).subscribe(() => {
            // this.orderedNodes.push(object);
            if (type === 'redo' && this.redoOrderedNodes.length !== 0) {
                this.undoOrderedNodes.push(object);
                this.redoOrderedNodes.pop();
            } else if (type === 'undo' && this.undoOrderedNodes.length !== 0) {
                this.redoOrderedNodes.push(object);
                this.undoOrderedNodes.pop();
            } else {
                this.undoOrderedNodes.push(object);
                this.redoOrderedNodes = [];
            }
            this.latestNodeId = object.newParentId;
            this.callChild1();
        }).add(() => {
            this.createInteractiveVideoObj.spinner = false;
        });
    }

    undoOrder(): void {
        if (this.undoOrderedNodes.length !== 0) {
            const object = this.undoOrderedNodes[this.undoOrderedNodes.length - 1];
            const newParentId = object.newParentId;
            object.newParentId = object.parentId;
            object.parentId = newParentId;
            this.saveDragDropNode(object, 'undo');
        }
    }

    redoOrder(): void {
        if (this.redoOrderedNodes.length !== 0) {
            const object = this.redoOrderedNodes[this.redoOrderedNodes.length - 1];
            const newParentId = object.newParentId;
            object.newParentId = object.parentId;
            object.parentId = newParentId;
            this.saveDragDropNode(object, 'redo');
        }
    }

    toggleDragDropNode(value) {
        this.dragDropNode = value;
        this.visRef.attr('class', this.dragDropNode ? 'rearrange' : '');
    }

    getLatestItem(nodes, id: string) {
        return nodes.filter(node => node._id === id);
    }

    zoomIn() {
        // this.zoomListener.event(this.visRef);

        //WC 02172024
        // if (this.zoomRef <= 1.5) {
        //     this.zoomRef = this.zoomRef + 0.1;
        //     this.zoomListener.scale(this.zoomRef);
        //     this.translateZoomInOut('IN', this.zoomRef);
        // }

        if (this.scaleIncrement == undefined) {
            if (this.zoomRef <= 0.10) {
                this.scaleIncrement = 0.10;
            } else {
                this.scaleIncrement = 0.01;
            }
        }

        //console.log("this.scaleIncrement = ", this.scaleIncrement);
        //console.log("this.ZoomRef before increasing = ", this.zoomRef);
        this.zoomRef = this.zoomRef + this.scaleIncrement;
        //console.log("this.ZoomRef after increasing " + this.scaleIncrement + " = " + this.zoomRef);

        this.zoomListener.scale(this.zoomRef);
        this.translateZoomInOut('IN', this.zoomRef);
        //WC 02172024

        // this.translateZoomInOut('IN', this.zoomRef);
        // this.visRef
        //     .transition()
        //     .duration(750)
        //     .attr('transform', 'scale(' + this.zoomRef + ')');
    }

    translateZoomInOut(type: string, scale?) {
        let svgContainer = document.getElementById('rootg');
        let treeContainer = document.getElementById('svg-id');
        let treeView = document.getElementById('tree-view');

        const treeRect = svgContainer.getBoundingClientRect();
        // console.log(treeRect)
        const isTreeOutsideContainer = treeRect.right > svgContainer.clientWidth || treeRect.bottom > svgContainer.clientHeight;
        const scrollTopValue = (treeRect.height) / 2;

        if (isTreeOutsideContainer) {
            if (type === 'IN') {
                // WC 02/17/2024 --- To avoid making the node size too big after increase zooming by 10%
                // treeContainer.style.width = (this.svgWidth * 2) + 'px';
                // treeContainer.style.height = (this.svgHeight * 2) + 'px';
            }
            if (type === 'OUT') {
                // WC 02/17/2024 --- To avoid making the node size too big after increase zooming by 10%
                // treeContainer.style.width = (this.svgWidth / 2) + 'px';
                // treeContainer.style.height = (this.svgHeight / 2) + 'px';
            }
            if(type === 'RESET') {
                treeContainer.style.width = this.svgWidthForReset;
                treeContainer.style.height = this.svgHeightForReset;
            }
            let transformScale = scale;
            
            treeContainer.style.overflow = 'auto';
            const scrollLeftValue = (treeContainer.scrollWidth - treeRect.width) / 3;

            // let transformScale = scale ? scale : 0.7;
            if (treeRect.width > 2000) {
                // transformScale = scale ? scale : 0.2;
                let trans = [50, -250]
                this.visRef.attr('transform', 'translate(' + trans + ') scale(' + transformScale + ')');
            } else {
                let trans;
                if(treeRect.width > 1000) {
                    trans = [100, -300];
                } else {
                    trans = [100, -300];
                }
                this.visRef.attr('transform', 'translate(' + trans + ') scale(' + transformScale + ')');
                if(treeContainer.clientWidth > 2000) {
                    treeView.scrollLeft = scrollLeftValue;
                }
                if(treeContainer.clientHeight > 1200) {
                    treeView.scrollTop = scrollTopValue;
                }
            }
            this.zoomRef = transformScale;
        }

        const mainNode = this.nodes.find(node => node._id === this.mainID);
        const mainNodePosition = (treeContainer.querySelector(`g[id=node-${mainNode.id}]`) as SVGGElement).getCTM();
        const topValue = Math.abs(mainNodePosition.f);

        if(mainNode.x > 400) {
            treeView.scrollTop = 0;
            treeView.scrollLeft = 0;
            treeView.scrollTop = topValue - 200;
            treeView.scrollLeft = Math.abs(mainNodePosition.e) - 200;
        }

        this.svgHeight = treeContainer.clientHeight;
        this.svgWidth = treeContainer.clientWidth;
    }

    zoomOut() {
        // this.zoomListener.event(this.visRef);
        //console.log("zoomOut - this.zoomRef = ", this.zoomRef);

        
        if (this.zoomRef >= 0.1) {

            //WC 02172024
            //this.zoomRef = this.zoomRef - 0.1;
            if (this.scaleIncrement == undefined)
                this.scaleIncrement = this.zoomRef * 0.1;

            //console.log("this.scaleIncrement = ", this.scaleIncrement);

            this.zoomRef = this.zoomRef - this.scaleIncrement;
            //WC 02172024

            this.zoomListener.scale(this.zoomRef);
            this.translateZoomInOut('OUT', this.zoomRef);
        } 
        // this.visRef
        //     .transition()
        //     .duration(750)
        //     .attr('transform', 'scale(' + this.zoomRef + ')');
    }

    zoomReset() {
        // this.visRef
        //     .transition()
        //     .duration(750)
        //     .attr('transform', 'scale(' + 1 + ')');
        let treeContainer = document.getElementById('svg-id');
        let transformScale = this.getTransformScale(treeContainer);;
        this.zoomRef = transformScale;
        this.translateZoomInOut('RESET', this.scaleForReset);
        // this.visRef.attr('transform', 'translate(' + this.rootInitialPosition + ') scale(1)');
        // this.zoomListener.scale(this.zoomRef);
        // this.zoomListener.translate(this.rootInitialPosition);
        // this.zoomListener.event(this.visRef);
    }

    openPublishBadgeModal() {
        const dialogRef = this.dialog.open(CreateVideoComponent, {
            data: {
                badges: true,
                links: false,
                videos: false
            },
            minWidth: '50vw'
        });
        dialogRef.afterClosed().subscribe((result: UploadedFileResponse) => {
            if (result) {
                this.assignPublishBadge = result;
            }
        });
    }

    openBadgeModal() {
        const dialogRef = this.dialog.open(CreateVideoComponent, {
            data: {
                badges: true,
                links: false,
                videos: false
            },
            minWidth: '50vw'
        });
        dialogRef.afterClosed().subscribe((result: UploadedFileResponse) => {
            if (result) {
                this.currentMessageStep = 6;
                this.assignBadge = result;
                this.getBotAnswer('Badge', 'badge', result.pic, result.name, result);
            }
        });
    }

    handleActiveWatchPath(activePath?) {
        this.createInteractiveVideoObj.activePathNodes = activePath?.pathNodes || [];
        $(`rect`).removeClass('active');
        $(`#tree-view`).removeClass('pathways');
        if (this.createInteractiveVideoObj.activePathNodes && this.createInteractiveVideoObj.activePathNodes.length) {
            for (const i of this.createInteractiveVideoObj.activePathNodes) {
                // console.log(activePathElement)
                $(`rect[data-id=${i}]`).addClass('active');
            }
            $(`#tree-view`).addClass('pathways');
            return;
        }
        // if (this.editWatchPath) {
        //   this.handleActiveWatchPath(this.editWatchPath);
        // }
    }

    handleHoveredPath(hoveredPath?) {
        if (hoveredPath && hoveredPath.length) {
            this.clearAllPathNodesColor(this.pathNodes);
            for (const i of hoveredPath) {
                $(`rect[data-id=${i._id}]`).attr('fill', hoveredPath[hoveredPath.length - 1].color);
            }
            return;
        } else {
            this.updateCurrentHighlight();
        }
    }

    sendMessage(msg?: string) {
        if (msg) {
            this.msgValue = msg;
        }
        //console.log('this.currentMessageStep',this.currentMessageStep)

        this.msgValue = this.msgValue.trim();
        const msgValueUc = this.msgValue.toUpperCase();

        if (msgValueUc === this.messageMap.reChooseEndNode.toUpperCase()) {
            this.deSelectWatchPath(false);
            this.currentMessageStep = 2;
        } else if (msgValueUc === this.messageMap.restart.toUpperCase()) {
            this.clearAllPathNodesColor(this.pathNodes);
            this.deSelectWatchPath(true);
            this.deSelectWatchPath(false);
            this.currentMessageStep = 1;
        }
        if (this.currentMessageStep === 4 && msgValueUc === this.messageMap.save.toUpperCase()) {
            let paths = '';
            if (this.selectedPathIndex < 1) {
                let i = 0;
                for (const pathNode of this.pathNodes.reverse()) {
                    paths += `${i ? ' <i class="fas fa-chevron-right"></i> ' : ''} ${pathNode.name}`;
                    i++;
                }
            } else {
                let i = 0;
                for (const pathNode of this.allPathNodes[this.selectedPathIndex - 1]) {
                    paths += `${i ? ' <i class="fas fa-chevron-right"></i> ' : ''} ${pathNode.name}`;
                    i++;
                }
            }
            this.addMessage('bot', paths, 'text');
            this.isSelectedPathIndex = true;
        }
        if (this.currentMessageStep === 6 && msgValueUc === this.messageMap.save.toUpperCase()) {
            this.createWatchPath();
            this.msgValue = '';
            return;
        }
        if ((this.currentMessageStep === 0 || this.currentMessageStep === 6) && msgValueUc === this.messageMap.rename.toUpperCase()) {
            this.msgValue = this.watchPathName;
            this.watchPathName = '';
        }
        if (this.currentMessageStep === 111) {
            if (msgValueUc === this.messageMap.cancel.toUpperCase()) {
                this.currentMessageStep = 6;
            } else {
                this.openBadgeModal();
            }
        }
        if ((this.currentMessageStep === 0 || this.currentMessageStep === 6) && msgValueUc === this.messageMap.addBadge.toUpperCase()) {
            this.currentMessageStep = 111;
        }
        if (this.currentMessageStep === 5) {
            this.watchPathName = this.msgValue;
            this.isInitialStep = false;
        }
        if (this.currentMessageStep === 7) {
            this.currentMessageStep = 5;
            this.watchPathName = this.msgValue;
            this.isInitialStep = false;
        }
        if (this.msgValue === this.messageMap.addLearningObjectives) { 
            // console.log("this.isEditPathWay", this.isEditPathWay);
                
            if (this.isEditPathWay) {
                this.isDisableInput = false
                this.messages = this.messages.filter((i) => i.content !== "Objectives")
                this.inputValues = this.inputValues.length ? this.inputValues : this.inputValues = ['']
                this.inputLength = this.inputLength.length ? this.inputLength : this.inputLength = ['']
            }
            this.currentMessageStep = 8
        }

        if(this.msgValue === this.messageMap.hubSpotLifeCycle){
            this.messages = this.messages.filter((i:any) => i.type !== 'select' && i.content !== this.messageMap.hubSpotLifeCycle)
            this.currentMessageStep = 9;
        }

        if (this.msgValue) {
            this.getBotAnswer(this.msgValue);
        }
        this.msgValue = '';
        // console.log(this.currentMessageStep)
    }

    addMessage = (author, content?, type?, url?, description?, data?) => {
        this.messages.push({ author, content, type, url, description, data });

        $('#messagesCard').animate({ scrollTop: $('#messagesCard .messages').height() }, 500);
    }

    getBotAnswer(userMessage: string, type = 'text', url = '', description = '', data = null) {        
        if (userMessage && userMessage !== this.messageMap.addLearningObjectives) {
            this.addMessage('user', userMessage, type, url, description, data)
            // this.messages.push({author: 'user', content: userMessage, type});
        }
        this.isMessageLoad = true;
        setTimeout(() => {
            let botMessage = '';
            if (!this.messages.length) {
                botMessage = 'Hello!';
                // this.messages.push({author: 'bot', content: botMessage, type: 'text'});
                this.addMessage('bot', botMessage, 'text')
                this.getBotAnswer('');
                return;
            } else if (!this.startWatchNode) {
                this.currentMessageStep = 1;
                botMessage = 'Please choose a starting node from the tree map';
            } else if (!this.endWatchNode) {
                this.currentMessageStep = 2;
                botMessage = 'Please choose an ending node from the tree map';
            } else if (this.currentMessageStep === 111) {
                botMessage = 'Select badge from library';
            }
            else if (this.currentMessageStep === 8) {
                botMessage = "You can add up to 5 objectives"
                // this.addMessage('bot', '', 'input')
            }
            else if (this.currentMessageStep === 9) {                
                    botMessage = "Please choose the HubSpot LifeCycle stages"
                    this.ishubSpotLifeCycle = true
            }
            else if (this.currentMessageStep === 7) {
                if (!this.watchPathName) {
                    botMessage = 'Please enter a name of the pathways';
                } else {
                    if (this.isInitialStep) {
                        this.currentMessageStep = 0;
                        this.isInitialStep = false;
                    } else {
                        this.currentMessageStep = 6;
                    }
                    botMessage = 'What would you like to do next?'
                }
            } else if (this.currentMessageStep === 5) {
                if (this.isInitialStep) {
                    this.currentMessageStep = 0;
                    this.isInitialStep = false;
                } else {
                    this.currentMessageStep = 6;
                }
                botMessage = 'What would you like to do next?'
            } else {
                this.currentMessageStep = 3;
                if ((this.allPossiblePaths.length === 0 || this.pathNodes.length === 0) && type !== 'updatePathways') {
                    this.allPossiblePaths = [];
                    this.allPossiblePaths = this.utilityService.listPaths(this.clonedArr[0], this.startWatchNode._id, this.endWatchNode._id);
                    this.totalPaths = this.allPossiblePaths;
                    this.pathNodes = this.allPossiblePaths.flat(Infinity)
                    this.pathNodes = this.pathNodes.filter((obj, index, self) =>
                        index === self.findIndex((o) => o['_id'] === obj['_id'])
                    );
                }
                if (type === 'updatePathways') {
                    if (this.editWatchPath) {
                        this.pathNodes = [];
                        this.editWatchPath.pathNodes.forEach(id => {
                            let node = this.createInteractiveVideoObj.finalObj.find((i) => i._id === id)
                            if (node) {
                                this.pathNodes.push({
                                    URL: node.URL,
                                    poster: node.poster,
                                    time: node.time,
                                    name: node.name,
                                    _id: node._id,
                                    color: '#ffb46e'
                                })
                            }
                        });
                        this.allPossiblePaths = [this.pathNodes]
                    }
                }
                // this.pathNodes = this.utilityService.parentNode(this.createInteractiveVideoObj.finalObjTree, this.endWatchNode._id);
                //console.log("this.pathNodes = ", this.pathNodes);

                if (this.endWatchNode.isEndingNode) {
                    this.allPathNodes = this.utilityService.getParentNodesForEndNodes(this.createInteractiveVideoObj.finalObjTree, this.endWatchNode._id);
                }
                // select index number to highlight nodes
                if (this.editWatchPath) {
                    //console.log("this.editWatchPath = ", this.editWatchPath);
                    if (this.utilityService.areArraysEqual(this.editWatchPath.pathNodes, this.pathNodes.map(x => x._id))) {
                        this.selectedPathIndex = 0;
                    }
                    let i = 0;
                    for (const allPathNode of this.allPathNodes) {
                        ++i;
                        if (this.utilityService.areArraysEqual(this.editWatchPath.pathNodes, allPathNode.map(x => x._id))) {
                            this.selectedPathIndex = i;
                            break;
                        }
                    }
                }
                this.updateCurrentHighlight();
                if (!this.pathNodes) {
                    botMessage = 'The end node is not a child of the start node.';
                } else if (!this.pathNodes.find(x => x._id === this.startWatchNode._id)) {
                    botMessage = 'The end node is not a child of the start node.';
                } else if (this.startWatchNode._id === this.endWatchNode._id) {
                    botMessage = 'The end node and the start node must be unique.';
                } else if (this.endWatchNode.isEndingNode && this.allPathNodes.length && !this.isSelectedPathIndex) {
                    this.currentMessageStep = 4;
                    botMessage = 'We have detected multiple pathways';
                    this.updateCurrentHighlight();
                } else if (this.allPossiblePaths.length > 1) {
                    this.currentMessageStep = 10;
                    botMessage = 'We have detected multiple pathways, Please choose any one path';
                } else if (!this.watchPathName) {
                    this.currentMessageStep = 5;
                    botMessage = 'Please enter a name of the pathways';
                }
                else {
                    if (this.isInitialStep) {
                        this.currentMessageStep = 0;
                        this.isInitialStep = false;
                    } else {
                        this.currentMessageStep = 6;
                    }
                    botMessage = 'What would you like to do next?'
                }
            }

            this.isMessageLoad = false;
            // console.log(this.currentMessageStep)
            // this.messages.push({author: 'bot', content: botMessage, type: 'text'});

            type = this.currentMessageStep === 8 ? 'input' :  this.currentMessageStep === 9 ? 'select' : 'text'

            this.addMessage('bot', botMessage, type)
        }, 1000);
    }

    addInput() {        
        if ((this.inputLength.length + 1) > 5) {
            this.toastr.info('You can add up to 5 objectives');
            return;
        }

        if (this.inputValues.length !== this.inputLength.length) {
            this.toastr.info('You can not add empty objectives');
            return;
        }

        this.inputLength.push('');
        $('#messagesCard').animate({ scrollTop: $('#messagesCard .messages').height() }, 500);

        // Autofocus on the newly added input field
        this.inputFields.changes.subscribe(() => {
            this.inputFields.last?.nativeElement.focus();
        });
    }

    removeInput(i: number) {
        this.inputLength = this.inputLength.filter((_, index) => index !== i);
        this.inputValues = this.inputValues.filter((_, index) => index !== i);
    }

    btnNext() {
        this.isDisableInput = true
        this.inputValues = this.inputValues.filter(Boolean);
        this.inputLength.length = this.inputValues.length
        this.currentMessageStep = 6
        $('#messagesCard').animate({ scrollTop: $('#messagesCard .messages').height() }, 500);
    }

    btnCancelObjective() {
        this.currentMessageStep = 6
        this.messages.pop();
        this.inputLength = []
        this.inputLength.push('')
        this.inputValues = []
    }

    clearAllPathNodesColor(pathNodes) {
        if (pathNodes) {
            for (const video of pathNodes) {
                $(`rect[data-id=${video._id}]`).attr('fill', '#fff');
            }
        }
    }

    getSelectedPath(item) {
        this.clearAllPathNodesColor(this.pathNodes);
        this.pathNodes = item;
        this.pathNodes.forEach((i) => i['color'] = item[item.length - 1].color);
        this.allPossiblePaths = [item];
        this.updateCurrentHighlight();
        this.currentMessageStep = 7;
        this.getBotAnswer('');
        this.addMessage('user', 'Selected Path :', 'pathColor', '', item[item.length - 1].color);
    }

    openAddWatchPath() {
        this.getUserInfoWithHubSpot();
        this.hubSpotLifeCycle = ''
        this.isDisableInput = false
        if (this.isMessageLoad) {
            return;
        }
        this.toggleDragDropNode(false);
        this.cancelAddWatchPath();
        $('#tree-view').addClass('add-pathways');
        this.isAddWatchPath = true;
        this.getBotAnswer('');
    }

    isEditPathWay: boolean = false
    onEditWatchPath(i) {
        this.getUserInfoWithHubSpot();

        this.isInitialStep = true;
        this.isEditPathWay = true;

        if (this.isMessageLoad) {
            return;
        }
        this.toggleDragDropNode(false);
        this.cancelAddWatchPath();
        $('#tree-view').addClass('add-pathways');
        this.isAddWatchPath = true;
        this.editWatchPath = i;
        this.startWatchNode = i.startNode;
        this.endWatchNode = i.endNode;
        this.watchPathName = i.pathName;
        this.isSelectedPathIndex = true;
        this.updateCurrentHighlight();
        this.addMessage('bot', 'Pathway name: ' + this.watchPathName, 'text');
        this.addMessage('bot', 'Starting node', 'image', this.startWatchNode?.poster, this.startWatchNode?.name);
        this.addMessage('bot', 'Ending node', 'image', this.endWatchNode?.poster, this.endWatchNode?.name);
        
        if (i?.learningObjectives) {
            this.inputValues = i.learningObjectives
            this.inputLength = i.learningObjectives
            this.addMessage('bot', 'Objectives', 'input');
            this.isDisableInput = true
        }

        if (i?.hubSpotLifeCycle) {
            this.hubSpotLifeCycle = i.hubSpotLifeCycle
            this.addMessage('bot', `HubSpot LifeCycle: ${this.hubSpotLifeCycle}`, 'select');
        }

        if (this.editWatchPath.viewCompletionBadge) {
            this.addMessage('bot', 'Badge', 'badge',
                this.editWatchPath?.viewCompletionBadgeInfo[0]?.pic,
                this.editWatchPath?.viewCompletionBadgeInfo[0]?.name,
                this.editWatchPath?.viewCompletionBadgeInfo[0]);
        }
        this.getBotAnswer('', 'updatePathways');
        // this.handleActiveWatchPath(i);
    }

    cancelAddWatchPath() {
        this.msgValue = '';
        this.messages = [];
        this.currentMessageStep = 0;
        this.assignBadge = null;
        this.editWatchPath = null;
        this.startWatchNode = null;
        this.endWatchNode = null;
        this.allPathNodes = [];
        this.totalPaths = [];
        this.clearAllPathNodesColor(this.pathNodes);
        this.pathNodes = [];
        this.selectedPathNodes = [];
        this.inputLength = [''];
        this.inputValues = [];
        this.isSelectedPathIndex = false;
        this.selectedPathIndex = 0;
        this.isAddWatchPath = false;
        this.isSubmitPathWaysForm = false;
        this.submitPathWaysFormError = '';
        this.watchPathName = '';
        this.handleActiveWatchPath('');
        this.handleHoveredPath('');
        $('rect').removeClass('nodeSelected');
        $('#tree-view').removeClass('add-pathways');
    }


    updateInputValue(index: number, value: string) {
        this.inputValues[index] = value;
    }

    createWatchPath() {
        // console.log("this.inputValues", this.inputValues);

        // if (!this.startWatchNode) {
        //   this.toastr.error('please select start node');
        //   return;
        // }
        // if (!this.endWatchNode) {
        //   this.toastr.error('please select end node');
        //   return;
        // }
        // this.submitPathWaysFormError = '';
        this.isSubmitPathWaysForm = true;
        // if (!this.watchPathName.trim()) {
        //   this.submitPathWaysFormError = 'Path name is required.';
        //   return
        // }
        // const node = this.getParentNode(this.createInteractiveVideoObj.finalObjTree, this.endWatchNode._id);
        // console.log(node, this.createInteractiveVideoObj.finalObjTree)
        // if (!node) {
        //   this.submitPathWaysFormError = 'The end node is not a child of the start node.';
        //   return;
        // }
        // if (!node.includes(this.startWatchNode._id)) {
        //   this.submitPathWaysFormError = 'The end node is not a child of the start node.';
        //   return;
        // }
        // if (this.startWatchNode._id === this.endWatchNode._id) {
        //   this.submitPathWaysFormError = 'The end node and the start node must be unique.';
        //   return;
        // }
        const pathNodes = []
        for (const selectedPathNode of this.selectedPathNodes) {
            pathNodes.push(selectedPathNode._id);
            // if (this.startWatchNode._id === selectedPathNode._id && !this.endWatchNode.isEndingNode) {
            //     break;
            // }
        }
        const payload: any = {
            videoId: this.mainID,
            pathName: this.watchPathName,
            startNodeId: this.startWatchNode._id,
            startNodeType: this.startWatchNode.type,
            endNodeId: this.endWatchNode._id,
            endNodeType: this.endWatchNode.type,
            pathNodes,
            learningObjectives: this.inputValues
        };
        if (this.assignBadge) {
            payload.viewCompletionBadge = this.assignBadge._id
        }
        if (this.editWatchPath) {
            payload._id = this.editWatchPath._id;
        }
        if(this.hubSpotLifeCycle){
            payload.hubSpotLifeCycle = this.hubSpotLifeCycle
        }
        // console.log(payload)
        this.spinner = true
        this.urlService.setPathway(payload, this.editWatchPath).subscribe((res) => {
            this.isDisableInput = false
            this.cancelAddWatchPath();
            this.getVideoPathWays();
            this.toastr.success(`Pathways ${this.editWatchPath ? 'updated' : 'created'} successfully`);
        }, (err) => {
            this.isSubmitPathWaysForm = false;
            this.watchPathName = '';
            this.addMessage('bot', 'The name of the pathway already exists! select a different name', 'text');
            this.getBotAnswer('')
        }).add(() => this.spinner = false);
    }

    deleteWatchPath(i) {
        this.deleteDialogRef.open({
            header: 'Delete path',
            title: `Are you sure? Do you want to delete "${i.pathName}" path?`,
            trueButton: 'Delete'
        }, (e) => {
            if (e) {
                this.spinner = true;
                this.urlService.deletePathway(i._id).subscribe((res) => {
                    this.toastr.success(res.message)
                    this.getVideoPathWays();
                }).add(() => this.spinner = false);
            }
        });
    }

    selectWatchPath(d) {
        if ((this.currentMessageStep === 1 || this.currentMessageStep === 2) && !this.isMessageLoad) {
            if (!this.startWatchNode) {
                this.startWatchNode = d;
                // console.log(d)
                this.getBotAnswer('Starting node', 'image', d.poster, d.name);
            } else {
                this.getBotAnswer('Ending node', 'image', d.poster, d.name);
                this.endWatchNode = d;
            }
            this.updateCurrentHighlight();
        }
    }

    deSelectWatchPath(start) {
        if (start) {
            this.startWatchNode = null;
        } else {
            this.endWatchNode = null;
        }
        this.selectedPathNodes = [];
        this.pathNodes = [];
        this.selectedPathIndex = 0;
        this.isSelectedPathIndex = false;
        this.updateCurrentHighlight();
    }

    updateCurrentHighlight() {
        //console.log("updateCurrentHighlight");

        this.submitPathWaysFormError = '';
        $('rect').removeClass('nodeSelected');
        if (this.allPathNodes.length && this.selectedPathIndex) {
            this.selectedPathNodes = this.allPathNodes[this.selectedPathIndex - 1];
            const startFind = this.selectedPathNodes.find(x => x._id === this.startWatchNode?._id);
            if (startFind) {
                for (const video of this.selectedPathNodes) {
                    $(`rect[data-id=${video._id}]`).addClass('nodeSelected');
                }
            }
        } else if (this.pathNodes) {
            this.selectedPathNodes = this.pathNodes;
            const startFind = this.pathNodes.find(x => x._id === this.startWatchNode?._id);
            if (startFind) {
                for (const video of this.pathNodes) {
                    if (this.totalPaths.length && this.totalPaths.length === 1) {
                        $(`rect[data-id=${video._id}]`).addClass('nodeSelected');
                    } else {
                        $(`rect[data-id=${video._id}]`).attr('fill', video.color);
                    }
                }
            }
        }
        if (this.startWatchNode) {
            $(`rect[data-id=${this.startWatchNode._id}]`).addClass('nodeSelected');
        }
        if (this.endWatchNode) {
            $(`rect[data-id=${this.endWatchNode._id}]`).addClass('nodeSelected');
        }
        // console.log(this.pathNodes)
        // console.log(this.pathNodes,'pathNodes')
        // console.log(this.selectedPathNodes,'selectedPathNodes')

    }

    addQuestionnaire() {
        const dialogRef = this.dialog.open(CreateVideoComponent, {
            width: '95%',
            maxWidth: '1300px',
            panelClass: 'my-dialog',
            height: 'auto',
            minHeight: '500px',
            data: {
                questionnaire: true
            },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                const payload = {
                    questionnaireId: result._id,
                    videoId: this.createInteractiveVideoObj.currentObj._id,
                    type: this.createInteractiveVideoObj.currentObj.type
                };
                this.urlService.attachQuestionnaireToVideo(payload).subscribe((res) => {
                    this.currentVideoData.isEdit = true;
                    // this.refreshInteractiveVideo();
                    location.reload();
                });
            }
        });
    }

    removeQuestionnaire() {
        this.deleteDialogRef.open({
            header: 'Remove Questionnaire',
            title: 'Are you sure you want to remove this questionnaire?',
            trueButton: 'Yes',
            falseButton: 'No',
        }, (e) => {
            if (e) {
                const payload = {
                    questionnaireId: this.createInteractiveVideoObj.currentObj.questionnaireId,
                    videoId: this.createInteractiveVideoObj.currentObj._id,
                    type: this.createInteractiveVideoObj.currentObj.type
                };
                this.urlService.detachQuestionnaireFromVideo(payload).subscribe((res) => {
                    this.currentVideoData.isEdit = true;
                    // this.refreshInteractiveVideo();
                    location.reload();
                });
            }
        });
    }

    deleteParentIdsNode(d) {

        if (d.child && d.parent) {
            const payload = {
                videoId: this.mainID,
                connectorNodeId: d.child,
                removeParentIds: [d.parent]
            }

            this.urlService.removeParentIds(payload).subscribe((res) => {
                this.createInteractiveVideoObj.spinner = true;
                this.callChild1();
            })
        }
    }

    editQuestionnaire(questionnaire) {
        const dialogRef = this.dialog.open(CreateQuestionnaireComponent, {
            width: '100%',
            maxWidth: '1000px',
            height: 'auto',
            autoFocus: false,
            data: {
                questionnaire
            },
        });

        dialogRef.afterClosed().subscribe((res) => {
            if (res) {
                this.currentVideoData.isEdit = true;
                // this.refreshInteractiveVideo();
                location.reload();
            }
        });
    }

    //WC 02232024
    editTimer() {
        const dialogRef = this.dialog.open(CreateQuestionnaireComponent, {
            width: '100%',
            maxWidth: '1000px',
            height: 'auto',
            autoFocus: false,
            data: {
                //questionnaire
            },
        });

        dialogRef.afterClosed().subscribe((res) => {
            if (res) {
                this.currentVideoData.isEdit = true;
                // this.refreshInteractiveVideo();
                location.reload();
            }
        });
    }

    changeLanguage() {
        if (this.textareaData !== this.initialValue) {
            this.confirmationModal();
        } else {
            this.getVttFile();
        }
    }

    confirmationModal() {
        const dialogRef = this.dialog.open(this.confirmationDialog, {
            width: '100%',
            maxWidth: '500px',
            panelClass: 'my-dialog',
            height: 'auto',
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.getVttFile();
            }
        })
    }

    getDefaultLangTranscription(video) {
        if (video.subtitleUrl) {
            const defaultLang = this.utilityService.getTranscriptLang(video.subtitleUrl)
            const langCode = defaultLang.code.split('-')[0];
            let defaultSubTitle = {
                subtitle: {
                    code: langCode,
                    disabled: false,
                    language: defaultLang.language
                }
            }
            defaultSubTitle[langCode] = video.subtitleUrl;
            return defaultSubTitle
        }
    }

    updateTranscript() {
        //console.log(this.createInteractiveVideoObj.currentObj)
        this.language = this.createInteractiveVideoObj.currentObj.subtitleTranslations[0];
        this.getVttFile();
        const dialogRef = this.dialog.open(this.updateTranscriptDialog, {
            width: '100%',
            maxWidth: '700px',
            panelClass: 'position-choices',
            height: 'auto',
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.uploadTranscription();
            }
        })
    }

    updateAIGeneratedScriptOfNode(currentNode) {
        this.selectedNodesScript = currentNode?.aiGeneratedScript;
        const dialogRef = this.dialog.open(this.updateAIGeneratedScriptDialog, {
            width: '100%',
            maxWidth: '700px',
            panelClass: 'position-choices',
            height: 'auto',
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                let payload = {
                    videoId: currentNode._id,
                    type: currentNode.type,
                    updatedScript: this.selectedNodesScript
                }

                this.urlService.updateAiGeneratedScript(payload).subscribe((res) => {
                    this.getMainVideoInfo();
                }, (err) => {
                    this.toastr.error('Error!', err);
                })
            }
        })
    }

    uploadTranscription() {
        const url = (this.language[this.language.subtitle.code]).split('?')[0];
        const formData = new FormData();
        formData.append('inputStr', this.textareaData);
        formData.append('fileName', url);
        this.createInteractiveVideoObj.spinner = true;
        this.urlService.uploadFileToS3(formData).subscribe((res) => {
            this.urlService.getSignedUrl(res.data).subscribe((preSignedUrl) => {
                this.language[this.language.subtitle.code] = preSignedUrl;
            })
            this.toastr.success(res.message);
            this.createInteractiveVideoObj.spinner = false;
        });
    }

    getVttFile(url = null) {
        this.urlService.getTextData(url || this.language[this.language.subtitle.code]).subscribe((res) => {
            this.textareaData = res;
            this.initialValue = res;
        });
    }

    positionChoices() {
        const dialogRef = this.dialog.open(PositionChoicesComponent, {
            width: '95%',
            maxWidth: '1300px',
            panelClass: 'position-choices',
            height: 'auto',
            data: {
                video: this.createInteractiveVideoObj.currentObj,
                nodes: this.createInteractiveVideoObj.currentObj.children
            },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.currentVideoData.isEdit = true;
                this.refreshInteractiveVideo();

                // WC 08162023 - Adding reload to resolve the issue in Staging where it's showing 1 empty node and all nodes are gone unless the user refreshes
                setTimeout(() => {
                    location.reload();
                }, 500);
                // WC 08162023

                // this.createChoiceObj.child = result.choices;
                // this.createInteractiveVideoObj.currentObj.width = result.positionsData.width;
                // this.createInteractiveVideoObj.currentObj.height = result.positionsData.height;
            }
        })
    }

    reorderChoices(s) {
        this.isReorderChoice = s;
    }

    saveReorderChoices() {
        const object = {
            videoId: this.mainID,
            parentId: this.createChoiceObj.child[0].parentId,
            childrenOrder: this.createChoiceObj.child.map(x => x._id)
        }
        // console.log(object)
        this.createInteractiveVideoObj.spinner = true;
        this.urlService.reorderNodeChildren(object).subscribe((res: any) => {
            this.latestNodeId = res.data[0].parentId;
            this.callChild1();
            this.reorderChoices(false);
            this.createInteractiveVideoObj.spinner = false;
        });
    }

    clickedItem(d) {        
        if (!this.createInteractiveVideoObj) {
            this.createInteractiveVideoObj = {};
        }

        if (!this.createInteractiveVideoObj.currentObj) {
            this.createInteractiveVideoObj.currentObj = {};
        }

        if (this.createInteractiveVideoObj.finalObj.length) {
            this.createInteractiveVideoObj.connectorNodes = this.createInteractiveVideoObj.finalObj.filter((x) => x.isConnectorNode === true && d._id !== x._id);
        }
        if (this.isAddWatchPath) {
            return this.selectWatchPath(d);
        }
        if (this.isAssistCreateVideo) {
            this.assistEventsSubject.next({
                type: this.msgEvent.nodeSelected,
                data: d
            });
            return;
        }
        this.isReorderChoice = false;

        //WC 01232024 --- to handle the clicking of the main parent video (bookmark video)

        // if (d?.type == 'child')
        //     var str = d?.name as string;
        // else
        //     var str = d?.Title as string;
        //WC 01232024

        var str = d?.name ? d?.name : d?.Title;
        this.videoName = str?.split('20')[0];
        this.createChoiceObj.child = [];
        // this.createInteractiveVideoObj.currentObj = {};

        setTimeout(() => {
            this.value = 0;
            this.highValue = d?.duration;
            this.options = {
                floor: 0,
                ceil: d?.duration,
            };
            // console.log('clicked duration is', d.duration);
        }, 2000);

        if (d?.URL) {
            if (d?.URL.includes('.mp4')) {
                this.currentType = 'video/mp4';
            } else {
                this.currentType = 'application/x-mpegURL';
            }
        }

        const videoTitleElement = document.getElementById('videoName');
        videoTitleElement.style.fontSize = '1.5rem';
        this.createInteractiveVideoObj.currentObj = d;
        // if (this.createInteractiveVideoObj.currentObj.subtitleTranslations) {
        //     this.createInteractiveVideoObj.currentObj.subtitleTranslations = this.createInteractiveVideoObj.currentObj.subtitleTranslations.map(x => {
        //         x.subtitle = languages.find(c => c.code === Object.keys(x)[0]);
        //         return x;
        //     });
        //     if(this.createInteractiveVideoObj.currentObj.subtitleUrl) {
        //         const defaultLang = this.getDefaultLangTranscription(this.createInteractiveVideoObj.currentObj)
        //         this.createInteractiveVideoObj.currentObj.subtitleTranslations.unshift(defaultLang);
        //     }
        // }
        if (this.createInteractiveVideoObj.currentObj) {
            this.createInteractiveVideoObj.currentObj.open = true;
        }


        for (const i of this.createInteractiveVideoObj.finalObj) {

            //i is the child of d
            if (d?._id === i.parentId) {
                if (i.parentIds) {
                    if (Array.isArray(i.parentIds) && !i.parentIds.includes(d?._id)) {
                        i.URLName = i.name;
                        this.name = i.name;
                        this.createChoiceObj.child.push(i);
                    }
                } else {
                    i.URLName = i.name;
                    this.name = i.name;
                    this.createChoiceObj.child.push(i);
                }
            }
        }

        //WC 12/01/2023 --- To add connector node children to be shown in the add choices modal
        for (const i of this.createInteractiveVideoObj.connectorNodes) {

            if (i.parentIds && i.parentIds.includes(d?._id)) {
                i.URLName = i.name;
                this.name = i.name;
                this.createChoiceObj.child.push(i);
            }
        }
        //WC 12/01/2023 --- To add connector node children

        // console.log(d, this.createInteractiveVideoObj.currentObj)
        if (d?.name === 'Add Video' && !d?.videoID && !this.isStoryBoard) {
            this.replaceVideo();
        } else {
            if (!this.createInteractiveVideoObj.currentObj?.time) {
                this.createInteractiveVideoObj.currentObj && (this.createInteractiveVideoObj.currentObj.time = 0);
            }
            const minutes = Math.floor(
                this.createInteractiveVideoObj.currentObj?.time / 60
            );
            const seconds = Math.floor(
                this.createInteractiveVideoObj.currentObj?.time - minutes * 60
            )
            let m: number | string;
            let s: number | string;
            if (minutes > 10) {
                m = minutes;
            } else {
                m = `0${minutes}`;
            }
            if (seconds > 10) {
                s = seconds;
            } else {
                s = `0${seconds}`;
            }
            this.createInteractiveVideoObj.currentObj && (this.createInteractiveVideoObj.currentObj.timeValue = `000:${m}:${s}`);
            this.currentVideoData = this.createInteractiveVideoObj.currentObj;
            // console.log("currentVideoData", this.createInteractiveVideoObj.currentObj);
            
            if(!this.isChatWindowVisible) {
                this.userPrompt = this.createInteractiveVideoObj.currentObj?.userPrompt ? this.createInteractiveVideoObj.currentObj?.userPrompt : "Make your next choice"
                $('#tree-Modal').modal('show');
            }
            $('#tree-Modal').on('hidden.bs.modal', () => {
                this.closeInteractiveVideo();
            });
            const popData = this.dashboardService.getHelpPopOver();
            if (!popData?.popTmpAddChoice) {
                setTimeout(() => {
                    if (this.popTmpAddChoiceEle) {
                        this.popTmpAddChoiceEle.show();
                    }
                }, 500);
            }
        }

        if(this.createInteractiveVideoObj.currentObj?.timer) {
            const timer = this.createInteractiveVideoObj.currentObj?.timer;
            this.hh = timer?.hh ? timer.hh : '00';
            this.mm = timer?.mm ? timer.mm : '00';
            this.ss = timer?.ss ? timer.ss : '00';
            if(this.createInteractiveVideoObj.currentObj?.timer?.timerUpMessage) {
                this.userMessage = this.createInteractiveVideoObj.currentObj?.timer?.timerUpMessage;
            }
        }
    }

    openSetTimerModal() {
        const timerData = this.createInteractiveVideoObj.currentObj?.timer;
        //console.log("timerData = ", timerData);

        this.hh = timerData?.hh ? timerData.hh.toString() : '00';
        this.mm = timerData?.mm ? timerData.mm.toString() : '00';
        this.ss = timerData?.ss ? timerData.ss.toString() : '00';
        //console.log("this.ss  = ", this.ss );

        this.userMessage = timerData?.timerUpMessage ? timerData?.timerUpMessage : '';

        const dialogRef = this.dialog.open(this.setTimerForCurrentNodeDialog, {
            width: '100%',
            maxWidth: '500px',
            panelClass: 'position-choices',
            height: 'auto',
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.saveTimer();
            }
        })
    }

    saveTimer() {
        const timer = this.getTime();

        const payload = {
            videoId: this.createInteractiveVideoObj.currentObj._id,
            type: this.createInteractiveVideoObj.currentObj.type,
            timer: timer
        }
        
        this.urlService.setTimerInVideoChoice(payload).subscribe((res) => {
            this.createInteractiveVideoObj.currentObj.timer = res.data.timer;
            this.toastr.success('Timer added successfully!');
            location.reload();
        }, (err) => {
            this.toastr.error('Error!');
        })
    }

    prependZero(number:any) {
        if(number == "00")
            return "00" 
        if (number < 9)
            return "0" + number;
        else
            return number;
     }

    resetTimer() {
        const payload = {
            videoId: this.createInteractiveVideoObj.currentObj._id,
            type: this.createInteractiveVideoObj.currentObj.type
        }

        this.urlService.resetTimerInVideoChoice(payload).subscribe((res) => {
            this.createInteractiveVideoObj.currentObj.timer = null;
            this.toastr.success('Timer reset successfully!');
            location.reload();
        }, (err) => {
            this.toastr.error('Error!');
        })
    }

    getTime() {
        const timer = {
            hh: this.hh,
            mm: this.mm,
            ss: this.ss,
            timerUpMessage: this.userMessage
        }
        return timer;
    }

    openPreviewUrl(url: string, isAI: boolean = false) {
        if (url) {
            window?.open(url, '_blank');
            if (isAI) {
                this.assistEventsSubject.next({ type: this.msgEvent.close, data: '' });
            }
        } else {
            this.replaceVideo(isAI);
        }
    }

    UptheMouse() {
        if (this.createChoiceObj.currentNo > 4) {
            this.createChoiceObj.currentNo = 4;
        } else if (this.createChoiceObj.currentNo < 1) {
            this.createChoiceObj.currentNo = 1;
        }
    }

    clickSelectVideo() {
        if (!this.isAssistCreateVideo) {
            this.isOpenCreateVideo = true;
            this.openVideoCreateModel('freeboard');
            this.createInteractiveVideoObj.selectedVideoCheck = false;
            this.createInteractiveVideoObj.interactiveStep = 'three';
        }
    }

    openVideoCreateModel(type: string, isAI: boolean = false) {
        const dialogRef = this.dialog.open(CreateVideoComponent, {
            width: '95%',
            maxWidth: '1300px',
            panelClass: 'my-dialog',
            height: 'auto',
            minHeight: '500px',
            data: {},
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.createInteractiveVideoObj.selectedItem = result;
                if(result.type === 'application/pdf'){
                    this.createInteractiveVideoObj.selectedItem.poster = "assets/images/pdf-img.png"
                }
                    
                if (type === 'videoTemplate') {
                    this.replaceVideoData();
                    if (isAI) {
                        this.assistEventsSubject.next({ type: this.msgEvent.close, data: result });
                    }
                } else {
                    this.createInteractiveVideo();
                }
            } else {
                if (this.isAssistCreateVideo) {
                    this.assistEventsSubject.next({ type: this.msgEvent.addFirstNode });
                }
            }
            this.dialog.closeAll();
        });
    }

    uploadAVideo() {
        this.router.navigate(['/upload-videos']);
    }

    createInteractiveVideo() {
        if (typeof this.createInteractiveVideoObj.selectedItem !== 'object') {
            this.toastr.warning('Please select an initial video!');
            return;
        }
        this.createInteractiveVideoObj.spinner = true;
        const obj: any = {
            type: 'main',
            poster: this.createInteractiveVideoObj.selectedItem.poster ?
                this.createInteractiveVideoObj.selectedItem.poster : this.createInteractiveVideoObj.selectedItem.thumbnail,
            name: this.createInteractiveVideoObj.selectedItem.name,
            URL: this.createInteractiveVideoObj.selectedItem.URL,
            Thumbnail: this.createInteractiveVideoObj.selectedItem.Thumbnail,
            Title: '',
            Description: '',
            // WC 07272022 - Add Tagging
            Tag: '',
            // WC 07272022
            Categories: [],
            time: 0,
            parentId: null,
            videoID: this.createInteractiveVideoObj.selectedItem._id,
            // WC 08092022
            subtitleUrl: this.createInteractiveVideoObj.selectedItem.subtitleUrl,
            // WC 08092022
        };
        if (this.createInteractiveVideoObj.selectedItem.originalName) {
            obj.name = this.createInteractiveVideoObj.selectedItem.originalName;
        }
        // Use original name if it exist in creating interating video
        this.urlService.createInteractiveVideo(obj).subscribe((success) => {
            this.closeModal();
            this.isOpenCreateVideo = false;
            // $('#upload-vid-Modal').modal('hide');
            this.createInteractiveVideoObj.spinner = false;
            this.createInteractiveVideoObj.selectedItem = {};
            this.createInteractiveVideoObj.selectedVideoCheck = false;
            this.router.navigate(['create-interactive-video'], {
                queryParams: { video: success.data.data._id },
            });
            this.localStorageService.removeItem('freeTemp');
            this.initialFunction('');
            // sync user data
            this.dashboardService.onGetUserData();
            if (this.isAssistCreateVideo) {
                this.assistEventsSubject.next({ type: this.msgEvent.addFirstNode, data: success.data.data });
            }
        },
            (error) => {
                this.createInteractiveVideoObj.spinner = false;
            }
        );
        this.createInteractiveVideoObj.step = 'three';
    }

    createEmptyNodes(obj?: any, type: string = 'notObject', emptyNodeData?) {
        this.createInteractiveVideoObj.spinner = true;
        if (!obj) {
            obj = [{
                level: 0,
                parentId: null,
                name: 'Parent Node',
                Tag: 'storyboard'
            }];
        }
        this.urlService.createEmptyNodes(obj).subscribe((success) => {
            this.createInteractiveVideoObj.mainVideo = success.data;
            this.isOpenCreateVideo = false;
            $('#tree-Modal').modal('hide');
            this.createInteractiveVideoObj.spinner = false;
            this.createInteractiveVideoObj.selectedItem = {};
            this.createInteractiveVideoObj.selectedVideoCheck = false;
            if (!this.mainID) {
                this.mainID = success.data._id;
                const obj1: any = {
                    id: this.createInteractiveVideoObj.mainVideo._id,
                    type: this.createInteractiveVideoObj.mainVideo.type,
                    name: this.createInteractiveVideoObj.mainVideo.name,
                    URL: this.createInteractiveVideoObj.mainVideo.URL,
                    Title: this.createInteractiveVideoObj.mainVideo.Title,
                    Description: this.createInteractiveVideoObj.mainVideo.Description,
                    Tag: 'storyboard',
                    Categories: this.createInteractiveVideoObj.mainVideo.Categories,
                    time: this.createInteractiveVideoObj.mainVideo.time,
                    parentId: this.createInteractiveVideoObj.mainVideo.parentId,
                    poster: this.createInteractiveVideoObj.mainVideo.poster,
                    videoID: this.createInteractiveVideoObj.mainVideo.videoID,
                };
                
                if(this.createInteractiveVideoObj.mainVideo?.userPrompt){
                    obj1.userPrompt = this.createInteractiveVideoObj.mainVideo?.userPrompt ? this.createInteractiveVideoObj.mainVideo?.userPrompt : "Make your next choice"
                }

                this.urlService.updateInteractiveVideo(obj1).subscribe();
                this.router.navigate(['create-interactive-video'], {
                    queryParams: { video: success.data._id },
                });
            }
            this.localStorageService.removeItem('freeTemp');
            // this.intialFunction('');
            if (obj[0].parentId) {
                setTimeout(() => {
                    // console.log("createEmptyNodes - calling this.refreshInteractiveVideo()");
                    // this.refreshInteractiveVideo();
                    if (this.isAssistCreateVideo) {
                        this.callChild('');
                    } else {
                        location.reload();
                    }
                }, 1000);
            }

            if (type === 'Object' && this.isAssistCreateVideo) {
                this.assistEventsSubject.next({ type: this.msgEvent.addMoreChoices });
            } else {
                this.assistEventsSubject.next({ type: this.msgEvent.addFirstNode, data: success.data.data });
                if (this.mainID && !obj[0].parentId) {
                    this.router.navigate(['create-interactive-video'], {
                        queryParams: { video: success.data._id },
                    });
                }
            }
        },
            (error) => {
                this.createInteractiveVideoObj.spinner = false;
            });
        this.createInteractiveVideoObj.step = 'three';
    }

    openDeleteConfirmation() {
        $('#deleteCurrentNodeModal').modal('show');
    }

    deleteCurrentNode() {
        this.createInteractiveVideoObj.spinner = true;
        if (this.deleteCurrentNodeType) {
            // this.createInteractiveVideoObj.spinner = true;
            if (this.createInteractiveVideoObj.currentObj.type === 'main') {
                this.urlService
                    .deleteBookMarkVideo(this.createInteractiveVideoObj.currentObj._id)
                    .subscribe((success) => {

                        this.toastr.success('Delete successfully!');
                        $('#tree-Modal').modal('hide');
                        $('#deleteCurrentNodeModal').modal('hide');
                        this.createChoiceObj.child = [];
                        this.callChild1();
                        this.deleteCurrentNodeType = 1;
                    }).add(() => {
                        this.createInteractiveVideoObj.spinner = false;
                    });
            } else {
                const Payload = {
                    videoId: this.createInteractiveVideoObj.currentObj._id,
                    mainId: this.createInteractiveVideoObj.currentObj.parentId,
                    isDeleteSingleNode: this.deleteCurrentNodeType === 1
                };

                this.urlService.deleteChildVideo(Payload).subscribe((success) => {
                    // this.createInteractiveVideoObj.spinner = false;
                    $('#tree-Modal').modal('hide');
                    $('#deleteCurrentNodeModal').modal('hide');
                    this.createChoiceObj.child = [];
                    this.latestNodeId = this.createInteractiveVideoObj.currentObj.parentId;
                    this.callChild1();

                    if (!success.error)
                        this.toastr.info('Updated Successfully!');
                    else
                        this.toastr.error(success.message);

                    this.deleteCurrentNodeType = 1;
                }).add(() => {
                    this.createInteractiveVideoObj.spinner = false;
                });
            }
        }
    }

    addChoices(no) {
        for (let i = 0; i < no; i++) {
            const name = 'Untitled ' + i;
            const obj: any = {
                name,
                URL: '',
                _id: '',
                parentId: this.createInteractiveVideoObj.currentObj._id,
            };
            this.createChoiceObj.child.push(obj);
        }
        this.createChoiceObj.parent = this.createInteractiveVideoObj.currentObj;
    }

    updateVideoTitle(name: any, child = false) {
        if (child) {
            this.childTitleChange$.next({ ...name });
            return;
        }
        const title = document.getElementById('videoName'); // 39
        let initialSize = 24;
        if (name.length > 39) {
            const size = name.length - 39;
            initialSize = 24 - size;
        }
        if (initialSize > 15) {
            title.style.fontSize = initialSize + 'px';
        } else {
            title.style.fontSize = '15px';
        }
        if (name.length > 2) {
            this.titleChange$.next(name);
        }
        // else {
        //   this.toastr.warning('Minimum 3 letter are allowed!');
        // }
    }


    updateVideoUserPrompt(userPrompt:any){
        this.userPromptChange$.next(userPrompt)
    }

    updateVideoUserPromptValue(userPrompt){
        this.createInteractiveVideoObj.spinner = true;
        
        const obj: any = {
            id: this.createInteractiveVideoObj.currentObj._id,
            type: this.createInteractiveVideoObj.currentObj.type,
            name: this.createInteractiveVideoObj.currentObj.name,
            URL: this.createInteractiveVideoObj.currentObj.URL,
            Title: this.createInteractiveVideoObj.currentObj.Title,
            Description: this.createInteractiveVideoObj.currentObj.Description,
            // WC 07272022 - Add Tag
            Tag: this.createInteractiveVideoObj.currentObj.Tag,
            // WC 07272022
            Categories: this.createInteractiveVideoObj.currentObj.Categories,
            time: this.createInteractiveVideoObj.currentObj.time,
            parentId: this.createInteractiveVideoObj.currentObj.parentId,
            poster: this.createInteractiveVideoObj.currentObj.poster,
            videoID: this.createInteractiveVideoObj.currentObj.videoID,
            // WC 08092022 - subtitleUrl
            subtitleUrl: this.createInteractiveVideoObj.currentObj.subtitleUrl,
            // WC 08092022
            userPrompt
        };

        
        this.urlService.updateInteractiveVideo(obj).subscribe((success) => {            
            this.latestNodeId = this.createInteractiveVideoObj.currentObj._id;
            if(this.createInteractiveVideoObj.currentObj.type === "main"){
                this.createInteractiveVideoObj.mainVideo = success.data
            }
            this.callChild1();
            this.toastr.info('Updated Successfully!');
            this.createInteractiveVideoObj.spinner = false;
        });
    }


    deleteChoice(selectedNode) {
        this.createInteractiveVideoObj.spinner = true;
        this.toastr.warning('Deleting a choice will also delete all the child choices!');
        if (selectedNode.type === 'main') {
            this.urlService
                .deleteBookMarkVideo(selectedNode._id)
                .subscribe((success) => {
                    this.toastr.success('Delete successfully!');
                    $('#tree-Modal').modal('hide');
                    $('#deleteCurrentNodeModal').modal('hide');
                    this.createChoiceObj.child = [];
                    this.callChild1();
                    this.deleteCurrentNodeType = 1;
                }).add(() => {
                    this.createInteractiveVideoObj.spinner = false;
                });
        } else {
            const Payload = {
                videoId: selectedNode._id,
                mainId: selectedNode.parentId,
                isDeleteSingleNode: this.deleteCurrentNodeType === 1
            };

            this.urlService.deleteChildVideo(Payload).subscribe((success) => {
                $('#tree-Modal').modal('hide');
                $('#deleteCurrentNodeModal').modal('hide');
                this.createChoiceObj.child = [];
                this.latestNodeId = selectedNode.parentId;
                this.callChild1();

                if (!success.error)
                    this.toastr.info('Updated Successfully!');
                else
                    this.toastr.error(success.message);

                this.deleteCurrentNodeType = 1;
            }).add(() => {
                this.createInteractiveVideoObj.spinner = false;
            });
        }
    }

    updateCurrentName(name: any) {
        this.createInteractiveVideoObj.spinner = true;
        this.createInteractiveVideoObj.currentObj.name = name;
        if (this.createInteractiveVideoObj.currentObj.type === 'main') {
            this.createInteractiveVideoObj.mainVideo.name = name;
        }
        const obj: any = {
            id: this.createInteractiveVideoObj.currentObj._id,
            type: this.createInteractiveVideoObj.currentObj.type,
            name,
            URL: this.createInteractiveVideoObj.currentObj.URL,
            Title: this.createInteractiveVideoObj.currentObj.Title,
            Description: this.createInteractiveVideoObj.currentObj.Description,
            // WC 07272022 - Add Tag
            Tag: this.createInteractiveVideoObj.currentObj.Tag,
            // WC 07272022
            Categories: this.createInteractiveVideoObj.currentObj.Categories,
            time: this.createInteractiveVideoObj.currentObj.time,
            parentId: this.createInteractiveVideoObj.currentObj.parentId,
            poster: this.createInteractiveVideoObj.currentObj.poster,
            videoID: this.createInteractiveVideoObj.currentObj.videoID,
            // WC 08092022 - subtitleUrl
            subtitleUrl: this.createInteractiveVideoObj.currentObj.subtitleUrl,
            // WC 08092022
        };
        
        if(this.createInteractiveVideoObj.currentObj?.userPrompt){
            obj.userPrompt = this.createInteractiveVideoObj.currentObj?.userPrompt ? this.createInteractiveVideoObj.currentObj?.userPrompt : "Make your next choice"
        }

        this.urlService.updateInteractiveVideo(obj).subscribe(
            (success) => {
                this.latestNodeId = this.createInteractiveVideoObj.currentObj._id;
                this.callChild1();
                this.toastr.info('Updated Successfully!');
                if (this.isAssistCreateVideo) {
                    this.refreshInteractiveVideo();
                    // this.assistEventsSubject.next({type: 'editFirstNodeName', data: success.data});
                }
            },
            (error) => {
                this.createInteractiveVideoObj.spinner = false;
            }
        );
    }

    updateChildNodeName(i) {
        this.createInteractiveVideoObj.spinner = true;
        if (!i.name) {
            i.name = 'untitled'
        }
        const obj: any = {
            id: i._id,
            type: i.type,
            name: i.name,
            URL: i.URL,
            Title: i.Title,
            Description: i.Description,
            Tag: i.Tag,
            Categories: i.Categories,
            time: i.time,
            parentId: i.parentId,
            poster: i.poster,
            videoID: i.videoID,
            subtitleUrl: i.subtitleUrl,
        };

        if(i?.userPrompt){
            obj.userPrompt = i?.userPrompt ? i?.userPrompt : "Make your next choice"
        }

        this.urlService.updateInteractiveVideo(obj).subscribe(
            (success) => {
                this.latestNodeId = i._id;
                this.callChild1();
                this.toastr.info('Updated Successfully!');
            },
            (error) => {
                this.createInteractiveVideoObj.spinner = false;
            }
        );
    }

    addMoreChoices() {
        if (this.createChoiceObj.child.length > 3) {
            this.toastr.warning('Choices can not be more than 4!');
            return;
        }
        const i = this.createChoiceObj.child.length - 1;
        const name = 'Untitled ' + i;
        const obj: any = {
            name,
            URL: '',
            _id: '',
            parentId: this.createInteractiveVideoObj.currentObj._id,
        };
        this.createChoiceObj.child.push(obj);
    }

    getConnectorNodeData(video, allObj, selectedVideo?) {
        if (video && video?.parentId) {
            let videoObj = this.createInteractiveVideoObj.finalObj.find((i) => i._id === video.parentId);
            let parentOfSelectedVideo = videoObj?.parent;
            let index = allObj.indexOf(parentOfSelectedVideo);
            if (index >= 0) {
                allObj.splice(index, 1);
            }
            if (selectedVideo && selectedVideo.parentId) {
                let selectedVideoObj = this.createInteractiveVideoObj.finalObj.find((i) => i._id === selectedVideo.parentId);
                if (selectedVideoObj.parentIds && selectedVideoObj.parentIds.length) {
                    for (const id of selectedVideoObj.parentIds) {
                        let parentObj = this.createInteractiveVideoObj.finalObj.find((i) => i._id === id);
                        let index = allObj.indexOf(parentObj);
                        if (index >= 0) {
                            allObj.splice(index, 1);
                        }
                    }
                }
            }
            if (parentOfSelectedVideo?.parent) {
                this.getConnectorNodeData({ parentId: parentOfSelectedVideo._id }, allObj);
            }
        }
    }

    // openvideoListModal is used to select a video during ADDING the choices by open video-list.component modal
    //item is the to-be-added new node which is the child of the current node
    openVideoListModal(index: number, item?: any) {
        let data = [];

        // console.log("openVideoListModal - this.createInteractiveVideoObj.finalObj = ", this.createInteractiveVideoObj.finalObj);
        // console.log("openVideoListModal - item = ", item);

        if (this.createInteractiveVideoObj.finalObj.length) {
            //Exclude the parent node and children nodes of the to-be-added new node
            if(item) {
                data = this.createInteractiveVideoObj.finalObj.filter((i) => i._id !== item.parentId && i.parentId !== item.parentId && i.type == 'child')
                this.getConnectorNodeData(item, data, item);
            }

            //console.log("openVideoListModal - tree nodes after filtering parent and children nodes = ", data);

        }
        if (item?.URL) {
            return;
        }

        if (item?._id) {
            this.createInteractiveVideoObj.currentNodeOption = item;
        }

        // const dialogRef = this.dialog.open(VideoListComponent, {
        const dialogRef = this.dialog.open(CreateVideoComponent, {
            data: {
                treeNodes: this.createInteractiveVideoObj.endingNodes,
                connectorNodes: this.createInteractiveVideoObj.connectorNodes,
                nodes: data && data.length ? data : []
            },
            minWidth: '50vw'
        });

        dialogRef.afterClosed().subscribe((result) => {
            //console.log("result", result);
            if (result) {
                // this.createChoiceObj.child[index] = { ...result };

                this.createChoiceObj.child[index] = result;
                this.createChoiceObj.child[index].poster = result.poster ? result.poster : result.thumbnail;
                this.createChoiceObj.child[index].URLName = result.name;

                //WC 03262024 --- result.videoID not result._id as this will impact connector node.
                //this.createChoiceObj.child[index].videoID = result._id;
                this.createChoiceObj.child[index].videoID = result.videoID;

                if(result.type === 'application/pdf'){
                    this.createChoiceObj.child[index].poster = "assets/images/pdf-img.png";
                }

            
                this.selectionChanged(result, index);
                
            }
        });
    }

    // add child
    selectionChanged(e: any, index) {

        this.createInteractiveVideoObj.spinner = true;
        if (!this.createInteractiveVideoObj.currentNodeOption) {
            const objFinal: any = {
                type: 'child',
                name: e.name,
                //parentId: this.createInteractiveVideoObj.currentObj._id,
                URL: e.URL,
                time: 0,
                poster: e.poster ? e.poster : e.thumbnail,
                videoID: e._id,
                // WC 08092022
                subtitleUrl: e.subtitleUrl,
                isEndingNode: e.type === 'url'
                // WC 08092022
            };

            if (e?.isEndingNode) {
                objFinal.videoID = e.videoID;
                objFinal.endingNodeId = e._id;
            };
            if (e?.isConnectorNode) {
                //console.log("setting connector node property: e.videoID and e._id")

                objFinal._id = e._id;
                objFinal.isConnectorNode = e.isConnectorNode;
                objFinal.parentIds = e.parentIds;
                objFinal.videoID = e.videoID;

                //Save the original parentId
                objFinal.oldParentId = e.parentId;

                //Set the new parent Id
                objFinal.parentId = this.createInteractiveVideoObj.currentObj._id;

                //console.log("*** objFinal = ", objFinal);
            } else {
                objFinal.parentId = this.createInteractiveVideoObj.currentObj._id
            };

            this.urlService.createInteractiveVideo(objFinal).subscribe(
                (success) => {
                    //console.log("Adding NEW CHILD --- success = ", success);

                    this.createChoiceObj.child[index] = success.data?.data;

                    //console.log("Adding NEW CHILD - this.createChoiceObj.child[index].name = ", this.createChoiceObj.child[index].name);

                    this.createChoiceObj.child[index].URLName = this.createChoiceObj.child[index].name
                    this.latestNodeId = this.createInteractiveVideoObj.currentObj._id;
                    if (this.createInteractiveVideoObj.currentObj.children === undefined) {
                        this.createInteractiveVideoObj.currentObj.children = [];
                    }
                    const childObjFinal = success.data?.data;
                    // this.createInteractiveVideoObj.currentObj.children.push(objFinal);
                    this.createInteractiveVideoObj.currentObj.children.push(childObjFinal);
                    this.callChild1();
                    this.toastr.info('Created Successfully!');
                    if (this.isAssistCreateVideo) {
                        this.assistEventsSubject.next({
                            type: this.msgEvent.addChildNode,
                            data: objFinal
                        });
                    }
                },
                (error) => {
                    this.createInteractiveVideoObj.spinner = false;
                }
            );
        } else {
            // REPLACING A VIDEO
            let subtitleUrl = '';
            if (this.createInteractiveVideoObj.selectedItem.subtitleUrl != undefined)
                subtitleUrl = e.subtitleUrl;
            const objFinal:any = {
                id: this.createInteractiveVideoObj.currentNodeOption._id,
                type: this.createInteractiveVideoObj.currentNodeOption.type,
                name: e.name ? e.name : this.createInteractiveVideoObj.currentNodeOption.name,
                URL: e.URL,
                poster: e.poster ? e.poster : e.thumbnail,
                Title: this.createInteractiveVideoObj.currentNodeOption.Title,
                Description: this.createInteractiveVideoObj.currentNodeOption.Description,
                // WC 07272022
                Tag: this.createInteractiveVideoObj.currentNodeOption.Tag,
                // WC 07272022
                Categories: this.createInteractiveVideoObj.currentNodeOption.Categories,
                time: this.createInteractiveVideoObj.currentNodeOption.time,
                parentId: this.createInteractiveVideoObj.currentNodeOption.parentId,
                videoID: this.createInteractiveVideoObj.currentNodeOption.videoID || e._id,
                // WC 08092022
                subtitleUrl,
                // WC 08092022
            };
            
            if(this.createInteractiveVideoObj.currentNodeOption?.userPrompt){
                objFinal.userPrompt = this.createInteractiveVideoObj.currentNodeOption?.userPrompt ? this.createInteractiveVideoObj.currentNodeOption?.userPrompt : "Make your next choice"

            }
            
            this.urlService.updateInteractiveVideo(objFinal).subscribe(
                (success) => {
                    // this.createChoiceObj.child[index] = success.data;
                    // this.createChoiceObj.child[index].URLName = this.createChoiceObj.child[index].name
                    this.createInteractiveVideoObj.spinner = false;
                    this.createInteractiveVideoObj.currentNodeOption = null;
                    this.latestNodeId = this.createInteractiveVideoObj.currentObj._id;
                    this.callChild1();
                    this.toastr.info('Updated Successfully!');
                },
                (error) => {
                    this.createInteractiveVideoObj.spinner = false;
                }
            );
        }
    }


    // Add node between two nodes
    insertNode(item) {
        const dialogRef = this.dialog.open(CreateVideoComponent, {
            data: {
                links: false
            },
            minWidth: '50vw'
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.createInteractiveVideoObj.spinner = true;
                const objFinal: any = {
                    type: 'child',
                    name: result.name,
                    parentId: item.parent,
                    childId: item.child,
                    URL: result.URL,
                    time: 0,
                    poster: result.poster ? result.poster : result.thumbnail,
                    videoID: result._id,
                    subtitleUrl: result.subtitleUrl,
                    isEndingNode: result.type === 'url'
                };
                this.urlService.insertNode(objFinal).subscribe((res) => {
                    this.checkEditPathWay(objFinal, res.data);
                    this.initialFunction('');
                    // this.callChild1();
                    this.toastr.info('Node Inserted Successfully!');
                }, () => {
                    this.createInteractiveVideoObj.spinner = false;
                });
            }
        });
    }

    checkEditPathWay(objFinal, newNode) {
        for (const pathway of this.createInteractiveVideoObj.pathways) {
            if (pathway.pathNodes.includes(objFinal.parentId) && pathway.pathNodes.includes(objFinal.childId)) {
                pathway.pathNodes.push(newNode.data._id);
                const payload: any = {
                    _id: pathway._id,
                    videoId: this.mainID,
                    pathName: pathway.pathName,
                    startNodeId: pathway.startNodeId,
                    startNodeType: pathway.startNodeType,
                    endNodeId: pathway.endNodeId,
                    endNodeType: pathway.endNodeType,
                    pathNodes: pathway.pathNodes
                };
                this.urlService.setPathway(payload, true).subscribe((res) => {
                    this.getVideoPathWays();
                });
            }
        }
    }

    durationChange(duration?: string) {
        const time = duration.split(':');
        const currentTime =
            Number(time[2]) + Number(time[1]) * 60 + Number(time[0]);
        if (currentTime > this.createInteractiveVideoObj.currentObj.duration) {
            this.toastr.error('Time should be less then duration!');
            return;
        } else {
            this.duationChange$.next(currentTime);
        }
    }

    updateCurrentTime(duration: any) {
        // console.log("In updateCurrentTime - this.createInteractiveVideoObj.currentObj.subtitleUrl = ", this.createInteractiveVideoObj.currentObj.subtitleUrl);

        this.createInteractiveVideoObj.currentObj.time = duration;
        const obj: any = {
            id: this.createInteractiveVideoObj.currentObj._id,
            type: this.createInteractiveVideoObj.currentObj.type,
            poster: this.createInteractiveVideoObj.currentObj.poster,
            name: this.createInteractiveVideoObj.currentObj.name,
            URL: this.createInteractiveVideoObj.currentObj.URL,
            Title: this.createInteractiveVideoObj.currentObj.Title,
            Description: this.createInteractiveVideoObj.currentObj.Description,
            // WC 07272022
            Tag: this.createInteractiveVideoObj.currentObj.Tag,
            // WC 07272022
            Categories: this.createInteractiveVideoObj.currentObj.Categories,
            time: this.createInteractiveVideoObj.currentObj.time,
            parentId: this.createInteractiveVideoObj.currentObj.parentId,
            videoID: this.createInteractiveVideoObj.currentObj.videoID,
            // WC 08092022
            subtitleUrl: this.createInteractiveVideoObj.currentObj.subtitleUrl,
            // WC 08092022
        };
        
        if(this.createInteractiveVideoObj.currentObj?.userPrompt){
            obj.userPrompt = this.createInteractiveVideoObj.currentObj?.userPrompt ? this.createInteractiveVideoObj.currentObj?.userPrompt : "Make your next choice"
        }

        this.createInteractiveVideoObj.spinner = true;
        this.urlService.updateInteractiveVideo(obj).subscribe(
            (success) => {
                this.callChild1();
                this.toastr.info('Updated Successfully!');
            },
            (error) => {
                this.createInteractiveVideoObj.spinner = false;
                this.toastr.error('Update Failed!');
            }
        );
    }

    refreshInteractiveVideo() {
        $('#tree-Modal').modal('hide');
        this.createChoiceObj.child = [];
        this.createInteractiveVideoObj.currentObj.open = false;
        this.initialFunction('');
    }

    closeInteractiveVideo() {
        $('#tree-Modal').modal('hide');
        this.createChoiceObj.child = [];
        this.createInteractiveVideoObj.currentObj = {};
    }

    saveStoryBoardChildNodes(currentObj?) {
        const nodes = [];
        let i = 0;
        for (const childElement of this.createChoiceObj.child) {
            ++i;
            if (childElement._id === '') {
                nodes.push({
                    level: currentObj ? currentObj?.level + 1 : this.createInteractiveVideoObj.currentObj.level + 1,
                    parentId: currentObj ? currentObj._id : this.createInteractiveVideoObj.currentObj._id,
                    mainId: this.mainID,
                    name: childElement.name ? childElement.name : 'Child ' + i
                })
            }
        }

        // console.log("saveStoryBoardChildNodes - nodes.length = ", nodes.length);

        if (nodes.length) {
            this.createEmptyNodes(nodes, 'Object');
            // WC 07122023 - The newly added nodes are not shown in Staging and Prod
            // location.reload();
        } else {
            this.refreshInteractiveVideo();
        }
    }

    getDuration(e) {
        this.createInteractiveVideoObj.currentObj.duration = e.target.duration;
        let hasChilren = false;
        if (
            this.createInteractiveVideoObj.currentObj.children == null ||
            this.createInteractiveVideoObj.currentObj.children == undefined
        ) {
            hasChilren = false;
        } else {
            hasChilren = this.createInteractiveVideoObj.currentObj.children.length > 0;
        }
        this.placeMarker(
            hasChilren,
            this.createInteractiveVideoObj.currentObj.time,
            'video-all',
            e.target.duration
        );
    }

    replaceVideo(isAI: boolean = false) {
        $('#tree-Modal').modal('hide');
        // $("#select-vid-Modal").modal(
        //   { backdrop: "static", keyboard: false },
        //   "show"
        // );
        this.openVideoCreateModel('videoTemplate', isAI);
        this.createInteractiveVideoObj.currentObj.open = false;
        this.OpenPopupAll = true;
    }

    editVideo() {
        $('#tree-Modal').modal('hide');
        this.currentVideoData.isEdit = true;
        const dialogRef = this.dialog.open(EditVideoComponent, {
            disableClose: true,
            width: '100%',
            height: '80%',
            maxWidth: '80%',
            panelClass: 'my-dialog',
            data: {
                video: this.createInteractiveVideoObj.currentObj,
            },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result?.isAnyChange) {
                this.refreshInteractiveVideo();
            } else {
                this.clickedItem(result?.video);
            }
        })
    }

    checkedReaplceItem(item) {
        this.createInteractiveVideoObj.selectedItem = item;

        // console.log("*** this.createInteractiveVideoObj.selectedItem FOR REPLACING = ", this.createInteractiveVideoObj.selectedItem);
    }

    replaceVideoData() {        
        let obj: any = {};
        if (this.createInteractiveVideoObj.currentObj.name === 'Add Video') {
            obj = {
                // ADDING A VIDEO
                id: this.createInteractiveVideoObj.currentObj._id,
                type: this.createInteractiveVideoObj.currentObj.type,
                name: this.createInteractiveVideoObj.selectedItem.name,
                URL: this.createInteractiveVideoObj.selectedItem.URL,
                poster: this.createInteractiveVideoObj.selectedItem.poster ?
                    this.createInteractiveVideoObj.selectedItem.poster : this.createInteractiveVideoObj.selectedItem.thumbnail,
                Title: this.createInteractiveVideoObj.selectedItem.Title,
                Description: this.createInteractiveVideoObj.currentObj.Description,
                // WC 07272022
                Tag: this.createInteractiveVideoObj.currentObj.Tag,
                // WC 07272022
                Categories: this.createInteractiveVideoObj.currentObj.Categories,
                time: this.createInteractiveVideoObj.currentObj.time,
                parentId: this.createInteractiveVideoObj.currentObj.parentId,
                videoID: this.createInteractiveVideoObj.selectedItem._id,
                // WC 08092022
                subtitleUrl: this.createInteractiveVideoObj.selectedItem.subtitleUrl,
                
                // WC 08092022
            };
            if(this.createInteractiveVideoObj.selectedItem?.userPrompt){
                obj.userPrompt = this.createInteractiveVideoObj.selectedItem?.userPrompt ? this.createInteractiveVideoObj.selectedItem?.userPrompt : "Make your next choice"
            }
        } else {
            // REPLACING A VIDEO
            let subtitleUrl = '';
            if (this.createInteractiveVideoObj.selectedItem.subtitleUrl != undefined)
                subtitleUrl = this.createInteractiveVideoObj.selectedItem.subtitleUrl;
            obj = {
                id: this.currentVideoData._id,
                type: this.currentVideoData.type,
                // name: this.currentVideoData.name,
                name: this.createInteractiveVideoObj.selectedItem.name,
                URL: this.createInteractiveVideoObj.selectedItem.URL,
                poster: this.createInteractiveVideoObj.selectedItem.poster ?
                    this.createInteractiveVideoObj.selectedItem.poster : this.createInteractiveVideoObj.selectedItem.thumbnail,
                Title: this.currentVideoData.Title,
                Description: this.currentVideoData.Description,
                // WC 07272022
                Tag: this.currentVideoData.Tag,
                // WC 07272022
                Categories: this.currentVideoData.Categories,
                time: this.currentVideoData.time,
                parentId: this.currentVideoData.parentId,
                videoID: this.createInteractiveVideoObj.selectedItem._id,
                // WC 08092022
                subtitleUrl,
                // WC 08092022
            };
            
            if(this.createInteractiveVideoObj?.userPrompt){
                obj.userPrompt = this.createInteractiveVideoObj?.userPrompt ? this.createInteractiveVideoObj?.userPrompt : "Make your next choice"
            }
        }
        this.createInteractiveVideoObj.spinner = true;
        this.urlService.updateInteractiveVideo(obj).subscribe((success) => {
            let isCheckUrl = false;
            if (success.data.URL) {
                isCheckUrl = this.utilityService.checkIsVideoUrl(success.data.URL);
            }
            this.createInteractiveVideoObj.currentObj.isUrl = isCheckUrl;
            this.createInteractiveVideoObj.currentObj.poster1 = '';
            this.createInteractiveVideoObj.currentObj.poster =
                this.createInteractiveVideoObj.selectedItem.poster ? this.createInteractiveVideoObj.selectedItem.poster : this.createInteractiveVideoObj.selectedItem.thumbnail;
            this.createInteractiveVideoObj.currentObj.imvd = 'ncs';
            this.createInteractiveVideoObj.currentObj.basck = 'videochart';
            this.createInteractiveVideoObj.currentObj.URL = this.createInteractiveVideoObj.selectedItem.URL;
            this.createInteractiveVideoObj.currentObj.videoID = this.createInteractiveVideoObj.selectedItem._id;
            if (this.createInteractiveVideoObj.currentObj.name === 'Add Video') {
                this.createInteractiveVideoObj.currentObj.name = this.createInteractiveVideoObj.selectedItem.name;
                this.createInteractiveVideoObj.currentObj.Title = '';
            }
            this.createInteractiveVideoObj.currentObj.open = true;
            this.latestNodeId = this.createInteractiveVideoObj.currentObj._id;
            // this.callChild1();
            this.currentVideoData.isEdit = true;
            this.getMainVideoInfo();
            this.toastr.info('Updated Successfully!');
            this.OpenPopupAll = false;
            this.closeModal();
            // this.createInteractiveVideoObj.selectedItem.URL = null
            // $('#tree-Modal').modal('show');
            // $('#select-vid-Modal').modal('hide');
        },
            (error) => {
                this.createInteractiveVideoObj.spinner = false;
            }
        );
    }

    // Preview Video
    previewVideo() {
        if (this.createInteractiveVideoObj.mainVideo.name == 'Add Video') {
            this.toastr.warning('Please Add parent Video!');
            return;
        }
        this.createInteractiveVideoObj.preview = true;
        // this.toastr.info('Preparing Video!')
        $('#tree-preview-vid-Modal').modal('show');
        $('#tree-publish-vid-Modal').modal('hide');
    }

    getCurrentTime(e) {
        this.interval = setInterval(() => {
            this.createInteractiveVideoObj.currentPlayingItem.currentTime =
                e.target.currentTime;
        }, 1000);

        let hasChilren: any = false;
        if (
            this.createInteractiveVideoObj.currentPlayingItem.children == null ||
            this.createInteractiveVideoObj.currentPlayingItem.children == undefined
        ) {
            hasChilren = false;
        } else {
            hasChilren = this.createInteractiveVideoObj.currentPlayingItem.children.length > 0;
        }

        //  this.createInteractiveVideoObj.currentPlayingItem.currentTime = e.target.currentTime
        this.placeMarker(
            hasChilren,
            this.createInteractiveVideoObj.currentPlayingItem.time,
            'previewVideo',
            e.target.duration
        );

        this.createInteractiveVideoObj.currentPlayingItem.currentTime =
            e.target.currentTime;
    }

    closePreview() {
        $('#tree-preview-vid-Modal').modal('hide');
        this.createInteractiveVideoObj.preview = false;
        clearInterval(this.interval);
        // this.createInteractiveVideoObj.currentPlayingItem = {};
    }

    saveDraft() {
        this.toastr.success('Your video is successfully saved in draft.');
        this.router.navigate(['my-videos'], { queryParams: { currentTab: 'draft' } });
    }

    publishPreview() {
        this.getUserInfoWithHubSpot();
        
        if(this.createInteractiveVideoObj.mainVideo?.hubSpotLifeCycle){
            if(this.userMeData?.hubSpot?.integrationCompletedTS){
                this.getCrmLifeCycleStages();
            }
        }

        // First time this video is being published
        if (!this.createInteractiveVideoObj.mainVideo.isPublished) {
            // WC 03072023 -- Check if the user exceeds the allowable max no of published video as per their subsctiption package
            this.urlService.getUserSubscriptionPlan().subscribe(success => {

                this.currSubscriptionId = success.data._id;
                // console.log('this.currSubscriptionId = ', success.data);

                const maxNoOfAllowedPublishedVideos = success.data.subscriptionPlanInfo.maxNoOfVideos;
                // console.log("maxNoOfAllowedPublishedVideos = ", maxNoOfAllowedPublishedVideos);

                this.urlService.getAllMainVideosCountV2().subscribe(success => {

                    const videoCounts = success.data;

                    const getCountOfPublishedVideos = () => {
                        let countOfPublishedVideos = 0;
                        videoCounts.forEach(item => {
                            if (item._id.isPublished != undefined && item._id.isPublished)
                                countOfPublishedVideos = item.count;

                            // console.log("countOfPublishedVideos = ", countOfPublishedVideos);
                        });

                        return countOfPublishedVideos;
                    };

                    if (getCountOfPublishedVideos() < maxNoOfAllowedPublishedVideos) {
                        this.publishModal();
                    } else {

                        this.pricingUrl = '/pricing?subId=' + this.currSubscriptionId;

                        $('#exceed-max-videos-Modal').modal('show');
                    }
                })
            })
        } else {
            this.publishModal();
        }
    }

    publishModal() {
        const checkAllUrl = this.createInteractiveVideoObj.finalObj.filter(x => !!!x.URL);
        if (checkAllUrl.length) {
            this.toastr.error('Unable to publish until all nodes have videos');
            return;
        }
        clearInterval(this.interval);
        this.toastr.info('Preparing Video!');
        this.createInteractiveVideoObj.spinner = true;

        if (this.createInteractiveVideoObj.mainVideo?.viewCompletionBadge) {
            this.assignPublishBadge = this.createInteractiveVideoObj.mainVideo?.viewCompletionBadgeInfo[0];
        }
        this.urlService.fetchCategories().subscribe((sucess) => {
            this.availableCategory = sucess.data.categories;
            this.availableCategory.sort();
            this.closePreview();
            this.createInteractiveVideoObj.spinner = false;
            this.createInteractiveVideoObj.publish = true;
            $('#tree-publish-vid-Modal').modal(
                { backdrop: 'static', keyboard: false },
                'show'
            );
            this.filledLogin('main_title');
            this.filledLogin('user_Description');
        });
    }

    loadNextOption(e) {
        clearInterval(this.interval);
        this.createInteractiveVideoObj.currentPlayingItem.currentTime = 0;
        this.createInteractiveVideoObj.preview = false;
        // this.toastr.info('Choice Selected');
        setTimeout(() => {
            this.createInteractiveVideoObj.currentPlayingItem = e;
            this.createInteractiveVideoObj.preview = true;
        }, 100);
        // this.createInteractiveVideoObj.currentPlayingItem = e;
    }

    /** translate subtitles with multiple languages */
    private translateVideo() {
        const obj = {
            videoId: this.createInteractiveVideoObj.mainVideo._id,
            targetLanguages: this.targetLanguages
        }
        if (obj.targetLanguages.length) {
            this.urlService.translateInteractiveVideo(obj).subscribe((response) => {
                if (!response.error && response.data.status) {
                    this.toastr.success(response.data.status)
                }
            });
        }
    }

    onSubmit() {
        // WC 03072023 -- To check if the no of published video exceed the allowable max of videos per the subscription plan

        if (this.allowTranslate) {
            this.translateVideo();
        }
        let obj;
        if (!!this.item) {
            this.createInteractiveVideoObj.mainVideo.poster = this.item;
        }
        obj = {
            id: this.createInteractiveVideoObj.mainVideo._id,
            type: this.createInteractiveVideoObj.mainVideo.type,
            name: this.createInteractiveVideoObj.mainVideo.name,
            URL: this.createInteractiveVideoObj.mainVideo.URL,
            Title: this.createInteractiveVideoObj.mainVideo.Title,
            Description: this.createInteractiveVideoObj.mainVideo.Description,
            // WC 07272022 - Add tagging
            Tag: this.createInteractiveVideoObj.mainVideo.Tag,
            // WC 07272022
            Categories: this.createInteractiveVideoObj.mainVideo.Categories,
            time: this.createInteractiveVideoObj.mainVideo.time,
            parentId: this.createInteractiveVideoObj.mainVideo.parentId,
            poster: this.createInteractiveVideoObj.mainVideo.poster,
            videoID: this.createInteractiveVideoObj.mainVideo.videoID,
            isPrivate: this.isPrivate,
            // WC 08092022 - Add subtitleUrl
            subtitleUrl: this.createInteractiveVideoObj.mainVideo.subtitleUrl,
            hubSpotLifeCycle: this.createInteractiveVideoObj.mainVideo?.hubSpotLifeCycle,
            // WC 08092022
        };
        
        if(this.createInteractiveVideoObj.mainVideo?.userPrompt){
            obj.userPrompt = this.createInteractiveVideoObj.mainVideo?.userPrompt ? this.createInteractiveVideoObj.mainVideo?.userPrompt : "Make your next choice"
        }

        if (this.assignPublishBadge) {
            obj.viewCompletionBadge = this.assignPublishBadge._id
        }

        if (this.groupList.length > 0 || this.followerListForPublic.length > 0) {
            obj.shareTo = {
                groupCount: this.groupLength,
                followerCount: this.followersLength,
                shareWithFollower: this.followerListForPublic,
                shareInGroup: this.groupList,
            };
        }
        const mainTitle = this.createInteractiveVideoObj.mainVideo.Title;
        this.createInteractiveVideoObj.spinner = true;
        this.urlService.updateInteractiveVideo(obj).subscribe((success) => {
            if (!this.createInteractiveVideoObj.mainVideo.isPublished) {
                const finalObj = {
                    type: 'publish',
                    videoID: this.createInteractiveVideoObj.mainVideo._id,
                };
                const totalCategories =
                    this.createInteractiveVideoObj.mainVideo.Categories.length;
                if (totalCategories < 1) {
                    this.createInteractiveVideoObj.spinner = false;
                    this.toastr.warning('Select at least one category');
                } else if (mainTitle === '') {
                    this.createInteractiveVideoObj.spinner = false;
                    this.toastr.error('Title can not be blank');
                } else {
                    this.urlService.publishunpublish(finalObj).subscribe((success) => {
                        this.createInteractiveVideoObj.spinner = false;
                        this.toastr.success('Published successfully!');

                        $('#tree-publish-vid-Modal').modal('hide');
                        this.cancelPublish();
                        // this.localStorageService.setItem('tempToken', '848484');
                        this.localStorageService.setItem('latestPublishedVideoId', this.createInteractiveVideoObj.mainVideo._id);
                        this.router.navigate(['my-videos']);
                    });
                }
            } else {
                $('#tree-publish-vid-Modal').modal('hide');
                this.cancelPublish();
                // this.localStorageService.setItem('tempToken', '848484');
                this.localStorageService.setItem('latestPublishedVideoId', this.createInteractiveVideoObj.mainVideo._id);
                this.router.navigate(['my-videos']);
                this.createInteractiveVideoObj.spinner = false;
            }
        });
    }

    addVideoTitle(isAI: boolean = false) {
        const obj: any = {
            id: this.createInteractiveVideoObj.mainVideo._id,
            type: this.createInteractiveVideoObj.mainVideo.type,
            name: this.createInteractiveVideoObj.mainVideo.name,
            URL: this.createInteractiveVideoObj.mainVideo.URL,
            Title: this.createInteractiveVideoObj.mainVideo.Title,
            Description: this.createInteractiveVideoObj.mainVideo.Description,
            // WC 07272022 - Add tagging
            Tag: this.createInteractiveVideoObj.mainVideo.Tag,
            // WC 07282022
            Categories: this.createInteractiveVideoObj.mainVideo.Categories,
            time: this.createInteractiveVideoObj.mainVideo.time,
            parentId: this.createInteractiveVideoObj.mainVideo.parentId,
            poster: this.createInteractiveVideoObj.mainVideo.poster,
            videoID: this.createInteractiveVideoObj.mainVideo.videoID,
        };
        
        if(this.createInteractiveVideoObj.mainVideo?.userPrompt){
            obj.userPrompt = this.createInteractiveVideoObj.mainVideo?.userPrompt ? this.createInteractiveVideoObj.mainVideo?.userPrompt : "Make your next choice"
        }
        this.urlService.updateInteractiveVideo(obj).subscribe((success) => {
            if (isAI) {
                this.assistEventsSubject.next({
                    type: this.msgEvent.saveStoryBoardName,
                    data: this.createInteractiveVideoObj.mainVideo.Title
                })
            }
        });
    }

    cancelPublish() {
        $('#tree-publish-vid-Modal').modal('hide');
        this.createInteractiveVideoObj.publish = false;
    }

    getCurrentTimePublish(e) {
        let hasChilren: any = false;
        if (
            this.createInteractiveVideoObj.mainVideo.children == null ||
            this.createInteractiveVideoObj.mainVideo.children == undefined
        ) {
            hasChilren = false;
        } else {
            hasChilren = this.createInteractiveVideoObj.mainVideo.children.length > 0;
        }

        //// console.log(hasChilren,this.createInteractiveVideoObj.mainVideo,'publishVideo',e.target.duration)
        //  this.createInteractiveVideoObj.mainVideo.currentTime = e.target.currentTime
        this.placeMarker(
            hasChilren,
            this.createInteractiveVideoObj.mainVideo.time,
            'publishVideo',
            e.target.duration
        );
    }

    //  publishProfileForm

    placeMarker(haveChildren, markerTime, id, duration) {
        const that = this;

        const resetDom = document.createElement('button')
        resetDom.innerHTML = '<svg class="bi bi-arrow-clockwise" width="1.5em" height="1.5em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">' +
            '<path fill-rule="evenodd" d="M3.17 6.706a5 5 0 017.103-3.16.5.5 0 10.454-.892A6 6 0 1013.455 5.5a.5.5 0 00-.91.417 5 5 0 11-9.375.789z" clip-rule="evenodd"/>' +
            ' <path fill-rule="evenodd" d="M8.147.146a.5.5 0 01.707 0l2.5 2.5a.5.5 0 010 .708l-2.5 2.5a.5.5 0 11-.707-.708L10.293 3 8.147.854a.5.5 0 010-.708z" clip-rule="evenodd"/>' +
            '</svg>';
        resetDom.setAttribute('class', 'vjs-control vjs-button');
        resetDom.setAttribute('title', 'Start Over');
        resetDom.setAttribute('ids', 'Start Over');
        $('#' + id + ' .vjs-control-bar').append(resetDom)
        resetDom.addEventListener('click', () => {
            that.createInteractiveVideoObj.preview = false;
            setTimeout(() => {
                that.createInteractiveVideoObj.currentPlayingItem =
                    that.createInteractiveVideoObj.mainVideo;
                if (id !== 'video-all') {
                    that.createInteractiveVideoObj.preview = true;
                }
                clearInterval(that.interval);
            }, 100);
        });

        if (!haveChildren) {
            $('.marker').remove();
        } else {
            const width = $('#' + id).width();
            const precentageOfMarker = (markerTime / duration) * 100;
            $('.marker').remove();
            $(
                '#' + id + ' .vjs-progress-holder.vjs-slider.vjs-slider-horizontal'
            ).append(
                '<div class="marker" style="left:' + precentageOfMarker + '%"></div>'
            );

            // $('#'+id).parent().append();
        }
    }

    changeUserLogin(id) {
        if ($('#' + id).val() != '') {
            $('#' + id)
                .siblings('.input-field')
                .addClass('video_input_focus');
            $('#' + id).addClass('focusIn');
        } else {
            $('#' + id)
                .siblings('.input-field')
                .removeClass('video_input_focus');
        }
    }

    filledLogin(id) {
        $('#' + id)
            .siblings('.input-field')
            .addClass('video_input_focus');
        $('#' + id).addClass('focusIn');
    }

    async preview(files) {
        if (files.length === 0) return;

        const mimeType = files[0].type;
        if (mimeType.match(/image\/*/) == null) {
            this.toastr.error('Only images are supported!');
            return;
        }

        const reader = new FileReader();
        // this.createInteractiveVideoObj.mainVideo.imagePath = files;
        const cleanImage = await sanitizeSVG(files[0])
        if (!cleanImage) {
            this.toastr.error('invalid SVG file');
            return;
        }
        this.createInteractiveVideoObj.mainVideo.fileData = cleanImage;
        reader.readAsDataURL(files[0]);
        reader.onload = (_event) => {
            // this.createInteractiveVideoObj.mainVideo.profilePic = reader.result;
            // this.createInteractiveVideoObj.mainVideo.thumbnail.push(reader.result)
        };
        this.updateProfilePic();
    }

    updateProfilePic() {
        this.createInteractiveVideoObj.spinner = true;
        const formData = new FormData();
        formData.append('file', this.createInteractiveVideoObj.mainVideo.fileData);
        formData.append('VideoID', this.createInteractiveVideoObj.mainVideo._id);

        this.urlService.uploadThumbnails(formData).subscribe((success) => {
            this.toastr.success('Image updated successfully');
            // this.createInteractiveVideoObj.mainVideo.poster = success.data;
            this.createInteractiveVideoObj.mainVideo.thumbnail.push(success.data.url);
            // console.log('success.data = ', success.data);
            // console.log('this.createInteractiveVideoObj.mainVideo.thumbnail = ', this.createInteractiveVideoObj.mainVideo.thumbnail);

            this.createInteractiveVideoObj.spinner = false;
        });
    }

    openContributorModal() {
        this.findorselect = '';
        // WC 04062022
        this.collabPage = 1;
        this.followers = [];
        this.getFollowerContributors();
        // WC 04062022
        this.isShareOpen = true;
        $('#contributor-Modal').modal('show');
    }

    closeContributorModal() {
        $('#contributor-Modal').modal('hide');
        this.isShareOpen = false;
    }

    deleteThumbnails(i: any) {
        const obj = {
            url: i,
            videoId: this.mainID,
        };
        this.createInteractiveVideoObj.spinner = true;
        this.urlService.deleteThumbnails(obj).subscribe((success) => {
            this.createInteractiveVideoObj.spinner = false;
            for (
                let j = this.createInteractiveVideoObj.mainVideo.thumbnail.length - 1;
                j > -1;
                j--
            ) {
                if (i == this.createInteractiveVideoObj.mainVideo.thumbnail[j]) {
                    this.createInteractiveVideoObj.mainVideo.thumbnail.splice(j, 1);
                    break;
                }
            }
        });
    }

    getFollowingId(e: any, isChecked) {
        if (isChecked == false) {
            for (let i = this.contrubutors.length - 1; i >= 0; i--) {
                if (e == this.contrubutors[i]) {
                    this.contrubutors.splice(i, 1);
                }
            }
        } else {
            this.contrubutors.push(e);
        }
        // //console.log(this.contrubutors)
    }

    // Set contributor
    setContributor() {
        this.createInteractiveVideoObj.spinner = true;
        const obj = {
            videoID: this.mainID,
            contributorID: this.contrubutors[0],
        };
        this.urlService.setContributor(obj).subscribe(
            (success) => {
                this.contrubutors.shift();
                if (this.contrubutors.length == 0) {
                    this.createInteractiveVideoObj.spinner = false;
                    this.toastr.success('Contributor added');
                    $('#contributor-Modal').modal('hide');
                    this.isShareOpen = false;
                    for (const i of this.followers) {
                        i.setFollowerCheck = false;
                    }
                    for (const i of this.following) {
                        i.setFollowingCheck = false;
                    }
                } else {
                    this.setContributor();
                }
            },
            (error) => {
                this.toastr.warning('Already contributor for this video');
                this.contrubutors.shift();
                if (this.contrubutors.length == 0) {
                    this.createInteractiveVideoObj.spinner = false;
                    $('#contributor-Modal').modal('hide');
                    this.isShareOpen = false;
                } else {
                    this.setContributor();
                }
            }
        );
    }

    // WC 04062022 - To get the list of contributors for this video
    getContributors() {
        this.urlService.getContributors().subscribe((data) => {
            this.contributors = data;
        });
    }

    // WC 04062022
    getMainVideoInfo() {
        console.log("this.mainID = ", this.mainID);

        this.urlService.getVideoInfov2(this.mainID).subscribe((res) => {

            // WC 08182023 - To redirect user to their profile page if they are not the owner of the video.
            // Only the owner of the video can edit it
            // console.log("Checking Video Ownership - res.data.videoinfo.user = ", res.data.videoinfo.user);

            // The current logged in user is not the owner of the video
            if (this.localStorageService.getItem('user') != res.data.videoinfo.user) {

                // Check if the current logged in user is the contributor to collab on this video
                this.urlService.isContributor(this.mainID).subscribe((res) => {

                    //console.log('isContributor = ', res);

                    if (!res.data.isContributor) {
                        this.toastr.error('You don\'t have the ownership of the video');
                        this.router.navigate(['/profile']);
                    }
                })
            };

            this.createInteractiveVideoObj.currentObj = res.data.videoinfo;
            if (this.createInteractiveVideoObj.currentObj.subtitleTranslations) {
                this.createInteractiveVideoObj.currentObj.subtitleTranslations = this.createInteractiveVideoObj.currentObj.subtitleTranslations.map(x => {
                    x.subtitle = languages.find(c => c.code === Object.keys(x)[0]);
                    return x;
                });
                if (this.createInteractiveVideoObj.currentObj.subtitleUrl) {
                    const defaultLang = this.getDefaultLangTranscription(this.createInteractiveVideoObj.currentObj)
                    this.createInteractiveVideoObj.currentObj.subtitleTranslations.unshift(defaultLang);
                }
            }

            let isCheckUrl = false;
            if (this.createInteractiveVideoObj.currentObj.URL) {
                isCheckUrl = this.utilityService.checkIsVideoUrl(this.createInteractiveVideoObj.currentObj.URL);
            }
            this.createInteractiveVideoObj.currentObj.isUrl = !isCheckUrl;
            this.createInteractiveVideoObj.currentObj.transcriptLang = this.utilityService.getTranscriptLang(this.createInteractiveVideoObj.currentObj.subtitleUrl);
            this.createInteractiveVideoObj.mainVideo = JSON.parse(JSON.stringify(this.createInteractiveVideoObj.currentObj));
            this.alreadyTargetedLanguages = this.createInteractiveVideoObj.mainVideo?.subtitleTranslations || [];
            this.alreadyTargetedLanguages = this.alreadyTargetedLanguages.map((x) => {
                return Object.keys(x)[0];
            });
            this.languages = this.languages.map(x => {
                x.disabled = this.alreadyTargetedLanguages.includes(x.code)
                return x;
            });
            this.item = res.data.videoinfo.poster;
            this.createInteractiveVideoObj.currentPlayingItem = this.createInteractiveVideoObj.currentObj;
            this.isPrivate = res.data.videoinfo.isRestricted;

            // console.log("this.createInteractiveVideoObj = ", this.createInteractiveVideoObj);
            this.callChild('');
            if (this.isAssistCreateVideo) {
                this.assistEventsSubject.next({
                    // type: 'this.msgEvent.editFirstNodeName',
                    type: this.msgEvent.editFirstNodeName,
                    data: this.createInteractiveVideoObj.mainVideo
                });
            }
            if (this.createInteractiveVideoObj.mainVideo.Tag === 'storyboard') {
                this.buttonType = 'storyBoard';
            }
        }, (err) => {
            this.router.navigateByUrl('/my-videos');
        });
    }

    getVideoPathWays() {
        this.urlService.getPathway({ videoId: this.mainID })
            .subscribe((res) => {
                this.createInteractiveVideoObj.pathways = res.data;
                //console.log("=====> ", this.createInteractiveVideoObj.pathways)
                for (const watchPath of this.createInteractiveVideoObj.pathways) {
                    watchPath.startNode = this.createInteractiveVideoObj.finalObj.find(x => x._id === watchPath.startNodeId);
                    watchPath.endNode = this.createInteractiveVideoObj.finalObj.find(x => x._id === watchPath.endNodeId);
                }
                // for (const watchPath of this.createInteractiveVideoObj.pathways) {
                //     watchPath.videos = this.utilityService.parentNode(this.createInteractiveVideoObj.finalObjTree, watchPath.endNodeId) || [];
                //     watchPath.startNode = watchPath.videos.find(x => x._id === watchPath.startNodeId);
                //     watchPath.endNode = watchPath.videos.find(x => x._id === watchPath.endNodeId);
                //     if (watchPath.videos) {
                //         const filterVideo = [];
                //         for (const video of watchPath.videos) {
                //             filterVideo.push(video);
                //             if (watchPath.startNodeId === video._id) {
                //                 break;
                //             }
                //         }
                //         watchPath.videos = filterVideo.reverse();
                //     }
                // }
                // console.log(this.createInteractiveVideoObj.pathways)
                setTimeout(() => {
                    this.setHorizontalScrollbarPosition();
                }, 100);
            });
    }

    setHorizontalScrollbarPosition() {
        const footer = document.getElementById('vid-publish-footer');
        const pathwayWrapper = document.getElementById('container-fluid');
        const treeView = document.getElementById('tree-view-chart-sec');
        let footerHeight = 263;
        if(footer) {
            footerHeight = this.createInteractiveVideoObj.pathways.length ? footer.offsetHeight + 176 : footer.offsetHeight + 161;
        }


        (treeView as any).style.height = `calc(100% - ${footerHeight + 'px'})`;

        if(pathwayWrapper?.offsetHeight > 60) {
            (treeView as any).style.height = `calc(100% - ${footerHeight + (pathwayWrapper.offsetHeight - 60) + 'px'})`;

        }
    }

    formValidation() {
        const group: any = {};
        for (const field of this.lstForm) {

            if (field.fieldName.toLowerCase().indexOf('email') > -1) {

                group[field.fieldName] = new FormControl(field.fieldValue || '', [
                    Validators.required,
                    Validators.pattern('[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[A-Za-z]{2,6}$'),
                ]);
            }
            ;

            this.formEmail = new FormGroup(group);
        }
    }

    getEmailsforVideoForm() {

        this.urlService.getUserSubscriptionPlan().subscribe(success => {
            this.planInfo = success.data.subscriptionPlanInfo;

            let noOfEmailsPerVideo = 0;
            if (this.planInfo?.noOfEmailsPerVideo)
                noOfEmailsPerVideo = this.planInfo?.noOfEmailsPerVideo;

            this.lstForm = [];

            for (let i = 0; i < noOfEmailsPerVideo; i++) {
                const formField = {
                    fieldName: 'email' + i,
                    fieldType: 'email',
                    fieldValue: '',
                } as IFormField;
                this.lstForm.push(formField);
            }
            // console.log("getSubscriptionPlanInfo - this.lstForm = ", this.lstForm);

            // after set form comtrols //set form control validation
            this.formValidation();

            /* check gold plan for subtitle translate*/
            // if (this.planInfo._id === '627e75fcf76daf5009dc2aef' || this.planInfo._id === '629e78dd467f4115b1f7dbfb') {
            // console.log('this.planInfo.name = ', this.planInfo.name);

            this.allowTranslate = !this.planInfo.name.includes('Bronze');
            /* check plan for subtitle translate*/

            this.spinner = false;
        })
    }

    openEmailsForVideoModal() {
        // console.log("in openEmailsForVideoModal")

        this.urlService.userDetailsV2().subscribe(success => {
            const user = success.data;
            
            this.maxNoOfCreatedVideos = user.totalmainvideos;
            // console.log("In openEmailsForVideoModal - this.maxNoOfCreatedVideos = ", this.maxNoOfCreatedVideos);

            // let currNoOfRetries;
            if (this.planInfo.emailsForVideos) {
                if (user.details.currentNoOfTries == undefined) {
                    this.currNoOfRetries = 1;
                    // console.log('In openEmailsForVideoModal - this.currNoOfRetries = ', this.currNoOfRetries);
                } else {
                    this.currNoOfRetries = user.details.currentNoOfTries;
                    // console.log('In openEmailsForVideoModal - currNoOfRetries = ', this.currNoOfRetries);
                }
            }
            // console.log("In openEmailsForVideoModal - this.planInfo.emailsForVideos = ", this.planInfo.emailsForVideos);

            if (this.planInfo.emailsForVideos) {
                if (this.currNoOfRetries < this.planInfo.noOfRetries)
                    $('#add-emails-Modal').modal('show');
                else
                    $('#exceed-max-videos-Modal').modal('show');
            }
        });
    };

    updateEmails() {
        // console.log('updateEmails - this.lstForm = ', this.lstForm);

        const emails = [];

        for (const field in this.formEmail.controls) {
            emails.push(this.formEmail.controls[field].value);
        }

        // console.log('emails = ', emails);

        let isDup = false;

        // check for dups inside the emails array
        for (let i = 0; i < emails.length; i++) {

            let noOfOccurence = 0
            for (let j = 0; j < emails.length; j++) {
                if (emails[i].toLowerCase() == emails[j].toLowerCase()) {
                    noOfOccurence++;
                }
            }

            // Found dup
            if (noOfOccurence > 1) {
                isDup = true;
                // this.toastr.error("Duplicate email " + emails[i]);
            }
        }

        if (isDup) {
            this.toastr.error('Oops! Looks like you entered duplicate email addresses. Please invite three separate friends to publish your free video tree.');

            return;
        }

        const payload = {
            emails
        }

        // setEmailsForVideos response
        // {
        //   "email": "freefusedevops@gmail.com",
        //   "duplicate": true,
        //   "persisted": false,
        //   "emailSent": false
        // }
        // Check dups with the backend data
        this.urlService.setEmailsForVideos(payload).subscribe(success => {
            // console.log('success = ', success);

            const emailsForVideos = success.data;

            let noOfDups = 0;
            for (let i = 0; i < emailsForVideos.length; i++) {
                // console.log('emailsForVideos[' + i + '] = ', emailsForVideos[i]);

                if (emailsForVideos[i].duplicate) {
                    // console.log('emailsForVideos[' + i + '].duplicate = ', emailsForVideos[i].duplicate);

                    // this.toastr.error("Duplicate email " + emailsForVideos[i].email);
                    noOfDups++;
                }

                // console.log('i = ' + i + ', emailsForVideos.length - 1 = ' + (emailsForVideos.length - 1));
                if (i == (emailsForVideos.length - 1)) {

                    // console.log('noOfDups = ', noOfDups);
                    if (noOfDups == 0) {

                        const payload = {
                            userId: this.localStorageService.getItem('user')
                        };

                        // console.log('about to updateUserEmailsForVideos');
                        const user = this.urlService.updateUserEmailsForVideos(payload).subscribe(success => {

                            this.toastr.info('Thank you for sharing our platform with your friends! You are helping us spread the good word about FreeFuse and for that we are grateful. Enjoy your free video tree.')

                            if (success.data) {
                                $('#add-emails-Modal').modal('hide');
                                this.publishModal();
                            }
                        });
                    } else {
                        this.toastr.error('Oops! Looks like you entered duplicate email addresses. Please invite three separate friends to publish your free video tree.')
                    }
                }
            }
        });
    };

    drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.createChoiceObj.child, event.previousIndex, event.currentIndex);
        // console.log(this.createChoiceObj)
    }

    // WC 11152022

    closePopover(popEle3: PopoverDirective, popEle32: string) {
        popEle3.hide();
        this.dashboardService.setHelpPopOver(popEle32);
    }

    goBack() {
        // if ((this.location.getState() as any)?.navigationId > 1)
        //     this.location.back();
        // else
        this.router.navigateByUrl('/my-videos');
    }

    confirmDelete(id: any) {
        // this.deleteWaitingId = id;
        // console.log(this.deleteWaitingId)
        this.deleteDialogRef.open({
            header: 'Delete Video',
            title: 'Are you sure you want to delete this video?'
        }, (e) => {
            if (e) {
                this.spinner = true;
                this.urlService.deleteBookMarkVideo(id).subscribe((success) => {
                    // this.getMainVideos();
                    this.dashboardService.onGetUserData();
                    // this.createInteractiveVideoObj.spinner = false;
                    this.router.navigateByUrl('/my-videos');
                    this.toastr.success('Deleted successfully!');
                }, (err) => {
                    this.spinner = false;
                });
            }
        });
    }

    onAssistEvent(e) {
        // console.log('main', e)
        if (e.type === this.msgEvent.addFirstNode) {
            if (e.data.name === 'Story Board') {
                this.createEmptyNodes();
            } else {
                this.openVideoCreateModel('freeboard');
            }
        } else if (e.type === this.msgEvent.editFirstNodeName) {
            // this.clickSelectVideo();
            this.createInteractiveVideoObj.currentObj = e.data;
            this.updateCurrentName(e.data.name);
        } else if (e.type === this.msgEvent.close) {
            this.isAssistCreateVideo = null;
            this.initialFunction('');
            this.localStorageService.removeItem('assistCreateVideo');
        } else if (e.type === this.msgEvent.addMoreChoices) {
            this.createChoiceObj.child = [];
            if (e.data.name === 'Story Board') {
                for (let i = 0; i < e.data.choices; i++) {
                    const obj: any = {
                        name: '',
                        URL: '',
                        _id: '',
                        parentId: this.createInteractiveVideoObj.currentObj._id,
                    };
                    this.createChoiceObj.child.push(obj);
                }
                this.saveStoryBoardChildNodes(e.data?.currentObj);
            } else {
                this.addMoreChoices();
            }
        } else if (e.type === this.msgEvent.addChildNode) {
            this.createInteractiveVideoObj.currentObj = e.data.currentObj;
            this.openVideoListModal(e.data.index);
        } else if (e.type === this.msgEvent.addTime) {
            this.createInteractiveVideoObj.currentObj = e.data.currentObj;
            this.updateCurrentTime(e.data.duration);
        } else if (e.type === this.msgEvent.createPathways) {
            setTimeout(() => {
                this.openAddWatchPath();
            }, 1000);
        } else if (e.type === this.msgEvent.publish) {
            setTimeout(() => {
                this.publishPreview();
            }, 1000);
        } else if (e.type === this.msgEvent.addVideoForEmptyNode) {
            if(e.data?.currentObj && !e.data?.isAIContentTree) {
                this.currentVideoData = e.data?.currentObj;
                this.isChatWindowVisible = true;
                this.openPreviewUrl(e.data?.currentObj?.URL, true);
            } else {
                this.currentVideoData = e.data?.currentObj;
                this.createInteractiveVideoObj.spinner = true;
                this.urlService.callPexelAPI(this.currentVideoData.name).subscribe((res) => {
                    const objFinal:any = {
                        id: this.currentVideoData._id,
                        type: this.currentVideoData.type,
                        name: this.currentVideoData.name ? this.currentVideoData.name : '',
                        URL: res.data.videos[0].video_files[0].link,
                        poster: res.data.videos[0]?.image ? res.data.videos[0].image : this.currentVideoData.poster,
                        Title: this.currentVideoData.Title,
                        Description: this.currentVideoData.Description,
                        time: this.currentVideoData.time,
                        parentId: this.currentVideoData.parentId,
                        videoID: this.currentVideoData.videoID || this.currentVideoData._id,
                        subtitleUrl: '',
                    };
                    
                    if(this.currentVideoData?.userPrompt){
                        objFinal.userPrompt = this.currentVideoData?.userPrompt ? this.currentVideoData?.userPrompt : "Make your next choice"
                    }
                    this.urlService.updateInteractiveVideo(objFinal).subscribe(
                        (success) => {
                            this.createInteractiveVideoObj.spinner = false;
                            this.createInteractiveVideoObj.currentNodeOption = null;
                            this.latestNodeId = this.createInteractiveVideoObj.currentObj._id;
    
                            this.callChild1();
                            this.toastr.info('Updated Successfully!');
                            this.assistEventsSubject.next({ type: this.msgEvent.close, data: '' });
                        },
                        (error) => {
                            this.createInteractiveVideoObj.spinner = false;
                        }
                    );
                })

            }
        } else if (e.type === this.msgEvent.saveStoryBoardName) {
            this.createInteractiveVideoObj.mainVideo.Title = e?.data.title;
            this.addVideoTitle(true);
        }
    }


    toggleEndingNode() {
        if (this.createInteractiveVideoObj.currentObj?.connection &&
            this.createInteractiveVideoObj.currentObj?.isEndingNode) {
            const dialogRef = this.dialog.open(this.unlinkEndNodeDialog, {
                width: '500px',
                maxWidth: '90%',
                panelClass: 'my-dialog',
                height: 'auto'
            });
            dialogRef.afterClosed().subscribe((result) => {
                if (result) {
                    this.saveEndingNode();
                } else {
                    this.createInteractiveVideoObj.currentObj.isEndingNode = !this.createInteractiveVideoObj.currentObj?.isEndingNode;
                }
            });
        } else {
            this.saveEndingNode();
        }
    }

    toggleConnectorNode() {
        if (this.createInteractiveVideoObj.currentObj?.connection &&
            this.createInteractiveVideoObj.currentObj?.isConnectorNode) {
            const dialogRef = this.dialog.open(this.unlinkEndNodeDialog, {
                width: '500px',
                maxWidth: '90%',
                panelClass: 'my-dialog',
                height: 'auto'
            });
            dialogRef.afterClosed().subscribe((result) => {
                if (result) {
                    this.saveConnectorNode();
                } else {
                    this.createInteractiveVideoObj.currentObj.isConnectorNode = !this.createInteractiveVideoObj.currentObj?.isConnectorNode;
                }
            });
        } else {
            this.saveConnectorNode();
        }
    }

    saveConnectorNode() {
        //console.log("saveConnectorNode - this.createInteractiveVideoObj.currentObj = ", this.createInteractiveVideoObj.currentObj);

        this.createInteractiveVideoObj.spinner = true;
        setTimeout(() => {
            this.urlService.setConnectorNode({
                videoId: this.createInteractiveVideoObj.currentObj._id,
                isConnectorNode: this.createInteractiveVideoObj.currentObj.isConnectorNode,
            }).subscribe((res) => {
                this.callChild1(res?.data);
                this.toastr.success(res.message);
            }).add(() => this.createInteractiveVideoObj.spinner = false);
        });
    }

    saveEndingNode() {
        this.createInteractiveVideoObj.spinner = true;
        setTimeout(() => {
            if (this.createInteractiveVideoObj.currentObj.isConnectorNode === true) {
                this.createInteractiveVideoObj.currentObj.isConnectorNode = !this.createInteractiveVideoObj.currentObj.isConnectorNode;
                this.saveConnectorNode();
            }

            this.urlService.setEndingNode({
                videoId: this.createInteractiveVideoObj.currentObj._id,
                isEndingNode: this.createInteractiveVideoObj.currentObj.isEndingNode,
            }).subscribe((res) => {
                this.callChild1();
                this.toastr.success(res.message);
            }).add(() => this.createInteractiveVideoObj.spinner = false);
        });
    }

    removeBadge() {

        this.urlService.removeMainVideoBadge({ videoId: this.mainID }).subscribe((res) => {
            if (res.data.viewCompletionBadge == undefined) {
                this.toastr.success('Badge was successfully removed');
                this.assignPublishBadge = false;
            }
        })
    }

    showObjectivesPopup(data: any) {
        this.objectivesPopupData = data
    }

    hideObjectivesPopup() {        
        if(!this.isDisplayPopupWithObjective){
            this.objectivesPopupData = null;
        }
    }

    showPopupWithObjective(){
        this.isDisplayPopupWithObjective = true
    }

    hidePopupWithObjective(){
        this.isDisplayPopupWithObjective = false
        this.objectivesPopupData = null;
    }

    getUserInfoWithHubSpot(){
        this.urlService.userDetailsV2().subscribe(success => {
            this.userMeData = success?.data?.details
            if(this.userMeData?.hubSpot?.integrationCompletedTS){
                this.getCrmLifeCycleStages();
            }
        })
    }


    getCrmLifeCycleStages(){
        this.urlService.getCrmLifeCycleStages().subscribe(success => {
            this.lifeCycleStages = success?.data;
            if (!this.createInteractiveVideoObj?.mainVideo?.hubSpotLifeCycle) {
                this.createInteractiveVideoObj.mainVideo.hubSpotLifeCycle = 'None';
            }
            if(!this.hubSpotLifeCycle){
                this.hubSpotLifeCycle = 'None'
            }
        })
    }

    changeHubSpotLifeCycleStage(value:any){
        this.hubSpotLifeCycle = value;
        this.currentMessageStep = 6
        this.ishubSpotLifeCycle = false;
        if (this.inputValues?.length) {
            this.isDisableInput = true
        }
        this.messages.push({author: 'user', content: this.hubSpotLifeCycle, type:'select'});
        $('#messagesCard').animate({ scrollTop: $('#messagesCard .messages').height() }, 500);
        this.cdr.detectChanges();

    }
}
