import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {CrudService} from 'src/app/services/crud.service';
import {ToastrService} from 'ngx-toastr';
import {DashboardService} from '../../services/dashboard.service';
import {LocalStorageService} from '../../services/localStorage.service';
import { environment } from 'src/environments/environment';

declare var $;

@Component({
    selector: 'profileCompletion',
    templateUrl: './profileCompletion.component.html',
    styleUrls: ['./profileCompletion.component.css']
})

export class ProfileCompletionComponent implements OnInit {

    public profileCompleteObj: any = {
        categories: [],
        useCases: [],
        bio: '',
        file: '',
        spinner: '',
        imagePath: '',
        imgURL: '',
        step: 'first',
        checkedList: [],
        selectedUseCase: '',
        fileData: ''
    }
    formErrors = {
        category: '',
        useCase: '',
    }
    groupId:any;

    constructor(
        public localStorageService: LocalStorageService,
        public dashboardService: DashboardService,
        public urlService: CrudService,
        public router: Router,
        public toastr: ToastrService) {
    }

    ngOnInit() {
        
        this.profileCompleteObj.spinner = true;
        this.urlService.fetchCategories().subscribe(
            success => {
                // console.log(success)
                this.profileCompleteObj.spinner = false;
                this.profileCompleteObj.categories = success.data.categories;
                this.profileCompleteObj.useCases = success.data.useCases;
                // console.log("success.data ===> ", success.data);
            },
            error => {
            })
        this.groupId = this.localStorageService.getItem('groupId')
    }


    preview(files) {
        if (files.length === 0)
            return;

        const mimeType = files[0].type;
        if (mimeType.match(/image\/*/) == null) {
            this.toastr.error('Only images are supported!');
            return;
        }

        const reader = new FileReader();
        this.profileCompleteObj.imagePath = files;
        this.profileCompleteObj.fileData = files[0]
        reader.readAsDataURL(files[0]);
        reader.onload = (_event) => {
            this.profileCompleteObj.imgURL = reader.result;
        }
    }


    changeUserLogin(id) {
        if ($('#' + id).val()) {
            $('#' + id).siblings('.input-field').addClass('video_input_focus');
            $('#' + id).addClass('focusIn');
        } else {
            $('#' + id).siblings('.input-field').removeClass('video_input_focus');
        }
    }

    checkChange(e: any, obj: any) {
        if (e.target.checked) {
            this.profileCompleteObj.checkedList.push(obj);
        } else {
            for (let i = 0; i < this.profileCompleteObj.categories.length; i++) {
                if (this.profileCompleteObj.checkedList[i] == obj) {
                    this.profileCompleteObj.checkedList.splice(i, 1);
                }
            }
        }
        this.categoryValidate();
    }

    categoryValidate() {
        const totalCategories = this.profileCompleteObj.checkedList.length;
        if (totalCategories < 1) {
            this.formErrors.category = 'Please select at least one category'
            return true;
        }
        this.formErrors.category = ''
        return false;
    }

    useCaseValidate() {
        if (this.profileCompleteObj.selectedUseCase === '') {
            this.formErrors.useCase = 'Please select what are you using FreeFuse for'
            return true;
        }
        this.formErrors.useCase = ''
        return false;
    }

    onSubmit() {

        // console.log("this.profileCompltetObj.selectedUseCase = ", this.profileCompltetObj.selectedUseCase);


        if (this.categoryValidate()) {
            return;
        }

        if (this.useCaseValidate()) {
            return;
        }

        this.profileCompleteObj.spinner = true;

        const formData = new FormData();
        formData.append('file', this.profileCompleteObj.fileData);
        formData.append('bio', this.profileCompleteObj.bio);
        formData.append('useCase', this.profileCompleteObj.selectedUseCase);
        for (const i of this.profileCompleteObj.checkedList) {
            formData.append('category', i);

        }

        // console.log("campaignId : ", this.localStorageService.getItem("campaignId"));

        // WC 07122022 - Persist Campaign ID along with the rest of user data
        if (window.sessionStorage.getItem('campaignId') != undefined)
            formData.append('campaignId', window.sessionStorage.getItem('campaignId'));
        // WC 07122022

        // WC 08202022 - Persist Org Name along with the rest of user data
        // console.log('org : ', this.localStorageService.getItem('org'));
        if (this.localStorageService.getItem('org') != undefined)
            formData.append('org', this.localStorageService.getItem('org'));
        // WC 08202022

        this.urlService.completeProfile(formData).subscribe(success => {
            this.profileCompleteObj.spinner = false;

            // check if the campaign Id is associated with a plan
            // if (window.sessionStorage.getItem('campaignId') != undefined && window.sessionStorage.getItem('campaignId') != 'Pricing') {
            if (window.sessionStorage.getItem('campaignId') != undefined) {

                const getCid = () => {

                    if (window.sessionStorage.getItem('campaignId').startsWith('EOFF'))
                        return 'EOFF'
                    else
                        return window.sessionStorage.getItem('campaignId');
                };

                this.urlService.getMarketingCampaignPlan(getCid()).subscribe(success => {

                    //console.log('getMarketingCampaignPlan - success = ', success);

                    const planId = success.data[0]?.planId;

                    // There is a plan associated with the campaign Id
                    if (planId != undefined) {

                        const payload = {
                            planId
                        }

                        this.urlService.setUserSubscriptionPlan(payload).subscribe(success => {
                            this.dashboardService.onGetUserData();
                            // console.log('set user subscription plan ' + planId + ' of cid = ' + window.sessionStorage.getItem('campaignId'));

                            this.localStorageService.setItem('planId', planId);
                            this.localStorageService.setItem('isSubscriber', 'true');
                        });
                    }
                });
            } else {
                this.dashboardService.onGetUserData();
            }

            //console.log("In profileCompletion - this.localStorageService.getItem('isPricingRedirect') = ", this.localStorageService.getItem('isPricingRedirect'));
            
            // WC 05232023 --- for user coming the pricing  page, redirect it back to the pricing page            
            if (this.localStorageService.getItem('isPricingRedirect') === 'true'){
                this.router.navigate(['/pricing']);
            }else{   
                if(this.localStorageService.getItem('groupId')){
                    this.existGroupId();
                }else{
                    this.router.navigate(['/profile']);
                    this.makeMemberOfGroup()
                }
            }
        });
    }

    existGroupId(){
        this.urlService.getAllGroups().subscribe(res => {
        const allGroups = res.data;
        
          //Filter for the group which _id is the same as the ssoGroupId passed in the query string param
        const group = allGroups.filter(x => JSON.stringify(x._id) == JSON.stringify(this.groupId));
    
          //If the SAML user is already a member of the group
        if (group.length > 0) {    
                this.router.navigateByUrl(`/my-group/${this.groupId}`);
        } else {
    
              //The SAML user is not a member of the ssoGroup yet
              //Get the sso Group details
            this.urlService.getGroupDetail(this.groupId).subscribe(res => {
                var groupDetails = res.data[0];
                  // console.log("SAML groupDetails = ", groupDetails);
                  // console.log("Checking if SAML user is following group admin");
                this.urlService.isFollowing(groupDetails.admin).subscribe(res => {
                      // console.log("SAML user is following group admin = ", res.data);
    
                    const isFollowingGroupAdmin = res.data;
    
                    if (!isFollowingGroupAdmin) {
    
                        const obj: any = {
                            type: 'follow',
                            followToUserID: groupDetails.admin
                        };
                          //Follow the sso group admin
                        this.urlService.followUser(obj).subscribe(res => {
                            // console.log("SAML user SUCCESSFULLY follow group admin - res = ", res);
                              //Successfully follow the sso group admin
                        });
                    };
                })
    
                  //Add the SAML user to the group as a member
                const newMember = {
                    groupId: groupDetails._id,
                    members:  [{"accessType":"MEMBER","memberId":this.localStorageService.getItem('user')}]
                };
    
                this.urlService.joinGroup(newMember).subscribe(res => {
    
                        //SAML user has been added to the group successfully
                        this.router.navigateByUrl(`/my-group/${this.groupId}`);
    
                });
            });
        };
    });
    }

    makeMemberOfGroup(){

        this.urlService.getGroupDetail(environment.FreeFuse_GroupId).subscribe(res => {
            var groupDetails = res.data[0];

              //Add the SAML user to the group as a member
            const newMember = {
                groupId: groupDetails._id,
                members:  [{"accessType":"MEMBER","memberId":this.localStorageService.getItem('user')}]
            };

            this.urlService.joinGroup(newMember).subscribe();
        });
    }
}
