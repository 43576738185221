import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {CrudService} from '../../services/crud.service';

declare const $: any;

@Component({
    selector: 'app-new-my-videos-public',
    templateUrl: './new-my-videos-public.component.html',
    styleUrls: ['./new-my-videos-public.component.css']
})
export class NewMyVideosPublicComponent implements OnInit {
    list: any = true;
    userDetails: any = {};
    allMainVideos: Array<any> = [];
    val: any = 'recent';
    spinner: any = false;
    chunks: Array<any> = [];
    private mainID: any;


    constructor(public crudService: CrudService,
                public router: Router,
                public activeRoute: ActivatedRoute) {
        this.activeRoute.queryParams.subscribe(params => {
            this.mainID = params.user;
        });
    }

    ngOnInit() {
        if (!(this.mainID)) {
            this.router.navigate(['profile']);
        }

        this.spinner = true;

        this.getUserDetails();
        this.getRecentVideos();
       
    }

    // Get userdetails
    getUserDetails() {
        this.crudService.getUserInfoV2(this.mainID).subscribe(success => {
            this.userDetails = success.data.userInfo;
            //console.log("this.userDetails = ", this.userDetails);

        });
    }

    getRecentVideos() {

        this.crudService.getInteractiveVideoV2(this.mainID, true, 10, 1, "recent").subscribe(
            success => {
                this.allMainVideos = success.data;
                //console.log("this.allMainVideos = ", this.allMainVideos);
                this.spinner = false;
            });
    }

    showCategory(id: any) {
        this.router.navigate(['category'], {queryParams: {category: id}});
    }

    // Show video
    showVideo(id: any) {
        this.router.navigate(['videos'], {queryParams: {video: id}});
    }

    userProfile() {
        this.router.navigate(['public-profile'], {queryParams: {user: this.mainID}});
    }

    changeFilter(val: any) {
        this.list = true;
        this.val = val;
        $('.video-container').scrollTop(0);
        if (val === 'recent') {
            this.allMainVideos = this.allMainVideos.sort((a, b) => {
                if (a.publishTime > b.publishTime) {
                    return -1;
                }
                return 1
            })
        } else if (val === 'viewed') {
            this.allMainVideos = this.allMainVideos.sort((a, b) => {
                if (a.viewCount > b.viewCount) {
                    return -1;
                }
                return 1
            })
        } else if (val === 'interactive') {
            this.allMainVideos = this.allMainVideos.sort((a, b) => {
                if (a.totalchild > b.totalchild) {
                    return -1;
                }
                return 1
            })

        } else {
            this.allMainVideos = this.allMainVideos.sort((a, b) => {
                if (a.comments.length > b.comments.length) {
                    return -1;
                }
                return 1
            })
        }

    }
}
