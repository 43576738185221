import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {BehaviorSubject, Subject} from 'rxjs';
import {CrudService} from './crud.service';
import {LocalStorageService} from './localStorage.service';

@Injectable({providedIn: 'root'})
export class DashboardService {

  isShowColumnArea = false;
  isMobileMenuShow = false;
  userData: any;

  private _searchTerm = new Subject<string>();
  private _isMiniSidebar = new BehaviorSubject<boolean>(false);
  private _showColumnArea = new BehaviorSubject<boolean>(this.isShowColumnArea);
  private _showMobileMenu = new BehaviorSubject<boolean>(this.isMobileMenuShow);
  private _userData = new BehaviorSubject<any>(null);
  private _activeGroupDetails = new BehaviorSubject<any>(null);
  private _activePage = new BehaviorSubject<any>('');
  private _shoppingCarts = new BehaviorSubject<any>([]);
  miniSidebar = this._isMiniSidebar.asObservable();
  columnAreaShow = this._showColumnArea.asObservable();
  mobileMenuShow = this._showMobileMenu.asObservable();

  get getUserData() {
    if (this._userData.getValue()) {
      this.saveUserData(this._userData.getValue())
    }
    return this._userData.asObservable()
  };


  set setGroupDetails(group) {
    this._activeGroupDetails.next(group);
  }

  get getGroupDetails() {
    return this._activeGroupDetails.asObservable();
  }

  set setActivePage(page: string) {
    this._activePage.next(page);
  }

  get getActivePage() {
    return this._activePage.asObservable();
  }

  set setSearchTerm(page: string) {
    this._searchTerm.next(page);
  }

  get getSearchTerm() {
    return this._searchTerm.asObservable();
  }

  getShoppingCarts = this._shoppingCarts.asObservable();
  isLoggedIn = false;
  isAnonymous = false;

  constructor(
      private readonly crudService: CrudService,
      private readonly router: Router,
      private localStorageService: LocalStorageService
  ) {
    this.isLoggedIn = !!this.localStorageService.getItem('token');
    this.isAnonymous = !!this.localStorageService.getItem('anonymousToken');

    if (this.localStorageService.getItem('skipHelp') != null) {

      if (this.localStorageService.getItem('skipHelp') === 'true') {

        this.isShowColumnArea = true;

        this._showColumnArea.next(this.isShowColumnArea);

      } else {
        this.isShowColumnArea = false;

        this._showColumnArea.next(this.isShowColumnArea);
      }

    } else {
      this.localStorageService.setItem('skipHelp', 'false');  // showing help

      this.isShowColumnArea = false;

      this._showColumnArea.next(this.isShowColumnArea);

    }
    this.onGetShoppingCart();
  }

  private saveUserData(result) {
    this.isLoggedIn = true;
    this.localStorageService.setItem('userName', result.username);
    this.localStorageService.setItem('profilePic', result.profilePic);
    this.localStorageService.setItem('user', result._id);
    this.localStorageService.setItem('role', result.role);
    this.localStorageService.setItem('email', result.email);
    if (result.isSubscriber)
      this.localStorageService.setItem('isSubscriber', 'true')
    else
      this.localStorageService.setItem('isSubscriber', 'false')
    if (result?.connectedAccountId)
      this.localStorageService.setItem('isSeller', 'true')
    else
      this.localStorageService.removeItem('isSeller')
    if (result?.accountOnboardingStatus === 'Completed')
      this.localStorageService.setItem('isSellerCompleted', 'true')
    else
      this.localStorageService.removeItem('isSellerCompleted')
  }

  clearUserData() {
    this._userData.next(null);
  }

  onClickSkipImage() {
    this.isShowColumnArea = !this.isShowColumnArea;

    if (this.localStorageService.getItem('skipHelp') === 'true')
      this.localStorageService.setItem('skipHelp', 'false');
    else
      this.localStorageService.setItem('skipHelp', 'true');

    this._showColumnArea.next(this.isShowColumnArea);
  }

  resetSkipImage() {
    this.isShowColumnArea = false;
    this._showColumnArea.next(this.isShowColumnArea);
    this.localStorageService.setItem('skipHelp', 'false');
  }

  onClickMobileMenu(val) {
    this.isMobileMenuShow = val;
    this._showMobileMenu.next(this.isMobileMenuShow);
  }

  onToggleSidebar(val) {
    this._isMiniSidebar.next(val);
  }

  getAnonymousUser() {
    return new Promise((resolve => {
      this.crudService.getAnonymousUser().subscribe(success => {
        if (success?.data?.token) {
          this.localStorageService.setItem('anonymousToken', success.data.token);
        }
      }).add(() => resolve(true));
    }));
  }


  onGetUserData() {
    this.crudService.userDetailsV2().subscribe((res) => {
      if (res.data) {
        // console.log("dashboard service.onGetUserData() - userDetailsV2 res.data = ", res.data);

        this.crudService.getUserInfoV2(res.data.details._id).subscribe((response) => {
          if (!response.data.userInfo.category) {
            this.router.navigate(['/complete-profile']);
          } else {
            const result = {...res.data.details, ...response.data.userInfo};
            this.saveUserData(result);
            result.totalmainvideos = res.data.totalmainvideos;
            result.createdAt = res.data.details.createdAt;
            this._userData.next(result);
          }
        })
      }
    });
  }

  onGetShoppingCart() {
    // Check if there's an active shopping cart by this user
    if (this.localStorageService.getItem('token')) {
      this.crudService.getShoppingCartByUser().subscribe(success => {

        // There's an active shopping cart
        if (success.data) {
          //console.log('DashboardService - onGetShoppingCart --- success.data = ', success.data)

          // shopping cart is active and there's an item in the shopping cart
          // if (success.data.isActive && success.data) {
          this._shoppingCarts.next(success.data);
          // }
        } else {
          this._shoppingCarts.next([]);
        }
      });
    }
  }

  setHelpPopOver(id) {
    const aData = this.getHelpPopOver();
    aData[id] = true;
    this.localStorageService.setItem('ff-help', JSON.stringify(aData));
  }

  getHelpPopOver() {
    const data: string = this.localStorageService.getItem('ff-help');
    if (data) {
      const aData = JSON.parse(data);
      if (typeof aData === 'object' && aData !== null) {
        return aData;
      }
    }
    return {};
  }
}
