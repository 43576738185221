import {AfterViewInit, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild,} from '@angular/core';
import {CrudService} from 'src/app/services/crud.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
// import {Options} from '@angular-slider/ngx-slider';
import {MatDialog} from '@angular/material/dialog';
// import {VideoShareTo} from './public-video.model';
import {environment} from 'src/environments/environment';
import {BehaviorSubject} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, tap,} from 'rxjs/operators';
import {CreateVideoComponent} from '../../layout/modals/create-video/create-video.component';
// WC 11152022
import {EventType, IFormField, MessageType} from './createInteractiveVideo.interface';
import {FormBuilder, FormControl, FormGroup, Validators,} from '@angular/forms'
import languages from '../../services/languages';
import {PopoverDirective} from 'ngx-bootstrap/popover';
import {DashboardService} from '../../services/dashboard.service';
// WC 11152022
import {Location} from '@angular/common'
import {UtilityService} from '../../services/utility.service';
import {DeleteConfirmationComponent} from '../../layout/modals/delete-confirmation/delete-confirmation.component';
import {createVideoEvent} from './createInteractiveVideo.data';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {EditVideoComponent} from './edit-video/edit-video.component';
import {LocalStorageService} from '../../services/localStorage.service';

declare var $;
declare var d3;



@Component({
  selector: 'createInteractiveVideoComponent',
  templateUrl: './createInteractiveVideo.component.html',
  styleUrls: ['./createInteractiveVideo.component.css'],
  // animations: [fadeInOutAnimation]
})
export class CreateInteractiveVideoComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('popTmpAddChoiceEle') popTmpAddChoiceEle: PopoverDirective;
  @ViewChild('deleteDialogRef') deleteDialogRef: DeleteConfirmationComponent;

  findorselect: string;
  durations: any = 0;
  freeTemplate: any;
  currentVideoData: any = {};
  buttonType: string;
  name;
  videoName: any = null;
  currentType = 'application/x-mpegURL';
  currentHeight: any;
  // videoShareTo: VideoShareTo[] = [];
  // videoShareToValues = [];
  groupList = [];
  followerListForPublic = [];
  uploadVideoObject: any = {
    spinner: false,
    allVideos: [],
    selectedVideos: [],
    format: '',
    url: '',
    isUploaded: false,
    duration: 0,
    name: '',
    type: '',
    size: '',
    preSignedUrl: '',
    file: [],
    allUploadedVideo: [],
    allUrls: [],
    changeFilterUploadVideo: 'recent',
    changeFilterURL: 'recent',
    uploadPercentage: 0,
    loadingVideo: false,
  };

  allGroups: any = [];
  allFollowers: any = [];
  groupLength: number;
  followersLength: number;
  // private isUploaded: any = false;
  private allVideos: any = [];
  // private selectedVideos: any = [];
  // private allUploadedVideo: any = [];
  // private uploadPercentage: any = 0;
  private spinner: any = false;
  private loadingVideo: any = false;
  private uploadedCount: any = 0;
  private finalCount: any = 0;
  isOpen: any = false;
  private changeFilterUploadVideoVal: any = 'recent';
  item: any = ';';
  private contrubutors: any = [];
  private zoomRef: any = 1;
  private interval: any;
  private visRef: any;
  footerSection: any;
  buttonView: any;
  zoomBtnPosition: any;
  iconPosition: any = '0px';
  createInteractiveVideoObj: any = {
    spinner: false,
    selectedType: '',
    selectedVideoCheck: false,
    interactiveStep: 'one',
    tree: '',
    root: '',
    duration: 750,
    diagonal: '',
    svg: '',
    i: 0,
    currentObj: {},
    allVideos: [],
    allUploadedVideo: [],
    allUrls: [],
    changeFilterUploadVideo: 'recent',
    changeFilterURL: 'recent',
    selectedItem: '',
    query: '',
    interactiveVideo: [],
    finalObj: [],
    endingNodes: [],
    currentPlayingItem: {},
    mainVideo: '',
    child: [],
    publish: false,
    currentNodeOption: '',
    preview: false,
    // WC 03102022
    totalUploadedVideoCount: 0,
    // WC 04062022
    isRestricted: false,
    pathways: [],
    finalObjTree: [],
    conclusionLinks: []
  };
  currentBtnName: any = null;
  isOpenCreateVideo: any = false;
  OpenPopupAll: any = false;
  availableCategory: any = [];
  languages = languages;
  targetLanguages = [];
  alreadyTargetedLanguages = [];
  allowTranslate = false;
  isShareOpen: any = false;
  createChoiceObj: any = {child: [], currentNo: 2, parent: ''};
  mainID: any = '';

  followers: Array<any> = [];
  following: Array<any> = [];

  // private contributorId: any;
  // private isChecked = false;
  // private setFollowerCheck = false;
  // private setFollowingCheck = false;
  //
  // private isSelectedCut = false;
  // private isSelectedAnnotation = false;
  // private isSelectedTag = false;
  // private isSelectedBrush = false;
  // private isSelectedShare = false;
  // private isSelectedDownload = false;

  // WC 03102022 - To handle infinite scrolling
  private limit = 15;
  private page = 1;
  private sort = '';
  // ngx-infinite-loop
  // private distance = 2;
  // private throttle = 0;
  // WC 03102022

  // WC 04062022 - To handle uploaded list of AI Editor Videos
  private aiEditorVideo = 'no';
  private collabLimit = 10;
  private collabPage = 1;
  private contributors = [];
  // WC 04062022

  subtool = false;
  minValue = 50;
  maxValue = 200;
  value = 0;
  highValue = 100;
  isPrivate = false;
  options: any = {
    floor: 0,
    ceil: 100,
  };
  currentVideoUrl: any;
  isVideoAvailable = true;
  fileUrl: any;
  isProjectCreated = false;
  // tempFileUpload: any;
  fileToUpload: any = [];
  fileResData: any;
  selectedFile: string[] = [];
  projectUrl: any;
  isSliderView = false;

  top: any = '0%';
  left: any = '0';
  bottom: any = '26%';
  right: any;
  color: any;
  fontFamily: any;

  // Start editing
  isEdit = true;
  itemStartEdit = false;
  cutImage = '../../../assets/edit tool/scissors.svg';
  annotationImage = '../../../assets/edit tool/font.svg';
  audioImage = '../../../assets/edit tool/medium-volume.svg';
  drawImage = '../../../assets/edit tool/pencil gray.svg';
  shareImage = '../../../assets/edit tool/share.svg';
  downloadImage = '../../../assets/edit tool/download gray.svg';
  currentOperation: any = null;
  isFIleView = false;
  isAllEditingTool = true;
  videoSpinner = false;
  projectName: any;
  clipData: any;

  // currentType = "video/mp4"
  videoProgress: any;

  VideoOutput: any;
  public player: any;

  // videoUrl = '../../../assets/video/demo test.mp4'
  videoUrl = null;
  videoViewOne = true;

  colorView = true;
  fontView = true;
  annotatioView = false;
  allColors = false;
  fontfamilyView = false;
  annotTxtView = false;
  @ViewChild('annotationName', {static: false}) annotationTxt: ElementRef;
  audioToolView = false;
  audioSubTool = false;
  volumeRange = false;
  drawView = false;
  drawAllColors = false;
  lineWidth: any = 2;
  canvasView = false;
  pointerEvent = 'none';
  drwaColor = 'black';
  drawRangeView = false;
  @ViewChild('canvas', {static: true}) canvas: ElementRef<HTMLCanvasElement>;
  ctx: CanvasRenderingContext2D;
  titleChange$ = new BehaviorSubject('');
  duationChange$ = new BehaviorSubject(0);
  nodes = [];
  latestNodeId: string;
  orderedNodes = [];
  undoOrderedNodes = [];
  redoOrderedNodes = [];

  // WC 11152022
  private maxNoOfVideosAllowed = 0;
  maxNoOfCreatedVideos = 0;
  private currNoOfRetries = 0;
  planInfo: any = null;
  currSubscriptionId = '';
  pricingUrl = '';
  lstForm: IFormField[] = [];

  // Form declaration for email exchanges for videos
  formEmail = this.formBuilder.group({});
  // WC 09062022

  editWatchPath: any;
  isAddWatchPath = false;
  startWatchNode: any;
  endWatchNode: any;
  watchPathName = '';
  submitPathWaysFormError = '';
  isSubmitPathWaysForm = false;
  pathNodes = [];
  messages: MessageType[] = [];
  currentMessageStep = 0;
  msgValue = '';
  isMessageLoad = false;
  messageMap = {
    rename: 'Rename Pathway',
    restart: 'Update Starting and Ending Nodes',
    reChooseEndNode: 'Update Ending Node',
    save: 'Save',
  };
  userData: any;

  isInitialStep = true;
  isAssistCreateVideo: any;
  assistEventsSubject = new BehaviorSubject<EventType>({type: ''});
  private msgEvent = createVideoEvent;
  private zoomListener: any;
  dragDropNode = false;
  deleteCurrentNodeType = 1;
  rootInitialPosition: any;
  isReorderChoice = false;

  constructor(
      private dashboardService: DashboardService,
      private urlService: CrudService,
      private utilityService: UtilityService,
      private router: Router,
      private toastr: ToastrService,
      private activatedRoute: ActivatedRoute,
      private dialog: MatDialog,
      private formBuilder: FormBuilder,
      private localStorageService: LocalStorageService,
      private location: Location
  ) {
    this.isAssistCreateVideo = this.localStorageService.getItem('assistCreateVideo');
    this.activatedRoute.queryParams.subscribe((params) => {
      if (params.freeTemp) {
        this.freeTemplate = params.freeTemp;
      } else {
        this.createInteractiveVideoObj.currentObj.id = params.video;
        this.mainID = params.video;
      }
    });

    // setTimeout(() => {
    //   console.log(this.createInteractiveVideoObj)
    // }, 5000)
  }

  get dragListOrientation() {
    return window.innerWidth < 768 ? 'vertical' : 'horizontal';
  }

  get isStoryBoard() {
    return this.buttonType === 'storyBoard';
  }

  get filterFollowers() {
    const keys = 'username,firstname,lastname';
    return (this.followers || []).filter(item => {
      item = item.followersDetails[0];
      return keys.split(',').some(key => item.hasOwnProperty(key) && new RegExp(this.findorselect, 'gi').test(item[key]))
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (this.createInteractiveVideoObj.finalObjTree.length) {
      this.callTree(this.createInteractiveVideoObj.finalObjTree);
    }
  }

  ngOnInit() {
    // WC 11152022
    // this.getEmailsforVideoForm();
    // WC 11152022

    this.checkTranslationAvailability();
    this.getUserInfo();

    this.buttonType = this.localStorageService.getItem('interactive');
    // this.buttonType = 'storyBoard';

    // console.log('this.buttonType = ', this.buttonType);

    // WC 04062022 -- To handle listing of uploaded AI Editor Video
    if (this.localStorageService.getItem('interactive') === 'isEditor')
      this.aiEditorVideo = 'yes';
    else
      this.aiEditorVideo = 'no';

    // console.log("this.aiEditorVideo = ", this.aiEditorVideo);
    // WC 04062022
    this.activatedRoute.queryParams.subscribe((params) => {
      this.intialFunction('');
    });

    if (this.isAssistCreateVideo) {
      this.assistEventsSubject.next({type: this.msgEvent.init});
    }
    // WC 04062022
    // this.getFollowersList();
    if (this.mainID != undefined) this.getFollowerContributors();
    // WC 04062022

    $(document).ready(() => {
      $('.filterBtn,.fa-times').click(() => {
        $('#dropdownMenu').slideToggle();
      });
    });

    $('.modal').on('hidden.bs.modal', () => {
      $('video').trigger('pause');
    });

    this.getGroupList();
    this.getFollowersList();
    if (this.buttonType === 'isInteractive') {
      this.currentBtnName = 'Create interactive Video';
    } else {
      this.currentBtnName = 'Auto Edit';
    }

    if (this.isStoryBoard && !this.mainID) {
      this.createEmptyNodes();
    }

    const popData = this.dashboardService.getHelpPopOver();
    if (!popData.popTmpAddChoice) {
      setTimeout(() => {
        this.popTmpAddChoiceEle.show();
      }, 1000);
    }

  }

  getUserInfo() {

    this.dashboardService.getUserData.subscribe((res) => {
      if (res) {
        this.userData = res;
      }
    });
  }

  checkTranslationAvailability() {

    this.urlService.getUserSubscriptionPlan().subscribe(success => {
      this.planInfo = success.data.subscriptionPlanInfo;

      // console.log("this.planInfo.name = ", this.planInfo.name);

      this.allowTranslate = !this.planInfo.name.includes('Freemium') && !this.planInfo.name.includes('Bronze');

    });
  }

  // WC 03312022
  onSearchUploadedVideos(): void {
    this.page = 1;

    // console.log("onSearchUploadedVideos - search: " + this.createInteractiveVideoObj.query + ", for page: " + this.page);

    this.listVideosWithSearchCriteria(true);
    this.countUploadedVideos();
  }

  headerSeachValues(value: string) {
    if (value) {
      const matchingItems = this.nodes.filter(item => item.name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
      const notMatchingItems = this.nodes.filter(item => item.name.toLowerCase().indexOf(value.toLowerCase()) === -1);
      matchingItems.forEach((i) => {
        const latestNodeItem = document.getElementById(`node-button-${i.id}`);
        latestNodeItem.style.background = '#d3c1eb';
      });
      notMatchingItems.forEach((i) => {
        const latestNodeItem = document.getElementById(`node-button-${i.id}`);
        latestNodeItem.style.background = '#ffffff';
      })
    } else {
      this.nodes.forEach((i) => {
        const latestNodeItem = document.getElementById(`node-button-${i.id}`);
        latestNodeItem.style.background = '#ffffff';
      });
      const latestItem = this.getLatestItem(this.nodes, this.latestNodeId);
      const latestNodeItem = document.getElementById(`node-button-${latestItem[0].id}`);
      latestNodeItem.style.background = '#d3c1eb';
    }
  }

  // WC 03202022
  onScroll(): void {
    ++this.page;

    // console.log("onScroll - search: " + this.createInteractiveVideoObj.query + ", this.page: " + this.page + ", this.sort: " + this.sort +", this.aiEditorVideo: " + this.aiEditorVideo);

    this.getCurrentWidth();
    const freeTemp = this.localStorageService.getItem('freeTemp');

    // WC 03312022 - Either call uploaded video list without search criteria or call with search criteria
    if (this.createInteractiveVideoObj.query == '') {
      // WC 03102022 - Calling V2
      this.urlService
          .getUploadedVideoV2(
              this.limit,
              this.page,
              this.sort,
              this.aiEditorVideo,
              'video'
          )
          .subscribe(
              (success) => {
                setTimeout(() => {
                  if (freeTemp) {
                    this.clickSelectVideo();
                  }
                }, 500);
                this.createInteractiveVideoObj.allVideos = success.data;
                // this.createInteractiveVideoObj.allUploadedVideo = success.data;

                // console.log("onScroll - this.createInteractiveVideoObj.allVideos: ", this.createInteractiveVideoObj.allVideos);

                for (const i of this.createInteractiveVideoObj.allVideos) {
                  // WC 08272022
                  i.durationInHHMMSS = this.utilityService.convertSecondsToHHMMSSFormat(
                      i.duration
                  );
                  // WC 08272022

                  i.URLName = i.originalName;
                  if (!i.originalName) {
                    i.URLName = i.name;
                  }

                  // WC 03102022 to handle undefined URLName from mobile app
                  if (i.URLName === undefined) {
                    i.URLName = '';
                    // console.log("onScroll - i.URLName = ", i.URLName)
                  }

                  // console.log("#### this.createInteractiveVideoObj.allUploadedVideo BEFORE PUSHING NEW DATA = ", this.createInteractiveVideoObj.allUploadedVideo.length);

                  this.createInteractiveVideoObj.allUploadedVideo.push(i);
                }

                // console.log("onScroll - this.createInteractiveVideoObj.allUploadedVideo: ", this.createInteractiveVideoObj.allUploadedVideo);

                if (!this.mainID) {
                  this.createInteractiveVideoObj.spinner = false;
                  this.createInteractiveVideoObj.selectedVideoCheck = true;
                  this.createInteractiveVideoObj.interactiveStep = 'one';
                }

              },

              (error) => {
                this.createInteractiveVideoObj.spinner = false;
              }
          );
    } else {
      // listing video with a search criteria

      // console.log("onScroll - calling this.listVideosWithSearchCriteria() for page: ", this.page);

      this.listVideosWithSearchCriteria(false);
    }
  }

  // WC 04062022 -- Scrolling Collaborators
  onScrollCollab(): void {
    ++this.collabPage;

    // console.log("onScrollCollab - this.page: " + this.page);

    this.getFollowerContributors();
  }

  onCollabChange(follower: any): void {
    // console.log("onCollabChange");

    this.createInteractiveVideoObj.spinner = true;
    // This follower is not a contributor yet
    if (follower.followersWhoContribute.length == 0) {
      const contributor = {
        videoID: this.mainID,
        contributorID: follower.followersDetails[0]._id,
      };

      this.urlService.setContributorV2(contributor).subscribe((success) => {
        this.toastr.success(
            follower.followersDetails[0].username + ' added as a contributor'
        );
      });
    } else {
      // Remove the contributor

      const docId = follower.followersWhoContribute[0]._id;

      // console.log("Contributor docId: ", docId);
      const data = { id: docId };
      this.urlService.deleteContributor(data).subscribe((success) => {
        this.toastr.success(
            follower.followersDetails[0].username + ' removed as a contributor'
        );
      });
    }

    this.createInteractiveVideoObj.spinner = false;
  }

  // WC 04062022 -- Scrolling Collaborators

  getGroupList() {
    // const id = this.localStorageService.getItem('users');
    this.urlService.getAllGroups().subscribe((res) => {
      // const type: GroupType[] = [];
      // res.data.forEach((e: any, i: number) => {
      //   type.push({value: e._id, data: e});
      // });
      // this.videoShareTo.push({name: 'Groups', type});
      // console.log("this.videoShareTo for Groups: ", this.videoShareTo);

      this.allGroups = res.data;
      this.groupLength = this.allGroups.length;
    });
  }

  onScrollFollowers() {
    ++this.collabPage;
    this.getFollowersList();
  }

  getFollowersList(reset = false) {
    if (reset) {
      this.collabPage = 1;
      this.allFollowers = [];
    }
    this.urlService.getFollowers(this.collabLimit, this.collabPage).subscribe((res) => {
      this.allFollowers.push(...res.data.followers);
    });
    // console.log(this.allFollowers)
  }

  getFollowerContributors() {
    // console.log("this.mainID ", this.mainID);
    if (!this.mainID) {
      return;
    }
    this.urlService
        .getFollowerContributors(this.mainID, this.collabLimit, this.collabPage)
        .subscribe((res) => {
          // const type: GroupType[] = [];
          // res.data.forEach((e: any, i: number) => {
          //   const info = {
          //     _id: e.followersDetails[0]._id,
          //     username: e.followersDetails[0].username,
          //     profilePic: e.followersDetails[0].profilePic,
          //   };
          //   type.push({value: e._id, data: info});
          // });

          // this.videoShareTo.push({name: 'Followers', type});
          // console.log("this.videoShareTo for Followers: ", this.videoShareTo);

          this.followers.push(...res.data);

          // console.log("this.followers: ", this.followers);

          this.followersLength = this.followers.length;
        });
  }

  // WC 04062022

  // recipientValueCheck(event: any) {
  //   const selectedItems = event;
  //   const groupList = [];
  //   const followersList = [];
  //   selectedItems.forEach((element) => {
  //     this.videoShareTo.forEach((e) => {
  //       e.type.forEach((item) => {
  //         if (item.value === element) {
  //           if (e.name === 'Groups') {
  //             groupList.push(element);
  //           } else {
  //             followersList.push(element);
  //           }
  //         }
  //       });
  //     });
  //   });
  //   this.groupList = groupList;
  //   this.followerListForPublic = followersList;
  // }

  recipientValueCheckV2(event: any) {
    const groupList = [];
    const followersList = [];
    setTimeout(() => {
      this.allGroups.forEach((e) => {
        if (e.checked) {
          groupList.push(e._id)
        }
      });
      // console.log(this.allGroups, groupList)
      this.allFollowers.forEach((e) => {
        if (e.checked) {
          followersList.push(e._id)
        }
      });
      this.groupList = groupList;
      this.followerListForPublic = followersList;
    });
  }

  getPublicPrivate(event: any) {
    this.isPrivate = event.checked;
    $(document).ready(() => {
      $('.filterBtn,.fa-times').click(() => {
        $('#dropdownMenu').slideToggle();
      });
    });
  }

  intialFunction(p) {
    // this.getCurrentWidth();
    const freeTemp = this.localStorageService.getItem('freeTemp');
    this.createInteractiveVideoObj.allUploadedVideo = [];
    this.createInteractiveVideoObj.spinner = true;

    // WC 03102022 - Set the initial sorting to recent
    this.sort = 'recent';

    // WV 04062022 - Updated to handle the AI Editor Video listing
    // WC 03102022 - Calling V2
    // this.urlService.getUploadedVideo().subscribe((success) => {
    this.urlService
        .getUploadedVideoV2(this.limit, this.page, this.sort, this.aiEditorVideo, 'video')
        .subscribe(
            (success) => {
              setTimeout(() => {
                if (freeTemp) {
                  this.clickSelectVideo();
                }
              }, 500);

              this.createInteractiveVideoObj.allVideos = success.data;

              // console.log("intialFunction - this.createInteractiveVideoObj.allVideos: ", this.createInteractiveVideoObj.allVideos);

              // WC 03102022
              for (const i of this.createInteractiveVideoObj.allVideos) {
                // WC 08272022
                i.durationInHHMMSS = this.utilityService.convertSecondsToHHMMSSFormat(i.duration);
                // WC 08272022

                i.URLName = i.originalName;
                if (!i.originalName) {
                  i.URLName = i.name;
                }

                // WC 03102022 to handle undefined URLName from mobile app
                if (i.URLName === undefined) {
                  i.URLName = '';
                  // console.log("intialFunction - i.URLName = ", i.URLName)
                }

                this.createInteractiveVideoObj.allUploadedVideo.push(i);
              }

              // console.log("intialFunction - this.createInteractiveVideoObj.allUploadedVideo: ", this.createInteractiveVideoObj.allUploadedVideo);

              // WC 03102022 - Getting the total count of uploaded videos
              this.countUploadedVideos();

              if (this.mainID) {
                this.getMainVideoInfo();
              } else {
                this.createInteractiveVideoObj.spinner = false;
                this.createInteractiveVideoObj.selectedVideoCheck = true;
                // this.createInteractiveVideoObj.selectedVideoCheck= true;
                this.createInteractiveVideoObj.interactiveStep = 'one';
              }
            },
            (error) => {
              this.createInteractiveVideoObj.spinner = false;
            }
        );
  }

  hideShowFooter() {
    const width = window.innerWidth;
    if (this.footerSection === 'none') {
      this.footerSection = 'block';
      this.zoomBtnPosition = '70px';
      if (width < 991) {
        this.zoomBtnPosition = '115px';
      }
      this.iconPosition = '98px';
    } else {
      this.footerSection = 'none';
      this.zoomBtnPosition = '115px';
      this.iconPosition = '0px';
    }
  }

  getCurrentWidth() {
    const width = window.innerWidth;
    if (width < 991) {
      this.footerSection = 'none';
      this.buttonView = 'block';
      this.zoomBtnPosition = '115px';
    } else {
      this.footerSection = 'block';
      this.buttonView = 'none';
      this.zoomBtnPosition = '70px';
    }
    return width;
  }
  callChild(p) {
    this.urlService
        .getChildVideosAll(this.createInteractiveVideoObj.currentObj._id)
        .subscribe((success) => {
          const tree = [];
          if (!!!this.createInteractiveVideoObj.currentObj.URL) {
            this.createInteractiveVideoObj.currentObj.name = this.createInteractiveVideoObj.currentObj.name ? this.createInteractiveVideoObj.currentObj.name : 'Add Video';
            this.createInteractiveVideoObj.currentObj.poster1 = 'assets/images/H-vidcam.svg';
            this.createInteractiveVideoObj.currentObj.basck = 'gt';
            this.createInteractiveVideoObj.currentObj.title = this.createInteractiveVideoObj.currentObj.title ? this.createInteractiveVideoObj.currentObj.title : 'Add Video';
            this.createInteractiveVideoObj.currentObj.imvd = 'imvd';
          } else {
            this.createInteractiveVideoObj.currentObj.basck = 'videochart';
            this.createInteractiveVideoObj.currentObj.imvd = 'ncs';
          }
          this.createInteractiveVideoObj.currentObj.parentId = '0';
          tree.push(this.createInteractiveVideoObj.currentObj);
          for (const i of success.data) {
            // i.children = null;
            i.durationInHHMMSS = this.utilityService.convertSecondsToHHMMSSFormat(i.duration);
            if (!!!i.URL) {
              i.name = i.name ? i.name : 'Add Video';
              i.poster1 = 'assets/images/H-vidcam.svg';
              i.basck = 'gt';
              i.title = i.title ? i.title : 'Add Video';
              i.imvd = 'imvd';
            } else {
              i.basck = 'videochart';
              i.imvd = 'ncs';
            }
            tree.push(i);
          }
          this.createInteractiveVideoObj.selectedVideoCheck = false;
          this.createInteractiveVideoObj.interactiveStep = 'three';
          this.createInteractiveVideoObj.spinner = false;
          this.createInteractiveVideoObj.finalObj = tree;
          this.createInteractiveVideoObj.endingNodes = tree.filter(x => x.isEndingNode);
          this.createInteractiveVideoObj.currentPlayingItem = tree[0];
          this.createInteractiveVideoObj.currentObj.open = false;
          this.createInteractiveVideoObj.finalObjTree = this.createNestedArray(tree);
          this.getVideoPathWays();
          this.callTree(this.createInteractiveVideoObj.finalObjTree);
          // console.log(this.createInteractiveVideoObj)
          // this.editVideo();
          if (this.currentVideoData?.isEdit) {
            const currentObj = this.createInteractiveVideoObj.finalObj.find(x => x._id === this.currentVideoData._id)
            if (currentObj) {
              this.clickedItem(currentObj)
            }
          }
        });
  }

  callChild1() {
    $('#tree-view').html();
    $('svg[width="100%"]').remove();

    this.urlService.getChildVideosAll(this.mainID).subscribe((success) => {
      const tree = [];
      this.createInteractiveVideoObj.mainVideo.parentId = '0';
      this.createInteractiveVideoObj.mainVideo.level = 0;
      if (!!!this.createInteractiveVideoObj.mainVideo.URL) {
        this.createInteractiveVideoObj.mainVideo.name = this.createInteractiveVideoObj.mainVideo.name ? this.createInteractiveVideoObj.mainVideo.name : 'Add Video';
        this.createInteractiveVideoObj.mainVideo.poster1 = 'assets/images/H-vidcam.svg';
        this.createInteractiveVideoObj.mainVideo.basck = 'gt';
        this.createInteractiveVideoObj.mainVideo.title = this.createInteractiveVideoObj.mainVideo.title ? this.createInteractiveVideoObj.mainVideo.title : 'Add Video';
        this.createInteractiveVideoObj.mainVideo.imvd = 'imvd';
      } else {
        this.createInteractiveVideoObj.mainVideo.basck = 'videochart';
        this.createInteractiveVideoObj.mainVideo.imvd = 'ncs';
      }
      tree.push(this.createInteractiveVideoObj.mainVideo);
      for (const i of success.data) {
        // i.children = null;
        i.durationInHHMMSS = this.utilityService.convertSecondsToHHMMSSFormat(i.duration);
        if (!!!i.URL) {
          i.name = i.name ? i.name : 'Add Video';
          i.poster1 = 'assets/images/H-vidcam.svg';
          i.basck = 'gt';
          i.title = i.title ? i.title : 'Add Video';
          i.imvd = 'imvd';
        } else {
          i.basck = 'videochart';
          i.imvd = 'ncs';
        }
        i.id = undefined;
        tree.push(i);
      }
      this.createInteractiveVideoObj.spinner = false;
      this.createInteractiveVideoObj.finalObj = tree;
      this.createInteractiveVideoObj.endingNodes = tree.filter(x => x.isEndingNode);
      this.createInteractiveVideoObj.finalObjTree = this.createNestedArray(tree);
      this.getVideoPathWays();
      this.callTree(this.createInteractiveVideoObj.finalObjTree);
    });
  }

  createNestedArray(list) {
    this.createInteractiveVideoObj.conclusionLinks = [];
    // list[8].endingNodeId = list[1]._id;
    const map = {};
    let node;
    const roots = [];
    let i;
    for (i = 0; i < list.length; i += 1) {
      map[list[i]._id] = i; // initialize the map
      list[i].children = []; // initialize the children
    }
    for (i = 0; i < list.length; i += 1) {
      node = list[i];
      if (node.endingNodeId && list[map[node.endingNodeId]]) {
        this.createInteractiveVideoObj.conclusionLinks.push({
          source: list[map[node.endingNodeId]],
          target: list[map[node.parentId]],
          type: 'conclusion'
        });
        continue;
      }
      if (node.parentId !== '0') {
        // if you have dangling branches check that map[node.parentId] exists
        if (list[map[node.parentId]])
          list[map[node.parentId]].children.push(node);
      } else {
        roots.push(node);
      }
    }
    // console.log(list)
    return roots;
  }

  // checkedItem(val) {
  //   this.createInteractiveVideoObj.selectedItem = val;
  // }

  closeModal() {
    this.createInteractiveVideoObj.selectedItem = {};
    $('video').trigger('pause');
  }

  closeUploadModal() {
    // console.log("In closeUploadModal");

    this.isOpenCreateVideo = false;
    this.createInteractiveVideoObj.selectedItem = {};
    this.createInteractiveVideoObj.interactiveStep = 'one';
    this.createInteractiveVideoObj.selectedVideoCheck = true;
    $('video').trigger('pause');

    const freeTemp = this.localStorageService.getItem('freeTemp');
    // console.log("In closeUploadModal, freeTemp: ", freeTemp);

    if (freeTemp) {
      this.router.navigate(['/upload-videos']);
    }
  }

  callTree(data) {
    const a = this.getCurrentWidth();
    $('#tree-view svg').remove();

    const that = this;
    if (a < 991) {
      this.currentHeight = $(window).height();
    } else {
      this.currentHeight = $(window).height() - 300;
    }

    const treedata = data[0];
    const m = [20, 20, 20, 20];
    const w = $(window).width() - 20;
    let h = this.currentHeight;
    let i = 0;
    const r = 800;
    const x = d3.scale.linear().domain([0, w]).range([0, w]);
    const y = d3.scale.linear().domain([0, h]).range([0, h]);
    let root;
    const b = -300;
    that.zoomListener = d3.behavior.zoom().x(x).y(y).scaleExtent([0.1, 10]).on('zoom', zoom);

    const vis = d3
        .select('#tree-view')
        .append('svg:svg')
        .attr('viewBox', '0 -300 900 900')
        .attr('class', 'svgViewBox')
        .attr('width', w)
        .attr('height', this.currentHeight)
        .attr('xmlns', 'http://www.w3.org/2000/svg')
        .append('svg:g')
        .attr('id', 'rootg')
        .call(
            that.zoomListener
        );
    // vis.append('svg:defs').append('svg:marker')
    //     .attr('id', 'arrow')
    //     .attr('viewBox', '0 -5 10 10')
    //     .attr('refX', -80)
    //     .attr('markerWidth', 10)
    //     .attr('markerHeight', 10)
    //     .attr('orient', 'auto')
    //     .append('svg:path')
    //     .attr('d', 'M0,-5L10,0L0,5');

    this.visRef = vis;
    // console.log(this.visRef)
    $('#zoom-reset').click(() => {
      zoom;
    });

    vis
        .append('rect')
        .attr('class', 'overlay')
        .attr('width', w + m[1] + m[3])
        .attr('height', h + m[0] + m[2])
        .attr('opacity', 0)
        .attr('fill', '#eee');

    let tree = d3.layout
        .tree()
        .size([h, w])
        .nodeSize([200 + 10, 700 + 10])
        .separation(() => {
          return 0.5;
        });

    const diagonal = d3.svg.diagonal().projection((d) => {
      return [d.y, d.x];
    });

    root = treedata;
    root.x0 = h / 2;
    root.y0 = 0;
    // console.log(JSON.stringify(root))
    // console.log(root)

    // function toggleAll(d) {
    //   if (d.children) {
    //     d.children.forEach(toggleAll);
    //     //  toggle(d);
    //   }
    // }

    // initialize the display to show a few nodes.
    // root.children.forEach(toggleAll);
    // toggle(root.children[1]);
    // toggle(root.children[9]);
    this.visRef.attr('class', this.dragDropNode ? 'rearrange' : '');

    update(root);

    function update(source) {
      i = 0;
      const duration = d3.event && d3.event.altKey ? 5000 : 500;
      // Compute the new height, function counts total children of root node and sets tree height accordingly.
      // This prevents the layout looking squashed when new nodes are made visible or looking sparse when nodes are removed
      // This makes the layout more consistent.
      const levelWidth = [1];
      const childCount = (level, n) => {

        if (n.children && n.children.length > 0) {
          if (levelWidth.length <= level + 1) levelWidth.push(0);

          levelWidth[level + 1] += n.children.length;
          n.children.forEach(d => {
            childCount(level + 1, d);
          });
        }
      };
      childCount(0, root);
      h = (d3.max(levelWidth) + 1) * 175; // 25 pixels per line
      const ww = (levelWidth.length + 1) * 250; // 25 pixels per line
      tree = tree.size([h, ww]);
      vis.select('rect.overlay')
          .attr('height', h)
          .attr('width', ww)
      // Compute the new tree layout.
      const nodes = tree.nodes(root).reverse();

      // Normalize for fixed-depth.
      nodes.forEach((d) => {
        d.y = d.depth * 250;
        // d.x = d.depth;
      });

      // Update the nodes...
      const node = vis.selectAll('g.node').data(nodes, (d) => {
        return (d.id = ++i);
      });

      // Enter any new nodes at the parent's previous position.
      const nodeEnter = node
          .enter()
          .append('svg:g')
          .attr('class', 'node')
          .attr('id', (d) => {
            //   //console.log(d)
            return 'node-' + d.id;
          })
          .attr('transform', (d) => {
            return 'translate(' + source.y0 + ',' + source.x0 + ')';
          })
          // .attr("-webkit-transform", function (d) {
          // })
          .on('click', (d) => {
            // toggle(d);
            // update(d);
            // if (d['info']) {
            //   playvid(d['info']);
            // }
          });

      // Update the nodes...
      vis.selectAll('g.node').data(nodes, (d) => {
        return (d.ele = this);
      });

      nodeEnter
          .append('svg:circle')
          .attr('r', 1e-6)
          .style('fill', (d) => {
            return d._children ? '#000' : '#000';
          })
      const dragListener = d3.behavior.drag().on('dragstart', dragstart).on('drag', drag).on('dragend', dragEnd);
      nodeEnter.call(dragListener)
      const foreignObject = nodeEnter
          .append('svg:foreignObject')
          .attr('class', (d) => {
            return `${d.children && d.children.length >= 4 ? 'max-node' : 'droppable-node'}`
          })
          // .attr("y", function(d) { return d.children || d._children ? -10 : 10; })
          // .attr("dx", ".35em")
          // .attr("x", function(d) {
          //  return d.children || d._children ? -10 : 10;
          // })
          .attr('y', -60)
          .attr('x', -40)
          .attr('text-anchor', (d) => {
            return d.children || d._children ? 'end' : 'start';
          })
          .style('fill-opacity', 1e-6)
          .attr('width', 140)
          .attr('height', 140)
          .on('mouseover', d => {
            overCircle(d);
          }).on('mouseout', d => {
            outCircle(d);
          });

      const nodeText = foreignObject.append('xhtml:button')
          .attr('class', (d) => {
            return `video-component ${d.children && d.children.length >= 4 ? 'max-node' : 'droppable-node'}`
          })
          .attr('id', (d) => {
            return 'node-button-' + d.id;
          })
          .attr('data-id', (d) => {
            return d._id;
          })
          .on('click', (d) => {
            if (d3.event.defaultPrevented) return;
            that.clickedItem(d);
          })
      // .attr("data-toggle","modal")
      // .attr("data-target","tree-Modal");

      nodeText
          .append('xhtml:video')

          // attr('class', 'videochart')
          .attr('class', (d) => {
            return d.basck;
          })
          //  attr('src', function (d) { return d.URL })
          .attr('poster', (d) => {
            return d.poster;
          });

      // nodeText
      // .append('iframe','div').
      // attr('class','iframechart').
      // attr('src',function(d) { return d.URL})
      // .attr('allow','accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture');

      // .attr('controls','true')imvd
      // tslint:disable-next-line: no-var-keyword
      var SpanText = nodeText
          .append('img', 'div')
          .attr('class', (d) => {
            return d.imvd;
          })
          .attr('src', (d) => {
            return d.poster1;
          });
      // tslint:disable-next-line: no-var-keyword
      var SpanText = nodeText
          .append('span', 'div')
          .attr('class', 'node-text')
          .text((d) => {
            return d.name;
          });
      // tslint:disable-next-line: no-var-keyword
      var SpanText = nodeText
          .append('span', 'div')
          .attr('class', 'overlay-vid');

      // Edit node button
      nodeText
          .filter((d) => {
            return d.editable;
          })
          .append('a')
          .attr('class', 'node-edit')
          .on('click', onEditNode, true)
          .append('i')
          .attr('class', 'fa fa-pencil');

      // Add node button
      nodeText
          .filter((d) => {
            return d.addable;
          })
          .append('a')
          .attr('class', 'node-add')
          .on('click', onAddNode, true)
          .append('i')
          .attr('class', 'fa fa-plus');

      // Remove node button
      nodeText
          .filter((d) => {
            return d.removable;
          })
          .append('a')
          .attr('class', 'node-remove')
          .on('click', onRemoveNode, true)
          .append('i')
          .attr('class', 'fa fa-times');

      // Transition nodes to their new position.
      const nodeUpdate = node
          .transition()
          .duration(duration)
          .attr('transform', (d) => {
            return 'translate(' + d.y + ',' + d.x + ')';
          });
      // // .attr("-webkit-transform", function (d) {
      // });

      nodeUpdate
          .select('circle')
          .attr('r', 4.5)
          .style('fill', (d) => {
            return d._children ? '#000' : '#000';
          });

      nodeUpdate.select('text').style('fill-opacity', 1);

      // Transition exiting ndoes to the parent's new position.
      const nodeExit = node
          .exit()
          .transition()
          .duration(duration)
          .attr('transform', (d) => {
            return 'translate(' + source.y + ',' + source.x + ')';
          })
          .remove();

      nodeExit.select('circle').attr('r', 1e-6);
      nodeExit.select('text').style('fill-opacity', 1e-6);

      // Update the links...
      const jsonLinks = tree.links(nodes);
      for (const conclusionLink of that.createInteractiveVideoObj.conclusionLinks) {
        jsonLinks.push(conclusionLink)
      }
      // jsonLinks.push({source:jsonLinks[1].target,target:jsonLinks[0].target})
      // console.log(jsonLinks,root)
      // const link = vis.selectAll('path.link').data(tree.links(nodes), (d) => {
      //   return d.target.id;
      // });
      const link = vis.selectAll('path.link').data(jsonLinks);

      // Enter any new links at hte parent's previous position
      link
          .enter()
          .insert('svg:path', 'g')
          .attr('class', (d) => {
            return 'link' + (d.type ? (' ' + d.type) : '');
          })
          .attr('d', (d) => {
            const o = {
              x: source.x0,
              y: source.y0,
            };
            return diagonal({
              source: o,
              target: o,
            });
          })
          .transition()
          .duration(duration)
          .attr('d', diagonal);

      // Transition links to their new position.
      link.transition().duration(duration).attr('d', diagonal);

      // Transition exiting nodes to the parent's new position.
      link
          .exit()
          .transition()
          .duration(duration)
          .attr('d', (d) => {
            const o = {
              x: source.x,
              y: source.y,
            };
            return diagonal({
              source: o,
              target: o,
            });
          })
          .remove();
      // link.attr('marker-start', 'url(#arrow)');

      // Stash the old positions for transition.
      nodes.forEach((d) => {
        d.x0 = d.x;
        d.y0 = d.y;
      });

      const rootG = document.getElementById('rootg');
      const rect = rootG.getBoundingClientRect();

      that.nodes = nodes;

      const calculateTranslation = (currentNode) => {
        const xAxis = rect.x + (currentNode[0].y0);
        const yAxis = (currentNode[0].x0 > 0) ? (rect.y + currentNode[0].x0) : (-currentNode[0].x0 - rect.y);

        const x2 = window.innerWidth / 2;
        const y2 = window.innerHeight / 2;
        const x3 = xAxis > x2 ? (x2 - xAxis) : (xAxis - x2);
        let y3 = 0;
        if (currentNode[0].x0 > 0) {
          y3 = Math.floor((yAxis > y2) ? (y2 - yAxis) : (yAxis - y2));
        } else {
          y3 = Math.floor((-yAxis < y2) ? (yAxis + y2) : (yAxis - (-y2)));
        }
        return [x3, y3];
      }
      let trans;
      if (that.latestNodeId) {
        const latestNodeItem = that.getLatestItem(nodes, that.latestNodeId);
        const latestNode = document.getElementById(`node-button-${latestNodeItem[0].id}`);
        latestNode.style.background = '#d3c1eb';
        trans = calculateTranslation(latestNodeItem);
      } else {
        const latestNodeItem = that.getLatestItem(nodes, root._id);
        trans = calculateTranslation(latestNodeItem);
      }
      if (!that.rootInitialPosition) {
        that.rootInitialPosition = trans;
      }
      that.visRef.attr('transform', 'translate(' + trans + ') scale(1)');
      that.zoomListener.translate(trans);
      // console.log(trans, that.latestNodeId)
      // that.zoomListener.event(that.visRef);
      // var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
      // if(isSafari){
      //   setInterval(()=>{
      //         $('#tree-view g[transform]').each(function() {
      //           let transform = $(this).attr('transform')
      //           transform = transform.split('(');
      //           //transform = transform[1].split(',')
      //         transform = transform[1].split(')')
      //           transform = transform[0].split(',')

      //           // `this` is the div
      //       });
      //     },2000)
      //   }
    }

    const panSpeed = 100;
    const panBoundary = 5;
    let panTimer;
    let translateCoords;
    let translateX;
    let translateY;
    let dragStarted = false;
    let nodesChild;
    let selectedNode = null
    let draggingNode = null;
    let domNode;

    const overCircle = d => {
      if (draggingNode && d.id !== draggingNode.id) {
        selectedNode = d;
        // console.log('over', d)
        updateTempConnector();
      }
    };
    const outCircle = d => {
      selectedNode = null;
      updateTempConnector();
    };

    // Function to update the temporary connector indicating dragging affiliation
    const updateTempConnector = () => {
      // let data2 = [];
      if (draggingNode !== null && selectedNode !== null) {
        $(`#node-button-${selectedNode.id}`).addClass('drop-node');
        $(`#node-button-${selectedNode.id}`).parent('foreignObject').addClass('droppable');
        // console.log('drop')
        // have to flip the source coordinates since we did this for the existing connectors on the original tree
        // data2 = [{
        //   source: {
        //     x: selectedNode.y0,
        //     y: selectedNode.x0
        //   },
        //   target: {
        //     x: draggingNode.y0,
        //     y: draggingNode.x0
        //   }
        // }];
      } else {
        $('.node button').removeClass('drop-node');
        $('.node foreignObject').removeClass('droppable');
      }
      // const link = vis.selectAll('.templink').data(data2);
      //
      // link.enter().append('path')
      //     .attr('class', 'templink')
      //     .attr('d', d3.svg.diagonal())
      //     .attr('pointer-events', 'none');
      //
      // link.attr('d', d3.svg.diagonal());
      //
      // link.exit().remove();
    };

    function dragstart(d) {
      if (!that.dragDropNode) {
        return;
      }
      if (d === root) {
        return;
      }
      dragStarted = true;
      nodesChild = tree.nodes(d);
      d3.event.sourceEvent.stopPropagation();
      d3.select(this).classed('fixed', d.fixed = true);
    }

    function pan(domNode1, direction) {
      const speed = panSpeed;
      if (panTimer) {
        clearTimeout(panTimer);
        translateCoords = d3.transform(vis.attr('transform'));
        if (direction === 'left' || direction === 'right') {
          translateX = direction === 'left' ? translateCoords.translate[0] + speed : translateCoords.translate[0] - speed;
          translateY = translateCoords.translate[1];
        } else if (direction === 'up' || direction === 'down') {
          translateX = translateCoords.translate[0];
          translateY = direction === 'up' ? translateCoords.translate[1] + speed : translateCoords.translate[1] - speed;
        }
        const scaleX = translateCoords.scale[0];
        const scaleY = translateCoords.scale[1];
        const scale = that.zoomListener.scale();
        vis.transition().attr('transform', 'translate(' + translateX + ',' + translateY + ')scale(' + scale + ')');
        d3.select(domNode1).select('g.node').attr('transform', 'translate(' + translateX + ',' + translateY + ')');
        // that.zoomListener.scale(that.zoomListener.scale());
        // that.zoomListener.translate([translateX, translateY]);
        // that.zoomListener.event(that.visRef);
        // panTimer = setTimeout(() => {
        //   // pan(domNode1,speed, direction);
        // }, 50);
      }
    }

    function drag(d) {
      if (!that.dragDropNode) {
        return;
      }
      if (d === root) {
        return;
      }
      // console.log('drag',d);
      if (!dragStarted) {
        d.x0 += d3.event.dy;
        d.y0 += d3.event.dx;
      }
      if (dragStarted) {
        initiateDrag(d, this);
      }


      // get coords of mouseEvent relative to svg container to allow for panning
      const relCoords = d3.mouse($('#tree-view svg').get(0));
      if (relCoords[0] < panBoundary) {
        panTimer = true;
        pan(this, 'left');
      } else if (relCoords[0] > ($('#tree-view svg').width() - panBoundary)) {
        panTimer = true;
        pan(this, 'right');
      } else if (relCoords[1] < panBoundary) {
        panTimer = true;
        pan(this, 'up');
      } else if (relCoords[1] > ($('#tree-view svg').height() - panBoundary)) {
        panTimer = true;
        pan(this, 'down');
      } else {
        try {
          clearTimeout(panTimer);
        } catch (e) {

        }
      }

      // console.log(d.x0, d.y0, d.x, d.y, d)

      const node1 = d3.select(this);
      node1.attr('transform', 'translate(' + d.y0 + ',' + d.x0 + ')');

    }

    function initiateDrag(d, domNode) {
      draggingNode = d;
      $(`#node-button-${d.id}`).addClass('drag-node');
      d3.select(domNode).select('.ghostCircle').attr('pointer-events', 'none');
      d3.selectAll('.ghostCircle').attr('class', 'ghostCircle show');
      d3.select(domNode).attr('class', 'node activeDrag');

      vis.selectAll('g.node').sort((a, b) => { // select the parent and sort the path's
        if (a.id !== draggingNode.id) return 1; // a is not the hovered element, send "a" to the back
        else return -1; // a is the hovered element, bring "a" to the front
      });
      // if nodes has children, remove the links and nodes
      if (nodesChild.length > 1) {
        // remove link paths
        const links = tree.links(nodesChild);
        const nodePaths = vis.selectAll('path.link')
            .data(links, d => d.target.id).remove();
        // remove child nodes
        const nodesExit = vis.selectAll('g.node')
            .data(nodesChild, d => d.id).filter((d, i) => {
              return d.id !== draggingNode.id;
            }).remove();
      }

      // remove parent link
      const parentLink = tree.links(tree.nodes(draggingNode.parent));
      vis.selectAll('path.link').filter((d, i) => {
        return d.target.id === draggingNode.id;

      }).remove();

      dragStarted = false;
    }

    function dragEnd(d) {
      clearTimeout(panTimer);
      if (!that.dragDropNode) {
        return;
      }
      if (d === root) {
        return;
      }
      domNode = this;
      // console.log(selectedNode, draggingNode)
      if (selectedNode) {
        if (!selectedNode.children) {
          selectedNode.children = [];
        }
        if (selectedNode.children.length < 4) {
          // now remove the element from the parent, and insert it into the new elements children
          const index = draggingNode.parent.children.indexOf(draggingNode);
          if (index > -1) {
            draggingNode.parent.children.splice(index, 1);
          }
          if (typeof selectedNode.children !== 'undefined' || typeof selectedNode._children !== 'undefined') {
            if (typeof selectedNode.children !== 'undefined') {
              selectedNode.children.push(draggingNode);
            } else {
              selectedNode._children.push(draggingNode);
            }
          } else {
            selectedNode.children = [];
            selectedNode.children.push(draggingNode);
          }
          // Make sure that the node being added to is expanded so user can see added node is correctly moved
          // expand(selectedNode);
          // sortTree();
          const object = {
            videoId: draggingNode._id,
            parentId: draggingNode.parentId,
            newParentId: selectedNode._id
          }
          that.saveDragDropNode(object);
        } else {
          endDrag();
        }
      } else {
        endDrag();
      }
    }

    function endDrag() {
      selectedNode = null;
      d3.selectAll('.ghostCircle').attr('class', 'ghostCircle');
      d3.select(domNode).attr('class', 'node');
      // now restore the mouseover event or we won't be able to drag a 2nd time
      d3.select(domNode).select('.ghostCircle').attr('pointer-events', '');
      if (draggingNode !== null) {
        update(root);
        draggingNode = null;
        $(`.node button`).removeClass('drag-node');
      }
    }

    // Toggle children
    // function toggle(d) {
    //   if (d.children) {
    //     d._children = d.children;
    //     d.children = null;
    //   } else {
    //     d.children = d._children;
    //     d._children = null;
    //   }
    // }

    // function zoomed() {
    //   this.visRef.attr("transform", d3.event.transform)
    // }

    // zoom in / out
    function zoom(d) {

      //   const nodes = vis.selectAll('g.node');
      //   nodes.attr('transform', transform);
      //   // vis.select('rect.overlay').attr('transform', 'translate(' + d3.event.translate + ')scale(' + d3.event.scale + ')');
      //   // // nodes.attr("-webkit-transform", transform);
      //   // // Update the links...
      //   const link = vis.selectAll('path.link');
      //   link
      //       .attr('d', translate)
      //       .attr('stroke-width', '1px')
      //       .attr('stroke', 'black');
      //   vis.attr('transform', 'scale(' + d3.event.scale + ')');
      // var velocity = 1;
      const scale = d3.event.scale;
      const eTransform = d3.transform(vis.attr('transform'));
      // console.log(translate)
      // console.log(eTransform.translate[0], d3.event.translate[0])
      const nTranslateX = (/*eTransform.translate[0] +*/ eTransform.translate[0] + d3.event.translate[0] * scale) / 2;
      const nTranslateY = (/*eTransform.translate[1] +*/ eTransform.translate[1] + d3.event.translate[1] * scale) / 2;
      that.visRef.attr('transform', 'translate(' + [nTranslateX, nTranslateY] + ') scale(' + d3.event.scale + ')');
      // that.visRef.attr('transform', 'translate(' + d3.event.translate + ') scale(' + d3.event.scale + ')');


      // console.log(d3.event)
      // setInterval(()=>{
      that.zoomRef = d3.event.scale;

      // const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
      // if (isSafari) {
      //   d3.selectAll('.node').style('transform', `scale(${scale})`);
      // }

      // },1000);

      // }

      // Enter any new links at hte parent's previous position
      // link.attr("d", function(d) {
      //      var o = {x: d.x0, y: d.y0};
      //      return diagonal({source: o, target: o});
      //    });
    }

    // $(".video-component iframe[src^='https://video-playback-public.s3.amazonaws.com'").html('');
    function transform(d) {
      return 'translate(' + x(d.y) + ',' + y(d.x) + ')';
    }

    function translate(d) {
      const sourceX = x(d.target.parent.y);
      const sourceY = y(d.target.parent.x);
      const targetX = x(d.target.y);
      const targetY = (sourceX + targetX) / 2;
      const linkTargetY = y(d.target.x0);
      const result =
          'M' +
          sourceX +
          ',' +
          sourceY +
          ' C' +
          targetX +
          ',' +
          sourceY +
          ' ' +
          targetY +
          ',' +
          y(d.target.x0) +
          ' ' +
          targetX +
          ',' +
          linkTargetY +
          '';
      //// console.log(result);

      return result;
    }

    function onEditNode(d) {
      const length = 9;
      const id = Math.random()
          .toString(36)
          .replace(/[^a-z]+/g, '')
          .substr(0, length);
      addChildNode(d.id, {
        name: 'new child node',
        id,
        type: 'type2',
      });
      stopPropogation();
    }

    function onAddNode(d) {
      const length = 9;
      const id = Math.random()
          .toString(36)
          .replace(/[^a-z]+/g, '')
          .substr(0, length);
      addChildNode(d.id, {
        name: 'new child node',
        id,
        type: 'type2',
      });
      stopPropogation();
    }

    function onRemoveNode(d) {
      const index = d.parent.children.indexOf(d);
      if (index > -1) {
        d.parent.children.splice(index, 1);
      }
      // update(d.parent);
      stopPropogation();
    }

    function addChildNode(parentId, newNode) {
      const node = d3.select('#' + 'node-' + parentId);
      const nodeData = node.datum();
      if (nodeData.children === undefined && nodeData._children === undefined) {
        nodeData.children = [newNode];
      } else if (nodeData._children != null) {
        nodeData._children.push(newNode);
        //  toggle(nodeData);
      } else if (nodeData.children != null) {
        nodeData.children.push(newNode);
      }
      update(node);
      stopPropogation();
    }

    function stopPropogation() {
      d3.event.stopPropagation();
    }

    this.createInteractiveVideoObj.selectedVideoCheck = false;
    this.createInteractiveVideoObj.interactiveStep = 'three';
    this.createInteractiveVideoObj.spinner = false;
  }

  saveDragDropNode(object, type?) {
    this.createInteractiveVideoObj.spinner = true;
    this.urlService.moveVideoNode(object).subscribe(() => {
      // this.orderedNodes.push(object);
      if (type === 'redo' && this.redoOrderedNodes.length !== 0) {
        this.undoOrderedNodes.push(object);
        this.redoOrderedNodes.pop();
      } else if (type === 'undo' && this.undoOrderedNodes.length !== 0) {
        this.redoOrderedNodes.push(object);
        this.undoOrderedNodes.pop();
      } else {
        this.undoOrderedNodes.push(object);
        this.redoOrderedNodes = [];
      }
      this.latestNodeId = object.newParentId;
      this.callChild1();
    }).add(() => {
      this.createInteractiveVideoObj.spinner = false;
    });
  }

  undoOrder(): void {
    if (this.undoOrderedNodes.length !== 0) {
      const object = this.undoOrderedNodes[this.undoOrderedNodes.length - 1];
      const newParentId = object.newParentId;
      object.newParentId = object.parentId;
      object.parentId = newParentId;
      this.saveDragDropNode(object, 'undo');
    }
  }

  redoOrder(): void {
    if (this.redoOrderedNodes.length !== 0) {
      const object = this.redoOrderedNodes[this.redoOrderedNodes.length - 1];
      const newParentId = object.newParentId;
      object.newParentId = object.parentId;
      object.parentId = newParentId;
      this.saveDragDropNode(object, 'redo');
    }
  }

  toggleDragDropNode(value) {
    this.dragDropNode = value;
    this.visRef.attr('class', this.dragDropNode ? 'rearrange' : '');
  }

  getLatestItem(nodes, id: string) {
    return nodes.filter(node => node._id === id);
  }

  zoomIn() {
    this.zoomRef = this.zoomRef + 0.3;
    this.zoomListener.scale(this.zoomRef);
    this.zoomListener.event(this.visRef);
    // this.visRef
    //     .transition()
    //     .duration(750)
    //     .attr('transform', 'scale(' + this.zoomRef + ')');
  }
  zoomOut() {
    this.zoomRef = this.zoomRef - 0.3;
    this.zoomListener.scale(this.zoomRef);
    this.zoomListener.event(this.visRef);
    // this.visRef
    //     .transition()
    //     .duration(750)
    //     .attr('transform', 'scale(' + this.zoomRef + ')');
  }
  zoomReset() {
    // this.visRef
    //     .transition()
    //     .duration(750)
    //     .attr('transform', 'scale(' + 1 + ')');
    this.zoomRef = 1;
    this.visRef.attr('transform', 'translate(' + this.rootInitialPosition + ') scale(1)');
    this.zoomListener.scale(this.zoomRef);
    this.zoomListener.translate(this.rootInitialPosition);
    this.zoomListener.event(this.visRef);
  }

  highlightUpdatedNode(id: string) {
    const latestNodeItem = document.getElementById(`node-button-${id}`);
    latestNodeItem.style.background = '#d3c1eb';
  }

  handleActiveWatchPath(activePath?) {
    $(`.video-component`).removeClass('active');
    $(`#tree-view`).removeClass('active');
    if (activePath && activePath?.videos?.length) {
      for (const activePathElement of activePath.videos || []) {
        // console.log(activePathElement)
        $(`.video-component[data-id=${activePathElement._id}]`).addClass('active');
      }
      $(`#tree-view`).addClass('active');
      return;
    }
    // if (this.editWatchPath) {
    //   this.handleActiveWatchPath(this.editWatchPath);
    // }
  }

  sendMessage(msg?: string) {
    if (msg) {
      this.msgValue = msg;
    }

    this.msgValue = this.msgValue.trim();
    if (this.msgValue.toUpperCase() === this.messageMap.reChooseEndNode.toUpperCase()) {
      this.deSelectWatchPath(false);
      this.currentMessageStep = 2;
    } else if (this.msgValue.toUpperCase() === this.messageMap.restart.toUpperCase()) {
      this.deSelectWatchPath(true);
      this.deSelectWatchPath(false);
      this.currentMessageStep = 1;
    }
    if (this.currentMessageStep === 5 && this.msgValue.toUpperCase() === this.messageMap.save.toUpperCase()) {
      this.createWatchPath();
      this.msgValue = '';
      return;
    }
    if ((this.currentMessageStep === 0 || this.currentMessageStep === 5) && this.msgValue.toUpperCase() === this.messageMap.rename.toUpperCase()) {
      this.watchPathName = '';
    }
    if (this.currentMessageStep === 4) {
      this.watchPathName = this.msgValue;
      this.isInitialStep = false;
    }
    if (this.msgValue) {
      this.getBotAnswer(this.msgValue);
    }
    this.msgValue = '';
  }

  addMessage = (author, content, type, url?, description?) => {
    this.messages.push({author, content, type, url, description});
    $('#messagesCard').animate({scrollTop: $('#messagesCard .messages').height()}, 500);
  }

  getBotAnswer(userMessage: string, type = 'text', url = '', description = '') {

    if (userMessage) {
      this.addMessage('user', userMessage, type, url, description)
      // this.messages.push({author: 'user', content: userMessage, type});
    }
    this.isMessageLoad = true;
    setTimeout(() => {
      let botMessage = '';
      if (!this.messages.length) {
        botMessage = 'Hello!';
        // this.messages.push({author: 'bot', content: botMessage, type: 'text'});
        this.addMessage('bot', botMessage, 'text')
        this.getBotAnswer('');
        return;
      } else if (!this.startWatchNode) {
        this.currentMessageStep = 1;
        botMessage = 'Please choose a starting node from the tree map';
      } else if (!this.endWatchNode) {
        this.currentMessageStep = 2;
        botMessage = 'Please choose an ending node from the tree map';
      }
      if (this.startWatchNode && this.endWatchNode) {
        this.currentMessageStep = 3;
        const node = this.getParentNode(this.createInteractiveVideoObj.finalObjTree, this.endWatchNode._id);
        if (!node) {
          botMessage = 'The end node is not a child of the start node.';
        } else if (!node.includes(this.startWatchNode._id)) {
          botMessage = 'The end node is not a child of the start node.';
        } else if (this.startWatchNode._id === this.endWatchNode._id) {
          botMessage = 'The end node and the start node must be unique.';
        } else if (!this.watchPathName) {
          this.currentMessageStep = 4;
          botMessage = 'Please enter a name of the pathways';
        } else {

          // console.log('this.isInitialStep = ', this.isInitialStep);

          if (this.isInitialStep) {
            this.currentMessageStep = 0;
            this.isInitialStep = false;
          } else
            this.currentMessageStep = 5;
          botMessage = 'What would you like to do next?'
        }
      }

      this.isMessageLoad = false;
      // this.messages.push({author: 'bot', content: botMessage, type: 'text'});
      this.addMessage('bot', botMessage, 'text')
    }, 1000);
  }

  openAddWatchPath() {
    if (this.isMessageLoad) {
      return;
    }
    this.toggleDragDropNode(false);
    this.cancelAddWatchPath();
    this.isAddWatchPath = true;
    this.getBotAnswer('');
  }

  onEditWatchPath(i) {
    this.isInitialStep = true;

    if (this.isMessageLoad) {
      return;
    }
    this.toggleDragDropNode(false);
    this.cancelAddWatchPath();
    this.isAddWatchPath = true;
    this.editWatchPath = i;
    this.startWatchNode = i.startNode;
    this.endWatchNode = i.endNode;
    this.watchPathName = i.pathName;
    this.updateCurrentHighlight();
    this.addMessage('bot', 'Pathway name: ' + this.watchPathName, 'text');
    this.addMessage('bot', 'Starting node', 'image', this.startWatchNode.poster,this.startWatchNode.name);
    this.addMessage('bot', 'Ending node', 'image', this.endWatchNode.poster,this.endWatchNode.name);
    this.getBotAnswer('');
    // this.handleActiveWatchPath(i);
  }

  cancelAddWatchPath() {
    this.msgValue = '';
    this.messages = [];
    this.currentMessageStep = 0;
    this.editWatchPath = null;
    this.startWatchNode = null;
    this.endWatchNode = null;
    this.isAddWatchPath = false;
    this.isSubmitPathWaysForm = false;
    this.submitPathWaysFormError = '';
    this.watchPathName = '';
    this.handleActiveWatchPath('');
    $('.video-component').removeClass('nodeSelected');
  }

  getParentNode = (arr, id) => {
    for (const item of arr) {
      if (item._id === id) {
        return [item._id];
      } else if (item.children && item.children.length) {
        const t = this.getParentNode(item.children, id);
        if (t !== false) {
          t.push(item._id);
          return t;
        }
      }
    }
    return false;
  }

  createWatchPath() {
    // if (!this.startWatchNode) {
    //   this.toastr.error('please select start node');
    //   return;
    // }
    // if (!this.endWatchNode) {
    //   this.toastr.error('please select end node');
    //   return;
    // }
    // this.submitPathWaysFormError = '';
    this.isSubmitPathWaysForm = true;
    // if (!this.watchPathName.trim()) {
    //   this.submitPathWaysFormError = 'Path name is required.';
    //   return
    // }
    // const node = this.getParentNode(this.createInteractiveVideoObj.finalObjTree, this.endWatchNode._id);
    // console.log(node, this.createInteractiveVideoObj.finalObjTree)
    // if (!node) {
    //   this.submitPathWaysFormError = 'The end node is not a child of the start node.';
    //   return;
    // }
    // if (!node.includes(this.startWatchNode._id)) {
    //   this.submitPathWaysFormError = 'The end node is not a child of the start node.';
    //   return;
    // }
    // if (this.startWatchNode._id === this.endWatchNode._id) {
    //   this.submitPathWaysFormError = 'The end node and the start node must be unique.';
    //   return;
    // }
    const payload: any = {
      videoId: this.mainID,
      pathName: this.watchPathName,
      startNodeId: this.startWatchNode._id,
      startNodeType: this.startWatchNode.type,
      endNodeId: this.endWatchNode._id,
      endNodeType: this.endWatchNode.type,
      pathNodes: this.pathNodes.map(x => x._id)
    };
    if (this.editWatchPath) {
      payload._id = this.editWatchPath._id;
    }
    // console.log(payload)
    this.spinner = true
    this.urlService.setPathway(payload, this.editWatchPath).subscribe((res) => {
      this.cancelAddWatchPath();
      this.getVideoPathWays();
      this.toastr.success(`Pathways ${this.editWatchPath ? 'updated' : 'created'} successfully`);
    }, (err) => {
      this.isSubmitPathWaysForm = false;
      this.watchPathName = '';
      this.addMessage('bot','The name of the pathway already exists! select a different name','text');
      this.getBotAnswer('')
    }).add(() => this.spinner = false);
  }

  deleteWatchPath(i) {
    this.deleteDialogRef.open({
      header: 'Delete path',
      title: `Are you sure? Do you want to delete "${i.pathName}" path?`,
      trueButton: 'Delete'
    }, (e) => {
      if (e) {
        this.spinner = true;
        this.urlService.deletePathway(i._id).subscribe((res) => {
          this.toastr.success(res.message)
          this.getVideoPathWays();
        }).add(() => this.spinner = false);
      }
    });
  }

  selectWatchPath(d) {
    if ((this.currentMessageStep === 1 || this.currentMessageStep === 2) && !this.isMessageLoad) {
      if (!this.startWatchNode) {
        this.startWatchNode = d;
        //console.log(d)
        this.getBotAnswer('Starting node', 'image', d.poster, d.name);
      } else {
        this.getBotAnswer('Ending node', 'image', d.poster, d.name);
        this.endWatchNode = d;
      }
      this.updateCurrentHighlight();
    }
  }

  deSelectWatchPath(start) {
    if (start) {
      this.startWatchNode = null;
    } else {
      this.endWatchNode = null;
    }
    this.updateCurrentHighlight();
  }

  updateCurrentHighlight() {
    this.submitPathWaysFormError = '';
    $('.video-component').removeClass('nodeSelected');
    this.pathNodes = this.utilityService.parentNode(this.createInteractiveVideoObj.finalObjTree, this.endWatchNode?._id);
    if (this.pathNodes) {
      const startFind = this.pathNodes.find(x => x._id === this.startWatchNode?._id);
      if (startFind) {
        for (const video of this.pathNodes) {
          $(`.video-component[data-id=${video._id}]`).addClass('nodeSelected');
          if (this.startWatchNode._id === video._id) {
            break;
          }
        }
      }
    }
    if (this.startWatchNode) {
      $(`.video-component[data-id=${this.startWatchNode._id}]`).addClass('nodeSelected');
    }
    if (this.endWatchNode) {
      $(`.video-component[data-id=${this.endWatchNode._id}]`).addClass('nodeSelected');
    }
    //console.log(this.pathNodes)
  }

  reorderChoices(s) {
    this.isReorderChoice = s;
  }

  saveReorderChoices() {
    const object = {
      videoId: this.mainID,
      parentId: this.createChoiceObj.child[0].parentId,
      childrenOrder: this.createChoiceObj.child.map(x => x._id)
    }
    // console.log(object)
    this.createInteractiveVideoObj.spinner = true;
    this.urlService.reorderNodeChildren(object).subscribe(res => {
      this.callChild1();
      this.reorderChoices(false);
      this.createInteractiveVideoObj.spinner = false;
    });
  }

  clickedItem(d) {
    if (this.isAddWatchPath) {
      return this.selectWatchPath(d);
    }
    if (this.isAssistCreateVideo) {
      this.assistEventsSubject.next({
        type: this.msgEvent.nodeSelected,
        data: d
      });
      return;
    }
    this.isReorderChoice = false;
    const str = d.name as string;
    this.videoName = str.split('20')[0];
    this.createChoiceObj.child = [];
    this.createInteractiveVideoObj.currentObj = {};
    // this.currentVideoData = d;
    let isCheckUrl = false;
    if (d.URL) {
      isCheckUrl = this.utilityService.checkIsUrl(d.URL);
    }
    d.isUrl = !isCheckUrl;
    // console.log('Clicked', d);
    setTimeout(() => {
      this.value = 0;
      this.highValue = d.duration;
      this.options = {
        floor: 0,
        ceil: d.duration,
      };
      // console.log('clicked duration is', d.duration);
    }, 2000);

    const name = d.name.split('.')[0];
    // this.checkEditabilit10y(name)
    if (d.URL) {
      if (d.URL.includes('.mp4')) {
        this.currentType = 'video/mp4';
        this.isProjectCreated = true;
      } else {
        this.isProjectCreated = false;
        this.currentType = 'application/x-mpegURL';
      }
    }
    if (d.openShotFileUrl != null) {
      setTimeout(() => {
        this.isAllEditingTool = true;
      }, 2000);
    } else {
      setTimeout(() => {
        this.isAllEditingTool = false;
      }, 2000);
    }
    const s = parseInt(d.duration);
    // console.log('ssssssssss', s);
    const videoTitleElement = document.getElementById('videoName');
    videoTitleElement.style.fontSize = '1.5rem';
    this.durations = s.toFixed(0);
    this.createInteractiveVideoObj.currentObj = d;
    this.createInteractiveVideoObj.currentObj.open = true;
    for (const i of this.createInteractiveVideoObj.finalObj) {
      if (d._id === i.parentId) {
        i.URLName = i.name;
        this.name = i.name;
        this.createChoiceObj.child.push(i);
      }
    }
    // console.log(d, this.createInteractiveVideoObj.currentObj)
    if (d.name === 'Add Video' && !d.videoID && !this.isStoryBoard) {
      this.replaceVideo();
    } else {
      const minutes = Math.floor(
          this.createInteractiveVideoObj.currentObj.time / 60
      );
      const seconds = Math.floor(
          this.createInteractiveVideoObj.currentObj.time - minutes * 60
      )
      let m: number | string;
      let s: number | string;
      if (minutes > 10) {
        m = minutes;
      } else {
        m = `0${minutes}`;
      }
      if (seconds > 10) {
        s = seconds;
      } else {
        s = `0${seconds}`;
      }
      this.createInteractiveVideoObj.currentObj.timeValue = `000:${m}:${s}`;
      this.currentVideoData = this.createInteractiveVideoObj.currentObj;
      $('#tree-Modal').modal('show');
    }
  }

  openPreviewUrl(url: string) {
    if (url) {
      window.open(url, '_blank');
    } else {
      this.replaceVideo();
    }
  }

  UptheMouse() {
    if (this.createChoiceObj.currentNo > 4) {
      this.createChoiceObj.currentNo = 4;
    } else if (this.createChoiceObj.currentNo < 1) {
      this.createChoiceObj.currentNo = 1;
    }
  }

  selectVideoTypes(type) {
    this.createInteractiveVideoObj.selectedType = type;
    this.createInteractiveVideoObj.interactiveStep = 'three';
  }

  // WC 03102022
  changeFilterUploadVideo(val) {
    this.createInteractiveVideoObj.allUploadedVideo = [];
    this.page = 1;
    this.sort = val;

    // WC 03312021 --- No search criteria
    if (this.createInteractiveVideoObj.query == '') {
      this.urlService
          .getUploadedVideoV2(
              this.limit,
              this.page,
              this.sort,
              this.aiEditorVideo,
              'video'
          )
          .subscribe((success) => {
            this.createInteractiveVideoObj.allVideos = success.data;

            // console.log("changeFilterUploadVideo - this.createInteractiveVideoObj.allVideos: ", this.createInteractiveVideoObj.allVideos);

            for (const i of this.createInteractiveVideoObj.allVideos) {
              i.URLName = i.originalName;
              if (!i.originalName) {
                i.URLName = i.name;
              }

              // WC 03102022 to handle undefined URLName from mobile app
              if (i.URLName === undefined) {
                i.URLName = '';
                // console.log("changeFilterUploadVideo - i.URLName = ", i.URLName)
              }

              this.createInteractiveVideoObj.allUploadedVideo.push(i);
            }
          });
    } else {
      // With search criteria

      this.urlService
          .searchVideoListByName(
              this.createInteractiveVideoObj.query,
              this.limit,
              this.page,
              this.sort,
              this.aiEditorVideo
          )
          .subscribe((success) => {
            this.createInteractiveVideoObj.allVideos = success.data;

            // console.log("changeFilterUploadVideo - this.createInteractiveVideoObj.allVideos: ", this.createInteractiveVideoObj.allVideos);

            for (const i of this.createInteractiveVideoObj.allVideos) {
              i.URLName = i.originalName;
              if (!i.originalName) {
                i.URLName = i.name;
              }

              // WC 03102022 to handle undefined URLName from mobile app
              if (i.URLName === undefined) {
                i.URLName = '';
                // console.log("changeFilterUploadVideo - i.URLName = ", i.URLName)
              }

              this.createInteractiveVideoObj.allUploadedVideo.push(i);
            }
          });
    }
  }

  clickSelectVideo() {
    if (!this.isAssistCreateVideo) {
      this.isOpenCreateVideo = true;
      this.openVideoCreateModel('freeboard');
      this.createInteractiveVideoObj.selectedVideoCheck = false;
      this.createInteractiveVideoObj.interactiveStep = 'three';
    }
  }

  openVideoCreateModel(type: string) {
    const dialogRef = this.dialog.open(CreateVideoComponent, {
      width: '95%',
      maxWidth: '1300px',
      panelClass: 'my-dialog',
      height: 'auto',
      minHeight: '500px',
      data: {},
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        //console.log(result)
        this.createInteractiveVideoObj.selectedItem = result;
        if (type === 'videoTemplate') {
          this.replaceVideoData();
        } else {
          this.createInteractiveVideo();
        }
      } else {
        if (this.isAssistCreateVideo) {
          this.assistEventsSubject.next({type: this.msgEvent.addFirstNode});
        }
      }
    });
  }

  uploadAVideo() {
    this.router.navigate(['/upload-videos']);
  }

  createInteractiveVideo() {
    // console.log("createInteractiveVideo - this.createInteractiveVideoObj.selectedItem.subtitleUrl = ", this.createInteractiveVideoObj.selectedItem.subtitleUrl);

    if (typeof this.createInteractiveVideoObj.selectedItem !== 'object') {
      this.toastr.warning('Please select an initial video!');
      return;
    }
    this.createInteractiveVideoObj.spinner = true;
    if (this.buttonType === 'isInteractive' || this.isStoryBoard) {
      const obj: any = {
        type: 'main',
        poster: this.createInteractiveVideoObj.selectedItem.poster ?
            this.createInteractiveVideoObj.selectedItem.poster : this.createInteractiveVideoObj.selectedItem.thumbnail,
        name: this.createInteractiveVideoObj.selectedItem.name,
        URL: this.createInteractiveVideoObj.selectedItem.URL,
        Thumbnail: this.createInteractiveVideoObj.selectedItem.Thumbnail,
        Title: '',
        Description: '',
        // WC 07272022 - Add Tagging
        Tag: '',
        // WC 07272022
        Categories: [],
        time: 0,
        parentId: null,
        videoID: this.createInteractiveVideoObj.selectedItem._id,
        // WC 08092022
        subtitleUrl: this.createInteractiveVideoObj.selectedItem.subtitleUrl,
        // WC 08092022
      };
      if (this.createInteractiveVideoObj.selectedItem.originalName) {
        obj.name = this.createInteractiveVideoObj.selectedItem.originalName;
      }
      // Use original name if it exist in creating interating video
      this.urlService.createInteractiveVideo(obj).subscribe((success) => {
        this.closeModal();
        this.isOpenCreateVideo = false;
        $('#upload-vid-Modal').modal('hide');
        this.createInteractiveVideoObj.spinner = false;
        this.createInteractiveVideoObj.selectedItem = {};
        this.createInteractiveVideoObj.selectedVideoCheck = false;
        this.router.navigate(['create-interactive-video'], {
          queryParams: {video: success.data.data._id},
        });
        this.localStorageService.removeItem('freeTemp');
        this.intialFunction('');
        // sync user data
        this.dashboardService.onGetUserData();
        if (this.isAssistCreateVideo) {
          this.assistEventsSubject.next({type: this.msgEvent.addFirstNode, data: success.data.data});
        }
      });
      this.createInteractiveVideoObj.step = 'three';
    } else {
      // console.log('$$$In createInteractiveVideo - parsing AI Editor URL = ', this.createInteractiveVideoObj.selectedItem.URL);

      const aiEditorVideoUrl = this.createInteractiveVideoObj.selectedItem.URL.split('?')[0];
      // console.log("aiEidtorVideoUrl = ", aiEditorVideoUrl);

      this.parseAiEditorVideoV2(aiEditorVideoUrl);
      this.createInteractiveVideoObj.selectedItem = {};
      this.createInteractiveVideoObj.selectedVideoCheck = false;
      this.intialFunction('');
    }
  }

  createEmptyNodes(obj?: any) {
    this.createInteractiveVideoObj.spinner = true;
    if (!obj) {
      obj = [{
        level: 0,
        parentId: null,
        name: 'Parent Node'
      }];
    }
    this.urlService.createEmptyNodes(obj).subscribe((success) => {
      this.closeModal();
      this.isOpenCreateVideo = false;
      $('#upload-vid-Modal').modal('hide');
      this.createInteractiveVideoObj.spinner = false;
      this.createInteractiveVideoObj.selectedItem = {};
      this.createInteractiveVideoObj.selectedVideoCheck = false;
      this.router.navigate(['create-interactive-video'], {
        queryParams: {video: success.data._id},
      });
      this.localStorageService.removeItem('freeTemp');
      // this.intialFunction('');
      this.refreshInteractiveVideo();
    });
    this.createInteractiveVideoObj.step = 'three';
  }

  openDeleteConfirmation() {
    $('#deleteCurrentNodeModal').modal('show');
  }

  deleteCurrentNode() {
    if (this.deleteCurrentNodeType) {
      // this.createInteractiveVideoObj.spinner = true;
      if (this.createInteractiveVideoObj.currentObj.type === 'main') {
        this.urlService
            .deleteBookMarkVideo(this.createInteractiveVideoObj.currentObj._id)
            .subscribe((success) => {
              this.createInteractiveVideoObj.spinner = false;
              this.toastr.success('Delete successfully!');
              $('#tree-Modal').modal('hide');
              $('#deleteCurrentNodeModal').modal('hide');
              this.createChoiceObj.child = [];
              this.callChild1();
              this.deleteCurrentNodeType = 1;
            });
      } else {
        const Payload = {
          videoId: this.createInteractiveVideoObj.currentObj._id,
          mainId: this.createInteractiveVideoObj.currentObj.parentId,
          isDeleteSingleNode: this.deleteCurrentNodeType === 1
        };

        this.urlService.deleteChildVideo(Payload).subscribe((success) => {
          // this.createInteractiveVideoObj.spinner = false;
          $('#tree-Modal').modal('hide');
          $('#deleteCurrentNodeModal').modal('hide');
          this.createChoiceObj.child = [];
          this.latestNodeId = this.createInteractiveVideoObj.currentObj.parentId;
          this.callChild1();

          if (!success.error)
            this.toastr.info('Updated Successfully!');
          else
            this.toastr.error(success.message);

          this.deleteCurrentNodeType = 1;
        });
      }
    }
  }

  addChoices(no) {
    for (let i = 0; i < no; i++) {
      const name = 'Untitled ' + i;
      const obj: any = {
        name,
        URL: '',
        _id: '',
        parentId: this.createInteractiveVideoObj.currentObj._id,
      };
      this.createChoiceObj.child.push(obj);
    }
    this.createChoiceObj.parent = this.createInteractiveVideoObj.currentObj;
  }

  updateVideoTitle(name: string) {
    const title = document.getElementById('videoName'); // 39
    let initialSize = 24;
    if (name.length > 39) {
      const size = name.length - 39;
      initialSize = 24 - size;
    }
    if (initialSize > 15) {
      title.style.fontSize = initialSize + 'px';
    } else {
      title.style.fontSize = '15px';
    }
    if (name.length > 2) {
      this.titleChange$.next(name);
    }
    // else {
    //   this.toastr.warning('Minimum 3 letter are allowed!');
    // }
  }

  updateCurrentName(name: any) {
    this.createInteractiveVideoObj.spinner = true;
    this.createInteractiveVideoObj.currentObj.name = name;
    if (this.createInteractiveVideoObj.currentObj.type === 'main') {
      this.createInteractiveVideoObj.mainVideo.name = name;
    }
    const obj: any = {
      id: this.createInteractiveVideoObj.currentObj._id,
      type: this.createInteractiveVideoObj.currentObj.type,
      name,
      URL: this.createInteractiveVideoObj.currentObj.URL,
      Title: this.createInteractiveVideoObj.currentObj.Title,
      Description: this.createInteractiveVideoObj.currentObj.Description,
      // WC 07272022 - Add Tag
      Tag: this.createInteractiveVideoObj.currentObj.Tag,
      // WC 07272022
      Categories: this.createInteractiveVideoObj.currentObj.Categories,
      time: this.createInteractiveVideoObj.currentObj.time,
      parentId: this.createInteractiveVideoObj.currentObj.parentId,
      poster: this.createInteractiveVideoObj.currentObj.poster,
      videoID: this.createInteractiveVideoObj.currentObj.videoID,
      // WC 08092022 - subtitleUrl
      subtitleUrl: this.createInteractiveVideoObj.currentObj.subtitleUrl,
      // WC 08092022
    };

    this.urlService.updateInteractiveVideo(obj).subscribe(
        (success) => {
          this.latestNodeId = this.createInteractiveVideoObj.currentObj._id;
          this.callChild1();
          this.toastr.info('Updated Successfully!');
          if (this.isAssistCreateVideo) {
            this.refreshInteractiveVideo();
            // this.assistEventsSubject.next({type: 'editFirstNodeName', data: success.data});
          }
        },
        (error) => {
          this.createInteractiveVideoObj.spinner = false;
        }
    );
  }

  addMoreChoices() {
    if (this.createChoiceObj.child.length > 3) {
      this.toastr.warning('Choices can not be more than 4!');
      return;
    }
    const i = this.createChoiceObj.child.length - 1;
    const name = 'Untitled ' + i;
    const obj: any = {
      name,
      URL: '',
      _id: '',
      parentId: this.createInteractiveVideoObj.currentObj._id,
    };
    this.createChoiceObj.child.push(obj);
  }

  // openvideoListModal is used to select a video during ADDING the choices by open video-list.component modal
  openVideoListModal(index: number, item?: any) {
    if (item?.URL) {
      return;
    }

    if (item?._id) {
      this.createInteractiveVideoObj.currentNodeOption = item;
    }

    // const dialogRef = this.dialog.open(VideoListComponent, {
    const dialogRef = this.dialog.open(CreateVideoComponent, {
      data: {
        treeNodes: this.createInteractiveVideoObj.endingNodes,
        connectorNodes: this.createInteractiveVideoObj.connectorNodes
      },
      minWidth: '50vw'
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.selectionChanged(result);
        this.createChoiceObj.child[index] = result;
        this.createChoiceObj.child[index].poster = result.poster ? result.poster : result.thumbnail;
      }
    });
  }

  // add child
  selectionChanged(e: any) {
    this.createInteractiveVideoObj.spinner = true;
    if (!this.createInteractiveVideoObj.currentNodeOption) {
      const objFinal: any = {
        type: 'child',
        name: e.name,
        parentId: this.createInteractiveVideoObj.currentObj._id,
        URL: e.URL,
        time: 0,
        poster: e.poster ? e.poster : e.thumbnail,
        videoID: e._id,
        // WC 08092022
        subtitleUrl: e.subtitleUrl,
        // WC 08092022
      };
      if (e?.isEndingNode) {
        objFinal.videoID = e.videoID;
        objFinal.endingNodeId = e._id;
      }
      this.urlService.createInteractiveVideo(objFinal).subscribe(
          (success) => {
            this.latestNodeId = this.createInteractiveVideoObj.currentObj._id;
            this.callChild1();
            this.toastr.info('Created Successfully!');
            if (this.isAssistCreateVideo) {
              this.assistEventsSubject.next({
                type: this.msgEvent.addChildNode,
                data: objFinal
              });
            }
          },
          (error) => {
            this.createInteractiveVideoObj.spinner = false;
          }
      );
    } else {

      // REPLACING A VIDEO
      let subtitleUrl = '';
      if (this.createInteractiveVideoObj.selectedItem.subtitleUrl != undefined)
        subtitleUrl = e.subtitleUrl;
      const objFinal = {
        id: this.createInteractiveVideoObj.currentNodeOption._id,
        type: this.createInteractiveVideoObj.currentNodeOption.type,
        name: this.createInteractiveVideoObj.currentNodeOption.name,
        URL: e.URL,
        poster: e.poster ? e.poster : e.thumbnail,
        Title: this.createInteractiveVideoObj.currentNodeOption.Title,
        Description: this.createInteractiveVideoObj.currentNodeOption.Description,
        // WC 07272022
        Tag: this.createInteractiveVideoObj.currentNodeOption.Tag,
        // WC 07272022
        Categories: this.createInteractiveVideoObj.currentNodeOption.Categories,
        time: this.createInteractiveVideoObj.currentNodeOption.time,
        parentId: this.createInteractiveVideoObj.currentNodeOption.parentId,
        videoID: e._id,
        // WC 08092022
        subtitleUrl,
        // WC 08092022
      };
      this.urlService.updateInteractiveVideo(objFinal).subscribe(
          (success) => {
            this.createInteractiveVideoObj.spinner = false;
            this.createInteractiveVideoObj.currentNodeOption = null;
            this.latestNodeId = this.createInteractiveVideoObj.currentObj._id;
            this.callChild1();
            this.toastr.info('Updated Successfully!');
          },
          (error) => {
            this.createInteractiveVideoObj.spinner = false;
          }
      );
    }
  }

  durationChange(duration?: string) {
    const time = duration.split(':');
    const currentTime =
        Number(time[2]) + Number(time[1]) * 60 + Number(time[0]);
    if (currentTime > this.createInteractiveVideoObj.currentObj.duration) {
      this.toastr.error('Time should be less then duration!');
      return;
    } else {
      this.duationChange$.next(currentTime);
    }
  }

  updateCurrentTime(duration: any) {
    // console.log("In updateCurrentTime - this.createInteractiveVideoObj.currentObj.subtitleUrl = ", this.createInteractiveVideoObj.currentObj.subtitleUrl);

    this.createInteractiveVideoObj.currentObj.time = duration;
    const obj: any = {
      id: this.createInteractiveVideoObj.currentObj._id,
      type: this.createInteractiveVideoObj.currentObj.type,
      poster: this.createInteractiveVideoObj.currentObj.poster,
      name: this.createInteractiveVideoObj.currentObj.name,
      URL: this.createInteractiveVideoObj.currentObj.URL,
      Title: this.createInteractiveVideoObj.currentObj.Title,
      Description: this.createInteractiveVideoObj.currentObj.Description,
      // WC 07272022
      Tag: this.createInteractiveVideoObj.currentObj.Tag,
      // WC 07272022
      Categories: this.createInteractiveVideoObj.currentObj.Categories,
      time: this.createInteractiveVideoObj.currentObj.time,
      parentId: this.createInteractiveVideoObj.currentObj.parentId,
      videoID: this.createInteractiveVideoObj.currentObj.videoID,
      // WC 08092022
      subtitleUrl: this.createInteractiveVideoObj.currentObj.subtitleUrl,
      // WC 08092022
    };
    this.createInteractiveVideoObj.spinner = true;
    this.urlService.updateInteractiveVideo(obj).subscribe(
        (success) => {
          this.callChild1();
          this.toastr.info('Updated Successfully!');
        },
        (error) => {
          this.createInteractiveVideoObj.spinner = false;
          this.toastr.error('Update Failed!');
        }
    );
  }

  refreshInteractiveVideo() {
    $('#tree-Modal').modal('hide');
    this.createChoiceObj.child = [];
    this.createInteractiveVideoObj.currentObj.open = false;
    this.intialFunction('');
  }

  closeInteractiveVideo() {
    $('#tree-Modal').modal('hide');
    this.createChoiceObj.child = [];
    this.createInteractiveVideoObj.currentObj = {};
  }

  saveStoryBoardChildNodes() {
    const nodes = [];
    let i = 0;
    for (const childElement of this.createChoiceObj.child) {
      ++i;
      if (childElement._id === '') {
        nodes.push({
          level: this.createInteractiveVideoObj.currentObj.level + 1,
          parentId: this.createInteractiveVideoObj.currentObj._id,
          mainId: this.mainID,
          name: 'Child ' + i
        })
      }
    }
    if (nodes.length) {
      this.createEmptyNodes(nodes);
    } else {
      this.refreshInteractiveVideo();
    }
  }

  getDuration(e) {
    this.createInteractiveVideoObj.currentObj.duration = e.target.duration;
    let hasChilren = false;
    if (
        this.createInteractiveVideoObj.currentObj.children == null ||
        this.createInteractiveVideoObj.currentObj.children == undefined
    ) {
      hasChilren = false;
    } else {
      hasChilren = this.createInteractiveVideoObj.currentObj.children.length > 0;
    }
    this.placeMarker(
        hasChilren,
        this.createInteractiveVideoObj.currentObj.time,
        'video-all',
        e.target.duration
    );
  }

  replaceVideo() {
    $('#tree-Modal').modal('hide');
    // $("#select-vid-Modal").modal(
    //   { backdrop: "static", keyboard: false },
    //   "show"
    // );
    this.openVideoCreateModel('videoTemplate');
    this.createInteractiveVideoObj.currentObj.open = false;
    this.OpenPopupAll = true;
  }

  editVideo() {
    $('#tree-Modal').modal('hide');
    this.currentVideoData.isEdit = true;
    const dialogRef = this.dialog.open(EditVideoComponent, {
      disableClose: true,
      width: '100%',
      height: '80%',
      maxWidth: '80%',
      panelClass: 'my-dialog',
      data: {
        video: this.createInteractiveVideoObj.currentObj,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.refreshInteractiveVideo();
      } else {
        this.clickedItem(this.createInteractiveVideoObj.currentObj);
      }
    })
  }

  checkedReaplceItem(item) {
    this.createInteractiveVideoObj.selectedItem = item;

    // console.log("*** this.createInteractiveVideoObj.selectedItem FOR REPLACING = ", this.createInteractiveVideoObj.selectedItem);
  }

  replaceVideoData() {
    let obj: any = {};
    if (this.createInteractiveVideoObj.currentObj.name === 'Add Video') {
      obj = {
        // ADDING A VIDEO
        id: this.createInteractiveVideoObj.currentObj._id,
        type: this.createInteractiveVideoObj.currentObj.type,
        name: this.createInteractiveVideoObj.selectedItem.name,
        URL: this.createInteractiveVideoObj.selectedItem.URL,
        poster: this.createInteractiveVideoObj.selectedItem.poster ?
            this.createInteractiveVideoObj.selectedItem.poster : this.createInteractiveVideoObj.selectedItem.thumbnail,
        Title: this.createInteractiveVideoObj.selectedItem.Title,
        Description: this.createInteractiveVideoObj.currentObj.Description,
        // WC 07272022
        Tag: this.createInteractiveVideoObj.currentObj.Tag,
        // WC 07272022
        Categories: this.createInteractiveVideoObj.currentObj.Categories,
        time: this.createInteractiveVideoObj.currentObj.time,
        parentId: this.createInteractiveVideoObj.currentObj.parentId,
        videoID: this.createInteractiveVideoObj.currentObj.videoID,
        // WC 08092022
        subtitleUrl: this.createInteractiveVideoObj.selectedItem.subtitleUrl,
        // WC 08092022
      };
    } else {
      // REPLACING A VIDEO
      let subtitleUrl = '';
      if (this.createInteractiveVideoObj.selectedItem.subtitleUrl != undefined)
        subtitleUrl = this.createInteractiveVideoObj.selectedItem.subtitleUrl;
      obj = {
        id: this.createInteractiveVideoObj.currentObj._id,
        type: this.createInteractiveVideoObj.currentObj.type,
        name: this.createInteractiveVideoObj.currentObj.name,
        URL: this.createInteractiveVideoObj.selectedItem.URL,
        poster: this.createInteractiveVideoObj.selectedItem.poster ?
            this.createInteractiveVideoObj.selectedItem.poster : this.createInteractiveVideoObj.selectedItem.thumbnail,
        Title: this.createInteractiveVideoObj.currentObj.Title,
        Description: this.createInteractiveVideoObj.currentObj.Description,
        // WC 07272022
        Tag: this.createInteractiveVideoObj.currentObj.Tag,
        // WC 07272022
        Categories: this.createInteractiveVideoObj.currentObj.Categories,
        time: this.createInteractiveVideoObj.currentObj.time,
        parentId: this.createInteractiveVideoObj.currentObj.parentId,
        videoID: this.createInteractiveVideoObj.currentObj.videoID,
        // WC 08092022
        subtitleUrl,
        // WC 08092022
      };
    }
    this.createInteractiveVideoObj.spinner = true;
    this.urlService.updateInteractiveVideo(obj).subscribe((success) => {
          let isCheckUrl = false;
          if (success.data.URL) {
            isCheckUrl = this.utilityService.checkIsUrl(success.data.URL);
          }
          this.createInteractiveVideoObj.currentObj.isUrl = isCheckUrl;
          this.createInteractiveVideoObj.currentObj.poster1 = '';
          this.createInteractiveVideoObj.currentObj.poster =
              this.createInteractiveVideoObj.selectedItem.poster ? this.createInteractiveVideoObj.selectedItem.poster : this.createInteractiveVideoObj.selectedItem.thumbnail;
          this.createInteractiveVideoObj.currentObj.imvd = 'ncs';
          this.createInteractiveVideoObj.currentObj.basck = 'videochart';
          this.createInteractiveVideoObj.currentObj.URL = this.createInteractiveVideoObj.selectedItem.URL;
          if (this.createInteractiveVideoObj.currentObj.name === 'Add Video') {
            this.createInteractiveVideoObj.currentObj.name = this.createInteractiveVideoObj.selectedItem.name;
            this.createInteractiveVideoObj.currentObj.Title = '';
          }
          this.createInteractiveVideoObj.currentObj.open = true;
          this.latestNodeId = this.createInteractiveVideoObj.currentObj._id;
          this.callChild1();
          this.toastr.info('Updated Successfully!');
          this.OpenPopupAll = false;
          this.closeModal();
          // this.createInteractiveVideoObj.selectedItem.URL = null
          $('#tree-Modal').modal('show');
          $('#select-vid-Modal').modal('hide');
        },
        (error) => {
          this.createInteractiveVideoObj.spinner = false;
        }
    );
  }

  // Preview Video
  previewVIdeo() {
    if (this.createInteractiveVideoObj.mainVideo.name == 'Add Video') {
      this.toastr.warning('Please Add parent Video!');
      return;
    }
    this.createInteractiveVideoObj.preview = true;
    // this.toastr.info('Preparing Video!')
    $('#tree-preview-vid-Modal').modal('show');
    $('#tree-publish-vid-Modal').modal('hide');
  }

  getCurrentTime(e) {
    this.interval = setInterval(() => {
      this.createInteractiveVideoObj.currentPlayingItem.currentTime =
          e.target.currentTime;
    }, 1000);

    let hasChilren: any = false;
    if (
        this.createInteractiveVideoObj.currentPlayingItem.children == null ||
        this.createInteractiveVideoObj.currentPlayingItem.children == undefined
    ) {
      hasChilren = false;
    } else {
      hasChilren = this.createInteractiveVideoObj.currentPlayingItem.children.length > 0;
    }

    //  this.createInteractiveVideoObj.currentPlayingItem.currentTime = e.target.currentTime
    this.placeMarker(
        hasChilren,
        this.createInteractiveVideoObj.currentPlayingItem.time,
        'previewVideo',
        e.target.duration
    );

    this.createInteractiveVideoObj.currentPlayingItem.currentTime =
        e.target.currentTime;
  }

  closePreview() {
    $('#tree-preview-vid-Modal').modal('hide');
    this.createInteractiveVideoObj.preview = false;
    clearInterval(this.interval);
    // this.createInteractiveVideoObj.currentPlayingItem = {};
  }

  publishPreview() {

    // WC 03072023 - Deactivate emails for videos per ML
    // WC 11152022
    // if (this.planInfo.emailsForVideos && !this.createInteractiveVideoObj.mainVideo.isPublished) {
    //   this.openEmailsForVideoModal();
    // } else {


    // First time this video is being published
    if (!this.createInteractiveVideoObj.mainVideo.isPublished) {
      // WC 03072023 -- Check if the user exceeds the allowable max no of published video as per their subsctiption package
      this.urlService.getUserSubscriptionPlan().subscribe(success => {

        this.currSubscriptionId = success.data._id;
        // console.log('this.currSubscriptionId = ', success.data);

        const maxNoOfAllowedPublishedVideos = success.data.subscriptionPlanInfo.maxNoOfVideos;
        // console.log("maxNoOfAllowedPublishedVideos = ", maxNoOfAllowedPublishedVideos);


        this.urlService.getAllMainVideosCountV2().subscribe(success => {

          const videoCounts = success.data;

          const getCountOfPublishedVideos = () => {
            let countOfPublishedVideos = 0;
            videoCounts.forEach(item => {
              if (item._id.isPublished != undefined && item._id.isPublished)
                countOfPublishedVideos = item.count;

              // console.log("countOfPublishedVideos = ", countOfPublishedVideos);
            });

            return countOfPublishedVideos;
          };

          if (getCountOfPublishedVideos() < maxNoOfAllowedPublishedVideos) {
            this.publishModal();
          } else {

            this.pricingUrl = '/pricing?subId=' + this.currSubscriptionId;

            $('#exceed-max-videos-Modal').modal('show');
          }
        })
      })
    } else {
      this.publishModal();
    }
  }

  publishModal() {
    const checkAllUrl = this.createInteractiveVideoObj.finalObj.filter(x => !!!x.URL);
    if (checkAllUrl.length) {
      this.toastr.error('Unable to publish until all nodes have vidoes');
      return;
    }
    clearInterval(this.interval);
    this.toastr.info('Preparing Video!');
    this.createInteractiveVideoObj.spinner = true;
    this.urlService.fetchCategories().subscribe((sucess) => {
      this.availableCategory = sucess.data.categories;
      this.availableCategory.sort();
      this.closePreview();
      this.createInteractiveVideoObj.spinner = false;
      this.createInteractiveVideoObj.publish = true;
      $('#tree-publish-vid-Modal').modal(
          {backdrop: 'static', keyboard: false},
          'show'
      );
      this.filledLogin('main_title');
      this.filledLogin('user_Description');
    });
  }

  loadNextOption(e) {
    clearInterval(this.interval);
    this.createInteractiveVideoObj.currentPlayingItem.currentTime = 0;
    this.createInteractiveVideoObj.preview = false;
    // this.toastr.info('Choice Selected');
    setTimeout(() => {
      this.createInteractiveVideoObj.currentPlayingItem = e;
      this.createInteractiveVideoObj.preview = true;
    }, 100);
    // this.createInteractiveVideoObj.currentPlayingItem = e;
  }

  /** translate subtitles with multiple languages */
  private translateVideo() {
    const obj = {
      videoId: this.createInteractiveVideoObj.mainVideo._id,
      targetLanguages: this.targetLanguages
    }
    if (obj.targetLanguages.length) {
      this.urlService.translateInteractiveVideo(obj).subscribe((response) => {
        if (!response.error && response.data.status) {
          this.toastr.success(response.data.status)
        }
      });
    }
  }

  onSubmit() {
    // WC 03072023 -- To check if the no of published video exceed the allowable max of videos per the subscription plan

    if (this.allowTranslate) {
      this.translateVideo();
    }
    let obj;
    if (!!this.item) {
      this.createInteractiveVideoObj.mainVideo.poster = this.item;
    }
    obj = {
      id: this.createInteractiveVideoObj.mainVideo._id,
      type: this.createInteractiveVideoObj.mainVideo.type,
      name: this.createInteractiveVideoObj.mainVideo.name,
      URL: this.createInteractiveVideoObj.mainVideo.URL,
      Title: this.createInteractiveVideoObj.mainVideo.Title,
      Description: this.createInteractiveVideoObj.mainVideo.Description,
      // WC 07272022 - Add tagging
      Tag: this.createInteractiveVideoObj.mainVideo.Tag,
      // WC 07272022
      Categories: this.createInteractiveVideoObj.mainVideo.Categories,
      time: this.createInteractiveVideoObj.mainVideo.time,
      parentId: this.createInteractiveVideoObj.mainVideo.parentId,
      poster: this.createInteractiveVideoObj.mainVideo.poster,
      videoID: this.createInteractiveVideoObj.mainVideo.videoID,
      isPrivate: this.isPrivate,
      // WC 08092022 - Add subtitleUrl
      subtitleUrl: this.createInteractiveVideoObj.mainVideo.subtitleUrl,
      // WC 08092022
    };

    if (this.groupList.length > 0 || this.followerListForPublic.length > 0) {
      obj.shareTo = {
        groupCount: this.groupLength,
        followerCount: this.followersLength,
        shareWithFollower: this.followerListForPublic,
        shareInGroup: this.groupList,
      };
    }
    const mainTitle = this.createInteractiveVideoObj.mainVideo.Title;
    this.createInteractiveVideoObj.spinner = true;
    this.urlService.updateInteractiveVideo(obj).subscribe((success) => {
      if (!this.createInteractiveVideoObj.mainVideo.isPublished) {
        const finalObj = {
          type: 'publish',
          videoID: this.createInteractiveVideoObj.mainVideo._id,
        };
        const totalCategories =
            this.createInteractiveVideoObj.mainVideo.Categories.length;
        if (totalCategories < 1) {
          this.createInteractiveVideoObj.spinner = false;
          this.toastr.warning('Select at least one category');
        } else if (mainTitle === '') {
          this.createInteractiveVideoObj.spinner = false;
          this.toastr.error('Title can not be blank');
        } else {
          this.urlService.publishunpublish(finalObj).subscribe((success) => {
            this.createInteractiveVideoObj.spinner = false;
            this.toastr.success('Published successfully!');

            $('#tree-publish-vid-Modal').modal('hide');
            this.cancelPublish();
            // this.localStorageService.setItem('tempToken', '848484');
            this.localStorageService.setItem('latestPublishedVideoId', this.createInteractiveVideoObj.mainVideo._id);
            this.router.navigate(['my-videos']);
          });
        }
      } else {
        $('#tree-publish-vid-Modal').modal('hide');
        this.cancelPublish();
        // this.localStorageService.setItem('tempToken', '848484');
        this.localStorageService.setItem('latestPublishedVideoId', this.createInteractiveVideoObj.mainVideo._id);
        this.router.navigate(['my-videos']);
        this.createInteractiveVideoObj.spinner = false;
      }
    });
  }

  addVideoTitle() {
    const obj: any = {
      id: this.createInteractiveVideoObj.mainVideo._id,
      type: this.createInteractiveVideoObj.mainVideo.type,
      name: this.createInteractiveVideoObj.mainVideo.name,
      URL: this.createInteractiveVideoObj.mainVideo.URL,
      Title: this.createInteractiveVideoObj.mainVideo.Title,
      Description: this.createInteractiveVideoObj.mainVideo.Description,
      // WC 07272022 - Add tagging
      Tag: this.createInteractiveVideoObj.mainVideo.Tag,
      // WC 07282022
      Categories: this.createInteractiveVideoObj.mainVideo.Categories,
      time: this.createInteractiveVideoObj.mainVideo.time,
      parentId: this.createInteractiveVideoObj.mainVideo.parentId,
      poster: this.createInteractiveVideoObj.mainVideo.poster,
      videoID: this.createInteractiveVideoObj.mainVideo.videoID,
    };
    this.urlService.updateInteractiveVideo(obj).subscribe((success) => {
    });
  }

  cancelPublish() {
    $('#tree-publish-vid-Modal').modal('hide');
    this.createInteractiveVideoObj.publish = false;
  }

  getCurrentTimePublish(e) {
    let hasChilren: any = false;
    if (
        this.createInteractiveVideoObj.mainVideo.children == null ||
        this.createInteractiveVideoObj.mainVideo.children == undefined
    ) {
      hasChilren = false;
    } else {
      if (this.createInteractiveVideoObj.mainVideo.children.length > 0) {
        hasChilren = true;
      } else {
        hasChilren = false;
      }
    }

    //// console.log(hasChilren,this.createInteractiveVideoObj.mainVideo,'publishVideo',e.target.duration)
    //  this.createInteractiveVideoObj.mainVideo.currentTime = e.target.currentTime
    this.placeMarker(
        hasChilren,
        this.createInteractiveVideoObj.mainVideo.time,
        'publishVideo',
        e.target.duration
    );
  }

  //  publishProfileForm

  placeMarker(haveChildren, markerTime, id, duration) {
    const that = this;

    const resetDom = document.createElement('button')
    resetDom.innerHTML = '<svg class="bi bi-arrow-clockwise" width="1.5em" height="1.5em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">' +
        '<path fill-rule="evenodd" d="M3.17 6.706a5 5 0 017.103-3.16.5.5 0 10.454-.892A6 6 0 1013.455 5.5a.5.5 0 00-.91.417 5 5 0 11-9.375.789z" clip-rule="evenodd"/>' +
        ' <path fill-rule="evenodd" d="M8.147.146a.5.5 0 01.707 0l2.5 2.5a.5.5 0 010 .708l-2.5 2.5a.5.5 0 11-.707-.708L10.293 3 8.147.854a.5.5 0 010-.708z" clip-rule="evenodd"/>' +
        '</svg>';
    resetDom.setAttribute('class', 'vjs-control vjs-button');
    resetDom.setAttribute('title', 'Start Over');
    resetDom.setAttribute('ids', 'Start Over');
    $('#' + id + ' .vjs-control-bar').append(resetDom)
    resetDom.addEventListener('click', () => {
      that.createInteractiveVideoObj.preview = false;
      setTimeout(() => {
        that.createInteractiveVideoObj.currentPlayingItem =
            that.createInteractiveVideoObj.mainVideo;
        if (id !== 'video-all') {
          that.createInteractiveVideoObj.preview = true;
        }
        clearInterval(that.interval);
      }, 100);
    });

    if (!haveChildren) {
      $('.marker').remove();
    } else {
      const width = $('#' + id).width();
      const precentageOfMarker = (markerTime / duration) * 100;
      $('.marker').remove();
      $(
          '#' + id + ' .vjs-progress-holder.vjs-slider.vjs-slider-horizontal'
      ).append(
          '<div class="marker" style="left:' + precentageOfMarker + '%"></div>'
      );

      // $('#'+id).parent().append();
    }
  }

  changeUserLogin(id) {
    if ($('#' + id).val() != '') {
      $('#' + id)
          .siblings('.input-field')
          .addClass('video_input_focus');
      $('#' + id).addClass('focusIn');
    } else {
      $('#' + id)
          .siblings('.input-field')
          .removeClass('video_input_focus');
    }
  }

  filledLogin(id) {
    $('#' + id)
        .siblings('.input-field')
        .addClass('video_input_focus');
    $('#' + id).addClass('focusIn');
  }

  preview(files) {
    if (files.length === 0) return;

    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.toastr.error('Only images are supported!');
      return;
    }

    const reader = new FileReader();
    this.createInteractiveVideoObj.mainVideo.imagePath = files;
    this.createInteractiveVideoObj.mainVideo.fileData = files[0];
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      // this.createInteractiveVideoObj.mainVideo.profilePic = reader.result;
      // this.createInteractiveVideoObj.mainVideo.thumbnail.push(reader.result)
    };
    this.updateProfilePic();
  }

  updateProfilePic() {
    this.createInteractiveVideoObj.spinner = true;
    const formData = new FormData();
    formData.append('file', this.createInteractiveVideoObj.mainVideo.fileData);
    formData.append('VideoID', this.createInteractiveVideoObj.mainVideo._id);

    this.urlService.uploadThumbnails(formData).subscribe((success) => {
      this.toastr.success('Image updated successfully');
      // this.createInteractiveVideoObj.mainVideo.poster = success.data;
      this.createInteractiveVideoObj.mainVideo.thumbnail.push(success.data.url);
      // console.log('success.data = ', success.data);
      // console.log('this.createInteractiveVideoObj.mainVideo.thumbnail = ', this.createInteractiveVideoObj.mainVideo.thumbnail);

      this.createInteractiveVideoObj.spinner = false;
    });
  }

  openContributorModal() {
    this.findorselect = '';
    // WC 04062022
    this.collabPage = 1;
    this.followers = [];
    this.getFollowerContributors();
    // WC 04062022
    this.isShareOpen = true;
    $('#contributor-Modal').modal('show');
  }

  closeContributorModal() {
    $('#contributor-Modal').modal('hide');
    this.isShareOpen = false;
  }

  deleteThumbnails(i: any) {
    const obj = {
      url: i,
      videoId: this.mainID,
    };
    this.createInteractiveVideoObj.spinner = true;
    this.urlService.deleteThumbnails(obj).subscribe((success) => {
      this.createInteractiveVideoObj.spinner = false;
      for (
          let j = this.createInteractiveVideoObj.mainVideo.thumbnail.length - 1;
          j > -1;
          j--
      ) {
        if (i == this.createInteractiveVideoObj.mainVideo.thumbnail[j]) {
          this.createInteractiveVideoObj.mainVideo.thumbnail.splice(j, 1);
          break;
        }
      }
    });
  }

  getFollowingId(e: any, isChecked) {
    if (isChecked == false) {
      for (let i = this.contrubutors.length - 1; i >= 0; i--) {
        if (e == this.contrubutors[i]) {
          this.contrubutors.splice(i, 1);
        }
      }
    } else {
      this.contrubutors.push(e);
    }
    // //console.log(this.contrubutors)
  }

  // Set contributor
  setContributor() {
    this.createInteractiveVideoObj.spinner = true;
    const obj = {
      videoID: this.mainID,
      contributorID: this.contrubutors[0],
    };
    this.urlService.setContributor(obj).subscribe(
        (success) => {
          this.contrubutors.shift();
          if (this.contrubutors.length == 0) {
            this.createInteractiveVideoObj.spinner = false;
            this.toastr.success('Contributor added');
            $('#contributor-Modal').modal('hide');
            this.isShareOpen = false;
            for (const i of this.followers) {
              i.setFollowerCheck = false;
            }
            for (const i of this.following) {
              i.setFollowingCheck = false;
            }
          } else {
            this.setContributor();
          }
        },
        (error) => {
          this.toastr.warning('Already contributor for this video');
          this.contrubutors.shift();
          if (this.contrubutors.length == 0) {
            this.createInteractiveVideoObj.spinner = false;
            $('#contributor-Modal').modal('hide');
            this.isShareOpen = false;
          } else {
            this.setContributor();
          }
        }
    );
  }

  // WC 04062022 - To get the list of contributors for this video
  getContributors() {
    this.urlService.getContributors().subscribe((data) => {
      this.contributors = data;
    });
  }

  onEdit() {
    this.isProjectCreated = true;
  }

  onSelectFile(event) {
    // console.log('onSelectFile');

    this.spinner = true;
    const file = event.target.files;
    let existItem = [];

    if (file) {
      for (const i of file) {
        i.originalName = i.name.replace(/[{()}]/g, '').replace(/\s/g, '');
        i.originalName = i.originalName.trim();
        // console.log('i.originalName = ', i.originalName);
        i.id = Math.random();
        i.uploadPercentage = 0;
        this.utilityService.onGetDuration(i).then((duration) => {
          i.duration = duration;

          if (i.type.indexOf('image') > -1) {
            i.format = 'image';
          } else if (i.type.indexOf('video') > -1) {
            i.format = 'video';
          }
          this.uploadVideoObject.file.push(i);

          existItem = existItem.filter((item, pos) => {
            return existItem.indexOf(item) === pos;
          });
          for (let i = this.uploadVideoObject.file.length - 1; i >= 1; i--) {
            for (const j of existItem) {
              if (this.uploadVideoObject.file[i].originalName === j) {
                // console.log(
                //     'Uploading video ',
                //     this.uploadVideoObject.file[i].originalName
                // );
                this.uploadVideoObject.file.splice(i, 1);
                break;
              }
            }
          }
        });
      }
    }

    setTimeout(() => {
      this.uploadVideo();
    }, 2000);
  }

  uploadVideo() {
    this.toastr.info('Uploading Video');
    this.spinner = true;
    this.finalUpload(this.uploadVideoObject.file[0]);
  }

  finalUpload(i) {
    // console.log('In finalUpload');

    const obj = {
      name: i.name,
      type: 'application/octet-stream',
      URL: null,
      duration: i.duration,
      thumbnail: '',
      size: i.size,
      originalName: i.name.split('.')[0],
    };

    if (this.localStorageService.getItem('interactive') === 'isEditor') {
      // tslint:disable-next-line: no-string-literal
      obj['bucketName'] = environment.AI_VIDEO_UPLOAD_BUCKET;
      // tslint:disable-next-line: no-string-literal
      // obj['directoryName'] = 'videos';
    }

    obj.name = i.name.replace(/[{()}]/g, '').replace(/\s/g, '');
    obj.name = obj.name.trim();
    let Objdata;

    this.urlService.createpresignedUrlV2(obj).subscribe(
        (success) => {
          i.counter = success.data.counter;
          this.uploadVideoObject.preSignedUrl = success.data.details;
          const pathArray = this.uploadVideoObject.preSignedUrl.split('?');
          const firstUrl = pathArray[0];
          this.urlService
              .uploadVideo(this.uploadVideoObject.preSignedUrl, i)
              .subscribe(
                  (success) => {
                    i.uploadPercentage = 0;

                    if (this.localStorageService.getItem('interactive') === 'isEditor') {
                      Objdata = {
                        name: i.name,
                        counter: i.counter,
                        bucketName: environment.AI_VIDEO_UPLOAD_BUCKET,
                        file: firstUrl,
                      };

                      this.parseAiEditorVideoV2(firstUrl);

                    } else {
                      Objdata = {
                        name: i.name,
                        counter: i.counter,
                        file: firstUrl,
                      };
                      const data: any = {
                        name: obj.name,
                        counter: i.counter,
                        file: firstUrl,
                      };

                      this.transcribingJob(data, i);
                    }
                  },
                  (error) => {
                    this.toastr.info('Error!', error);
                  }
              );

          this.progressPercentage(i);
        },
        (error) => {
          this.spinner = false;
        }
    );
  }

  parseAiEditorVideoV2(file: string) {

    const user = this.localStorageService.getItem('user');
    const email = this.localStorageService.getItem('email');

    // WC 06142023 --- To check for max no of AI Editor processed
    this.urlService.getUserSubscriptionPlan().subscribe(success => {

      const maxNoOfAIEditor = success.data.subscriptionPlanInfo.maxNoOfAIEditorVideos;

      const payload = {
        userID: user,
        userEmail: email,
        url: file,
        webhook_url: environment.AI_WEB_HOOK,
        // WC 060520 to add max allowable AI Editor processing as per the user's subscription plan
        maxNoOfAIEditor
      };

      // console.log("parseAiEditorVideoV2 - payload = ", payload);

      this.urlService.parseAIEditorVideoV2(payload).subscribe(
        (res: any) => {
          // console.log('Successful parseAIEditor - res: ', res);

          this.toastr.info(
              '',
              `Your video is being processed by the auto editor, you'll receive an email notification when the processing is done.`
          );

          // WC 08292022
          this.uploadVideoObject.file.shift();

          if (this.uploadVideoObject.file.length < 1) {
            this.cancelUpload();
            this.callInitialList();
            this.spinner = false;
            this.finalCount = 0;
            this.uploadedCount = 0;
          } else {
            this.uploadVideo();
            this.spinner = false;
          }
          // WC 08292022
        },
        (error) => {
          // Error callback
          // console.log(
          //     'Error in calling parse AI Editor API - error: ',
          //     error.message
          // );

          // this.toastr.error(
          //     'We are sorry, AI Editor is currently not available. Please try again later'
          // );

          if (error.error.isUnavailable)
            this.toastr.error(
              'We are sorry, AI Editor is currently not available. Please try again later'
            );

          this.cancelUpload();
          this.callInitialList();
          this.spinner = false;
          this.finalCount = 0;
          this.uploadedCount = 0;
        }
      );
    });
  }

  progressPercentage(i) {
    const interval = setInterval(() => {
      i.uploadPercentage = this.urlService.getProgress();
      if (i.uploadPercentage > 99.9) {
        clearInterval(interval);
      }
    }, 1000);
  }

  // WC 08082022
  /*************** TRANSCRIBING ****************/
  transcribingJob(data, i) {
    this.toastr.info('Transcribing Video');

    let requestObj;
    this.urlService.transcribeVideo(data).subscribe(
        (success) => {
          i.uploadPercentage = 0;
          i.isOtherone = true;

          if (this.localStorageService.getItem('interactive') === 'isEditor') {
            requestObj = {
              name: data.name,
              type: 'application/octet-stream',
              counter: i.counter,
              transcodeId: success.data.transcodeId,
              bucketName: environment.AI_VIDEO_UPLOAD_BUCKET,
            };
          } else {
            requestObj = {
              name: data.name,
              counter: i.counter,
              transcriptionJobName: success.data.TranscriptionJobName,
            };
          }

          if (i.uploadPercentage) {
            // console.log('in if i.uploadPercentage', i.uploadPercentage);
            i.uploadPercentage = i.uploadPercentage + 10;
          }

          this.checkTranscribingStatus(data, requestObj, i);
        },
        (error) => {
        }
    );
  }

  checkTranscribingStatus(data, requestObj, i) {
    // console.log("checkTranscribingStatus");

    this.urlService.checkTranscribingStatus(requestObj).subscribe(
        (success) => {
          if (success.data.TranscriptionJobStatus == 'COMPLETED') {
            this.toastr.info('Transcribing Completed');

            data.transcriptFileUri = success.data.Transcript.TranscriptFileUri;

            // this.toastr.info('Transcoding Video');
            this.transcodingJob(data, i);
          } else {
            // console.log('2. in else of check status', i.uploadPercentage);
            if (i.uploadPercentage < 95) {
              i.uploadPercentage = i.uploadPercentage + 5;
              // console.log('3. in if else of check status', i.uploadPercentage);
            } else {
              i.uploadPercentage = 98;
            }

            setTimeout(() => {
              this.checkTranscribingStatus(data, requestObj, i);
            }, 1500);
          }
        },
        (error) => {
          this.toastr.error('Transcribing Failed');
        }
    );
  }

  // WC 08082022

  /*************** TRANSCODING *****************/
  transcodingJob(data, i) {
    // console.log('in transcodingJob');
    this.toastr.info('Transcoding Video');

    let requestObj;

    this.urlService.transcodeVideo(data).subscribe(
        (success) => {
          // console.log("transcodVideo success.data = ", success.data);

          i.uploadPercentage = 0;
          i.isOtherone = true;
          if (this.localStorageService.getItem('interactive') === 'isEditor') {
            requestObj = {
              name: data.name,
              type: 'application/octet-stream',
              counter: i.counter,
              transcodeId: success.data.transcodeId,
              bucketName: environment.AI_VIDEO_UPLOAD_BUCKET,
            };
          } else {
            requestObj = {
              name: data.name,
              type: 'application/octet-stream',
              counter: i.counter,
              transcodeId: success.data.transcodeId,
            };
          }
          if (i.uploadPercentage) {
            // console.log('in if i.uploadPercentage', i.uploadPercentage);
            i.uploadPercentage = i.uploadPercentage + 10;
          }
          this.checkStatus(requestObj, i);
        },
        (error) => {
        }
    );
  }

  checkStatus(requestObj, i) {
    this.urlService.checkTranscodingStatus(requestObj).subscribe(
        (success) => {
          if (success.data._id != null || success.data._id !== undefined) {
            this.toastr.info('Transcoding Completed');
            this.uploadVideoObject.file.shift();
            if (this.uploadVideoObject.file.length < 1) {
              this.cancelUpload();
              this.callInitialList();
              this.spinner = false;
              this.finalCount = 0;
              this.uploadedCount = 0;
              i.isOtherone = false;
            } else {
              this.uploadVideo();
              this.spinner = false;
              i.isOtherone = false;
            }
          } else {
            // console.log('2. in else of check status', i.uploadPercentage);
            if (i.uploadPercentage < 95) {
              i.uploadPercentage = i.uploadPercentage + 5;
              // console.log('3. in if else of check status', i.uploadPercentage);
            } else {
              i.uploadPercentage = 98;
            }
            setTimeout(() => {
              this.checkStatus(requestObj, i);
              // console.log('4. in setTimeout', i.uploadPercentage);
            }, 1500);
          }
        },
        (error) => {
          this.toastr.error('Transcoding Failed');
        }
    );
  }

  cancelUpload() {
    this.uploadVideoObject.file = [];
    $('video').trigger('pause');
    this.changeFilterUploadVideoVal = 'recent';
    this.loadingVideo = false;
  }

  callInitialList() {
    // console.log("callInitialList with videolist V2 - this.aiEditorVideo = ", this.aiEditorVideo);

    this.spinner = true;
    this.createInteractiveVideoObj.allUploadedVideo = [];
    this.loadingVideo = true;

    // WC 05132022
    this.page = 1;

    this.urlService
        .getUploadedVideoV2(this.limit, this.page, this.sort, this.aiEditorVideo, 'video')
        .subscribe(
            (success) => {
              this.allVideos = success.data;

              for (const i of this.allVideos) {
                // WC 08272022
                i.durationInHHMMSS = this.utilityService.convertSecondsToHHMMSSFormat(i.duration);
                // console.log("i.duration = " + i.duration + ", i.durationInHHMMSS = " + i.durationInHHMMSS);
                // WC 08272022

                i.URLName = i.originalName;
                if (!i.originalName) {
                  i.URLName = i.name;
                }

                // WC 03102022 to handle undefined URLName from mobile app
                if (i.URLName === undefined) {
                  i.URLName = '';
                }

                this.createInteractiveVideoObj.allUploadedVideo.push(i);
              }
              // WC 05132022

              this.loadingVideo = false;
              this.spinner = false;
            },
            (error) => {
              this.spinner = false;
            }
        );
  }

  ngOnDestroy(): void {
    this.localStorageService.removeItem('interactive');
    this.localStorageService.removeItem('freeTemp');
    this.localStorageService.removeItem('assistCreateVideo');
  }

  onFileSelected(event) {
    for (let i = 0; i < event.target.files.length; i++) {
      this.selectedFile.push(event.target.files[i]);
    }
    // this.selectedFile = event.target.files[0];
  }

  onUpload() {
    // console.log(this.selectedFile); // You can use FormData upload to backend server
  }
  changeTextPositionTopLeft(pos1: any) {
    this.top = `${pos1 / 6}rem`;
  }

  changeTextPositionTop(pos1: any) {
    this.left = `${pos1 / 4}rem`;
  }

  changeColor(myColor: any) {
    this.color = myColor;
  }
  changeFamily(family: any) {
    this.fontFamily = family;
  }
  startediting() {
    this.isEdit = false;
    this.itemStartEdit = true;
  }

  cropVideo(item: any) {
    this.isAllEditingTool = true;
    this.subtool = false;
    this.isFIleView = true;
    this.currentOperation = item;

    this.cutImage = '../../../assets/edit tool/scissors purple.svg';
    this.audioImage = '../../../assets/edit tool/medium-volume.svg';
    this.drawImage = '../../../assets/edit tool/pencil gray.svg';
    this.downloadImage = '../../../assets/edit tool/download gray.svg';
    this.annotationImage = '../../../assets/edit tool/font.svg';
    this.isSliderView = true;
  }

  // Only for saving purpose call clip api
  clipVideo() {
    const objectUrl =
        'https://www.learningcontainer.com/wp-content/uploads/2020/05/sample-mp4-file.mp4';
    const payload = {
      file: this.fileResData.media,
      position: 0,
      start: 3,
      end: 1800,
      layer: 1,
      project: this.projectUrl,
      json: {},
    };
  }

  annoteVideo(item) {
    this.isFIleView = true;
    this.currentOperation = item;
    this.annotTxtView = true;
    this.annotatioView = true;
    this.isAllEditingTool = false;
    this.isSliderView = true;
    this.audioSubTool = false;
    this.volumeRange = false;
    this.annotationImage = '../../../assets/edit tool/edit icon/font (1).svg';
    this.cutImage = '../../../assets/edit tool/scissors.svg';
    this.audioImage = '../../../assets/edit tool/medium-volume.svg';
    this.drawImage = '../../../assets/edit tool/pencil gray.svg';
    this.downloadImage = '../../../assets/edit tool/download gray.svg';
    if (item === 'Annotation') {
    }
  }

  openColorView() {
    this.annotatioView = false;
    this.isAllEditingTool = false;
    this.allColors = true;
  }
  closeColorView() {
    this.isAllEditingTool = false;
    this.annotatioView = true;
  }
  openFontView() {
    this.isAllEditingTool = false;
    this.allColors = false;
    this.annotatioView = false;
    this.fontfamilyView = true;
  }
  closeFontView() {
    this.isAllEditingTool = false;
    this.annotatioView = true;
    this.fontfamilyView = false;
  }
  Addaudio(item: any) {
    this.isAllEditingTool = false;
    this.audioSubTool = true;
    this.annotatioView = false;

    this.currentOperation = item;
    this.audioImage =
        '../../../assets/edit tool/edit icon/medium-volume purple.svg';
    this.cutImage = '../../../assets/edit tool/scissors.svg';
    this.drawImage = '../../../assets/edit tool/pencil gray.svg';
    this.downloadImage = '../../../assets/edit tool/download gray.svg';
    this.annotationImage = '../../../assets/edit tool/font.svg';
    // operation for Audio
  }
  viewVolumeRange() {
    this.isAllEditingTool = false;
    this.audioSubTool = false;
    this.volumeRange = true;
  }
  DrawVideo(item: any) {
    this.pointerEvent = 'visible';
    this.canvasView = true;
    this.drawView = true;
    this.isAllEditingTool = false;

    this.currentOperation = item;
    this.drawImage = '../../../assets/edit tool/edit icon/pencil purple.svg';
    this.cutImage = '../../../assets/edit tool/scissors.svg';
    this.audioImage = '../../../assets/edit tool/medium-volume.svg';
    this.downloadImage = '../../../assets/edit tool/download gray.svg';
    this.annotationImage = '../../../assets/edit tool/font.svg';
  }
  changeDrwaColor(color: any) {
    this.drwaColor = color;
  }
  openDrawerColorView(item: any) {
    this.isAllEditingTool = false;
    this.drawView = false;
    this.drawAllColors = true;
  }
  closeDrawColorView() {
    this.isAllEditingTool = false;
    this.drawView = true;
    this.drawAllColors = false;
  }
  lineThick() {
    this.isAllEditingTool = false;
    this.drawRangeView = true;
    this.drawView = false;
  }
  getDrawerWidth(item: any) {
    this.lineWidth = item / 10;
  }
  onCloseLineDraw() {
    this.isAllEditingTool = false;
    this.drawView = true;
    this.drawAllColors = false;
    this.drawRangeView = false;
  }
  shareVideo(item: any) {
    this.currentOperation = item;
    this.shareImage = '../../../assets/edit tool/edit icon/share purple.svg';

    this.downloadImage =
        '../../../assets/edit tool/edit icon/download purple.svg';
    this.cutImage = '../../../assets/edit tool/scissors.svg';
    this.audioImage = '../../../assets/edit tool/medium-volume.svg';
    this.drawImage = '../../../assets/edit tool/pencil gray.svg';
    this.downloadImage = '../../../assets/edit tool/download gray.svg';
    this.annotationImage = '../../../assets/edit tool/font.svg';
  }
  downloadVideo(item: any) {
    this.downloadImage =
        '../../../assets/edit tool/edit icon/download purple.svg';
    this.cutImage = '../../../assets/edit tool/scissors.svg';
    this.audioImage = '../../../assets/edit tool/medium-volume.svg';
    this.drawImage = '../../../assets/edit tool/pencil gray.svg';
    this.shareImage = '../../../assets/edit tool/share.svg';
    this.annotationImage = '../../../assets/edit tool/font.svg';
    this.currentOperation = item;
  }
  onCancel() {
    this.isAllEditingTool = true;
    this.isSliderView = false;
  }

  ngAfterViewInit() {
    this.titleChange$
        .asObservable()
        .pipe(
            filter(Boolean),
            debounceTime(1500),
            distinctUntilChanged(),
            tap((event) => {
              // console.log(event);
              this.updateCurrentName(event);
            })
        )
        .subscribe();
    this.duationChange$
        .asObservable()
        .pipe(
            filter(Boolean),
            debounceTime(1500),
            distinctUntilChanged(),
            tap((event) => {
              // console.log('duration', event);
              this.updateCurrentTime(event);
            })
        )
        .subscribe();
  }

  // WC 03312022
  countUploadedVideos() {
    // console.log("countUploadedVideos - this.aiEditorVideo: ", this.aiEditorVideo);

    this.urlService
        .getUploadedVideoCount(
            this.createInteractiveVideoObj.query,
            this.aiEditorVideo
        )
        .subscribe((res) => {
          res.data.forEach((e, i) => {
            const exists = Object.values(e).includes('application/octet-stream');
            const isUrl = Object.values(e).includes('url');
            if (exists) {
              this.createInteractiveVideoObj.totalUploadedVideoCount = e.count;
            }
            if (isUrl) {
              this.createInteractiveVideoObj.totalUploadedUrlCount = e.count;
            }
          });
        });
  }

  // WC 04062022
  getMainVideoInfo() {
    // console.log("this.mainID = ", this.mainID);

    this.urlService.getVideoInfov2(this.mainID).subscribe((res) => {
      // console.log("res.data.videoinfo = ", res.data.videoinfo);

      this.createInteractiveVideoObj.currentObj = res.data.videoinfo;
      this.createInteractiveVideoObj.mainVideo = JSON.parse(JSON.stringify(res.data.videoinfo));
      this.alreadyTargetedLanguages = this.createInteractiveVideoObj.mainVideo.subtitleTranslations;
      this.alreadyTargetedLanguages = this.alreadyTargetedLanguages.map((x) => {
        return Object.keys(x)[0];
      });
      this.languages = this.languages.map(x => {
        x.disabled = this.alreadyTargetedLanguages.includes(x.code)
        return x;
      });
      this.item = res.data.videoinfo.poster;
      this.createInteractiveVideoObj.currentPlayingItem = res.data.videoinfo;
      this.isPrivate = res.data.videoinfo.isRestricted;

      // console.log("this.createInteractiveVideoObj = ", this.createInteractiveVideoObj);
      this.callChild('');
      if (this.isAssistCreateVideo) {
        this.assistEventsSubject.next({
          // type: 'this.msgEvent.editFirstNodeName',
          type: this.msgEvent.editFirstNodeName,
          data: this.createInteractiveVideoObj.mainVideo
        });
      }
    });
  }

  getVideoPathWays() {
    this.urlService.getPathway({videoId: this.mainID})
        .subscribe((res) => {
          this.createInteractiveVideoObj.pathways = res.data;
          for (const watchPath of this.createInteractiveVideoObj.pathways) {
            watchPath.videos = this.utilityService.parentNode(this.createInteractiveVideoObj.finalObjTree, watchPath.endNodeId) || [];
            watchPath.startNode = watchPath.videos.find(x => x._id === watchPath.startNodeId);
            watchPath.endNode = watchPath.videos.find(x => x._id === watchPath.endNodeId);
            if (watchPath.videos) {
              const filterVideo = [];
              for (const video of watchPath.videos) {
                filterVideo.push(video);
                if (watchPath.startNodeId === video._id) {
                  break;
                }
              }
              watchPath.videos = filterVideo.reverse();
            }
          }
          // console.log(this.createInteractiveVideoObj.pathways)
        });
  }

  // WC 03312022
  listVideosWithSearchCriteria(newSearch: boolean) {
    // console.log("newSearch = ", newSearch);

    if (newSearch) this.createInteractiveVideoObj.allUploadedVideo = [];

    const freeTemp = this.localStorageService.getItem('freeTemp');

    // console.log("listVideosWithSearchCriteria - this.createInteractiveVideoObj.query: ", this.createInteractiveVideoObj.query + ", this.limit = " + this.limit + ", this.page = " + this.page + ", this.sort = " + this.sort);

    this.urlService
        .searchVideoListByName(
            this.createInteractiveVideoObj.query,
            this.limit,
            this.page,
            this.sort,
            this.aiEditorVideo
        )
        .subscribe(
            (success) => {
              setTimeout(() => {
                if (freeTemp) {
                  this.clickSelectVideo();
                }
              }, 500);
              this.createInteractiveVideoObj.allVideos = success.data;

              // console.log("listVideosWithSearchCriteria - this.createInteractiveVideoObj.allVideos: ", this.createInteractiveVideoObj.allVideos);

              for (const i of this.createInteractiveVideoObj.allVideos) {
                i.URLName = i.originalName;
                if (!i.originalName) {
                  i.URLName = i.name;
                }

                // WC 03102022 to handle undefined URLName from mobile app
                if (i.URLName === undefined) {
                  i.URLName = '';
                }
                this.createInteractiveVideoObj.allUploadedVideo.push(i);
              }

              // console.log("listVideosWithSearchCriteria - this.createInteractiveVideoObj.allUploadedVideo: ", this.createInteractiveVideoObj.allUploadedVideo);
              // console.log("listVideosWithSearchCriteria - this.createInteractiveVideoObj.allUploadedVideo.length: ", this.createInteractiveVideoObj.allUploadedVideo.length);

              if (this.mainID) {
                this.getMainVideoInfo();
              } else {
                this.createInteractiveVideoObj.spinner = false;
                this.createInteractiveVideoObj.selectedVideoCheck = true;
                // this.createInteractiveVideoObj.selectedVideoCheck= true;
                this.createInteractiveVideoObj.interactiveStep = 'one';
              }
              // $('input[name=uploadedVideo]:checked').removeAttr('checked');
              // $('input[name=uploadedVideo1]:checked').removeAttr('checked');
            },

            (error) => {
              this.createInteractiveVideoObj.spinner = false;
            }
        );
  }

  // WC 03312022

  // WC 11152022

  formValidation() {
    const group: any = {};
    for (const field of this.lstForm) {

      if (field.fieldName.toLowerCase().indexOf('email') > -1) {

        group[field.fieldName] = new FormControl(field.fieldValue || '', [
          Validators.required,
          Validators.pattern('[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[A-Za-z]{2,6}$'),
        ]);
      }
      ;

      this.formEmail = new FormGroup(group);
    }
  }

  getEmailsforVideoForm() {

    this.urlService.getUserSubscriptionPlan().subscribe(success => {
      this.planInfo = success.data.subscriptionPlanInfo;

      let noOfEmailsPerVideo = 0;
      if (this.planInfo?.noOfEmailsPerVideo)
        noOfEmailsPerVideo = this.planInfo?.noOfEmailsPerVideo;

      this.lstForm = [];

      for (let i = 0; i < noOfEmailsPerVideo; i++) {
        const formField = {
          fieldName: 'email' + i,
          fieldType: 'email',
          fieldValue: '',
        } as IFormField;
        this.lstForm.push(formField);
      }
      // console.log("getSubscriptionPlanInfo - this.lstForm = ", this.lstForm);

      // after set form comtrols //set form control validation
      this.formValidation();

      /* check gold plan for subtitle translate*/
      // if (this.planInfo._id === '627e75fcf76daf5009dc2aef' || this.planInfo._id === '629e78dd467f4115b1f7dbfb') {
      // console.log('this.planInfo.name = ', this.planInfo.name);

      if (!this.planInfo.name.includes('Bronze')) {
        this.allowTranslate = true;
        // console.log("this.allowTranslate = ", this.allowTranslate);
      } else {
        this.allowTranslate = false;
        // console.log("this.allowTranslate = ", this.allowTranslate);
      }
      /* check plan for subtitle translate*/

      this.spinner = false;
    })
  }

  openEmailsForVideoModal() {
    // console.log("in openEmailsForVideoModal")

    this.urlService.userDetailsV2().subscribe(success => {
      const user = success.data;

      this.maxNoOfCreatedVideos = user.totalmainvideos;
      // console.log("In openEmailsForVideoModal - this.maxNoOfCreatedVideos = ", this.maxNoOfCreatedVideos);

      // let currNoOfRetries;
      if (this.planInfo.emailsForVideos) {
        if (user.details.currentNoOfTries == undefined) {
          this.currNoOfRetries = 1;
          // console.log('In openEmailsForVideoModal - this.currNoOfRetries = ', this.currNoOfRetries);
        } else {
          this.currNoOfRetries = user.details.currentNoOfTries;
          // console.log('In openEmailsForVideoModal - currNoOfRetries = ', this.currNoOfRetries);
        }
      }
      // console.log("In openEmailsForVideoModal - this.planInfo.emailsForVideos = ", this.planInfo.emailsForVideos);

      if (this.planInfo.emailsForVideos) {
        if (this.currNoOfRetries < this.planInfo.noOfRetries)
          $('#add-emails-Modal').modal('show');
        else
          $('#exceed-max-videos-Modal').modal('show');
      }
    });
  };

  updateEmails() {
    // console.log('updateEmails - this.lstForm = ', this.lstForm);

    const emails = [];

    for (const field in this.formEmail.controls) {
      emails.push(this.formEmail.controls[field].value);
    }

    // console.log('emails = ', emails);

    let isDup = false;

    // check for dups inside the emails array
    for (let i = 0; i < emails.length; i++) {

      let noOfOccurence = 0
      for (let j = 0; j < emails.length; j++) {
        if (emails[i].toLowerCase() == emails[j].toLowerCase()) {
          noOfOccurence++;
        }
      }

      // Found dup
      if (noOfOccurence > 1) {
        isDup = true;
        // this.toastr.error("Duplicate email " + emails[i]);
      }
    }

    if (isDup) {
      this.toastr.error('Oops! Looks like you entered duplicate email addresses. Please invite three separate friends to publish your free video tree.');

      return;
    }

    const payload = {
      emails
    }

    // setEmailsForVideos response
    // {
    //   "email": "freefusedevops@gmail.com",
    //   "duplicate": true,
    //   "persisted": false,
    //   "emailSent": false
    // }
    // Check dups with the backend data
    this.urlService.setEmailsForVideos(payload).subscribe(success => {
      // console.log('success = ', success);

      const emailsForVideos = success.data;

      let noOfDups = 0;
      for (let i = 0; i < emailsForVideos.length; i++) {
        // console.log('emailsForVideos[' + i + '] = ', emailsForVideos[i]);

        if (emailsForVideos[i].duplicate) {
          // console.log('emailsForVideos[' + i + '].duplicate = ', emailsForVideos[i].duplicate);

          // this.toastr.error("Duplicate email " + emailsForVideos[i].email);
          noOfDups++;
        }

        // console.log('i = ' + i + ', emailsForVideos.length - 1 = ' + (emailsForVideos.length - 1));
        if (i == (emailsForVideos.length - 1)) {

          // console.log('noOfDups = ', noOfDups);
          if (noOfDups == 0) {

            const payload = {
              userId: this.localStorageService.getItem('user')
            };

            // console.log('about to updateUserEmailsForVideos');
            const user = this.urlService.updateUserEmailsForVideos(payload).subscribe(success => {

              this.toastr.info('Thank you for sharing our platform with your friends! You are helping us spread the good word about FreeFuse and for that we are grateful. Enjoy your free video tree.')

              if (success.data) {
                $('#add-emails-Modal').modal('hide');
                this.publishModal();
              }
              ;
            });
          } else {
            this.toastr.error('Oops! Looks like you entered duplicate email addresses. Please invite three separate friends to publish your free video tree.')
          }
        }
        ;
      }
      ;
    });
  };

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.createChoiceObj.child, event.previousIndex, event.currentIndex);
    //console.log(this.createChoiceObj)
  }

  // WC 11152022

  closePopover(popEle3: PopoverDirective, popEle32: string) {
    popEle3.hide();
    this.dashboardService.setHelpPopOver(popEle32);
  }

  goBack() {
    if ((this.location.getState() as any)?.navigationId > 1)
      this.location.back();
    else
      this.router.navigateByUrl('/my-videos');
  }

  confirmDelete(id: any) {
    // this.deleteWaitingId = id;
    // console.log(this.deleteWaitingId)
    this.deleteDialogRef.open({
      header: 'Delete Video',
      title: 'Are you sure you want to delete this video?'
    }, (e) => {
      if (e) {
        this.spinner = true;
        this.urlService.deleteBookMarkVideo(id).subscribe((success) => {
          // this.getMainVideos();
          this.dashboardService.onGetUserData();
          // this.createInteractiveVideoObj.spinner = false;
          this.router.navigateByUrl('/my-videos');
          this.toastr.success('Deleted successfully!');
        }, (err) => {
          this.spinner = false;
        });
      }
    });
  }

  onAssistEvent(e) {
    // console.log('main', e)
    if (e.type === this.msgEvent.addFirstNode) {
      this.openVideoCreateModel('freeboard');
    } else if (e.type === this.msgEvent.editFirstNodeName) {
      // this.clickSelectVideo();
      this.createInteractiveVideoObj.currentObj = e.data;
      this.updateCurrentName(e.data.name);
    } else if (e.type === this.msgEvent.close) {
      this.isAssistCreateVideo = null;
      this.intialFunction('');
      this.localStorageService.removeItem('assistCreateVideo');
    } else if (e.type === this.msgEvent.addMoreChoices) {
      this.addMoreChoices();
    } else if (e.type === this.msgEvent.addChildNode) {
      this.createInteractiveVideoObj.currentObj = e.data.currentObj;
      this.openVideoListModal(e.data.index);
    } else if (e.type === this.msgEvent.addTime) {
      this.createInteractiveVideoObj.currentObj = e.data.currentObj;
      this.updateCurrentTime(e.data.duration);
    } else if (e.type === this.msgEvent.createPathways) {
      setTimeout(() => {
        this.openAddWatchPath();
      }, 1000);
    } else if (e.type === this.msgEvent.publish) {
      setTimeout(() => {
        this.publishPreview();
      }, 1000);
    }
  }

  toggleEndingNode() {
    setTimeout(() => {
      this.urlService.setEndingNode({
        videoId: this.createInteractiveVideoObj.currentObj._id,
        isEndingNode: this.createInteractiveVideoObj.currentObj.isEndingNode,
      }).subscribe((res) => {
        this.toastr.success(res.message);
      });
    });
  }

}
