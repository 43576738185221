import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { CrudService } from 'src/app/services/crud.service';

interface ModalData {
  promoCodeData: {
    promoCodeName: string,
    info: Info,
    couponId: string,
    isActive: boolean,
    _id?: string;
    isLimitedToFirstTimeSubscription: boolean
  };
}

interface Info {
  coupon: {
    _id: any;
  };
  expires_at: number;
}

@Component({
  selector: 'app-create-promo-code',
  templateUrl: './create-promo-code.component.html',
  styleUrls: ['./create-promo-code.component.css']
})
export class CreatePromoCodeComponent implements OnInit {

  createPromoCodeForm: FormGroup;
  title: string = 'Create Promo Code';
  plansData = [];
  isFormSubmitted: boolean = false;
  allCoupons = [];
  isSpinner: boolean = false;
  processing: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ModalData,
    private crudService: CrudService,
    public dialogRef: MatDialogRef<CreatePromoCodeComponent>,
    private datePipe: DatePipe,
    private toastr: ToastrService
  ) {
    this.createPromoCodeForm = new FormGroup({
      expiresAt: new FormControl(null),
      // couponId: new FormControl(null, [Validators.required]),
      couponId: new FormControl(this.data.promoCodeData.couponId, [Validators.required]),
      // plans: new FormControl([]),
      name: new FormControl(null, [Validators.required, Validators.maxLength(15), Validators.pattern(/^[a-zA-Z0-9]+$/)])
    });

    //console.log("this.data = ", this.data);
    if(this.data.promoCodeData) {
      const promoCodeData = this.data.promoCodeData;
      if(promoCodeData.promoCodeName) {
        this.createPromoCodeForm.addControl('isActive', new FormControl(true));
        this.title = 'Edit Promo Code';
        let expiresAt = this.datePipe.transform(new Date(promoCodeData.info.expires_at * 1000), 'yyyy-MM-dd');
        this.createPromoCodeForm.patchValue({
          name: promoCodeData.promoCodeName,
          couponId: promoCodeData.couponId,
          isLimitedToFirstTimeSubscription: promoCodeData.isLimitedToFirstTimeSubscription,
          expiresAt: expiresAt,
          isActive: promoCodeData.isActive
        });
        this.createPromoCodeForm.get('name').disable();
        this.createPromoCodeForm.get('couponId').disable();
        this.createPromoCodeForm.get('expiresAt').disable();
      } 
      //Create a new Promo Code for a coupon Id
      else if (promoCodeData.couponId) {
        this.createPromoCodeForm.addControl('isActive', new FormControl(true));
        this.createPromoCodeForm.addControl('isLimitedToFirstTimeSubscription', new FormControl(false));
      }
    }
  }

  ngOnInit(): void {
    // this.crudService.getAllPlans().subscribe((res) => {
    //   this.plansData = res.data;
    // })
    
    this.getCoupon(this.data.promoCodeData.couponId);
  }

  validateExpiryDate(control) {
    const selectedDate = new Date(control.value);
    const currentDate = new Date();
    if (selectedDate <= currentDate) {
      return { expired: true };
    }
    return null;
  }

  getCoupons(page: number) {
    this.isSpinner = true;
    this.crudService.getAllCoupon(page, 10).subscribe((res: any) => {
      if(res?.data.length === 10) {
        this.getCoupons(page + 1);
      }
      const allCoupons = res.data || [];
      allCoupons.forEach((coupon) => {
        if (coupon) {
          this.allCoupons.push(coupon);
        }
      });

      this.isSpinner = false;
      //console.log("getCoupons - allCoupons = ", allCoupons)
    }, (err) => {
      this.toastr.error('Fetching Coupons Failed');
    })
  }

  getCoupon(couponId: any) {
    this.isSpinner = true;
    this.crudService.getCouponDetail(couponId).subscribe((res: any) => {

      //console.log("res = ", res);
      const coupon = res.data;
      this.allCoupons.push(coupon);

      this.isSpinner = false;
      //console.log("getCoupons - allCoupons = ", this.allCoupons)
    }, (err) => {
      this.toastr.error('Fetching Coupons Failed');
    })
  }

  convertToUnixTimestampAtMidnight(dateString: any) {
    // Parse the date string
    const [month, day, year] = dateString.split('/');
    const date = new Date(year, month - 1, day);

    // Set time to 23:59:59
    date.setHours(23, 59, 59);

    // Convert to Unix timestamp (in seconds)
    return Math.floor(date.getTime() / 1000);
}
  
  onSubmit() {
    // console.log("this.createPromoCodeForm.value = ", this.createPromoCodeForm.value);
    // console.log("this.data?.promoCodeData = ", this.data?.promoCodeData);

    this.isFormSubmitted = true;
    if(this.createPromoCodeForm.valid) {
      this.processing = true;
      let formData = {};

      if(this.data?.promoCodeData.couponId) {

        formData = Object.assign({}, this.createPromoCodeForm.value);

        if (this.createPromoCodeForm.value.expiresAt) {
          const formattedDate = this.datePipe.transform(this.createPromoCodeForm.value.expiresAt, 'MM/dd/yyy');
          //console.log("formattedDate = ", formattedDate);

          //const unixTimestamp = new Date(formattedDate).getTime() / 1000;

          const unixTimestamp = new Date(formattedDate).setUTCHours(23, 59, 0, 0);
          
          //console.log("unixTimestamp = ", unixTimestamp);

          formData['expiresAt'] = unixTimestamp;
        };

        //console.log("onSubmit - formData = ", formData);

        this.crudService.createPromoCode(formData).subscribe((res) => {
          this.dialogRef.close(res);
          this.processing = false;
          this.toastr.success('Promo code created successfully !!');
        }, (err) => {
          this.dialogRef.close();
          this.processing = false;
          //this.toastr.error(err.error.message);
        })
      }
    }
  }

  errorHandling = (control: string, error: string) => {
    return this.createPromoCodeForm.controls[control].hasError(error) &&
        (this.createPromoCodeForm.controls[control].dirty || this.createPromoCodeForm.controls[control].touched || this.isFormSubmitted);
  }

}
