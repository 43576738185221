import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import {LocalStorageService} from '../services/localStorage.service';

@Injectable()
export class RoleGuardService implements CanActivate {
  constructor(public router: Router,private localStorageService:LocalStorageService) {}
  canActivate(): boolean {
    if (!(!!this.localStorageService.getItem('email'))) {
      this.router.navigate(['login']);
      return false;
    }
    return true;
  }
  }
