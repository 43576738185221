import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from './beforeLogin/login/login.component';
import {SignupComponent} from './beforeLogin/signup/signup.component';
import {ResetPasswordComponent} from './beforeLogin/resetPassword/resetPassword.component';
import {ForgetPasswordComponent} from './beforeLogin/forgotPassword/forgetPassword.component';
import {OtpComponent} from './beforeLogin/otp/otp.component';
import {ProfileCompletionComponent} from './afterLogin/profileCompletion/profileCompletion.component';
import {UploadVideosComponent} from './afterLogin/uploadVideos/uploadVideos.component';
import {AuthGuardService} from './guards/router-gaurd.service';
import {RoleGuardService} from './guards/role-gaurd.service';
import {CreateInteractiveVideoComponent} from './afterLogin/createInteractiveVideo/createInteractiveVideo.component';
import {ThatsallComponent} from './afterLogin/thatsall/thatsall.component';
import {PublicVideoComponent} from './afterLogin/public-video/public-video.component';
import {FollowingComponent} from './afterLogin/following/following.component';
import {ExplorePageComponent} from './beforeLogin/explore/explore.component';
import {FollowersComponent} from './afterLogin/followers/followers.component';
import {NewLandingPageComponent} from './beforeLogin/new-landing-page/new-landing-page.component';
import {UserActivitiyComponent} from './afterLogin/user-activitiy/user-activitiy.component';
import {PublicUserActivitiyComponent} from './afterLogin/public-user-activitiy/public-user-activitiy.component';
import {TrendingComponent} from './beforeLogin/trending/trending.component';
import {PrivacyPolicyComponent} from './beforeLogin/privacy-policy/privacy-policy.component';
import {TermsConditionsComponent} from './beforeLogin/terms-conditions/terms-conditions.component';
import {FaqComponent} from './beforeLogin/faq/faq.component';
import {CategoryWiseVideoComponent} from './beforeLogin/categoryWiseVideo/categoryWiseVideo.component';
import {NotificationsComponent} from './afterLogin/notifications/notifications.component';
import {LibraryComponent} from './afterLogin/library/library.component';
import {AboutComponent} from './beforeLogin/about/about.component';
import {CustomBrandComponent} from './afterLogin/custom-brand/custom-brand.component';


/************************ PART OF THE REDESIGNED PAGES ******************************************/
import {PricingComponent} from './pages/pricing/pricing.component';
import {CheckoutComponent} from './afterLogin/checkout/checkout.component';
import {PaymentConfirmationComponent} from './afterLogin/payment-confirmation/payment-confirmation.component';
import {DiscountPricingComponent} from './pages/discount-pricing/discount-pricing.component';
import {SavedNodesComponent} from './afterLogin/saved-nodes/saved-nodes.component';
import {SavedNodesAlbumComponent} from './afterLogin/saved-nodes-album/saved-nodes-album.component';
import {PublicPlaylistComponent} from './afterLogin/group/public-playlist/public-playlist.component';


// WC09242022 -- Redesigned Components
import {NewDashboardComponent} from './afterLogin/new-dashboard/new-dashboard.component';
import {NewMyGroupComponent} from './afterLogin/group/new-my-group/new-my-group.component';
import {MyGroupListComponent} from './afterLogin/group/my-group-list/my-group-list.component';
import {NewMyVideosComponent} from './afterLogin/new-my-videos/new-my-videos.component';
import {SettingsComponent} from './afterLogin/settings/settings.component';
import {GroupListComponent} from './afterLogin/group/group-list/group-list.component';
import {GroupDetailComponent} from './afterLogin/group/group-detail/group-detail.component';
import {NewSearchResultsComponent} from './afterLogin/new-search-results/new-search-results.component';
import {NewPublicProfileComponent} from './afterLogin/new-public-profile/new-public-profile.component';
import {NewMyVideosPublicComponent} from './afterLogin/new-my-videos-public/new-my-videos-public.component';
import {NoAuthGuard} from './guards/no-auth.guard';
// WC09242022
// WC 12082022 -- Seller Onboarding
import {SellerOnboardingComponent} from './afterLogin/seller-onboarding/seller-onboarding.component';
import {MyPlansComponent} from './afterLogin/my-plans/my-plans.component';
import {MainCommunityComponent} from './afterLogin/main-community/main-community.component';
import {CommunityCheckoutComponent} from './afterLogin/community-checkout/community-checkout.component';
import {
  CommunityPaymentConfirmationComponent
} from './afterLogin/community-payment-confirmation/community-payment-confirmation.component';
import {SellerGuard} from './guards/seller.guard';
import {BuildCommunityComponent} from './afterLogin/build-community/build-community.component';
// import {EmbedVideoComponent} from './embed-video/embed-video/embed-video.component';
import {ContactGroupComponent} from './afterLogin/group/contact-group/contact-group.component';
import {AdminGuard} from './guards/admin.guard';
import {
  SubscriptionChangeConfirmationComponent
} from './afterLogin/subscription-change-confirmation/subscription-change-confirmation.component';

// WC 04032023
import {PartnerCheckoutComponent} from './afterLogin/partner-checkout/partner-checkout.component';
import {DelegateAccessComponent} from './pages/delegate-access/delegate-access.component';
import {
  CreateInterActiveVideo1Component
} from './afterLogin/createInteractiveVideo/create-video/create-inter-active-video1.component';
import {SecurityPolicyComponent} from "./beforeLogin/security-policy/security-policy.component";
import {MyBadgesPublicComponent} from "./afterLogin/my-badges-public/my-badges-public.component";
import { CouponComponent } from './afterLogin/coupon/coupon.component';
import { EditCouponComponent } from './afterLogin/coupon/edit-coupon/edit-coupon.component';
import { QuestionnairesDashboardComponent } from './afterLogin/questionnaires-dashboard/questionnaires-dashboard.component';
import { QuestionnaireDetailsComponent } from './afterLogin/questionnaire-details/questionnaire-details.component';
import { SamlComponent } from './pages/saml/saml.component';
import { UploadTemplateVideosComponent } from './afterLogin/upload-template-videos/upload-template-videos.component';
import { LeadGenAnalyticsComponent } from './afterLogin/lead-gen-analytics/lead-gen-analytics.component';
import { AiVideoCreatorComponent } from './afterLogin/createInteractiveVideo/ai-video-creator/ai-video-creator.component';
import { BadgesComponent } from './afterLogin/badges/badges.component';
import { CloneCommunityComponent } from './pages/clone-community/clone-community.component';
import { FeatureGroupOverviewComponent } from './pages/feature-group-overview/feature-group-overview.component';
import { FeatureRequestGroupInfoComponent } from './pages/feature-request-group-info/feature-request-group-info.component';

/**
 * Routes
 */
const APP_ROUTES: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/new-front/pages.module').then((m) => m.PagesModule),
  },
  {
    path: 'embed',
    loadChildren: () => import('./embed-video/embed-video.module').then((m) => m.EmbedVideoModule)
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [NoAuthGuard],
    data: {
      // animation: 'Home',
      title: 'Log In'
    }
  },
  {
    path: 'signup',
    component: SignupComponent,
    canActivate: [NoAuthGuard],
    data: {
      // animation: 'Home',
      title: 'Sign Up'
    }
  },
  {
    path: 'forgotPassword',
    component: ResetPasswordComponent,
    canActivate: [NoAuthGuard],
    data: {
      // animation: 'Home',
      title: 'Forgot password'
    }
  },
  {
    path: 'resetPassword',
    component: ForgetPasswordComponent,
    data: {
      // animation: 'Home',
      title: 'Reset password'
    }
  },
  {
    path: 'otp',
    component: OtpComponent,
    canActivate: [RoleGuardService],
    data: {
      // animation: 'Home',
      title: 'OTP'
    }
  },{
    path: 'delegate',
    component: DelegateAccessComponent,
    canActivate: [AuthGuardService, AdminGuard],
    data: {
      title: 'Delegate Access'
    }
  },
  {
    path: 'cloneCommunity',
    component: CloneCommunityComponent,
    canActivate: [AuthGuardService, AdminGuard],
    data: {
      title: 'Clone a Community'
    }
  },
  // {
  //   path: 'featureGroupOverview/:id',
  //   component: FeatureGroupOverviewComponent,
  //   data: {
  //     title: 'Feature Group Overview'
  //   }
  // },
  // {
  //   path: 'requestGroupInfo/:id',
  //   component: FeatureRequestGroupInfoComponent,
  //   data: {
  //     title: 'Request Group Info'
  //   }
  // },
  {
    path: 'landing-page',
    component: NewLandingPageComponent,
    data: {
      // animation: 'Home',
      title: 'FreeFuse | Interactive Streaming'
    }
  },
  {
    path: 'explore',
    component: ExplorePageComponent,
    data: {
      // animation: 'Home',
      title: 'Explore'
    }
  },

  {
    path: 'category',
    component: CategoryWiseVideoComponent,
    data: {
      // animation: 'Home',
      title: 'Category'
    }
  },

  {
    path: 'trending',
    component: TrendingComponent,
    data: {
      // animation: 'Home',
      title: 'Trending'
    }
  },
  {
    path: 'security-policy',
    component: SecurityPolicyComponent,
    data: {
      // animation: 'Home',
      title: 'Security Policy'
    }
  },
  {
    path: 'privacy',
    component: PrivacyPolicyComponent,
    data: {
      // animation: 'Home',
      title: 'Privacy'
    }
  },
  {
    path: 'terms',
    component: TermsConditionsComponent,
    data: {
      // animation: 'Home',
      title: 'Terms'
    }
  },
  {
    path: 'about',
    component: AboutComponent,
    data: {
      // animation: 'Home',
      title: 'About'
    }
  },
  {
    path: 'faq',
    component: FaqComponent,
    data: {
      // animation: 'Home',
      title: 'FAQ'
    }
  },
  {
    path: 'thats-all',
    component: ThatsallComponent,
    canActivate: [AuthGuardService],
    data: {
      // animation: 'Home',
      title: 'Thats All'
    }
  },
  {
    path: 'complete-profile',
    component: ProfileCompletionComponent,
    canActivate: [AuthGuardService],
    data: {
      // animation: 'Home',
      title: 'Complete Profile'
    }
  },
  /*{
    path: 'dashboard',
    component: NewDashboardComponent,
    canActivate: [AuthGuardService],
    data: {
      //animation: 'Home',
      title: 'Dashboard'
    }
  },*/
  {
    path: 'profile',
    component: NewDashboardComponent,
    canActivate: [AuthGuardService],
    data: {
      // animation: 'Home',
      title: 'Profile'
    }
  },
  {
    path: 'coupon',
    component: CouponComponent,
    canActivate: [AuthGuardService],
    data: {
      title: 'Coupon'
    }
  },
  {
    path: 'coupon/:id',
    component: EditCouponComponent,
    data: {
      title: 'Coupon'
    }
  },
  {
    path: 'group-completion/:id',
    component: GroupListComponent,
    canActivate: [AuthGuardService],
    data: {
      // animation: 'Group',
      title: 'Group Completion',
    },
  },
  {
    path: 'group-detail/:id',
    component: GroupDetailComponent,
    canActivate: [AuthGuardService],
    data: {
      // animation: 'Group',
      title: 'Group Detail',
    },
  },
  {
    path: 'upload-videos',
    component: UploadVideosComponent,
    canActivate: [AuthGuardService],
    data: {
      // animation: 'Home',
      title: 'Upload a Video'
    }
  },
  {
    path: 'upload-template-videos',
    component: UploadTemplateVideosComponent,
    canActivate: [AuthGuardService],
    data: {
      // animation: 'Home',
      title: 'Upload Template Video'
    }
  },
  {
    path: 'saved-nodes',
    component: SavedNodesComponent,
    canActivate: [AuthGuardService],
    data: {
      // animation: 'Home',
      title: 'Saved Nodes'
    }
  },
  {
    path: 'saved-nodes-album/:id',
    component: SavedNodesAlbumComponent,
    canActivate: [AuthGuardService],
    data: {
      // animation: 'Home',
      title: 'Saved Albums'
    }
  },
  {
    path: 'my-group/:id',
    component: NewMyGroupComponent,
    // canActivate: [AuthGuardService],
    data: {
      // animation: 'Home',
      title: 'New My Group'
    }
  },
  {
    path: 'my-groups',
    component: MyGroupListComponent,
    canActivate: [AuthGuardService],
    data: {
      // animation: 'Home',
      title: 'My Groups'
    }
  }, {
    path: 'my-plans',
    component: MyPlansComponent,
    canActivate: [AuthGuardService, SellerGuard],
    data: {
      // animation: 'Home',
      title: 'My Plans'
    }
  },
  {
    path: 'settings',
    component: SettingsComponent,
    canActivate: [AuthGuardService],
    data: {
      // animation: 'Home',
      title: 'Edit Profile'
    }
  },
  {
    path: 'create-interactive-video1',
    component: CreateInteractiveVideoComponent,
    canActivate: [AuthGuardService],
    data: {
      // animation: 'Home',
      title: 'Create Interactive Videos'
    }
  }, {
    path: 'create-interactive-video',
    component: CreateInterActiveVideo1Component,
    canActivate: [AuthGuardService],
    data: {
      // animation: 'Home',
      title: 'Create Interactive Videos'
    }
  },
  {
    path: 'ai-video-creator',
    component: AiVideoCreatorComponent,
    canActivate: [AuthGuardService],
    data: {
      title: 'AI Video Creator'
    }
  },
  {
    path: 'classic-videos',
    component: PublicVideoComponent,
    data: {
      // animation: 'Home',
      title: 'Classic Video Player'
    }
  }, {
    path: 'videos',
    data: {
      // animation: 'Home',
      title: 'Video Player'
    },
    loadChildren: () => import('./pages/main-video/main-video.module').then((m) => m.MainVideoModule),
    
  }, 
  {
    path: 'video-editor',
    canActivate: [AuthGuardService],
    data: {
      title: 'Video Editor'
    },
    loadChildren: () => import('./afterLogin/video-editor/video-editor.module').then((m) => m.VideoEditorModule),
  },
  {
    path: 'my-videos',
    component: NewMyVideosComponent,
    canActivate: [AuthGuardService],
    data: {
      // animation: 'Home',
      title: 'My Videos'
    }
  },
  {
    path: 'questionnaires-dashboard/:id',
    component: QuestionnairesDashboardComponent,
    canActivate: [AuthGuardService],
    data: {
      // animation: 'Home',
      title: 'Questionnaires Dashboard'
    }
  },
  {
    path: 'questionnaires-dashboard-details',
    component: QuestionnaireDetailsComponent,
    canActivate: [AuthGuardService],
    data: {
      title: 'Questionnaires Details'
    }
  },
  // {
  //   path: 'create-group',
  //   component: CreateGroupComponent,
  //   canActivate: [AuthGuardService],
  //   data: {
  //     // animation: 'Home',
  //     title: 'Create Group'
  //   }
  // },
  {
    path: 'public-profile',
    component: NewPublicProfileComponent,

    data: {
      // animation: 'Home',
      title: 'Public Profile'
    }
  },
  {
    path: 'my-videos-public',
    component: NewMyVideosPublicComponent,

    data: {
      // animation: 'Home',
      title: 'My Videos'
    }
  },  {
    path: 'my-badges-public',
    component: MyBadgesPublicComponent,

    data: {
      // animation: 'Home',
      title: 'My Badges'
    }
  },
  {
    path: 'search-results',
    component: NewSearchResultsComponent,
    data: {
      // animation: 'Home',
      title: 'Search results'
    }
  },
  {
    path: 'following',
    component: FollowingComponent,
    canActivate: [AuthGuardService],
    data: {
      // animation: 'Home',
      title: 'Following'
    }
  },
  {
    path: 'followers',
    component: FollowersComponent,
    canActivate: [AuthGuardService],
    data: {
      // animation: 'Home',
      title: 'Followers'
    }
  },
  {
    path: 'user-activity',
    component: UserActivitiyComponent,
    canActivate: [AuthGuardService],
    data: {
      // animation: 'Home',
      title: 'User Activity'
    }
  },
  {
    path: 'badges',
    component: BadgesComponent,
    canActivate: [AuthGuardService],
    data: {
      // animation: 'Home',
      title: 'Badges'
    }
  },
  {
    path: 'public-user-activity',
    component: PublicUserActivitiyComponent,
    canActivate: [AuthGuardService],
    data: {
      // animation: 'Home',
      title: 'User Activity'
    }
  },
  {
    path: 'notifications',
    component: NotificationsComponent,
    canActivate: [AuthGuardService],
    data: {
      // animation: 'Home',
      title: 'Notifications'
    }
  },
  {
    path: 'library',
    component: LibraryComponent,
    canActivate: [AuthGuardService],
    data: {
      // animation: 'Home',
      title: 'Library'
    }
  },
  {
    path: 'leads',
    component: LeadGenAnalyticsComponent,
    canActivate: [AuthGuardService],
    data: {
      title: 'Analytics'
    }
  },
  {
    path: 'custom-brand',
    component: CustomBrandComponent,
    canActivate: [AuthGuardService],
    // data: { animation: 'Home' }
  },
  // WC 05132022
  {
    path: 'pricing',
    component: PricingComponent,
    data: {
      // animation: 'Home',
      title: 'Pricing'
    }
  },
  {
    path: 'checkout',
    component: CheckoutComponent,
    data: {
      // animation: 'Home',
      title: 'Checkout'
    }
  },
  {
    path: 'paymentConfirmation',
    component: PaymentConfirmationComponent,
    data: {
      // animation: 'Home',
      title: 'Payment Confirmation'
    }
  },
  // WC 05132022

  // WC 07132022
  {
    path: 'discount-pricing',
    component: DiscountPricingComponent,
    data: {
      // animation: 'Home',
      title: 'Discounted Pricing'
    }
  },
  // WC 07132022

  // WC 08302022
  {
    path: 'playlist',
    component: PublicPlaylistComponent,

    data: {
      title: 'Playlist'
    }
  },
  // WC 08302022
  {
    path: 'onboarding',
    component: SellerOnboardingComponent,
    canActivate: [AuthGuardService],
    data: {
      // title: 'Seller Onboarding'
      title: 'Earn On FreeFuse'
    }
  }, {
    path: 'build-community',
    component: BuildCommunityComponent,
    canActivate: [AuthGuardService, SellerGuard],
    data: {
      title: 'Build Community'
    }
  }, {
    path: 'group-contact/:id',
    component: ContactGroupComponent,
    canActivate: [AuthGuardService],
    data: {
      title: 'Contact Group Members'
    }
  }, {
    path: 'community/:id',
    component: NewMyGroupComponent,
    data: {
      title: 'Community'
    }
  }, {
    path: 'saml/:companyId',
    component: SamlComponent,
    data: {
      title: 'SSO'
    }
  }, 
  // {
  //   path: 'main-community',
  //   component: MainCommunityComponent,
  //   data: {
  //     title: 'Community'
  //   }
  // },{
  //   path: 'main-community/:slug',
  //   component: MainCommunityComponent,
  //   data: {
  //     title: 'Community'
  //   }
  // },
  {
    path: 'my-community',
    component: MainCommunityComponent,
    data: {
      title: 'My Community'
    }
  },
  {
    path: 'staaj-health',
    component: MainCommunityComponent,
    data: {
      title: 'My Community'
    }
  }
  ,{
    path: 'my-community/:slug',
    component: MainCommunityComponent,
    data: {
      title: 'My Community'
    }
  }
  ,{
    path: 'staaj-health/:slug',
    component: MainCommunityComponent,
    data: {
      title: 'My Community'
    }
  },
  {
    path: 'community-checkout',
    component: CommunityCheckoutComponent,
    canActivate: [AuthGuardService],
    data: {
      title: 'Community Checkout'
    }
  },
  {
    path: 'community-payment-confirmation',
    component: CommunityPaymentConfirmationComponent,
    data: {
      // animation: 'Home',
      title: 'Payment Confirmation'
    }
  }, {
    path: 'manage-blogs',
    loadChildren: () => import('./pages/manage-blogs/manage-blogs.module').then((m) => m.ManageBlogsModule),
    canActivate: [AuthGuardService, AdminGuard],
    data: {
      // animation: 'Home',
      title: 'Manage blogs'
    }
  },
  {
    path: 'plan-update',
    component: SubscriptionChangeConfirmationComponent,
    data: {
      // animation: 'Home',
      title: 'Update Subscription Plan'
    }
  },
  {
    path: 'partner-checkout',
    component: PartnerCheckoutComponent,
    data: {
      title: 'Partner Checkout'
    }
  },
  {
    path: '**',
    redirectTo: '/',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(APP_ROUTES, { relativeLinkResolution: 'legacy', anchorScrolling: 'enabled', initialNavigation: 'enabledBlocking' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
