import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CreateBadgeComponent } from 'src/app/layout/modals/create-badge/create-badge.component';
import { CrudService } from 'src/app/services/crud.service';
import { MainCertificatesComponent } from '../main-certificates/main-certificates.component';
import * as html2pdf from "html2pdf.js";

declare var $: any;

@Component({
  selector: 'app-badges',
  templateUrl: './badges.component.html',
  styleUrls: ['./badges.component.css']
})
export class BadgesComponent implements OnInit {
  spinner:boolean = false;
  currentBadgeType: "creation" | "earned" = "creation";
  earnedBadgeCount = 0;
  creationBadgeCount = 0;
  private limit = 18;
  private page = 1;
  private sortBy = "recent";
  allBadges = [];
  selectedEarnBadge: any = {};
  isShowObjective:boolean = false;
  selectedHoverBadge:any
  selectedBadgeForCertificate:any;
  certificateArray:any[] = [
    {id: 1, image: "../../../assets/certificates/certificate01.png"},
    {id: 2, image: "../../../assets/certificates/certificate02.png"},
    {id: 3, image: "../../../assets/certificates/certificate03.png"},
    {id: 4, image: "../../../assets/certificates/certificate04.png"},
    {id: 5, image: "../../../assets/certificates/certificate05.png"},
    {id: 6, image: "../../../assets/certificates/certificate06.png"},
  ]

  constructor(
    private apiService:CrudService,
    private dialog: MatDialog,
  ) {
   }

  ngOnInit(): void {
    this.getBadgeCount();
    this.getBadges();
  }

  onBadgeTypeChange(type) {
    this.currentBadgeType = type;
    this.getBadges(true);
  }

  getBadges(reset?) {
    this.spinner = true;
    if (reset) {
      this.page = 1;
      this.allBadges = [];
      this.getBadgeCount();
    }
    const object: any = {
      page: this.page,
      limit: this.limit,
      sort: this.sortBy,
      type: this.currentBadgeType,
    };
    this.apiService
      .getBadge(object)
      .subscribe((res: any) => {
        this.allBadges.push(...res.data);
        // this.badgeCount = res.data.length;
      })
      .add(() => (this.spinner = false));
  }

  getBadgeCount() {
    this.apiService.getBadgeCount({}).subscribe((response: any) => {
      this.earnedBadgeCount = response.data.find((x) => x.type === "earned")?.count || 0;
      this.creationBadgeCount = response.data.find((x) => x.type === "creation")?.count || 0;
    });
  }

  onScroll(): void {
    ++this.page;
    this.getBadges();
  }

  openCreateBadge(badge?) {
    this.selectedEarnBadge = badge;
    
    if (badge && this.currentBadgeType === "earned") {
      $("#earnedBadgeModal").modal("show");
      return;
    }
    const dialogRef = this.dialog.open(CreateBadgeComponent, {
      width: "100%",
      maxWidth: "1000px",
      height: "auto",
      panelClass: 'createBadgeModal',
      autoFocus: false,
      data: {
        badge,
      },
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.currentBadgeType = "creation";
        this.getBadges(true);
      }
    });
  }

  showObjectivesPopup(data: any) {
      this.selectedHoverBadge = data
      this.isShowObjective = true;
  }

  hideObjectivesPopup() {
            
      if(!this.isShowObjective){
        this.isShowObjective = false;
        this.selectedHoverBadge = null;
      }
  }

  showPopupWithObjective(){
      this.isShowObjective = true
  }

  hidePopupWithObjective(){
      this.isShowObjective = false
      this.selectedHoverBadge = null;
  }

  openModalForCertificate(badge:any){
    this.selectedBadgeForCertificate = badge;
    $('#certificateBackgroundImages').modal('show');

  }

  modalForSelectCertificate(img:any){
    $("#certificateBackgroundImages").modal('hide')
    let data = {
      badge : this.selectedBadgeForCertificate,
      image : img.image
    }
      let dialogRef = this.dialog.open(MainCertificatesComponent, {
      width: '1000px',
      data: data
    })

    dialogRef.afterClosed().subscribe((result)=>{
      if(result){
        html2pdf()
        .from(result.elementWithCorrectedUrl)
        .set(result.options)
        .save();
      }
    })
  }
}
