import {Component, Inject, OnInit} from '@angular/core';
import {environment} from 'src/environments/environment';
import {CrudService} from 'src/app/services/crud.service';
import {ActivatedRoute, Router} from '@angular/router';
import {DOCUMENT} from '@angular/common';
import {fadeInOutAnimation} from 'src/app/ngifAnimation';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {DashboardService} from '../../services/dashboard.service';
import {LocalStorageService} from '../../services/localStorage.service';
import {ToastrService} from 'ngx-toastr';

declare var Stripe;
declare var $;

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css'],
  animations: [fadeInOutAnimation]
})

export class CheckoutComponent implements OnInit {

  //private clientSecret: any = '';
  promoCode: string = '';
  isAppliedPromoCode: boolean = false;
  isPlanUpgrade = false;
  discountPecentage: number;

  constructor(
      private dashboardService: DashboardService,
      private urlService: CrudService,
      private activeRoute: ActivatedRoute,
      private localStorageService: LocalStorageService,
      private router: Router,
      @Inject(DOCUMENT) private document,
      private toaster: ToastrService,
  ) {

    // this.activeRoute.queryParams.subscribe(params => {
    //   // console.log("params.subscription_clientSecret= ", params.subscription_clientSecret);
    //   this.clientSecret = params.subscription_clientSecret;
    // });
  }

  private stripe = Stripe(environment.Stripe_PK);
  private elements: any;
  spinner = false;
  shoppingCart: any;
  shoppingCartId: any;
  isShoppingCartShown: any = false;
  firstName: any = '';
  lastName: any = '';
  email: any = '';
  payCustomerId: any = '';
  totalCharge = 0;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  paymentPromoCode = '';
  paymentPriceId = '';
  upgradedPlanName = '';
  userEmail = '';
  

  ngOnInit(): void {

    // console.log("environment.Stripe_PK = ", environment.Stripe_PK);
    //console.log("this.localStorageService.upgrade_CurrentSubscriptionId", this.localStorageService.getItem('upgrade_CurrentSubscriptionId'));

    if (this.localStorageService.getItem('upgrade_CurrentSubscriptionId')) {
      this.urlService.getUserSubscriptionPlanById(this.localStorageService.getItem('upgrade_CurrentSubscriptionId')).subscribe(success => {

        //console.log("current subscription = ", success);
        //The plan of the current subscrption is not monthly Freemium and not yearly Freemium then it's a plan upgrade flow
        if (success.data.planId != '6310da4c16d7473718a9ce79' && success.data.planId != '64376b208a0bf123fc0c9ca5')
          this.isPlanUpgrade = true;
        else
          this.isPlanUpgrade = false;
      });
    }

    this.isShoppingCartShown = false;

    if (this.localStorageService.getItem('shoppingCartId')) {

      this.getShoppingCart();

    } else {
      this.dashboardService.onGetShoppingCart();
      this.dashboardService.getShoppingCarts.pipe(takeUntil(this._unsubscribeAll)).subscribe((res) => {
        if (res.length) {
          this.shoppingCart = res.find(x => !x.shoppingCartItems[0]?.shoppingCartItemDetails?.groupId);
          if (this.shoppingCart) {
            this.shoppingCartId = this.shoppingCart._id;
            this.isShoppingCartShown = true;

            //console.log("this.shoppingCart.totalDiscount = ", this.shoppingCart.totalDiscount);

            if (this.shoppingCart.shoppingCartItems.length > 0) {
              this.totalCharge = (this.shoppingCart.totalAmount || 0) - (this.shoppingCart.totalDiscount || 0);
              //console.log("this.totalCharge = ", this.totalCharge);
            } else {
              this.shoppingCart.totalAmount = 0;
              this.shoppingCart.totalDiscount = 0;
              this.totalCharge = 0;
            }
          } else {
            this.router.navigate(['/community-checkout'])
          }
        }
        //console.log('FF shopping cart', this.shoppingCart)
      });
    }

    // get user first and last names for billing purposes
    this.urlService.userDetailsV2().subscribe(success => {
      this.firstName = success.data.details.firstname;
      this.lastName = success.data.details.lastname;
      this.email = success.data.details.email;
      this.payCustomerId = success.data.details.payCustomerId;
    })

    // document.getElementById('payment-form')

  }

  onApplyPromoCode() {
    if(this.promoCode) {

      const payload = {
        name: this.promoCode,
        subscriptionId: this.localStorageService.getItem('upgrade_CurrentSubscriptionId')
      };

      this.urlService.validatePromoCode(payload).subscribe((res: any) => {
          //console.log("onApplyPromoCode - res = ", res);

          this.discountPecentage = res.data.info.coupon.percent_off;
          this.isAppliedPromoCode = true;
          this.shoppingCart.totalDiscount = (res.data.info.coupon.percent_off/100) * this.totalCharge;
          this.shoppingCart.totalDiscount = Math.floor(this.shoppingCart.totalDiscount * 100) / 100; //To display 2-digit decimal without rounding up
          //console.log("onApplyPromoCode - this.shoppingCart.totalDiscount = ", this.shoppingCart.totalDiscount);
          this.totalCharge -= this.shoppingCart.totalDiscount;
          this.shoppingCart.promoCodeId = res.data._id;
          this.paymentPromoCode = res.data.promoCodeId;

      }, (err) => {

        this.isAppliedPromoCode = false;
              this.shoppingCart.totalDiscount = 0.00;
              //console.log("onApplyPromoCode - this.shoppingCart.totalDiscount = ", this.shoppingCart.totalDiscount);
              this.totalCharge -= this.shoppingCart.totalDiscount;
              this.shoppingCart.promoCodeId = "";
              this.paymentPromoCode = "";
              this.discountPecentage = null;
              this.promoCode = "";
      });
    }
 };

  onClose() {
    this.promoCode = null;
  }

  deleteItem(itemNo: any) {

    // {
    //   "shoppingCartId": "62b53ebe5c213943dbe04961",
    //   "items": [
    //       "627e7191f76daf5009dc2ae7"
    //   ]
    // }


    this.spinner = true;

    const payload = {
      shoppingCartId: this.localStorageService.getItem('shoppingCartId'),
      items: [itemNo]
    }

    this.urlService.deleteShoppingCartItems(payload).subscribe(success => {
      this.urlService.deactivateShoppingCart({shoppingCartId: this.shoppingCartId}).subscribe(() => {
        // console.log("After deleting items, shopping cart = ", success.data);

        this.localStorageService.removeItem('shoppingCartId');

        this.spinner = false;
        // this.router.navigate(['checkout']);
        // window.location.reload();

        this.router.navigate(['pricing'])
      });
    });
  }

  getShoppingCart() {

    this.shoppingCartId = this.localStorageService.getItem('shoppingCartId');

    this.urlService.getShoppingCart(this.shoppingCartId).subscribe(success => {
      this.shoppingCart = success.data;
      this.shoppingCartId = success.data._id;

      if (this.shoppingCart.shoppingCartItems.length > 0) {
        this.totalCharge = this.shoppingCart.totalAmount - this.shoppingCart.totalDiscount;
        // console.log("this.totalCharge = ", this.totalCharge);
      } else {
        this.shoppingCart.totalAmount = 0;
        this.shoppingCart.totalDiscount = 0;
        this.totalCharge = 0;
      }

      this.upgradedPlanName = this.shoppingCart.shoppingCartItems[0].shoppingCartItemDetails.name + " plan";
      this.isShoppingCartShown = true;
      // console.log("this.shoppingCart = ", this.shoppingCart);
    })

  }

  deleteCurrentSubscription() {

    //console.log("deleteCurrentSubscription");

    //WC 11/15/2023 Delete the current subscription
    const payload = {
      subscriptionId: this.shoppingCart.subscriptionId,
      isCancelNow: true
    }

    this.urlService.cancelPaySubscription(payload).subscribe(success => {
      //console.log("onApplyPromoCode - cancelPaySubscription - success = ", success);
      this.shoppingCart.subscriptionId = null,
      this.shoppingCart.clientSecret = null,
      this.shoppingCart.promoCodeId = null

      const payload1 = {
          shoppingCartId: this.shoppingCartId,
          clientSecret: null,
          subscriptionId: null,
          promoCodeId: null
      };

      this.urlService.saveClientSecretToShoppingCart(payload1).subscribe();
    });
    //WC 11/15/2023
  }

  // WC 03132022 - initializePayment will create Stripe payment intent based on the shopping cart id
  // Fetches a Stripe payment intent and captures the client secret
  initializePayment(planType: string) {

    //console.log("initializePayment - planType = ", planType);

    //console.log("initializePayment - this.isPlanUpgrade = ", this.isPlanUpgrade);

    if (!this.isPlanUpgrade) {

      if (planType === 'subscriptionPlanZero') {
        this.localStorageService.setItem('subscriptionPlanZero', 'true');
  
        // console.log("!!! planType is subscriptionPlanZero - navigate to paymentConfirmation");
  
        this.router.navigateByUrl('/paymentConfirmation');
        return;
      }
  
      this.spinner = true;
      this.isShoppingCartShown = false;
  
      //From previous session where payment is not completed but subscription has been created
      if (this.shoppingCart.subscriptionId)
        this.deleteCurrentSubscription();
  
      //Create a new subscription with or without promo code
      this.paymentPriceId = this.shoppingCart.shoppingCartItems[0].shoppingCartItemDetails.paymentPriceId;
  
      const getPayload = () => {
        if (this.isAppliedPromoCode) {
          return {
            payCustomerId: this.payCustomerId,
            paymentPriceId: this.paymentPriceId,
            promotionCode: this.paymentPromoCode
          }
        } else {
          return {
            payCustomerId: this.payCustomerId,
            paymentPriceId: this.paymentPriceId,
          }
        }
      }
  
      this.urlService.createPaySubscription(getPayload()).subscribe(success => {
  
        //console.log('createPaySubscription is successful, success.data = ', success);
  
        this.localStorageService.setItem('subscriptionId', success.data.subscriptionId);
        this.localStorageService.setItem('clientSecret', success.data.clientSecret);
  
        $('#payment-term-agreement-Modal').modal('hide');
    
        // Hide the payment terms
        document.getElementById('payment-term-agreement-Modal').classList.add('hidden');
    
        // Show the payment form
        document.getElementById('selected-plan-container').classList.remove('hidden');
        document.getElementById('payment-container').classList.remove('hidden');
    
        // Initialize Stripe payment element
        const clientSecret = success.data.clientSecret;
    
        const appearance = {
          theme: 'stripe',
          variables: {
            fontFamily: 'Roboto, "Helvetica Neue", sans-serif',
          }
        };
    
        // console.log("clientSecret = ", clientSecret);
    
        this.elements = this.stripe.elements({appearance, clientSecret});
    
        // Create and mount the payment element
        const paymentElement = this.elements.create('payment');
    
        paymentElement.mount('#payment-element');
    
        this.spinner = false;
      })
    } else {
      //Plan upgrade
      //console.log("PlanUpgrade");

      this.spinner = true;

      this.dashboardService.getUserData.pipe(takeUntil(this._unsubscribeAll)).subscribe((res) => {
        if (res) {
          //console.log("getUserData - res = ", res);
          this.userEmail = res.email;
        }
      });

      const getPayload = () => {
        if (this.isAppliedPromoCode) {
          return {
            newPlanId : this.shoppingCart.shoppingCartItems[0].shoppingCartItemDetails._id,
            subscriptionId : this.localStorageService.getItem('upgrade_CurrentSubscriptionId'),
            promotionCode: this.paymentPromoCode
          }
        } else {
          return {
            newPlanId : this.shoppingCart.shoppingCartItems[0].shoppingCartItemDetails._id,
            subscriptionId : this.localStorageService.getItem('upgrade_CurrentSubscriptionId'),
          }
        }
      }
  
      this.urlService.updateUserSubscriptionPlan(getPayload()).subscribe(success => {
        //console.log("updateUserSubscriptionPlan - success = ", success);
        this.deactivateShoppingCart();

        //Synch up the user data to get the latest subscription id
        this.dashboardService.onGetUserData();

        this.spinner = false;

        $('#plan-upgrade-confirmation-Modal').modal('show');
      }, error => {

        this.promoCode = '';
        this.isAppliedPromoCode = false;
        this.discountPecentage = 0;
        this.shoppingCart.totalDiscount = 0;
        this.totalCharge = this.shoppingCart.totalAmount - this.shoppingCart.totalDiscount;
        this.spinner = false;
      });
    }

  };


  deactivateShoppingCart() {

    //console.log("deactiveShoppingCart - this.shoppingCart.id = ", this.localStorageService.getItem('shoppingCartId'));

    const payload = {shoppingCartId : this.localStorageService.getItem('shoppingCartId')};

        this.urlService.deactivateShoppingCart(payload).subscribe(success => {
          this.localStorageService.removeItem('shoppingCartId');
          // console.log("Successfully deactivate shopping cart id ", this.shoppingCartId);
          this.dashboardService.onGetShoppingCart();
        });
  };

  onSubmitPayment() {
    this.handleSubmit(this.elements, this.document)
  };

  async handleSubmit(elements: any, document: any) {

    this.setLoading(true, document);

    const result = await this.stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: environment.Success_Payment_Redirect
      },
    });

    //console.log('stripe.confirmPayment result = ', result);

    // Inform the customer that there was an error.
    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (result && (result.error.type === 'card_error' || result.error.type === 'validation_error' || result.error.type === 'invalid_request_error')) {
      this.showMessage(result.error.message, document);
      this.setLoading(false, document);
    } else {
      this.showMessage('An unexpected error occured.', document);
      this.setLoading(false, document);
    }
  }

  goToProfilePage() {
    this.router.navigateByUrl('/profile');
  }

// ------- UI helpers -------

showMessage(messageText: any, document: any) {
  const messageContainer = document.getElementById('payment-message');

  messageContainer.classList.remove('hidden');
  messageContainer.textContent = messageText;

  setTimeout(() => {
    messageContainer.classList.add('hidden');
    messageContainer.textContent = '';
  }, 4000);
}

// Show a spinner on payment submission
setLoading(isLoading: boolean, document: any) {

  if (isLoading) {
    // Disable the button and show a spinner
    document.getElementById('submit').disabled = true;
    document.getElementById('spinner').classList.remove('hidden');
    document.getElementById('button-text').classList.add('hidden');
  } else {
    document.getElementById('submit').disabled = false;
    document.getElementById('spinner').classList.add('hidden');
    document.getElementById('button-text').classList.remove('hidden');
  }
}
}






