import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { DeleteConfirmationComponent } from 'src/app/layout/modals/delete-confirmation/delete-confirmation.component';
import { CrudService } from 'src/app/services/crud.service';

interface ModalData {
  couponData: {
    couponName: string,
    couponId: string;
    info: Info;
    _id?: string;
  };
}

interface Info {
  percent_off: number;
  duration: string;
  name: string;
  duration_in_months: number;
}

@Component({
  selector: 'app-create-coupon',
  templateUrl: './create-coupon.component.html',
  styleUrls: ['./create-coupon.component.css']
})
export class CreateCouponComponent implements OnInit {

  @ViewChild('deleteDialogRef') deleteDialogRef: DeleteConfirmationComponent;
  createCouponForm: FormGroup;
  title: string = 'Create Coupon';
  durationOptions = [
    {
      name: 'Once',
      value: 'once'
    },
    {
      name: 'Repeating',
      value: 'repeating'
    },
    {
      name: 'Forever',
      value: 'forever'
    }
  ];
  processing: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ModalData,
    public dialogRef: MatDialogRef<CreateCouponComponent>,
    private urlService: CrudService,
    private toastr: ToastrService
  ) {
    this.createCouponForm = new FormGroup({
      name: new FormControl(null, [Validators.required, Validators.maxLength(15)]),
      percent_off: new FormControl(50, [Validators.required, Validators.min(1), Validators.max(100)]),
      duration: new FormControl(null, [Validators.required]),
      duration_in_months: new FormControl(null),
    });
    if(this.data.couponData) {
      const couponData = this.data.couponData;
      if(couponData.couponName) {
        this.title = 'Edit Coupon';
        this.createCouponForm.patchValue({
          name: couponData.couponName,
          percent_off: couponData.info.percent_off,
          duration: couponData.info.duration,
          duration_in_months: couponData.info.duration_in_months
        });
      }
      this.createCouponForm.get('percent_off').disable();
      this.createCouponForm.get('duration').disable();
      this.createCouponForm.get('duration_in_months').disable();
    }
  }

  ngOnInit(): void {
    this.createCouponForm.get('duration').valueChanges.subscribe((durationValue) => {
      const durationInMonthsControl = this.createCouponForm.get('duration_in_months');
      if (durationValue === 'repeating') {
        durationInMonthsControl.setValidators([Validators.required]);
      } else {
        durationInMonthsControl.clearValidators();
      }
      durationInMonthsControl.updateValueAndValidity();
    });
  }

  onSubmit() {
    if(this.createCouponForm.valid) {
      this.processing = true;
      let formData = {};
      if(this.data?.couponData.couponId) {
        formData['id'] = this.data.couponData._id;
        formData['name'] = this.createCouponForm.value.name;
        this.urlService.updateCoupon(formData).subscribe((res) => {
          this.dialogRef.close(res);
          this.processing = false;
          this.toastr.success('Coupon updated successfully !!');
        }, (err) => {
          this.dialogRef.close();
          this.processing = false;
          this.toastr.error('Coupon updation failed !!');
        })
      } else {
        formData = Object.assign({}, this.createCouponForm.value);
        this.urlService.createCoupon(formData).subscribe((res) => {
          this.dialogRef.close(res);
          this.processing = false;
          this.toastr.success('Coupon created successfully !!');
        }, (err) => {
          this.dialogRef.close();
          this.processing = false;
          this.toastr.error('Coupon creation failed !!');
        })
      }
    }
  }

  errorHandling = (control: string, error: string) => {
    return this.createCouponForm.controls[control].hasError(error) &&
        (this.createCouponForm.controls[control].dirty || this.createCouponForm.controls[control].touched);
  }

  openConfirmationDeleteModal(item){
    this.deleteDialogRef.open({
      header: `Delete Coupon`,
      title: `Are you sure you want to delete this Coupon?`
    }, (e) => {
      if (e) {
        this.processing = true;
        this.urlService.deleteCoupon(item._id).subscribe((success) => {
          this.processing = false;
          this.dialogRef.close('deleteCoupon');
          this.toastr.success('Coupon deleted successfully !!');
        }, (err) => {
          this.processing = false;
          this.toastr.error('Coupon deletion failed !!');
        })
      }
    });
  }

}
