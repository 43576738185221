import {Component, ElementRef, Inject, OnInit, ViewChild, Renderer2} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {CrudService} from '../../../services/crud.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {DeleteConfirmationComponent} from '../delete-confirmation/delete-confirmation.component';
import {JsConfettiService} from '../../../services/js-confetti.service';
import insertTextAtCursor from 'insert-text-at-cursor';
import sanitizeSVG from "@mattkrick/sanitize-svg";
import SignaturePad from 'signature_pad';
import { MainCertificatesComponent } from 'src/app/afterLogin/main-certificates/main-certificates.component';
import * as html2pdf from "html2pdf.js";
import { LocalStorageService } from 'src/app/services/localStorage.service';

declare var $;
@Component({
    selector: 'app-create-badge',
    templateUrl: './create-badge.component.html',
    styleUrls: ['./create-badge.component.css']
})
export class CreateBadgeComponent implements OnInit {
    @ViewChild('deleteDialogRef') deleteDialogRef: DeleteConfirmationComponent;
    @ViewChild('emojiInputElement') emojiInputElement: ElementRef;
    @ViewChild('signPadCanvas', {static: false}) signaturePadElement:any;
    defaultUserMessage = 'Congratulations! You have earned a badge!';
    processing = false;
    badge: any;
    badgeForm = new FormGroup({
        name: new FormControl('', [Validators.required, Validators.maxLength(50)]),
        description: new FormControl('', [Validators.required, Validators.maxLength(200)]),
        userMessage: new FormControl(this.defaultUserMessage, [Validators.required, Validators.maxLength(500)]),
        background: new FormControl('#9370DB', []),
        type: new FormControl('creation'),
        emojis: new FormControl('', [Validators.maxLength(200)]),
        pic: new FormControl('', [Validators.required]),
        signatureTitle: new FormControl(''),
        signatureImage: new FormControl(''),
        // certificateBackground: new FormControl('#ffffff', []),
        isGenerateCertificate: new FormControl(false),
    });
    imgSrc: string | ArrayBuffer;
    isImage: boolean;
    isOpen: boolean;
    emojiRegex = /(\p{Emoji_Presentation}|\p{Emoji}\uFE0F|\p{Emoji_Modifier_Base}\p{Emoji_Modifier}?|\p{Emoji_Modifier_Base})+/gu;
    private selectedFile: any;
    private isFormSubmitted = false;
    signatureImageSrc:any;
    signatureImageFile:any;
    signPad: any;
    signatureImageError:any;
    certificateArray:any[] = [
        {id: 1, image: "../../../assets/certificates/certificate01.png"},
        {id: 2, image: "../../../assets/certificates/certificate02.png"},
        {id: 3, image: "../../../assets/certificates/certificate03.png"},
        {id: 4, image: "../../../assets/certificates/certificate04.png"},
        {id: 5, image: "../../../assets/certificates/certificate05.png"},
        {id: 6, image: "../../../assets/certificates/certificate06.png"},
    ]
    currentUserId:any;
    
    constructor(
        @Inject(MAT_DIALOG_DATA) public data,
        private jsConfettiService: JsConfettiService,
        private toaster: ToastrService,
        private crudService: CrudService,
        private dialogRef: MatDialogRef<CreateBadgeComponent>,
        private dialog: MatDialog,
        private localStorageService: LocalStorageService,
        private renderer: Renderer2
    ) {
        this.currentUserId = this.localStorageService.getItem("user");
        if (data.badge) {
            this.badge = data.badge;
            
            this.badgeForm.patchValue({
                name: this.badge.name,
                description: this.badge.description,
                userMessage: this.badge.userMessage,
                type: this.badge.type,
                background: this.badge.background,
                emojis: this.badge.emojis,
                signatureTitle: this.badge.signatureTitle,
                isGenerateCertificate: this.badge?.isGenerateCertificate ? this.badge?.isGenerateCertificate : false,
                certificateBackground: this.badge.certificateBackground ? this.badge?.certificateBackground : '#ffffff'
            });
            if (this.badge.pic) {
                // this.badgeForm.patchValue({
                //     pic: this.badge.pic.split('?')[0]
                // });
                this.badgeForm.get('pic').clearValidators();
            }
            if (this.badge.signatureImage) {
                this.badgeForm.get('signatureImage').clearValidators();
                this.signatureImageSrc = this.badge.signatureImage
            }
        }
    }

    ngOnInit(): void {
    }

    ngAfterViewInit() {
        this.signPad = new SignaturePad(this.signaturePadElement.nativeElement);
        console.log('this.signPad: ', this.signPad);
    }

    onSelectFile(event: any) {
        const file = event.target.files[0];
        if (file.size > 10485760) {
            this.toaster.error('File size should be less than 10MB');
            return;
        }
        if (file.type === 'image/png' || file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/gif') {
            const img = new Image()
            img.src = window.URL.createObjectURL(file)
            img.onload = async () => {
                // if (img.width > 70 || img.height > 70) {
                //     this.toaster.info('Minimum image size should be 70 x 70');
                // } else {
                    const cleanImage = await sanitizeSVG(file)
                    if (!cleanImage) {
                        this.toaster.error('invalid SVG file');
                        return;
                    }
                    const reader = new FileReader();
                    reader.onload = e => this.imgSrc = reader.result;
                    reader.readAsDataURL(file);
                    this.isImage = true;
                    this.selectedFile = cleanImage;
                }
            // }
        } else {
            this.toaster.info('Please select a PNG or JPG image file.')
        }
    }
    onSubmit() {
        this.isFormSubmitted = true;
        if(this.badgeForm.value?.isGenerateCertificate){
            if(!this.signatureImageSrc){
                this.signatureImageError = 'Signature Image is required!'
            }
        }
        
        if (this.badgeForm.valid && !this.signatureImageError) {
            this.processing = true;

            
            const obj = this.badgeForm.value;

            if(!obj?.isGenerateCertificate){
                obj.signatureImage = ''
                obj.signatureTitle = ''
            }
    
            // Function to handle saving the badge
            const saveBadge = () => {
                this.processing = true;
                // console.log("obj", obj);
                
                this.crudService.setBadge(obj).subscribe(res => {
                    this.processing = false;
                    this.toaster.success(`Badge ${this.badge ? 'updated' : 'created'} successfully !!`);
                    this.dialogRef.close(true);
                });
            };
    
            // Check if a new badge is being created or an existing one is being updated
            if (this.badge) {
                obj.id = this.badge._id;
            } else {
                // If creating a new badge, ensure selectedFile is present
                if (!this.selectedFile) {
                    this.toaster.error('Image file is required');
                    return;
                }
            }

    
            // Upload selected file if it exists
            if (this.selectedFile) {
                const uploadSelectedFile = new FormData();
                uploadSelectedFile.append('file', this.selectedFile);
    
                this.crudService.uploadBadgeThumbnail(uploadSelectedFile).subscribe((res: any) => {
                    obj.pic = (res.data.Location || '').split('?')[0];
    
                    // Check if signatureImageFile is present
                    if (this.signatureImageFile) {
                        // Upload signature image if present
                        const uploadSignatureImageFile = new FormData();
                        uploadSignatureImageFile.append('file', this.signatureImageFile);
    
                        this.crudService.uploadBadgeSignatureImage(uploadSignatureImageFile).subscribe((res: any) => {
                            obj.signatureImage = (res.data.Location || '').split('?')[0];
    
                            // Call saveBadge() after uploading both files
                            saveBadge();
                        });
                    } else {
                        // If signatureImageFile is missing, use existing signature image (if available)
                        if (this.badge && this.badge.signatureImage && this.badgeForm.value?.isGenerateCertificate) {
                            obj.signatureImage = this.badge.signatureImage.split('?')[0];
                        }
    
                        // Call saveBadge() after uploading selected file
                        saveBadge();
                    }
                });
            }else if (this.badge && this.signatureImageFile) {
                // Upload signature image if present
                const uploadSignatureImageFile = new FormData();
                uploadSignatureImageFile.append('file', this.signatureImageFile);

                this.crudService.uploadBadgeSignatureImage(uploadSignatureImageFile).subscribe((res: any) => {
                    obj.signatureImage = (res.data.Location || '').split('?')[0];
                    obj.pic = this.badge.pic.split('?')[0];
                    // Call saveBadge() after uploading both files
                    saveBadge();
                });
            } else {
                // If selectedFile is missing, handle scenarios for editing existing badge
                if (this.badge && this.badge.pic) {
                    obj.pic = this.badge.pic.split('?')[0];
                }
    
                // If signatureImageFile is missing, use existing signature image (if available)
                if (this.badge && this.badge.signatureImage && this.badgeForm.value?.isGenerateCertificate) {
                    obj.signatureImage = this.badge.signatureImage.split('?')[0];
                }
    
                // Call saveBadge() after handling scenarios for missing files
                saveBadge();
            }
        }
    }
    
    errorHandling = (control: string, error: string) => {
        return this.badgeForm.controls[control].hasError(error) &&
            (this.badgeForm.controls[control].dirty || this.badgeForm.controls[control].touched || this.isFormSubmitted);
    }

    openConfirmationDeleteBadge(item: any) {
        this.deleteDialogRef.open({
            header: `Delete Badge`,
            title: `Are you sure you want to delete this badge?`
        }, (e) => {
            if (e) {
                this.processing = true;
                this.crudService.deleteBadge(item._id).subscribe((success) => {
                    this.toaster.success(`Badge deleted successfully !!`);
                    this.dialogRef.close(true);
                }).add(() => this.processing = false);
            }
        });
    }

    previewConfetti() {
        const emojis = [...this.badgeForm.controls.emojis.value];
        // console.log(emojis)
        this.jsConfettiService.showConfetti(emojis);
    }

    addEmoji($event: any) {
        insertTextAtCursor(this.emojiInputElement.nativeElement, $event.emoji.native);
    }


    openSignaturePadModal(){
        let canvas: any = document.getElementById('signPadCanvas');
        canvas.width = 465;
        canvas.height = 200; 
        if(this.badge && this.badge.signatureImage){
                let payload:any = {
                    signatureImage: this.badge.signatureImage,
                }
                this.crudService
                .convertImageToBase64(payload)
                .subscribe((res) => {
                    const ctx = canvas.getContext('2d');
                    
                    // Base64 representation of the image
                    const base64Image = "data:image/png;base64," + res.data?.signatureBase64;
                    
                    // Create a new image element
                    const img = new Image();
                    
                    // Set the source of the image to the base64 data
                    img.src = base64Image;
                    
                    // Once the image has loaded, draw it on the canvas
                    img.onload = function() {
                        ctx.drawImage(img, 0, 0); // Draw the image at position (0, 0) on the canvas
                    };
                })
        }
        $('#signaturePadModal').modal('show')
          // Apply styles to change background color and z-index
        const formContainer = document.querySelector('.demo');

        // console.log("formContainer", formContainer);        
        if (formContainer) {
            // this.renderer.setStyle(formContainer, 'background-color', 'rgba(0,0,0,.32)');
            // this.renderer.setStyle(formContainer, 'z-index', '1050'); // Adjust the z-index as needed
        }
    }

    
    // onSubmit() {
    //     this.isFormSubmitted = true;
    //     if (this.badgeForm.valid) {
    //         this.processing = true;
    //         const obj = this.badgeForm.value;
    //         if (this.badge) {
    //             obj.id = this.badge._id;
    //         } else {
    //             if (!this.selectedFile) {
    //                 this.toaster.error('image file is required');
    //                 return;
    //             }
    //         }
    //         const saveBadge = () => {
    //             this.processing = true;
    //             this.crudService.setBadge(obj).subscribe(res => {
    //                 this.processing = false;
    //                 this.toaster.success(`Badge ${this.badge ? 'updated' : 'created'} successfully !!`);
    //                 this.dialogRef.close(true);
    //             });
    //         }
    //         if (this.selectedFile) {
    //             const formData = new FormData();
    //             formData.append('file', this.selectedFile);
    //             this.crudService.uploadBadgeThumbnail(formData).subscribe((res: any) => {
    //                 this.processing = false
    //                 obj.pic = (res.data.Location || '').split('?')[0];
    //                 saveBadge();
    //             });
    //         }
    //         else {
    //             if (this.badge?.pic) {
    //                 obj.pic = this.badge.pic.split('?')[0]
    //             }
    //             // delete obj.pic;
    //             saveBadge();
    //         }
            
    //     }
    // }

    undoSign() {
        const data = this.signPad.toData();
        if (data) {
            data.pop(); // remove the last step
            this.signPad.fromData(data);
        }
    }

    clearSignPad() {
        this.signPad.clear();
    }

    saveSignPad() {
        const base64ImageData = this.signPad.toDataURL(); // Get base64 image data
        const blob = this.dataURItoBlob(base64ImageData); // Convert base64 to Blob
        const file = new File([blob], 'signature.png', { type: 'image/png' }); // Create File object
        const img = new Image()
        img.src = window.URL.createObjectURL(file)
        img.onload = async () => {
                const reader = new FileReader();
                reader.onload = e => this.signatureImageSrc = reader.result;
                reader.readAsDataURL(file);
                this.signatureImageFile = file;
                this.signatureImageError = '';
        }
        $('#signaturePadModal').modal('hide')

    }
    
    // Function to convert data URI to Blob
    dataURItoBlob(dataURI: string) {
        const byteString = atob(dataURI.split(',')[1]);
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: mimeString });
    }

    modalForSelectCertificate(img:any){
        $("#certificateBackgroundImagesModal").modal('hide')
        
        this.badgeForm.value.signatureImage = this.signatureImageFile ? this.signatureImageFile : this.badge?.signatureImage;
        this.badgeForm.value.pic = this.selectedFile ? this.selectedFile : this.badge?.pic;

        let badgeFormData = Object.assign({userId: this.currentUserId}, this.badgeForm.value)
        let badgeData = this.badge ? this.badge : badgeFormData
        let data = {
            badge : badgeFormData,
            image : img.image
        }
        let dialogRef = this.dialog.open(MainCertificatesComponent, {
            width: '1000px',
            data: data
        })
    
        dialogRef.afterClosed().subscribe((result)=>{
            if(result){
                html2pdf()
                .from(result.elementWithCorrectedUrl)
                .set(result.options)
                .save();
            }
        })
    }

    openCertificateImagesModal(){
        // this.dialogRef.close();
        
        if ((!this.badge && !this.badgeForm.value.pic) || (!this.badgeForm.value.signatureTitle || !this.signatureImageSrc)) {
            this.toaster.info("Please enter badge image, signature title and signature image!")
            return
        }

        $('#certificateBackgroundImagesModal').modal('show');
    }

    onChangeGenerateCertificate(event:any){
        
        if(!event.target.checked){
            if(this.badge?.isGenerateCertificate){
                return
            }
                this.badgeForm.get('signatureTitle').setValue('')
                this.signatureImageSrc = '';
                this.signatureImageError = '';
                this.badgeForm.get('signatureTitle').clearValidators();
                this.badgeForm.get('signatureTitle').updateValueAndValidity();
        }
        
        if(event.target.checked){
            this.badgeForm.get('signatureTitle').setValidators([Validators.required]);
        }
    }
}
